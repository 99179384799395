<div class="container">
  <div class="row ipad-width">
    <div class="col-md-12 modal-title">
      <p>
        {{(mode === reviewOrderMode.QUICK_ORDER ? 'review_order_modal.title_quick_order' : 'review_order_modal.title' ) | translate }}
      </p>
    </div>
  </div>
  <div class="top-line"></div>
  <div class="control-container">
    <ng-container
            *ngIf="orderDetails?.nextInvoice?.summary.price != 0">
    <div class="next-invoice-title">
      {{orderDetails?.nextInvoice?.header?.title}}
    </div>
    <div *ngIf="orderDetails?.nextInvoice?.items?.length">
      <div class="row" *ngFor="let item of orderDetails?.nextInvoice?.items">
        <div class="section-item col-sm-9">
          {{item.label}}
        </div>
        <div class="section-price col-sm-3">
          {{item.price}}{{item.suffix ? item.suffix : ''}}
        </div>
      </div>
    </div>
    <div class="row section-summary" *ngIf="orderDetails?.nextInvoice?.summary; let summary">
      <div class="col-sm-8">
        {{ summary.label }}
      </div>
      <div class="section-price col-sm-4">
        <span *ngIf="summary?.arrowup" class="arrow-size">&#x25b2;</span>
        <span *ngIf="summary?.arrowdown" class="arrow-size">&#x25bc;</span>
        {{summary.price | currency: orderDetails?.currencyCode }}
      </div>
    </div>
    <div *ngIf="orderDetails?.nextInvoice?.summary?.arrowup" class="row">
      <p class="section-subtext col-sm-7" [ngbTooltip]="'review_order_modal.why_tooltip' | translate">Why is next month's invoice higher?</p>
    </div>
    <div class="section-divider"></div>
    </ng-container>
    <div class="changes-to-monthly-title" [ngClass]="{'margin-zero-next-invoice' : orderDetails?.nextInvoice?.summary.price == 0}">
      {{orderDetails?.changes?.header?.title}}
    </div>
    <ng-container *ngIf="orderDetails?.changes?.items?.length">
      <div *ngFor="let item of orderDetails?.changes?.items" class="row">
        <div class="section-item col-sm-9">
          {{item.label}}
        </div>
        <div class="section-price col-sm-3">
          {{item.price}}{{item.suffix ? item.suffix : ''}}
        </div>
      </div>
    </ng-container>
    <div class="row section-summary" *ngIf="orderDetails?.changes?.summary; let summary">
      <div class="col-sm-8">
        {{ summary.label }}
      </div>
      <div class="section-price col-sm-4">
        <span *ngIf="summary?.arrowup" class="arrow-size">&#x25b2;</span>
        <span *ngIf="summary?.arrowdown" class="arrow-size">&#x25bc;</span>
        <span>{{summary.price | currency: orderDetails?.currencyCode}}{{summary.suffix}}</span>
      </div>
    </div>
    <ng-container *ngIf="orderDetails?.chargeTo; let chargeTo">
      <div class="section-divider"></div>
      <div class="row charge-ship-to-section">
        <div class="section-item charge-ship-to-text col-sm-8">
          {{ 'review_order_modal.charge_to' | translate }}
          <img style="margin-left: 2px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAZxJREFUOBGlUz1LA0EQndnEdGlMGRAbq6viR/TEiAkkYKlWaawCYiVY+SsUK5tUWtilFS5+YC7kQ6LdVVqIYKlNrAR3nNmwsIZAFBdyu5n33tzczFuEoXXdvPO+tK5wuAREUwZGfOE9iClVLaxkI1eC9k8URYnX9/4hEOxysAWANUR4FJwIZvi5SQDLgHCSnkzue573KZhJYMRv/QskmAWFe8Xc4qmAw6sedrdB0zEhPKRTyXVJooQkbxZxPEEZV3wVdjcub7s7NpFgwhGuqZYBJd8sZcub877/bMmya9IFDbTlxgyHuaIRLdYbnSMmzBdXl3IucdyZdSFzenF+lLgV1WFB0Oj0uHtziNgcnRxr3NiKklHZbrtJJlLJNRafuTH3rBCfRGua6AL2nPe8D0Iyo7IxdycZLi8FbJLBnF14/NloWCsVBGKS8ZKfDAIt0wmU2FMcZkzicLiJ50BYZixbb7TvHQgGXPRFq4y32Z7isJt2e9oSY7HEAZe3wL8MoirbuOEwVywt2n9b+deXafDN6I+8TLY82f96nb8B+oPOa6RCIDUAAAAASUVORK5CYII=" [ngbTooltip]="'review_order_modal.charge_to_tooltip' | translate">
        </div>
        <div class="section-price col-sm-4">
          {{chargeTo}}
        </div>
      </div>
      <div class="row charge-ship-to-sub-section">
        <p class="section-subtext-invoice col-sm-12">{{ 'review_order_modal.your_invoice_shipping' | translate }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="orderDetails?.shipmentDetails?.shipTo !== ''">
      <div class="section-divider"></div>
      <div class="row charge-ship-to-section">
        <div class="section-item charge-ship-to-text col-sm-8">
          {{ 'review_order_modal.ship_to' | translate }}
        </div>
        <div class="section-price col-sm-4">
          {{orderDetails?.shipmentDetails?.shipTo}}
        </div>
        <div class="section-item charge-ship-to-text col-sm-6">
          {{ 'review_order_modal.shipping_speed' | translate }}
        </div>
        <div class="section-price col-sm-6">
          {{
          (orderDetails?.shipmentDetails?.shippingSpeed === ShippingSpeed.EXPEDITED ?
          'review_order_modal.expedited' :
          'review_order_modal.standard') | translate
          }}
        </div>
      </div>
      <div class="row charge-ship-to-sub-section">
        <p class="section-subtext-invoice col-sm-12">{{ 'review_order_modal.your_invoice_shipping' | translate }}</p>
      </div>
    </ng-container>
    <div class="bottom-line"></div>
  </div>

  <div class="row ipad-width">
    <div class="col-sm-12 footer-line">
      <div class="float-right">
        <button type="button" class="btn btn-default cancel" aria-label="Back To Edit"  (click)="backToEdit()">{{ 'review_order_modal.back_to_edit' | translate }}</button>
        <button type="button" class="btn btn-primary cancel" aria-label="Place Order"  (click)="placeOrder()">{{ 'review_order_modal.place_order' | translate }}</button>
      </div>
    </div>
  </div>
</div>
