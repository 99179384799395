import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/primeng';
import { Table } from 'primeng/table';

export interface SelectionChange {
  eventType: string;
  data?: any;
}

export const EventType = {
  rowSelect: 'RowSelect',
  rowUnelect: 'RowUnselect',
  selectAll: 'SelectAll',
  unselectAll: 'UnselectAll',
  rowClick: 'rowClick'
};

export const SelectionMode = {
  single: 'single',
  multiple: 'multiple',
};
@Component({
  selector: 'cux-table',
  templateUrl: './cux-table.component.html',
  styleUrls: ['./cux-table.component.scss']
})
export class CuxTableComponent implements OnInit {
  @Input() cols: any[];
  @Input() data: any[];
  @Input() sortField: string;
  @Input() scrollable: boolean;
  @Input() totalRecords: string;
  @Input() rows: number;
  @Input() minBufferPx: number;
  @Input() maxBufferPx: number;
  @Input() virtualScroll: boolean;
  @Input() scrollHeight: string;
  @Input() lazy: boolean;
  @Input() virtualRowHeight: string;
  @Input() loading: boolean;
  @Input() lastUpdatedUserId: number;
  @Input() selectionMode = SelectionMode.single;
  @Input() selectedData: any[];
  @Input() rowDisableField = '';
  @Input() rowDisableTextField = '';
  @Output() loadChunk = new EventEmitter();
  @Output() selectedDataRow = new EventEmitter<SelectionChange>();
  @Output() rowClick = new EventEmitter<SelectionChange>();

  @ViewChild('ptable') pTable: Table;
  singleSelection = true;
  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.singleSelection = this.selectionMode === SelectionMode.single;
  }
  loadDataOnScroll(event: LazyLoadEvent) {
    if (Number.isNaN(event.first) || event.first < 0) {
      console.error('Reached invalid data, event-first = ' + event.first);
      return;
    }

    this.loading = true;
    this.loadChunk.emit(event);
  }
  onRowSelect(event) {
    if (this.singleSelection) {
      this.selectedDataRow.emit({ eventType: EventType.rowSelect, data: event.data });
    } else {
      this.selectedDataRow.emit({ eventType: EventType.rowSelect, data: this.selectedData });
    }
  }

  onRowUnselect(event) {
    this.selectedDataRow.emit({ eventType: EventType.rowUnelect, data: this.selectedData });
  }

  onSelectAll(event) {
    let eventType;
    if (event) {
      eventType = EventType.selectAll;
      this.selectedData = this.data.filter(value => !(this.isRowDisabled(value)));
    } else {
      eventType = EventType.unselectAll;
      this.selectedData = [];
    }
    setTimeout(() => {
      this.selectedDataRow.emit({ eventType: eventType, data: this.selectedData });
    }, 250);
  }

  isRowDisabled(row): boolean {
    return this.rowDisableField.length > 0 ? row[this.rowDisableField] : false;
  }

  onRowClick(row) {
    if (!this.singleSelection) {
      this.rowClick.emit({ eventType: EventType.rowClick, data: row });
    }
  }


  /* istanbul ignore next */
  reset() {
    this.pTable.reset();
  }

  /* istanbul ignore next */
  resetScrollTop() {
    const body = this.pTable.containerViewChild.nativeElement.getElementsByClassName('ui-table-scrollable-body')[0];
    if (!!body && !!body.scrollTop) {
      body.scrollTop = 0;
    }
  }
}
