import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CallFlowsService as BossApiCallFlowsService } from '../modules/boss-api/generated/services';
import {
  CallFlowDC, ExtensionListDC, ExtensionListMemberDC,
} from '../modules/boss-api/generated/models';
import { mergeMap, map } from 'rxjs/operators';

export class ExtensionList {
  id: number;
  name: string;
  description: string;
}

export class Extension {
  id: string;
  name: string;
  members: Array<ExtensionListMemberDC>;
  candidates: Array<ExtensionListMemberDC>;
  candidateCount: number;
  errors: string;
}

export class ExtensionMember {
  id: string;
  enable: boolean;
  name: string;
  extension: string;
}
export class PagedExtensions {
  extensions: Extension[];
  count: number;
  constructor(extensions, count) {
    this.extensions = extensions;
    this.count = count;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ExtensionListAdaptorService {
  private readonly authToken = null;  // empty so that our  auth interceptor will handle this. Else should be 'Bearer <token>'

  static makeExtensionMember(extension) {
    return {
      id: extension.extensionFormatted,
      enable: false,
      name: extension.description,
      extension: extension.extension,
    };
  }

  static makeExtensionMemberDC(extension) {
    return {
      description: extension.name,
      extension: extension.extension,
      extensionFormat: extension.id,
    };
  }

  static makeIndividualExtension(extension: CallFlowDC): ExtensionList {
    return {
      id: extension.id,
      name: extension.name.toUpperCase(),
      description: ExtensionListAdaptorService.getMembers(extension)
    };
  }

  static getMembers(extension) {
    return `${extension.memberCount} ${extension.memberCount > 1 ? 'members' : 'member'}`;
  }

  constructor(private http: HttpClient,
              private bossCallFlowsService: BossApiCallFlowsService) { }

  getPagedExtensions(params: BossApiCallFlowsService.CallFlowsGetExtensionListsByAccountPagedParams): Observable<PagedExtensions> {

    return this.bossCallFlowsService.CallFlowsGetExtensionListsByAccountPaged(params)
        .pipe(
            mergeMap(async pageResults => {
              const tempExtensions = await this.makeExtensionList(pageResults.items);
              const pagedExtensions = new PagedExtensions(tempExtensions, pageResults.count);
              return pagedExtensions; // returns a Promise
            }));
  }

  getExtensions(): Observable<ExtensionList[]> {

    return this.bossCallFlowsService.CallFlowsGetExtensionListsByAccount(this.authToken)
        .pipe(
            // mergeMap
            // wait for promise to resolve, then return resolved result. In this case, flows. See  https://stackoverflow.com/a/53650135
            mergeMap(extensions => {

              return this.makeExtensionList(extensions); // returns a Promise
            }));
  }

  getNewExtension(): Observable<Extension> {
    return this.bossCallFlowsService.CallFlowsGetDefaultExtensionListSettings(this.authToken)
        .pipe(
            mergeMap(extension => {
              return this.makeExtension(extension);
            })
        );
  }

  getExtension(extensionId: number): Observable<Extension> {
    return this.bossCallFlowsService.CallFlowsGetExtensionListById({
      ExtensionListId: extensionId, Authorization: this.authToken })
        .pipe(
            mergeMap(extension => {
              return this.makeExtension(extension);
            })
        );
  }

  public updateExtension(id: number, extensionData: Extension): Observable<Extension> {
    const availableExtensionLists: Array<ExtensionListMemberDC> = [];
    const selectedExtensionLists: Array<ExtensionListMemberDC> = [];
    extensionData.candidates.forEach(item => {
      availableExtensionLists.push(ExtensionListAdaptorService.makeExtensionMemberDC(item));
    });
    extensionData.members.forEach(item => {
      selectedExtensionLists.push(ExtensionListAdaptorService.makeExtensionMemberDC(item));
    });
    const updateExtension: ExtensionListDC = {
      id: extensionData.id,
      name: extensionData.name,
      members: selectedExtensionLists,
      candidates: availableExtensionLists,
      candidateCount: extensionData.candidateCount,
      errors: extensionData.errors
    };
    return this.bossCallFlowsService.CallFlowsUpdateExtensionList({
      ExtensionListId: id,
      extensionListDC: updateExtension,
      Authorization: this.authToken,
    })
        .pipe(
            // convert to extension object
            mergeMap(updateExtensionResponse => {
              return this.makeExtension(updateExtensionResponse)
                  .then(extension => {
                    return Promise.resolve(extension);
                  });
            })
        );
  }

  public createExtension(extensionData: Extension): Observable<Extension> {
    const availableExtensionLists: Array<ExtensionListMemberDC> = [];
    const selectedExtensionLists: Array<ExtensionListMemberDC> = [];
    extensionData.candidates.forEach(item => {
      availableExtensionLists.push(ExtensionListAdaptorService.makeExtensionMember(item));
    });
    extensionData.members.forEach(item => {
      selectedExtensionLists.push(ExtensionListAdaptorService.makeExtensionMemberDC(item));
    });

    const createExtension: ExtensionListDC = {
      id: extensionData.id,
      name: extensionData.name,
      members: selectedExtensionLists,
      candidates: availableExtensionLists,
      candidateCount: extensionData.candidateCount,
      errors: extensionData.errors
    };
    return this.bossCallFlowsService.CallFlowsCreateExtensionList({
      extensionListDC: createExtension,
      Authorization: this.authToken,
    })
        .pipe(
            // convert to extension object
            mergeMap(createExtensionResponse => {
              return this.makeExtension(createExtensionResponse)
                  .then(extension => {
                    return Promise.resolve(extension);
                  });
            })
        );
  }

  deleteExtension(extensionId: number): Observable<string> {
    return this.bossCallFlowsService.CallFlowsDeleteExtensionList({
      ExtensionListId: extensionId, Authorization: this.authToken })
        .pipe(
            map(apiResponse => {
              return apiResponse;
            })
        );
  }

  private makeExtension(extensionDC: ExtensionListDC) {
    const availableExtensionLists: Array<ExtensionMember> = [];
    const selectedExtensionLists: Array<ExtensionListMemberDC> = [];
    extensionDC.candidates.forEach(item => {
        availableExtensionLists.push(ExtensionListAdaptorService.makeExtensionMember(item));
    });
    extensionDC.members.forEach(item => {
      selectedExtensionLists.push(ExtensionListAdaptorService.makeExtensionMember(item));
    });
    const extension = {
      id: extensionDC.id,
      name: extensionDC.name,
      members: selectedExtensionLists,
      candidates: availableExtensionLists,
      candidateCount: extensionDC.candidateCount,
      errors: extensionDC.errors
    };
    return Promise.resolve(extension);
  }
  private makeExtensionList(extensionLists: CallFlowDC[]): Promise<ExtensionList[]> {
    const extensions: ExtensionList[] = [];
    extensionLists.forEach(extension => {
      extensions.push(ExtensionListAdaptorService.makeIndividualExtension(extension));
    });
    return Promise.resolve(extensions);
  }
}
