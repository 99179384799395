import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {NewOption, Op} from '../auto-attendant.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-on-hours-block',
  templateUrl: './on-hours-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./on-hours-block.component.scss'],
})
export class OnHoursBlockComponent implements OnInit {
  @Input() aaForm: FormGroup;
  @Input() tabbable = true;
  @Input() hoursSection: FormGroup;
  @Input() actionLong: Op[];
  @Input() showAllTheTimes = true;
  @Input() showOnHours = false;
  @Input() hoursSchedules: any[] = [];
  @Input() subMenus: any[];
  @Input() openOnHoursAudio = false;
  @Input() showAllOptions1 = false;
  @Input() showAllOptions2 = false;
  @Input() showAllOptions3 = false;
  @Input() showAllOptions4 = false;
  @Input() extensionList: any[] = [];
  @Input() huntGroups: any[] = [];
  @Input() autoAttendants: any[] = [];
  @Input() key12Op1DestList: any;
  @Input() mainOptions1: Array<NewOption> = [];
  @Input() hideOpList1 = false;
  @Input() actionExtension: Op[];
  @Input() opcodeDD1: Op[];
  @Input() options: Op[];
  @Input() newOpCodeDestList: any;
  @Input() optionsDefault: Op[];
  @Input() hideCustomStep1 = true;
  @Input() key12Op2DestList: any;
  @Input() key14OpDestList: any;
  @Input() key13OpDestList: any;
  @Input() actionTooManyErrors: Op[];
  @Input() hoursTimeoutErr = '';
  @Input() hoursAfterThreeErr = '';
  @Input() hoursInvalidDestErr = '';
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() downloadAudioTrigger: EventEmitter<any> = new EventEmitter();
  @Output() previewAudioTrigger: EventEmitter<any> = new EventEmitter();
  @Output() removePromptTrigger: EventEmitter<any> = new EventEmitter();
  @Output() openUploadPromptTrigger: EventEmitter<any> = new EventEmitter();
  @Output() interactionOnClickTrigger: EventEmitter<any> = new EventEmitter();
  @Output() keyDestOnChangeTrigger: EventEmitter<any> = new EventEmitter();
  @Output() AddOptionTrigger: EventEmitter<any> = new EventEmitter();
  @Output() closeTrigger: EventEmitter<any> = new EventEmitter();
  @ViewChild('onHoursPrompt') onHoursFileElement: ElementRef;
  @ViewChild('onHoursPromptPreview') onHoursFileElementPreview: ElementRef;
  @ViewChild('onHoursAudio') onHoursAudioElement: ElementRef;
  OP_TO_EXT = '15';
  OP_TO_EXT_W_PROMPT = '7';
  OP_TO_AA = '5';
  OP_TO_HG = '150';
  OP_DIAL_BY_FIRST_NAME = '14';
  OP_DIAL_BY_LAST_NAME = '1';
  OP_LEAVE_MSG = '9';
  OP_LEAVE_MSG_BY_FIRST_NAME = '13';
  OP_LEAVE_MSG_BY_LAST_NAME = '12';
  OP_LOGIN_VM = '16';
  OP_REPEAT = '2';
  OP_DISCONNECT = '11';
  OP_EXTENSION_TO_EXT = '151';
  OP_EXTENSION_TO_EXT_PROMPT = '71';
  OP_EXTENSION_TO_MSG = '91';
  TIME_TYPE_ON_HOURS = 1;
  TIME_TYPE_OFF_HOURS = 2;
  TIME_TYPE_HOLIDAY = 3;
  TIME_TYPE_CUSTOM = 4;
  componentLoaded = false;

  OPT_EXTENSION = 15;
  NON_OPT_EXTENSION = 50;
  prevNewOptSelection = 50;
  clearFields = false;

  constructor() {
  }

  ngOnInit() {
  }

  scheduleOnChange(event) {
    this.change.emit(event);
  }

  downloadAudio(event) {
    this.downloadAudioTrigger.emit();
  }

  previewAudio(event) {
    this.previewAudioTrigger.emit();
  }
  removePrompt(e1, e2) {
    this.removePromptTrigger.emit();
  }

  openUploadPrompt(e) {
    this.openUploadPromptTrigger.emit();
  }

  onClose() {
    this.closeTrigger.emit();
  }

  nextStepOnClick(value: string, timeType: number) {
    this.hideCustomStep1 = timeType === this.TIME_TYPE_ON_HOURS && value === '1';
  }

  interactionOnClick(value: string, timetypeid: number) {
    this.showAllOptions1 = timetypeid === this.TIME_TYPE_ON_HOURS && value === '1';
  }

  hideActionDestInput(str0: string) {
    if (this.aaForm.get('aaSection.' + str0) && this.aaForm.get('aaSection.' + str0).value &&
        ( this.aaForm.get('aaSection.' + str0).value.value === this.OP_TO_EXT ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_TO_EXT_W_PROMPT ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG )) {
      return false;
    }

    return true;
  }

  // hide opcode destination dropdown field when an action does not have a DD to choose from
  hideActionDestDD(str0: string) {
    if (this.aaForm.get('aaSection.' + str0) && this.aaForm.get('aaSection.' + str0).value &&
        ( this.aaForm.get('aaSection.' + str0).value.value === this.OP_TO_AA ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_TO_HG ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_EXT ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_EXT_PROMPT ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_MSG ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_DIAL_BY_FIRST_NAME ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_DIAL_BY_LAST_NAME ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG_BY_FIRST_NAME ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG_BY_LAST_NAME )) {
      return false;
    }

    return true;
  }

  keyDestOnChange(e) {
    this.keyDestOnChangeTrigger.emit(e);
  }

  onAddOption(e) {
    this.AddOptionTrigger.emit();
  }

  optionDDOnClick(event, mainOptions) {
    this.options = _.cloneDeep(this.optionsDefault);
    const dupMainOptionKeys = _.map(mainOptions, fetchOpcode);
    function fetchOpcode(option) {
      return option.opcode !== 0 ? option.key : [];
    }
    const flatValues = _.flatten(dupMainOptionKeys);
    if (!_.isEmpty(flatValues)) {
      const dupOptions = _.map(this.options, fetchOption);
      this.options = _.flatten(dupOptions);
    }
    function fetchOption(option) {
      return !_.includes(flatValues, Number(option.value)) ? option : [];
    }
  }

  getDD(str0: string) {
    let ret = [];
    if ( this.aaForm.get('aaSection.' + str0) && this.aaForm.get('aaSection.' + str0).value ) {
      if (this.aaForm.get('aaSection.' + str0).value.value === this.OP_TO_AA ) {
        ret = this.autoAttendants;
      } else if (this.aaForm.get('aaSection.' + str0).value.value === this.OP_TO_HG ) {
        ret = this.huntGroups;
      } else if ( this.aaForm.get('aaSection.' + str0).value.value === this.OP_DIAL_BY_FIRST_NAME ||
          this.aaForm.get('aaSection.' + str0).value.value === this.OP_DIAL_BY_LAST_NAME ||
          this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG_BY_FIRST_NAME ||
          this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG_BY_LAST_NAME ||
          this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_EXT ||
          this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_EXT_PROMPT ||
          this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_MSG ) {
        ret = this.extensionList;
      }
    }

    return ret;
  }

  optionDDOnChange(str0: string) {
    // show actionExtension vs. actionLong
    if ( this.aaForm.get('aaSection.' + str0) && this.aaForm.get('aaSection.' + str0).value ) {
      console.log('optionDDOnChange :', 'entered');
      if (this.aaForm.get('aaSection.' + str0).value.value === '15') { // Extension
        this.clearFields = this.prevNewOptSelection !== this.OPT_EXTENSION;
        this.opcodeDD1 = this.actionExtension;
        this.prevNewOptSelection = this.OPT_EXTENSION;
      } else {
        this.clearFields = this.prevNewOptSelection !== this.NON_OPT_EXTENSION;
        this.opcodeDD1 = this.actionLong;
        this.prevNewOptSelection = this.NON_OPT_EXTENSION;
      }

      // clean up new option fields
      if (this.clearFields ) {
        this.aaForm.get('aaSection.onHoursSection').patchValue({
          new_opcode1: {value: ''},
          new_opcode1_dest_dd: {value: ''},
          new_opcode1_dest: ''
        });
      }
    }
  }

  isExtList(str0: string) {
    let bExtList = false;
    if ( this.aaForm.get('aaSection.' + str0) &&
        this.aaForm.get('aaSection.' + str0).value &&
        (this.aaForm.get('aaSection.' + str0).value.value === this.OP_DIAL_BY_FIRST_NAME ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_DIAL_BY_LAST_NAME ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_EXT ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_EXT_PROMPT ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_EXTENSION_TO_MSG ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG_BY_FIRST_NAME ||
            this.aaForm.get('aaSection.' + str0).value.value === this.OP_LEAVE_MSG_BY_LAST_NAME )) {
      bExtList = true;
    }
    return bExtList;
  }

  OpcodeOnChange(str0: string) {
    const bExtList = this.isExtList(str0);
    // console.log(this.aaForm.get('aaSection.' + str0 + '_dest').value);
    // this.aaForm.get('aaSection.' + str0 + '_dest_dd').touched &&
    if (this.aaForm.get('aaSection.' + str0) && this.aaForm.get('aaSection.' + str0).value) {
      console.log('OpcodeOnChange :', 'entered');
      if (this.aaForm.get('aaSection.' + str0 + '_dest')) {
        this.aaForm.get('aaSection.' + str0 + '_dest').patchValue('');
      }
      if (this.componentLoaded) {
          if ( this.aaForm.get('aaSection.' + str0 + '_dest_dd')) {
            setTimeout(() => {
            this.aaForm.get('aaSection.' + str0 + '_dest_dd').patchValue( {value: bExtList ? '0' : ''});
            }, 100);
          }
      } else {
        if ( this.aaForm.get('aaSection.' + str0 + '_dest_dd')) {
          this.aaForm.get('aaSection.' + str0 + '_dest_dd').patchValue( {value: bExtList ? '0' : ''});
        }
      }
    }
  }

  onRemoveOption(mainOptions0: Array<NewOption>, key0: number, $event) {
    $event.stopImmediatePropagation();
    for (const item0 of mainOptions0) {
      if (item0.key === key0) {
        item0.opcode = 0;
        this.aaForm.markAsDirty();
        break;
      }
    }
  }

  displayCFDestination(destination:  any) {
    if (typeof destination === 'string') {
      return destination;
    }
    if (destination != null) {
      console.log('Destination object:', true);
      return destination.extension ? destination.extension.split('-')[1] + ' : ' + destination.description :
          destination.extension + ' : ' + destination.description;
    }
  }

  updateComponentLoaded() {
    this.componentLoaded = true;
  }

  updateSelectedItem(data, field) {
    const extensionData = this.displayCFDestination(data);
    this.aaForm.controls['aaSection'].get(field).setValue(extensionData);
  }
}
