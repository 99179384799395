import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { rightPanelCloseReason } from '../shared/constants';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-navigation-confirmation',
  templateUrl: './navigation-confirmation.component.html',
  styleUrls: ['./navigation-confirmation.component.css']
})
export class NavigationConfirmationComponent implements OnInit {

  constructor(public translateSvc: TranslateService,
  	private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  leavePage() {
  	this.activeModal.close(rightPanelCloseReason.LEAVE);
  }

  stayOnPage() {
  	this.activeModal.close(rightPanelCloseReason.STAY);
  }

}
