<cux-dropdown
  [items]="items"
  [selectedItem]="selectedItem"
  [selectedItemIcon]="selectedItemIcon"
  (onClick)="onLocationClick($event)"
  (onChange)="onLocationChange($event)"
  (onFocus)="onLocationFocus($event)"
  (onBlur)="onLocationBlur($event)"
  (onShow)="onLocationShow($event)"
  (onHide)="onLocationHide($event)">
</cux-dropdown>
