<div class="nav-error-container row">
  <div class="col-md-4"></div>
  <div class="col-md-4">
    <div class="nav-error-title">
      {{ 'nav_error.' + errorNum + '.title' | translate }}
    </div>
    <div class="nav-error-body">
      <div class="nav-error-header">
        {{ 'nav_error.' + errorNum + '.header' | translate }}
      </div>
      <div class="nav-error-content">
        {{ 'nav_error.' + errorNum + '.content' | translate }}
      </div>
      <div class="nav-error-footer">
        {{ 'nav_error.' + errorNum + '.footer' | translate }}<a href="https://docs.shoretel.com/connectcloud/console/ContactUs">{{'nav_error.contact_us' | translate}}</a>
      </div>
    </div>
  </div>
  <div class="col-md-4"></div>
  </div>