import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtensionListService {
  private clickedExtension: any;
  clickedExtensionChanged = new Subject<any>();

  constructor() {
  }

  setClickedExtension(Extension: any) {
    this.clickedExtension = Extension;
    this.clickedExtensionChanged.next(this.clickedExtension);
  }

  getClickedExtension() {
    return this.clickedExtension;
  }
}
