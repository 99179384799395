import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
   } from '@angular/common/http';
   import { Injectable } from '@angular/core';
   import { Observable, throwError } from 'rxjs';
   import { catchError } from 'rxjs/operators';

import { AppUserClaimsService, MajorAlert } from '../services/app-user-claims.service';

   @Injectable()
   export class Http403Interceptor implements HttpInterceptor {
     constructor(private appSvc: AppUserClaimsService) {}

     // This interceptor is looking for 403 errors.
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          catchError((error) => {

            if  (error.status && error.status == 403 ||  (error.statusCode && error.statusCode == 403)   ) {
                this.appSvc.majorAlert.next(new MajorAlert('staff_http403.headline', 'staff_http403.message'));
            }

            // pass the error on up to caller
            return throwError(error);
          })
        );
    }
   }
