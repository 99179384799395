// https://medium.com/@er.surajnegi/what-are-angular-http-interceptors-and-how-to-create-them-82d1d6159c4e

import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


/**
 * Network packet tracing. Simply logs all tx/rx packets
 */
export class PacketTraceInteceptor implements HttpInterceptor {

    intercept(req: import('@angular/common/http').HttpRequest<any>, next: import('@angular/common/http').HttpHandler): import('rxjs').Observable<import('@angular/common/http').HttpEvent<any>> {

        if (environment.packetTrace) {

            const t0 = Date.now();
            // console.log(new Date().toISOString()+" tx>" + req.method + " " + JSON.stringify(req, null, 0)); // includes headers
            // console.log(new Date().toISOString() + ' tx>' + req.method + ' ' + req.url + ' ' + (req.body ? JSON.stringify(req.body) : ''));
            return next.handle(req)
                .pipe(
                    tap(
                        event => {
                            // if (event instanceof HttpResponse) {
                            //     const t1 = Date.now();
                            //     const elapsed = t1 - t0;  // milliseconds
                            //     console.log(new Date().toISOString() + ' rx>' + event.status + ' ' +
                            //         elapsed + ' ' + new Date().toISOString() + ' ' + event.url + ' ' + JSON.stringify(event.body));
                            // }
                        },
                        error => {
                            const t1 = Date.now();
                            const elapsed = t1 - t0;  // milliseconds
                            console.error(new Date().toISOString() + ' rx>' + error.status + ' ' +
                                elapsed + ' ' + new Date().toISOString() + ' ' + JSON.stringify(error));
                                // stringify so full content of error obj is displayed to log
                        }
                    )
                );

        } else {
            return next.handle(req);
        }
    }

}
