import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlowsService {

  private clickedFlow: any;
  clickedFlowChanged = new Subject<any>();
  rightPanelCloseInitiated = new Subject<any>();
  componentNavigateIntent = new Subject<any>();
  componentNavigateAway = new Subject<any>();

  constructor() { }

  setClickedFlow(flow: any) {
    this.clickedFlow = flow;
  	this.clickedFlowChanged.next(this.clickedFlow);
  }

  getClickedFlow() {
  	return this.clickedFlow;
  }
}
