<div id="invoicesmall" class="invoice-small">
  <div class="row" *ngIf="invoice.header">
    <div class="col-sm-12">
      <h1 class="title">{{invoice.header.title}}</h1>
    </div>
  </div>

  <div *ngIf="invoice.header && invoice.header.title" class="invoice-divider"></div>

  <div *ngFor="let section of invoice.sections">
    <div class="row" *ngIf="section.header">
      <div class="col-sm-12">
        <div id="sectiontitle" class="section-title">{{section.header.title}}</div>
        <div id="sectionsubtitle" class="section-subtitle">{{section.header.subtitle}}</div>
      </div>
    </div>
    <div class="empty-line-1"></div>
    <div *ngFor="let item of section.items">
      <div class="row">
        <div class="section-item col-sm-8">
          {{item.label}}
        </div>
        <div class="section-price col-sm-4">
          {{item.prefix}}{{item.price}}{{item.suffix}}
        </div>
      </div>
    </div>
    <div class="row section-summary" *ngIf="section.summary">
      <div class="col-sm-8">
        {{section.summary.label}}
      </div>
      <div class="section-price col-sm-4">
          <!-- arrow chars from https://www.fileformat.info/info/unicode/block/geometric_shapes/list.htm -->
          <span *ngIf="section.summary.arrowup">&#x25b2;</span><span *ngIf="section.summary.arrowdown">&#x25bc;</span>{{section.summary.prefix}}{{section.summary.price}}{{section.summary.suffix}}
      </div>
    </div>
    <div class="empty-line-2"></div>
  </div>

  <div *ngIf="invoice.footers">
    <div class="footer" *ngFor="let footer of invoice.footers">
      {{footer}}
    </div>
  </div>
</div>
