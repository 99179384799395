import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { User, UserCollection, UserPostRequest, UserPutRequest, SiteCollection } from '@mitel/cloudlink-sdk/admin';
import { Config, Utils, Token, Odata, HttpService, Account, AdminService as CloudAdmin } from '@mitel/cloudlink-sdk';

@Injectable()
export class AdminService {
    private readonly api: CloudAdmin;

    constructor() {
        Config.cloud = environment.cloud;
        console.log('In Accounts Admin Service, cloud = ', environment.cloud);
        this.api = new CloudAdmin();
    }

    public getAccountById( id: string ): Promise<Account> {
      return this.api.getAccount( {accountId: id} );
    }

    // public getUsers(odata?: Odata, options?: any): Promise<UserCollection> {
    //     if (odata) {
    //         return this.api.getUsers({
    //             $Skip: odata.$Skip, $Top: odata.$Top, $Filter: odata.$Filter,
    //             $Search: odata.$Search, $Expand: odata.$Expand, options
    //         });
    //     } else {
    //         return this.api.getUsers({ options });
    //     }
    // }


    public getUsersByAccountId( accountId: string, odata?: Odata, options?: any): Promise<UserCollection> {
        if (odata) {
            return this.api.getUsersByAccountId({ accountId: accountId,
                $Skip: odata.$Skip, $Top: odata.$Top, $Filter: odata.$Filter,
                $Search: odata.$Search, $Expand: odata.$Expand, options
            });
        } else {
            return this.api.getUsersByAccountId({ accountId: accountId, options });
        }
    }

    // public createUser(body: UserPostRequest): Promise<User> {
    //     return this.api.createUser({body});
    // }

    public getUser(userId: string): Promise<User> {
        return this.api.getUser({ userId });
    }

    public updateUser(accountId: string, userId: string, body: UserPutRequest): Promise<User> {
        return this.api.updateUserByAccountId({ accountId, userId, body });
    }

    public deleteUser(accountId: string, userId: string) {
        return this.api.deleteUserByAccountId({ accountId, userId });
    }

    public createUserForAccount(accountId: string, body: UserPostRequest) {
        return this.api.createUserInAccount({accountId, body});
    }

    public getLocationList( accountId: string, odata?: Odata, options?: any ): Promise<SiteCollection> {
        if ( odata ) {
            return this.api.getSites({ accountId: accountId,
                $Skip: odata.$Skip, $Top: odata.$Top,
                $Expand: odata.$Expand, options
            });
        } else {
            return this.api.getSites({accountId: accountId, options});
        }
    }

    getUsersList(accountId: string, odata?: Odata): Promise<User[]> {
      const promise = new Promise<User[]>(async (resolve, reject) => {
      try {
        let users: User[];
        const userCollection = await this.getUsersByAccountId( accountId, odata);
        if (userCollection) {
          users = Utils.getItemsFromCollection(userCollection);
          console.log('Retrieved users from Cloudlink', users);
          const next = Utils.getOdataNext(userCollection);
          if (next && next.$Skip) {
            const moreUsers = await this.getUsersList(accountId, next);
            console.log('retrieved more users from cloudlink', moreUsers);
            users = users.concat(moreUsers);
          }
          this.processUsername( users );
        }
        resolve(users);
      } catch (reason) {
        reject(reason);
      }
    });

    return promise;
  }

  processUsername(users: any) {
    users.forEach((user, i) => {
      if (user.name) {
        // if ( user.firstName &&  user.lastName )
        //   this.users[i].displayName = user.lastName.toUpperCase() + ', ' + user.firstName;
        // else
        // {
          const nameParts = user.name.split(' ');
          users[i].firstName = '';
          users[i].lastName = '';
          if ( nameParts && nameParts.length === 1 ) {
            users[i].lastName = user.name;
          } else if ( nameParts && nameParts.length === 2 ) {
            users[i].firstName = nameParts[0];
            users[i].lastName = nameParts[1];
          } else {
            users[i].firstName = user.name.substr(0, user.name.lastIndexOf(' '));
            users[i].lastName = nameParts[nameParts.length - 1];
          }
          users[i].displayName = users[i].lastName.toUpperCase() + ', ' + users[i].firstName.toUpperCase();
          console.log('THe display name of the user', users[i].displayName);
        // }
      }
      if ( user.role === 'ACCOUNT_ADMIN' ) {
        users[i].tokenValue = 'users_list.account_admin_token';
      }
    });
  }
}
