import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FlowsService } from 'src/app/services/flows.service';

@Component({
  selector: 'app-flows-model',
  templateUrl: './flows-model.component.html',
  styleUrls: ['./flows-model.component.scss'],
})
export class FlowsModelComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter();
  tabbable = true;
  constructor(public activeModal: NgbActiveModal,
    public translateSvc: TranslateService,
    private flowsSvc: FlowsService) { }

  ngOnInit() {}
  onClose() {
    this.flowsSvc.setClickedFlow(null);
    this.activeModal.close();
  }

  onClickOption(data) {
    console.log('user clicked', data);
    this.activeModal.close(data);
  }

}
