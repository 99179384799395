<div class="search-tab" *ngIf="numberType!==numberTypes.tollFree">
  <div class="tab-item" [ngClass]="phSvc.tnIntegration.filterByCity ? 'tab-item-main' : 'tab-item-side'"
    (click)="onTabClicked({filterByCity: true})" (keyup.enter)="onTabClicked({filterByCity: true})" tabindex="0">{{'choose_new_number.search_city_state' | translate}}</div>
  <div class="tab-item" [ngClass]="!phSvc.tnIntegration.filterByCity ? 'tab-item-main' : 'tab-item-side'"
    (click)="onTabClicked({filterByCity: false})" (keyup.enter)="onTabClicked({filterByCity: false})" tabindex="0">{{'choose_new_number.search_phone_num' | translate}}</div>
</div>
<div class="new-number-container">
  <div class="new-number-content-container" [ngClass]="{'wrapper-style': isLocalNumber}">
    <span class="col">
      <filter-numbers [numberType]='numberType' [currentUserLocation]='currentUserLocation' [tollFreeAreaCodes]="tollFreeAreaCodes"
        (onSearchByRateCenter)='searchByRateCenter($event)' (onsearchByNumber)='searchByNumber($event)' (searchTollFree)="onSearchTollFree($event)"
        [searchCriteriaRateCenter]='searchCriteriaRateCenter' [searchCriteriaDigits]='searchCriteriaDigits'>
      </filter-numbers>
    </span>
    <app-spinner delay="1000" [isPopUp]="(numberType === numberTypes.default) ? true: false" [showSpinner]="loading" message="{{ loadingText | translate}}"></app-spinner>
    <div [ngClass]="{'available-numbers-div': numberType !== 'default'}" *ngIf="showMessage || availableNumbers.length">
      <span class="row">
        <span class="col">
            <span class='section-heading' *ngIf="numberType!==numberTypes.tollFree && (availableNumbers.length || messageStyle)">
                {{'choose_new_number.available_numbers' | translate}}
                <span *ngIf="phSvc.tnIntegration.filterByCity === filterByCity">{{searchedTerm | translate}} </span>
            </span>
            <span class='section-heading' *ngIf="numberType===numberTypes.tollFree && (availableNumbers.length || messageStyle)">
                {{searchedTerm | translate}}
            </span>
        </span>
      </span>
      <span class="row numbers-grid" [ngClass]="{'loading': loading}">
        <span class="row message-bar" *ngIf="showMessage" [ngClass]="messageStyle">
          <span class="type-icon" [ngClass]="messageStyle !== 'warning' ? 'type-bg-img-error' : 'type-bg-img-warning'"></span>
          <span class="col-md-11" *ngIf="!showServerError">
            <p class="message-bar-body" *ngIf="messageStyle !== 'warning'; else elseBlock">
              <strong class="message-bar-title">
                {{ 'choose_new_number.message_box.' + messageType + '.title' | translate }}
              </strong>
              {{ 'choose_new_number.message_box.' + messageType + '.body' | translate }}
            </p>
            <ng-template class="message-bar-body" #elseBlock>{{ messageType }}</ng-template>
          </span>
          <span class="col-md-11" *ngIf="showServerError">
            <p class="message-bar-body">
              <strong class="message-bar-title">
                {{errMsg}}
              </strong>
            </p>
          </span>
          <span class="close-icon" [ngClass]="messageStyle !== 'warning' ? 'close-bg-img-error' : 'close-bg-img-warning'" (click)="clearMessage()" (keyup.enter)="clearMessage()" tabindex=0></span>
        </span>
        <span>
          <div *ngIf="(!showMessage || messageStyle === 'warning') && !filterByCity && phSvc.tnIntegration.filterByCity !== filterByCity" class="zero-state-message">
            {{'choose_new_number.zero_state_city_search' | translate}}
          </div>
          <div *ngIf="(!showMessage || messageStyle === 'warning') && filterByCity && phSvc.tnIntegration.filterByCity !== filterByCity" class="zero-state-message">
            {{'choose_new_number.zero_state_number_search' | translate}}
          </div>
        </span>
        <numbers-grid [numberType]='numberType' class="col-md-12" *ngIf="(!showMessage || messageStyle === 'warning') && showNumbersGridComponent && phSvc.tnIntegration.filterByCity === filterByCity"
          [availableNumbers]="availableNumbers" (newPhoneNumberSelected)="newPhoneNumberSelected($event)">
        </numbers-grid>
        <number-range class="col-md-12" *ngIf="!showMessage && showContinuousNumberRangeComponent && phSvc.tnIntegration.filterByCity === filterByCity"
        [numberRange]="numberRange"></number-range>
      </span>
    </div>
  </div>
  <button *ngIf="phSvc.tnIntegration.filterByCity === filterByCity && availableNumbers.length && (isLocalNumber && showContinuousNumberRangeComponent || !isLocalNumber || !pageReloaded)" type="button" tabindex="0"
    class="btn btn-default show-more row" (click)='onFetchMoreNumbers()' (keyup.enter)='onFetchMoreNumbers()' [ngClass]="{'local-number-style': isLocalNumber, 'toll-free-number-style': numberType===numberTypes.tollFree}"
    [disabled]="numberType===numberTypes.tollFree && availableNumsBelowTen"
    >
    <span class="load-more-icon"></span>
    <span class="load-more-text">
      <span *ngIf="isLocalNumber">{{'choose_new_number.show_more_local' | translate}}</span>
      <span *ngIf="!isLocalNumber">{{'choose_new_number.show_more' | translate}}</span>
    </span>
  </button>
  <div *ngIf="!isLocalNumber && numberType!==numberTypes.tollFree" class="save-search-criteria row" tabindex="0">
    <label>
      <input type="checkbox" checked="true" (click)="onClickSaveSearch($event)" (keyup.enter)="onClickSaveSearch($event)">
      {{'choose_new_number.save_search_criteria' | translate}}
    </label>
  </div>
</div>
