import * as core from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

export class PromptCreateInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method === 'POST' &&
            request.url.match(/AutoAttendants+[/]+[0-9]+[/]+Prompt+[/]+TimeType+[/]+[0-9]/)) {
            console.log('Prompt post and request url found');
            const promptPostRequest = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            return next.handle(promptPostRequest);
        } else {
            return next.handle(request);
        }
    }
}
