/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Endpoint } from '../models/endpoint';
import { UpdateEndpointRequest } from '../models/update-endpoint-request';
import { CallForwarding } from '../models/call-forwarding';
import { UpdateEndpointForwardingRequest } from '../models/update-endpoint-forwarding-request';
import { PstnRoutingInfo } from '../models/pstn-routing-info';
import { CallCollection } from '../models/call-collection';
import { Call } from '../models/call';
import { CreateCallRequest } from '../models/create-call-request';
import { UpdateEndpointCallRequest } from '../models/update-endpoint-call-request';

/**
 * Controllable endpoints registered to the cloud and / or premise PBX
 */
@Injectable({
  providedIn: 'root',
})
class EndpointsService extends __BaseService {
  static readonly getEndpointPath = '/endpoints/{endpointId}';
  static readonly updateEndpointPath = '/endpoints/{endpointId}';
  static readonly getEndpointForwardingOptionsPath = '/endpoints/{endpointId}/forwardings';
  static readonly updateEndpointForwardingOptionsPath = '/endpoints/{endpointId}/forwardings';
  static readonly deleteEndpointForwardingOptionsPath = '/endpoints/{endpointId}/forwardings';
  static readonly getPstnRoutingOptionsPath = '/endpoints/{endpointId}/pstn-routing';
  static readonly updatePstnRoutingNumberPath = '/endpoints/{endpointId}/pstn-routing';
  static readonly deletePstnRoutingNumberPath = '/endpoints/{endpointId}/pstn-routing';
  static readonly getEndpointCallsPath = '/endpoints/{endpointId}/calls';
  static readonly createEndpointCallPath = '/endpoints/{endpointId}/calls';
  static readonly getEndpointCallPath = '/endpoints/{endpointId}/calls/{callId}';
  static readonly updateCallByEndpointIdPath = '/endpoints/{endpointId}/calls/{callId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets the current state of the endpoint for the provided ID. Sometimes referred to as line state.
   * @param endpointId Device Identifier
   * @return Success. Returns the detail state of the device.
   */
  getEndpointResponse(endpointId: string): __Observable<__StrictHttpResponse<Endpoint>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/endpoints/${endpointId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Endpoint>;
      })
    );
  }
  /**
   * Gets the current state of the endpoint for the provided ID. Sometimes referred to as line state.
   * @param endpointId Device Identifier
   * @return Success. Returns the detail state of the device.
   */
  getEndpoint(endpointId: string): __Observable<Endpoint> {
    return this.getEndpointResponse(endpointId).pipe(
      __map(_r => _r.body as Endpoint)
    );
  }

  /**
   * Used for changing the state of the endpoint such as set do not disturb or turn on message waiting indicator for desktop phones.
   * @param params The `EndpointsService.UpdateEndpointParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `body`:
   *
   * @return Success. Returns and the detail state of the device.
   */
  updateEndpointResponse(params: EndpointsService.UpdateEndpointParams): __Observable<__StrictHttpResponse<Endpoint>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/endpoints/${params.endpointId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Endpoint>;
      })
    );
  }
  /**
   * Used for changing the state of the endpoint such as set do not disturb or turn on message waiting indicator for desktop phones.
   * @param params The `EndpointsService.UpdateEndpointParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `body`:
   *
   * @return Success. Returns and the detail state of the device.
   */
  updateEndpoint(params: EndpointsService.UpdateEndpointParams): __Observable<Endpoint> {
    return this.updateEndpointResponse(params).pipe(
      __map(_r => _r.body as Endpoint)
    );
  }

  /**
   * Gets the forwarding details of an endpoint.
   * @param endpointId Device Identifier
   * @return Success. Returns call forwarding details of the device.
   */
  getEndpointForwardingOptionsResponse(endpointId: string): __Observable<__StrictHttpResponse<CallForwarding>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/endpoints/${endpointId}/forwardings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CallForwarding>;
      })
    );
  }
  /**
   * Gets the forwarding details of an endpoint.
   * @param endpointId Device Identifier
   * @return Success. Returns call forwarding details of the device.
   */
  getEndpointForwardingOptions(endpointId: string): __Observable<CallForwarding> {
    return this.getEndpointForwardingOptionsResponse(endpointId).pipe(
      __map(_r => _r.body as CallForwarding)
    );
  }

  /**
   * Used for updating the forwarding details of the endpoint.
   * @param params The `EndpointsService.UpdateEndpointForwardingOptionsParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `body`:
   */
  updateEndpointForwardingOptionsResponse(params: EndpointsService.UpdateEndpointForwardingOptionsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/endpoints/${params.endpointId}/forwardings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used for updating the forwarding details of the endpoint.
   * @param params The `EndpointsService.UpdateEndpointForwardingOptionsParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `body`:
   */
  updateEndpointForwardingOptions(params: EndpointsService.UpdateEndpointForwardingOptionsParams): __Observable<null> {
    return this.updateEndpointForwardingOptionsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used for deleting the forwarding details of the endpoint.
   * @param endpointId Device Identifier
   */
  deleteEndpointForwardingOptionsResponse(endpointId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/endpoints/${endpointId}/forwardings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used for deleting the forwarding details of the endpoint.
   * @param endpointId Device Identifier
   */
  deleteEndpointForwardingOptions(endpointId: string): __Observable<null> {
    return this.deleteEndpointForwardingOptionsResponse(endpointId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * returns true if endpoint is pstn routing, false if it is not.
   * @param endpointId Device Identifier
   * @return Success. Returns pstn routing details of the device.
   */
  getPstnRoutingOptionsResponse(endpointId: string): __Observable<__StrictHttpResponse<PstnRoutingInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/endpoints/${endpointId}/pstn-routing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PstnRoutingInfo>;
      })
    );
  }
  /**
   * returns true if endpoint is pstn routing, false if it is not.
   * @param endpointId Device Identifier
   * @return Success. Returns pstn routing details of the device.
   */
  getPstnRoutingOptions(endpointId: string): __Observable<PstnRoutingInfo> {
    return this.getPstnRoutingOptionsResponse(endpointId).pipe(
      __map(_r => _r.body as PstnRoutingInfo)
    );
  }

  /**
   * Used for updating the pstn routing details of the endpoint.
   * @param endpointId Device Identifier
   */
  updatePstnRoutingNumberResponse(endpointId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/endpoints/${endpointId}/pstn-routing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used for updating the pstn routing details of the endpoint.
   * @param endpointId Device Identifier
   */
  updatePstnRoutingNumber(endpointId: string): __Observable<null> {
    return this.updatePstnRoutingNumberResponse(endpointId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Removes the pstnRouting number for the provided endpoint ID.
   * @param endpointId Device Identifier
   */
  deletePstnRoutingNumberResponse(endpointId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/endpoints/${endpointId}/pstn-routing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes the pstnRouting number for the provided endpoint ID.
   * @param endpointId Device Identifier
   */
  deletePstnRoutingNumber(endpointId: string): __Observable<null> {
    return this.deletePstnRoutingNumberResponse(endpointId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a list of active calls currently associated to the endpoint for the provided ID.
   * @param params The `EndpointsService.GetEndpointCallsParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$filter`: Odata filter param
   *
   * @return Success
   */
  getEndpointCallsResponse(params: EndpointsService.GetEndpointCallsParams): __Observable<__StrictHttpResponse<CallCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/endpoints/${params.endpointId}/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CallCollection>;
      })
    );
  }
  /**
   * Get a list of active calls currently associated to the endpoint for the provided ID.
   * @param params The `EndpointsService.GetEndpointCallsParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$filter`: Odata filter param
   *
   * @return Success
   */
  getEndpointCalls(params: EndpointsService.GetEndpointCallsParams): __Observable<CallCollection> {
    return this.getEndpointCallsResponse(params).pipe(
      __map(_r => _r.body as CallCollection)
    );
  }

  /**
   * Make a call from an endpoint to a destination.
   * @param params The `EndpointsService.CreateEndpointCallParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `body`:
   *
   * @return Success. Returns details of the call.
   */
  createEndpointCallResponse(params: EndpointsService.CreateEndpointCallParams): __Observable<__StrictHttpResponse<Call>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/endpoints/${params.endpointId}/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Call>;
      })
    );
  }
  /**
   * Make a call from an endpoint to a destination.
   * @param params The `EndpointsService.CreateEndpointCallParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `body`:
   *
   * @return Success. Returns details of the call.
   */
  createEndpointCall(params: EndpointsService.CreateEndpointCallParams): __Observable<Call> {
    return this.createEndpointCallResponse(params).pipe(
      __map(_r => _r.body as Call)
    );
  }

  /**
   * Get the snapshot of the call with provided call ID, associated with the endpoint of the provided endpoint ID.
   * @param params The `EndpointsService.GetEndpointCallParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `callId`: Call Identifier
   *
   * @return Success. Returns details of the call.
   */
  getEndpointCallResponse(params: EndpointsService.GetEndpointCallParams): __Observable<__StrictHttpResponse<Call>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/endpoints/${params.endpointId}/calls/${params.callId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Call>;
      })
    );
  }
  /**
   * Get the snapshot of the call with provided call ID, associated with the endpoint of the provided endpoint ID.
   * @param params The `EndpointsService.GetEndpointCallParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `callId`: Call Identifier
   *
   * @return Success. Returns details of the call.
   */
  getEndpointCall(params: EndpointsService.GetEndpointCallParams): __Observable<Call> {
    return this.getEndpointCallResponse(params).pipe(
      __map(_r => _r.body as Call)
    );
  }

  /**
   * Change the state of the call by issuing an appropriate action to take on the call, for the provided call ID associated with a provided endpoint ID. Some actions require additional arguments.
   *Examples
   *To toggle the holding state of the call
   *`{ "action": "hold"}`
   *To make a blind transfer
   *`{ "action": "blindTransfer", "destination": "5555"}`
   *To make a supervised transfer
   *`{ "action": "supervisedTransfer", "callId": "5555555555"} (coming soon)`
   *For additional actions and arguments see object model definition.
   * @param params The `EndpointsService.UpdateCallByEndpointIdParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `callId`: Call Identifier
   *
   * - `body`:
   */
  updateCallByEndpointIdResponse(params: EndpointsService.UpdateCallByEndpointIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/endpoints/${params.endpointId}/calls/${params.callId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Change the state of the call by issuing an appropriate action to take on the call, for the provided call ID associated with a provided endpoint ID. Some actions require additional arguments.
   *Examples
   *To toggle the holding state of the call
   *`{ "action": "hold"}`
   *To make a blind transfer
   *`{ "action": "blindTransfer", "destination": "5555"}`
   *To make a supervised transfer
   *`{ "action": "supervisedTransfer", "callId": "5555555555"} (coming soon)`
   *For additional actions and arguments see object model definition.
   * @param params The `EndpointsService.UpdateCallByEndpointIdParams` containing the following parameters:
   *
   * - `endpointId`: Device Identifier
   *
   * - `callId`: Call Identifier
   *
   * - `body`:
   */
  updateCallByEndpointId(params: EndpointsService.UpdateCallByEndpointIdParams): __Observable<null> {
    return this.updateCallByEndpointIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EndpointsService {

  /**
   * Parameters for updateEndpoint
   */
  export interface UpdateEndpointParams {

    /**
     * Device Identifier
     */
    endpointId: string;
    body: UpdateEndpointRequest;
  }

  /**
   * Parameters for updateEndpointForwardingOptions
   */
  export interface UpdateEndpointForwardingOptionsParams {

    /**
     * Device Identifier
     */
    endpointId: string;
    body: UpdateEndpointForwardingRequest;
  }

  /**
   * Parameters for getEndpointCalls
   */
  export interface GetEndpointCallsParams {

    /**
     * Device Identifier
     */
    endpointId: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Odata filter param
     */
    Filter?: string;
  }

  /**
   * Parameters for createEndpointCall
   */
  export interface CreateEndpointCallParams {

    /**
     * Device Identifier
     */
    endpointId: string;
    body: CreateCallRequest;
  }

  /**
   * Parameters for getEndpointCall
   */
  export interface GetEndpointCallParams {

    /**
     * Device Identifier
     */
    endpointId: string;

    /**
     * Call Identifier
     */
    callId: string;
  }

  /**
   * Parameters for updateCallByEndpointId
   */
  export interface UpdateCallByEndpointIdParams {

    /**
     * Device Identifier
     */
    endpointId: string;

    /**
     * Call Identifier
     */
    callId: string;
    body: UpdateEndpointCallRequest;
  }
}

export { EndpointsService }
