import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { Subscription } from 'rxjs';
import { AppUserClaimsService } from '../services/app-user-claims.service';
import { Account } from '@mitel/cloudlink-sdk/admin';

@Component({
  selector: 'app-extension-list',
  templateUrl: './extension-list.component.html',
  styleUrls: ['./extension-list.component.css']
})
export class ExtensionListComponent implements OnInit, OnDestroy {
  rightPanelOpened = false;
  columns = [
    {
      header: 'NAME',
      field: 'name',
      sortable: true,
      sorted: true,
      queryFields : ['name'],
      sortBy: ['name'],
      widthPercent: 29
    },
    {
      header: 'DESCRIPTION',
      field: 'description',
      sortable: true,
      sorted: false,
      queryFields : ['memberCount'],
      sortBy: ['memberCount'],
      widthPercent: 71
    },
  ];
  claims: UserClaims;
  company: Account;

  claimsChangedSubscription: Subscription;
  companyChangedSubscription: Subscription;

  constructor(private appSvc: AppUserClaimsService) { }

  ngOnInit() {
    this.claimsChangedSubscription = this.appSvc.claimsChanged.subscribe(claims => {
      this.claims = claims;
    });

    this.claims = this.appSvc.getClaims();

    this.companyChangedSubscription = this.appSvc.companyChanged.subscribe(company => {
      this.company = company;
    });

    this.company = this.appSvc.getCompany();
  }

  ngOnDestroy() {
    if (this.claimsChangedSubscription) {
      this.claimsChangedSubscription.unsubscribe();
    }
    if (this.companyChangedSubscription) {
      this.companyChangedSubscription.unsubscribe();
    }

  }

  rightPanelOpenedHandler(panelOpened: boolean) {
    this.rightPanelOpened = panelOpened;
  }

}
