import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  HostListener,
  Renderer2,
  ElementRef,
  OnChanges,
  SimpleChanges, ChangeDetectionStrategy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import * as _ from 'lodash';

export enum KEY_CODE { /*  https://keycode.info/ */
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  DOWN_ARROW = 40,
  UP_ARROW = 38,
  SPACE = 32,
  ENTER = 13,
  TAB = 9
}

@Component({
  selector: 'cl-dropdown',
  templateUrl: './cl-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClDropdownComponent),
      multi: true
    }
  ],
  styleUrls: ['./cl-dropdown.component.scss']
})

export class ClDropdownComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() items: any = [];
  @Input() placeholder = '';
  @Input('disabledropdown') disabled = false;
  @Input() id = 'custom-dropdown';
  @Input() tabbable = true;
  @Input() selectUser = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() manualWidth = false;
  @Input() manualWidthValue: '';
  @Input() redBorder = false;
  @Output() clickEventTrigger: EventEmitter<any> = new EventEmitter();
  public onChange: any = Function.prototype; // Trascend the onChange event
  public onTouched: any = Function.prototype; // Trascent the onTouch event
  private _value: any; // Private variable for input value
  selectedItem: any;
  selectedItemIdx = 0;
  showMenu = false;
  onFocused = false;
  prevIndex;
  prevSelectedItem;

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue) {
      this.items = changes.items.currentValue;
    }
  }

  @HostListener('document:touchend', ['$event'])
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  keyEvent(event: KeyboardEvent) {
    if (!this.disabled) {
      if (this.showMenu) {
        if (event.keyCode === KEY_CODE.DOWN_ARROW) {
          event.preventDefault();
          this.setSelectedItem(true);
        } else if (event.keyCode === KEY_CODE.UP_ARROW) {
          event.preventDefault();
          this.setSelectedItem(false);
        } else if (event.keyCode === KEY_CODE.SPACE || event.keyCode === KEY_CODE.ENTER) {
          event.preventDefault();
          this.onItemSelect(this.selectedItem);
        } else if (event.keyCode === KEY_CODE.TAB) {
          this.showMenu = false;  // close the dropdown on tab press
        }
      } else if (this.onFocused) {
        if (event.keyCode === KEY_CODE.DOWN_ARROW && this.items) {
          event.preventDefault();
          this.showMenu = true;
          this.selectedItemIdx = this.getValueItemIdx();
          this.selectedItem = this.items[this.selectedItemIdx];
        }
      }
    }
  }

  setSelectedItem(next: boolean) {
    // Capture old selection to set background color later on.
    console.log('setSelectedItem', this.selectedItem);
    this.prevIndex = this.selectedItemIdx;
    this.prevSelectedItem = this.selectedItem;

    if (next) {
      if (this.selectedItemIdx < this.items.length - 1) {
        this.selectedItemIdx ++;
        this.selectedItem = this.items[this.selectedItemIdx];
      }
    } else {
      if (this.selectedItemIdx > 0) {
        this.selectedItemIdx --;
        this.selectedItem = this.items[this.selectedItemIdx];
      }
    }
  }

  onOutsideClick(event) {
    if ( this.showMenu === true ) {
     this.onBlur();
    }
  }

  onClick(event) {
    this.clickEventTrigger.emit();
    this.showMenu = !this.showMenu;
    // Fixing SBOSS-10360. I can't find a reason for this if and it was causing issues
    // if (!this.showMenu && !this._value) {
    //   this.resetSelectedItemIdx();
    //  } else
    if (this.showMenu && this._value) {
      this.selectedItemIdx = this.getValueItemIdx();
      this.selectedItem = this.items[this.selectedItemIdx];

    }
    if ( event ) {
      event.stopPropagation();
    }
    if ( event ) {
      event.stopPropagation();
    }
  }

  resetSelectedItemIdx() {
    this.selectedItemIdx = 0;
    if (this.items) {
      this.selectedItem = this.items[this.selectedItemIdx];
    }
  }

  onFocus() {
    if (!this.disabled) {
      this.onFocused = true;
    }
  }

  onBlur() {
    if (!this.disabled) {
      this.showMenu = false;
      this.onTouched();
      this.onFocused = false;
      if (!this._value) {
        this.resetSelectedItemIdx();
      } else {
        this.selectedItemIdx = this.getValueItemIdx();
        this.selectedItem = this.items[this.selectedItemIdx];
      }
    }
  }

  getValueItemIdx() {
    let foundIdx = 0;
    if (this._value && this.items && this.items.length > 0) {
      for (let i = 0; i < this.items.length; i++) {
        if (this._value.value === this.items[i].value) {
          foundIdx = i;
          break;
        }
      }
    }

    return foundIdx;
  }

  onItemSelect(v: any) {
    console.log('select this item ', v);
    console.log('this._value is', this._value);
    if (this._value === undefined || v.value !== this._value.value) {
      this._value = v;
      this.onChange(v);
      this.change.emit(v);
    }
    this.showMenu = false;
  }

  public validate(c: FormControl) {
    // TODO: add custom validation here
    // return (this._value && this._value.length > 0) ? null : {
    //   required: {
    //     valid: false,
    //   },
    // };
    return null;
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    console.log('Dropdown, set value', v);
    if (this._value === undefined || v.value !== this._value.value) {
      this._value = v;
      console.log('calling on change');
      this.onChange(v);
    }
  }

  // Required for ControlValueAccessor interface
  writeValue(value: any) {
    console.log('dropdown write value called with', value);
    console.log('dropdown values, ', this.items);
    if (value !== undefined && value !== null)  {
      if (this.items && this.items.length > 0) {
        const dditem = this.items.filter(item => {
          return _.isEqual(String(value.value), item.value);
        });

        console.log('dropdown values, ', this.items);
        console.log('selected item, ', dditem[0]);
        this._value = dditem[0];
        this.onChange(dditem[0]);
        this.change.emit(dditem[0]); // Add here!
      } else {
        console.log('There are no items in this list');
      }
    }
  }

  // Required for ControlValueAccessor interface
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  // Required for ControlValueAccessor interface
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
