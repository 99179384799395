import { Injectable } from '@angular/core';
import {Observable, Subject, of, Subscription} from 'rxjs';
import { AppInfo, APP_INFO } from './app-info';



@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
    private appInfo: AppInfo[];
    private appSubscription: Subscription;

  constructor() { }
  supportedLanguages = [
      { name: 'English (US)', langCode: 'en', displayAbbr: 'EN (US)' },
  ];

  getAvailableAppList(): Observable<any[]> {
   // TBD; DO WE EXPECT THIS LIST TO COME SORTED AND IF NOT HOW IS IT TO BE SORTED???

      // possible future code
      /* let availableAppList: any[] = [];
      this.appSubscription = someAppService.getAvailableApps().subscribe( (appList: AppInfo[]) => {
        this.appInfo = appList;
    });

    if ( (this.appInfo) && (this.appInfo.length > 0) ) {
        availableAppList = this.appInfo.map( app => {
            let appChildren: any[] = [];
            if ((app.getChildren()) && ( app.getChildren().length > 0)) {
                appChildren = app.getChildren().map(child => {
                    return {name: child.name, id: child.id, minRole: child.minRole, navigation: child.navigation};
                });
            }
            return {name: app.name, id: app.id, minRole: app.minRole, navigation: app.navigation,
                    iconActive: app.getIconActive(), iconInactive: app.getIconActive(), children: appChildren };
        });
    }
    return of(availableAppList); */
      return of(APP_INFO);
  }

  getSupportedLanguages(): any[] {
      return (this.supportedLanguages);
  }

    getLangCodes(): string[] {
        const langCodes: string[] = [];
        for (const lang of this.supportedLanguages) {
            langCodes.push(lang.langCode);
        }
        return langCodes;
    }
}
