<div *ngIf="isCommitmentDateInFuture" class="invoice-divider"></div>
<div *ngIf="isCommitmentDateInFuture" xmlns="http://www.w3.org/1999/html">
  
  <div class="chart-block" style="float:left; width:28%">
<!--    <canvas -->
<!--        *ngIf="showChart" -->
<!--        id="commitment-chart" -->
<!--        mdbChart [chartType]="chartType"-->
<!--        [datasets]="chartDatasets" -->
<!--        [labels]="chartLabels" -->
<!--        [colors]="chartColors" -->
<!--        [options]="chartOptions"-->
<!--        [legend]="false">-->
<!--    </canvas>-->
    <svg *ngIf="!showChart" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>

  <div style="float:right; width:72%;">
    <div class="details" id="details" *ngIf="showChart">
        <p class="first-p" id="first-p">
          <span id="currentProfileAmount"  style="display:none">{{ currentProfileAmount }}</span>
          <span id="contractProfileAmount" style="display:none">{{ contractProfileAmount }}</span>
          {{ 'commitment_chart.contract_progress' | translate:param_contract_progress }} {{countingCurrent}}
        </p>
        <p class="second-p" id="second-p"> {{ 'commitment_chart.auto_activate_on' | translate:param_auto_activate_on }}</p>
      <!-- <a href="#" id="review-contract">{{ 'commitment_chart.contract_details' | translate }}</a> -->
    </div>
    <div class="details" *ngIf="!showChart">
      <p class="first-p" style="display: inline-block; margin-top: 5px" id="contractAmountReached">
        {{ 'commitment_chart.contract_setup_complete' | translate:param_contract_setup_complete}} {{countingOnEqualAmounts}}
        <span id="fullAmount" style="display:none">{{contractProfileAmount}}</span>
      </p>
    </div>
  </div>
</div>
