import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationMessage = new Subject<any>();
  notification = this.notificationMessage.asObservable();
  constructor() { }

  updateMessage(value= '') {
    this.notificationMessage.next(value);
  }
}
