/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ConnectivityDiagnostic } from '../models/connectivity-diagnostic';
import { ConnectivityDiagnosticResult } from '../models/connectivity-diagnostic-result';
@Injectable({
  providedIn: 'root',
})
class SystemService extends __BaseService {
  static readonly SystemAuthCheckHealthPath = '/api/System/AuthCheckHealth';
  static readonly SystemCheckHealthPath = '/api/System/CheckHealth';
  static readonly SystemGetConnectivityDiagnosticsPath = '/api/System/ConnectivityDiagnostics';
  static readonly SystemRunDiagnosticPath = '/api/System/RunDiagnostic/{testId}';
  static readonly SystemGetVersionPath = '/api/System/Version';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  SystemAuthCheckHealthResponse(Authorization: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/System/AuthCheckHealth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  SystemAuthCheckHealth(Authorization: string): __Observable<{}> {
    return this.SystemAuthCheckHealthResponse(Authorization).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @return OK
   */
  SystemCheckHealthResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/System/CheckHealth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @return OK
   */
  SystemCheckHealth(): __Observable<{}> {
    return this.SystemCheckHealthResponse().pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: ViewSystemDiagnostics (10102)
   * @param Authorization access token
   * @return OK
   */
  SystemGetConnectivityDiagnosticsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<ConnectivityDiagnostic>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/System/ConnectivityDiagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ConnectivityDiagnostic>>;
      })
    );
  }
  /**
   * Required Permission: ViewSystemDiagnostics (10102)
   * @param Authorization access token
   * @return OK
   */
  SystemGetConnectivityDiagnostics(Authorization: string): __Observable<Array<ConnectivityDiagnostic>> {
    return this.SystemGetConnectivityDiagnosticsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<ConnectivityDiagnostic>)
    );
  }

  /**
   * Required Permission: ViewSystemDiagnostics (10102)
   * @param params The `SystemService.SystemRunDiagnosticParams` containing the following parameters:
   *
   * - `testId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  SystemRunDiagnosticResponse(params: SystemService.SystemRunDiagnosticParams): __Observable<__StrictHttpResponse<ConnectivityDiagnosticResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/System/RunDiagnostic/${params.testId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConnectivityDiagnosticResult>;
      })
    );
  }
  /**
   * Required Permission: ViewSystemDiagnostics (10102)
   * @param params The `SystemService.SystemRunDiagnosticParams` containing the following parameters:
   *
   * - `testId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  SystemRunDiagnostic(params: SystemService.SystemRunDiagnosticParams): __Observable<ConnectivityDiagnosticResult> {
    return this.SystemRunDiagnosticResponse(params).pipe(
      __map(_r => _r.body as ConnectivityDiagnosticResult)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @return OK
   */
  SystemGetVersionResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/System/Version`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @return OK
   */
  SystemGetVersion(): __Observable<string> {
    return this.SystemGetVersionResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module SystemService {

  /**
   * Parameters for SystemRunDiagnostic
   */
  export interface SystemRunDiagnosticParams {
    testId: number;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { SystemService }
