import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import { CalendarModule } from 'primeng/primeng';
import {CuxDropdownComponent} from './components/cux-dropdown/cux-dropdown.component';
import {LocationDropdownComponent} from './components/dropdown/location-dropdown/location-dropdown.component';
import {CuxTableComponent} from './components/cux-table/cux-table.component';
import {CheckboxModule} from 'primeng/checkbox';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ODataBuilderService} from '../services/o-data-builder.service';
import { GeographicFeatureService } from './features/geographic-feature.service';
import { CuxDatepickerComponent } from './components/cux-datepicker/cux-datepicker.component';

@NgModule({
  declarations: [
    CuxDropdownComponent,
    LocationDropdownComponent,
    CuxTableComponent,
    CuxDatepickerComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    NgbTooltipModule
  ],
  providers: [
    ODataBuilderService,
    GeographicFeatureService
  ],
  exports: [
    CuxDropdownComponent,
    LocationDropdownComponent,
    CuxTableComponent,
    CuxDatepickerComponent
  ]
})
export class SharedModule { }
