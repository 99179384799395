import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClDropdownService {

  private doAction = new Subject<boolean>();

  constructor() { }

  sendAction (isClicked: boolean) {
    this.doAction.next(isClicked);
  }

  // getAction (): Observable<boolean>{
  //   return this.doAction.asObservable();
  // }
}
