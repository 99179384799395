import { Injectable } from '@angular/core';

import { GeographicFeatureService } from './geographic-feature.service';

export const FeatureType = {
  GEOGRAPHIC: 'Geographic',  
  FEATURE: 'Feature'
};

@Injectable({providedIn: 'root'})
export class FeatureService {

  constructor(private geographicLocationFeature: GeographicFeatureService) {}


  /**
  * Gets is Enabled
  * @param featureType
  * @param featureName
  * @returns boolean if feature is enabled for particular feature type
  */

  isEnabled(type, featureName) {
    return (this.checkIsFeatureEnabled(type, featureName));  
  }

  
  /**
  * Gets is Disabled
  * @param featureType
  * @param featureName
  * @returns boolean if feature is enabled for particular feature type
  */
  isDisabled(type, featureName) {
    return (this.checkIsFeatureDisabled(type, featureName));  
  }

  
  /**
  * Check is feature Enabled
  * @param featureType
  * @param featureName
  * @returns boolean, based on feature type the respective service will be inovoked
  */
  checkIsFeatureEnabled(type, featureName) {
    let isEnabled = false;
    switch (type) {
      case FeatureType.FEATURE:
        
        break;
    
      default:
        isEnabled = this.geographicLocationFeature.isEnabled(featureName);
        break;
    }
    return isEnabled;
  }

  checkIsFeatureDisabled(type, featureName) {
    let isDisabled = false;
    switch (type) {
      case FeatureType.FEATURE:
        
        break;
    
      default:
        isDisabled = this.geographicLocationFeature.isDisabled(featureName);
        break;
    }
    return isDisabled;
  }

}