// https://medium.com/@er.surajnegi/what-are-angular-http-interceptors-and-how-to-create-them-82d1d6159c4e
// https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/

import { HttpInterceptor, HttpErrorResponse, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthenticationService as ClAuthenticationService} from '@mitel/cloudlink-sdk';
import { from, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * Add BOSS access token to http requests
 *
 BOSS SDApi

 Below is an excerpt from Confluence page https://ottjira01.mitel.com/wiki/display/OBOSS/SDApi

 Boss will accept Cloudlink Token
 > You can then use [genuine CL] token for an hour to access SD API:
 >
 > When calling into SD API, set the HTTP header like:
 >
 >    Authorization: Bearer <token>
 >
 > Replace <token> with the "access_token" value retrieve from CL.
 */
@Injectable()
export class BossAuthInteceptor implements HttpInterceptor {

    authenticationService: ClAuthenticationService;

    constructor() {
        this.authenticationService = new ClAuthenticationService({cloud: environment.cloud});
    }


    intercept(req: import('@angular/common/http').HttpRequest<any>, next: import('@angular/common/http').HttpHandler): import('rxjs').Observable<import('@angular/common/http').HttpEvent<any>> {

        if (this.hasAuthHeader(req)) {

            return next.handle(req)
                .pipe(
                    catchError(e => {
                        return this.handleError(e, req, next);
                    })
                );

        } else {

            //the if statement below is trying to filter out local/static requests that
            // do not require the token
            if ( req.url.indexOf('http') == -1 ) {
                return next.handle(req);
            }

            return from(this.authenticationService.getToken())   // from converts a promise to an observable, https://stackoverflow.com/a/45979654
                .pipe(
                    switchMap(token => {
                        // if the token is null, either another page was logged out or the token was invalidated
                        // in either case, throw an error so the next inteceptor can handle it
                        if ( token == null ) {
                            return throwError({authError: true});
                        }
                        const updatedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token.access_token) });
                        return next.handle(updatedRequest)
                            .pipe(
                                catchError(e => {
                                    return this.handleError(e, req, next);
                                })
                            );
                    })
                );
        }
    }


    hasAuthHeader(req: import('@angular/common/http').HttpRequest<any>): boolean {
        const auth = req.headers.get('Authorization');
        return  auth != null  && auth.startsWith('Bearer') && auth.length > 20;
    }

    handle401Error(req: HttpRequest<any>, next: import('@angular/common/http').HttpHandler): Observable<HttpEvent<any>> {

        // refresh the token
        return from(this.authenticationService.refresh())
            .pipe(
                switchMap(newToken => {
                    // if the token is null, either another page was logged out or the token was invalidated
                    // or the refresh timed out. in either case, throw an error so the next inteceptor can handle it
                    if ( newToken == null ) {
                        return throwError({authError: true});
                    }
                    const updatedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + newToken.access_token) });
                    return next.handle(updatedRequest);
                })
            );
    }


    handleError( e: any, req: HttpRequest<any>, next) {
        if ( e instanceof HttpErrorResponse && e.status == 401) {
            console.log('BossAuth  401, refresh token ');
            return this.handle401Error(req, next);
        } else {
            return throwError(e);
        }
    }

}
