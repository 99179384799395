import {Component, Input, Output, OnChanges, EventEmitter, OnDestroy, OnInit, SimpleChanges, forwardRef} from '@angular/core';
import {Subscription} from 'rxjs';
import { TnAdapterService } from 'src/app/services/tn-adapter.service';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import * as uuid from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import { PhoneNumberFormatService } from 'src/app/services/phone-number-format.service';
import {ModalTemplate} from '../../cl-modal/modal-template';
import {ClModalComponent} from '../../cl-modal/cl-modal.component';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


export interface TemporaryNumber {
    rcfTarget: string;
    mgmtGUID: string;
}

@Component({
  selector: 'app-temp-number',
  templateUrl: './temporary-number.component.html',
  styleUrls: ['./temporary-number.component.css']
})

export class TemporaryNumberComponent implements OnInit, OnChanges, OnDestroy {

  static  mgmtGUID = uuid();
  @Input() existingTempTn;
  @Input() tempTnRequired = false;
  @Input() countryId = 840;
  @Output() newTempTn = new EventEmitter<TemporaryNumber>();

  rxjsSubscriptions: Subscription[] = [];
  assignText1 = this.translateSvc.instant('temporary_number.assign_msg_start');
  assignText2 = this.translateSvc.instant('temporary_number.assign_msg_end');
  keepText1 = this.translateSvc.instant('temporary_number.keep_msg_start');
  keepText2 = this.translateSvc.instant('temporary_number.keep_msg_end');
  removeText1 = this.translateSvc.instant('temporary_number.remove_msg_start');
  removeText2 = this.translateSvc.instant('temporary_number.remove_msg_end');
  errText1 = this.translateSvc.instant('temporary_number.no_num_error_headng');
  learnMoreErrMsg = this.translateSvc.instant('temporary_number.no_num_error_msg');
  learnMore = this.translateSvc.instant('temporary_number.learn_more');

  modalRef: NgbModalRef;
  @Input() textSegment1 = this.assignText1;
  @Input() textSegment2 = this.assignText2;
  tempNumber: TemporaryNumber = {rcfTarget: null, mgmtGUID: TemporaryNumberComponent.mgmtGUID};
  hasExistingTempTn = false;
  errorContext = false;
  // where should this URL come from?
  learnMoreUrl = 'https://docs.shoretel.com/connectcloud/console/TemporaryForwarding';
  componentInitialized = false;

  constructor(    public tnAdapterSvc: TnAdapterService,
                  public phoneNumberFormatService: PhoneNumberFormatService,
                  private translateSvc: TranslateService,
                  private modalSvc: NgbModal) { }

  ngOnInit() {
      if (this.existingTempTn && this.existingTempTn.rcfTarget > 0) {
          this.tempNumber.rcfTarget = this.existingTempTn.rcfTarget;
          this.hasExistingTempTn = true;
          this.formatTempNumber();
      }

      if (this.tempTnRequired ) {
          this.setupNewOrExistingTn();
      } else {
          this.updateMessageContextWhenNotUsingTn();
      }
      this.componentInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {

      if (this.componentInitialized) {
          if (changes.tempTnRequired) {
              if (changes.tempTnRequired.currentValue === true) {
                  this.setupNewOrExistingTn();
              } else {
                  if (this.isValidTempTnSet()) {
                      this.textSegment1 = this.removeText1;
                      this.textSegment2 = this.removeText2;
                  }
              }
          }
      }
  }

  setupNewOrExistingTn() {
      if (!this.isValidTempTnSet()) {
          this.textSegment1 = this.assignText1;
          this.textSegment2 = this.assignText2;
          this.setupTemporaryNumber();
      } else if (this.hasExistingTempTn) {
          this.textSegment1 = this.keepText1;
          this.textSegment2 = this.keepText2;
      }
  }

    updateMessageContextWhenNotUsingTn() {
        if (this.isValidTempTnSet()) {
            this.textSegment1 = this.removeText1;
            this.textSegment2 = this.removeText2;
        }
    }

  ngOnDestroy() {
      this.rxjsSubscriptions.forEach( s => s.unsubscribe());
  }

  isValidTempTnSet() {
      let rc = false;
      if (this.tempNumber.rcfTarget && this.tempNumber.rcfTarget.length > 0) {
          rc = true;
      }
      return rc;
  }

  async setupTemporaryNumber() {
      this.rxjsSubscriptions.push(this.tnAdapterSvc.getTemporaryNumber(TemporaryNumberComponent.mgmtGUID, this.countryId).subscribe(tempNumberString => {

          this.tempNumber.rcfTarget = tempNumberString;
          this.tempNumber.mgmtGUID = TemporaryNumberComponent.mgmtGUID;
          if (this.tempNumber.rcfTarget && this.tempNumber.rcfTarget.length > 0) {
               this.formatTempNumber();
          }
          this.newTempTn.emit(this.tempNumber);
          this.errorContext = false;
          },
          (error) => {
              this.rxjsSubscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                  console.log(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
              }));
              this.textSegment1 = this.errText1;
              this.textSegment2 = '';
              this.tempNumber.rcfTarget = '';
              this.errorContext = true;
          }));
  }

  async formatTempNumber() {
      await this.phoneNumberFormatService.formatPhoneNumberUsingIso3166NumericCountryCode(this.tempNumber.rcfTarget, this.countryId).then(result => this.tempNumber.rcfTarget = result);
  }

  // Removing this for now as it doesn't look like we will explicitly release the numbers even when unassigned as this should get taken care of in the backend
  /* releaseTemporaryNumber() {
      this.tempNumber.rcfTarget = '';
      this.rxjsSubscriptions.push(this.tnAdapterSvc.releaseTemporaryNumber(TemporaryNumberComponent.mgmtGUID).subscribe(count => {
              this.newTempTn.emit(this.tempNumber);
          },
          (error) => {
              this.rxjsSubscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                  console.log(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  console.log(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  this.textSegment1 = this.errText1;
                  this.textSegment2 = '';
              }));
          }));
  } */

  openLearnMore() {
      if (this.errorContext === true) {
          this.openErrorMsgModal();
      } else {
          this.openLearnMorePage();
      }
  }

  openLearnMorePage() {
      window.open(this.learnMoreUrl);
  }

  openErrorMsgModal() {
      if (!this.modalRef) {

          const modalTemp: ModalTemplate = {
              title: 'temporary_number.no_num_error_headng',
              content: 'temporary_number.no_num_error_msg',
              isContentHTML: false,
              primaryBtn: {
                  value: 'exit',
                  label: 'temporary_number.got_it',
                  width: 137
              },
          };

          const options: NgbModalOptions = {
              windowClass: 'sm-modal',
              backdrop: 'static'
          };

          this.modalRef = this.modalSvc.open(ClModalComponent, options);
          this.modalRef.componentInstance.modalTemp = modalTemp;

          this.modalRef.result.then((result) => {
              this.modalRef = null;
              if (result === 'exit') {
                 this.onCloseErr();
              }
              this.modalRef = null;
          }, (reason) => {
              this.modalRef = null;
          });
      }
  }

  onCloseErr() {
      this.errorContext = false;
  }
}
