import { AvailableNumbersModel, NumberRange } from './models';
import { messageTypes, numberTypes } from './constants';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LocationDC } from 'src/app/modules/boss-api/generated/models';

interface ProcessedNumbers {
  showMessage?: boolean,
  messageType?: string,
  messageStyle?: string,
  availableNumbers?: AvailableNumbersModel[],
  numberRange?: NumberRange
}

const parseNumberRange = (availableNumbers: AvailableNumbersModel[]): NumberRange => {
  let parsedNumberRange: NumberRange = {
    range: '',
    location: ''
  };
  let totalNumbers = availableNumbers.length;
  // parse the numbers here to match the UI
  if (totalNumbers) {
    let firstNumberFragment = availableNumbers[0].displayName;
    let lastNumber = availableNumbers[totalNumbers - 1].value;
    let lastNumberFragment = lastNumber && lastNumber.substr(lastNumber.length - 4);
    parsedNumberRange.range = `${firstNumberFragment} - ${lastNumberFragment}`;
    parsedNumberRange.location = availableNumbers[0].address;
  }
  return parsedNumberRange;
}

export const processNumbers = (availableNumbers: AvailableNumbersModel[], continuousRange?: boolean, quantity?: number, numberType?: string, translateSvc?: TranslateService, userLoc?: LocationDC): ProcessedNumbers => {
  let processed: ProcessedNumbers = {};
  if (availableNumbers) {
    if (availableNumbers.length === 1) {
      if (availableNumbers[0]['isOutsideServiceArea']) {
        processed.showMessage = true;
        processed.availableNumbers = [];
        processed.numberRange = parseNumberRange([]);
        processed.messageStyle = "error";
        processed.messageType = userLoc && userLoc.countryName === 'Canada' ?
            messageTypes.outsideCanadaArea : messageTypes.outsideServiceArea;
      } else if (Object.keys(availableNumbers[0]).length === 0) {
        processed.showMessage = true;
        processed.availableNumbers = [];
        processed.numberRange = parseNumberRange([]);
        processed.messageStyle = "error";
        processed.messageType = continuousRange ?
          messageTypes.noMatchingRanges : messageTypes.noMatchFound;
      } else if (!continuousRange && numberType === numberTypes.localNumber && quantity && availableNumbers.length < quantity) {
        processed.showMessage = true;
        processed.messageStyle = "warning";
        processed.availableNumbers = availableNumbers;
        processed.numberRange = parseNumberRange(availableNumbers);

        let msgTypeStart = 'choose_new_number.message_box.';
        let msgTypeEnd = messageTypes.lessThanResults;
        const msgText = translateSvc && translateSvc.instant(msgTypeStart + msgTypeEnd);
        processed.messageType = msgText && msgText.replace('<available_length>', availableNumbers.length);
      } else {
        processed.showMessage = false;
        processed.messageType = null;
        processed.messageStyle = null;
        processed.availableNumbers = availableNumbers;
        processed.numberRange = parseNumberRange(availableNumbers);
      }
    } else if (!continuousRange && numberType === numberTypes.localNumber && quantity && availableNumbers.length < quantity) {
      processed.showMessage = true;
      processed.messageStyle = "warning";
      processed.availableNumbers = availableNumbers;
      processed.numberRange = parseNumberRange(availableNumbers);

      let msgTypeStart = 'choose_new_number.message_box.';
      let msgTypeEnd = messageTypes.lessThanResults;
      const msgText = translateSvc && translateSvc.instant(msgTypeStart + msgTypeEnd);
      processed.messageType = msgText && msgText.replace('<available_length>', availableNumbers.length);
    } else {
      processed.showMessage = false;
      processed.messageType = null;
      processed.messageStyle = null;
      processed.availableNumbers = availableNumbers;
      processed.numberRange = parseNumberRange(availableNumbers);
    }
  }

  return processed;
}

export const highlightNumber=(key:string, numbers: AvailableNumbersModel[]): AvailableNumbersModel[] => {
  //if no key or numbers just return the original numbers
  if(!key || !numbers || _.isEmpty(numbers[0]) || numbers[0].isOutsideServiceArea){
    return numbers;
  }

  let tasRex = new RegExp(key, 'gi');
  let didMatch: boolean = false;

  return numbers.map(num => {
    let displayName = num.displayName.replace('-', '');
    let modNum = { ...num };

    didMatch = tasRex.test(displayName);
    if (didMatch) {
      let highlightedNum = displayName.replace(tasRex, `<strong>${key}</strong>`);
      //rebuild the number format
      let newNumFormatted = '';
      let areaCodeSection = highlightedNum.substr(0, 4);
      newNumFormatted += areaCodeSection;
      let nonAreaCodeSection = highlightedNum.substr(4);
      let numberCharCounter = 0;
      let hasDashBeenAdded = false;
      for (let char of nonAreaCodeSection) {
        if (char >= '0' && char <= '9') {
          numberCharCounter++;
        }
        newNumFormatted += char;

        if (numberCharCounter === 3 && !hasDashBeenAdded) {
          newNumFormatted += '-'
          hasDashBeenAdded = true;
        }
      }
      modNum.displayName = newNumFormatted;
    }
    return modNum;
  })
}
