<div class="container" [ngClass]="{'container_non_pop': !popUp}">
    <div class="row top-header" [ngClass]="{'title-row-non-pop': !popUp}">
        <div class="col-12 title_non_popup" *ngIf="!popUp">
            {{ schedule? schedule.name : ('custom.new_title'| translate)}}
        </div>
        <div class="col-10 title" *ngIf="popUp">
            {{'custom.add_title' | translate}}
        </div>
        <div class="col-2 header-close" *ngIf=popUp>
            <button id="on-hours-close" type="button" class="close closeX" aria-label="Close"
                    (click)="cancel()"
                    aria-hidden="true">
                &#10005;
            </button>
        </div>
    </div>
    <div *ngIf="showFormError" class="errorPlacement">
        <cl-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></cl-error-alert>
    </div>
    <div *ngFor="let err of otherErr" class="text-danger error-align"> {{err}}</div>
    <form [formGroup]="customForm" (ngSubmit)="onSubmit()" id="customPanel">
        <div formGroupName="customSection" class="content-scroll-block"
             [ngClass]="popUp===true ? 'schedule-content': 'schedule-content-non-popup'">
            <div class="row content-1">
                <div class="row">
                    <div class="form-group col-11 name">
                        <label for="name">{{ 'custom.name' | translate }}</label>
                        <input
                                type="text"
                                id="scheduleName"
                                formControlName="name"
                                [attr.tabindex]="tabbable ? 0 : -1"
                                maxlength="50"
                                class="form-control schedules-non-popup-width">
                        <div class="text-danger"
                             *ngIf="customForm.get('customSection.name').touched && customForm.get('customSection.name').hasError('required')">
                            {{ 'custom.name_required' | translate }}
                        </div>
                        <div class="text-danger" *ngIf="scheduleNameError != ''"> {{ scheduleNameError }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-11 timezone" *ngIf="timeZones.length > 0">
                        <label for="timeZone">{{ 'custom.time_zone' | translate }}</label>
                        <cl-dropdown [items]="timeZones" formControlName="timeZone" id="customTimeZoneDropdown"
                                     [manualWidth]="true"
                                     [manualWidthValue]="'412px'"
                                     [tabbable]="tabbable"
                                     ngDefaultControl (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
                        <div class="text-danger" id="timeZoneTextDanger"
                             *ngIf="customForm.get('customSection.timeZone').touched && customForm.get('customSection.timeZone').hasError('required')">
                            {{ 'custom.time_zone_required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="row" [hidden]="showCustomSchedule">
                    <div class="no-custom-text form-group"
                         [ngClass]="{'no-custom-text-non-popup': !popUp}">{{ 'custom.empty_custom_message' | translate
                        }}
                    </div>
                </div>
                <div class="row" [hidden]="showCustomSchedule">
                    <div class="form-group col-2 ">
                        <button
                                type="button"
                                class="btn btn-default btn-add"
                                [attr.tabindex]="tabbable ? 0 : -1"
                                (click)="addCustomSchedule(-1)">{{ 'custom.add' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="row content-2" [ngClass]="{'content-2-non-popup': !popUp}" [hidden]="!showCustomSchedule">
                <div formArrayName="customSchedule"
                     *ngFor="let schedule of customForm.get('customSection').get('customSchedule')['controls']; let i = index">
                    <div class="row" [formGroupName]="i">
                        <div class="form-group col-9 schedule-name">
                            <input
                                    type="text"
                                    formControlName="name"
                                    [attr.tabindex]="tabbable ? 0 : -1"
                                    maxlength="50"
                                    class="form-control schedule-input">
                            <div class="text-danger"
                                 *ngIf="customForm.get('customSection').get('customSchedule').controls[i].controls.name.touched && customForm.get('customSection').get('customSchedule').controls[i].controls.name.hasError('required')">
                                {{ 'custom.schedule_name_required' | translate }}
                            </div>
                        </div>
                        <div class="form-group col-1 ">
                            <a href="javascript:" class="hours-add hours-delete"
                               [ngClass]="{'custom-delete-non-popup': !popUp}"
                               (click)="removeCustomSchedule(i)"><span class="clear-icon"></span></a>
                        </div>
                        <div class="form-group col-1">
                            <a href="javascript:" class="hours-add"
                               [ngClass]="{'hours-add-non-popup': !popUp}"
                               (click)="addCustomSchedule(i)"> {{'custom.add' | translate}}</a>
                        </div>
                        <br>
                        <div class="form-group col-4 schedule-date-field">
                            <cux-datepicker
                                id="{{i}}scheduleDate"
                                formControlName="scheduleDate"
                                [attr.tabindex]="tabbable ? 0 : -1"
                                (onSelect)="updateScheduleDate($event, i)"
                                (onBlur)="updateScheduleDate($event, i)"
                                [appendTo]="'body'">
                            </cux-datepicker>
                        </div>
                        <div class="form-group col-3 hours-time-span">
                            <cux-datepicker
                                id="{{i}}startTime"
                                formControlName="startTime"
                                [isTimeOnly]="true"
                                (onSelect)="updateStartTime($event, i)"
                                (onBlur)="updateStartTime($event, i)"
                                [appendTo]="'body'">
                            </cux-datepicker>
                        </div>
                        <div style="position: relative">
                            <span class="span-time">-</span>
                        </div>
                        <div class="form-group col-3 hours-time-span">
                            <cux-datepicker
                                id="{{i}}stopTime"
                                formControlName="stopTime"
                                [isTimeOnly]="true"
                                (onSelect)="updateStopTime($event, i)"
                                (onBlur)="updateStopTime($event, i)"
                                [containsError]="customForm.get('customSection').get('customSchedule').controls[i].controls.stopTimeValidation.value"
                                [appendTo]="'body'">
                            </cux-datepicker>
                        </div>
                        <div class="text-danger text-danger-fix stop-time-validation-block"
                             *ngIf="customForm.get('customSection').get('customSchedule').controls[i].controls.stopTimeValidation.value">
                            <span class="stop-time-validation-span">
                                {{ customForm.get('customSection').get('customSchedule').controls[i].controls.stopTimeValidationErrorMessage.value | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" [ngClass]="{'footer-non-popup': !popUp}">
            <div class="form-group col-12 footer-block">
                <div *ngIf="updateSchedule" class="delete-action">
                    <button
                            type="button"
                            class="btn btn-default delete-hg"
                            (click)="onDelete()">
                        <img class="icon-delete-button"
                             src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAQABADASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAII/8QAHxAAAgICAgMBAAAAAAAAAAAAAQMCEQQSACEiMlET/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANIZCzkFscl85LW8LENIkeQFX137cY6zj/lHGyJhbMiS5R0iBYBuuuvXl5Rgp74tZBQm5bomZoEDW6P3xPGMVteiKWQbo9jpGBsAHarP3yHA/9k="
                             alt="delete">{{ 'custom.delete' | translate }}
                    </button>
                </div>

                <div class="pull-right actions">
                    <button
                            type="button"
                            class="btn btn-default"
                            [attr.tabindex]="tabbable ? 0 : -1"
                            (click)="cancel()">{{ 'custom.cancel' | translate }}
                    </button>
                    <button *ngIf="!updateSchedule"
                            type="submit"
                            class="btn btn-primary" style="width:148px"
                            [attr.tabindex]="tabbable ? 0 : -1">{{ 'custom.add_schedule' | translate }}
                    </button>
                    <button *ngIf="updateSchedule "
                            type="submit"
                            class="btn btn-primary"
                            [attr.tabindex]="tabbable ? 0 : -1">{{ 'custom.update' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>


<app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" [customPosition]="{ top: '45%', left: '22%' }" message="{{ 'app.loading' | translate}}"></app-spinner>
