/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Did } from '../models/did';
import { CreateDidRequest } from '../models/create-did-request';
import { DidCollection } from '../models/did-collection';
import { UpdateDidRequest } from '../models/update-did-request';

/**
 * Operations related to DID numbers
 */
@Injectable({
  providedIn: 'root',
})
class DIDNumbersService extends __BaseService {
  static readonly createDidPath = '/dids';
  static readonly getDidsPath = '/dids';
  static readonly getDidPath = '/dids/{didNumber}';
  static readonly updateDidPath = '/dids/{didNumber}';
  static readonly deleteDidPath = '/dids/{didNumber}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create DID. It can be assigned to an entity, i.e to user, app, and group etc. through creation.
   * @param body undefined
   * @return Ok
   */
  createDidResponse(body: CreateDidRequest): __Observable<__StrictHttpResponse<Did>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Did>;
      })
    );
  }
  /**
   * Create DID. It can be assigned to an entity, i.e to user, app, and group etc. through creation.
   * @param body undefined
   * @return Ok
   */
  createDid(body: CreateDidRequest): __Observable<Did> {
    return this.createDidResponse(body).pipe(
      __map(_r => _r.body as Did)
    );
  }

  /**
   * Get all DIDs from your account.
   * @return Success.
   */
  getDidsResponse(): __Observable<__StrictHttpResponse<DidCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DidCollection>;
      })
    );
  }
  /**
   * Get all DIDs from your account.
   * @return Success.
   */
  getDids(): __Observable<DidCollection> {
    return this.getDidsResponse().pipe(
      __map(_r => _r.body as DidCollection)
    );
  }

  /**
   * Get the DID from your account.
   * @param didNumber DID number Identifier, the value is the DID. It is in E.164 format
   * @return Success. Returns details of the DID.
   */
  getDidResponse(didNumber: string): __Observable<__StrictHttpResponse<Did>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dids/${didNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Did>;
      })
    );
  }
  /**
   * Get the DID from your account.
   * @param didNumber DID number Identifier, the value is the DID. It is in E.164 format
   * @return Success. Returns details of the DID.
   */
  getDid(didNumber: string): __Observable<Did> {
    return this.getDidResponse(didNumber).pipe(
      __map(_r => _r.body as Did)
    );
  }

  /**
   * Update the DID for the provided did number.
   * @param params The `DIDNumbersService.UpdateDidParams` containing the following parameters:
   *
   * - `didNumber`: DID number Identifier, the value is the DID. It is in E.164 format
   *
   * - `body`:
   *
   * @return Ok
   */
  updateDidResponse(params: DIDNumbersService.UpdateDidParams): __Observable<__StrictHttpResponse<Did>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dids/${params.didNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Did>;
      })
    );
  }
  /**
   * Update the DID for the provided did number.
   * @param params The `DIDNumbersService.UpdateDidParams` containing the following parameters:
   *
   * - `didNumber`: DID number Identifier, the value is the DID. It is in E.164 format
   *
   * - `body`:
   *
   * @return Ok
   */
  updateDid(params: DIDNumbersService.UpdateDidParams): __Observable<Did> {
    return this.updateDidResponse(params).pipe(
      __map(_r => _r.body as Did)
    );
  }

  /**
   * Delete the DID from your account.
   * @param didNumber DID number Identifier, the value is the DID. It is in E.164 format
   */
  deleteDidResponse(didNumber: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dids/${didNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the DID from your account.
   * @param didNumber DID number Identifier, the value is the DID. It is in E.164 format
   */
  deleteDid(didNumber: string): __Observable<null> {
    return this.deleteDidResponse(didNumber).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DIDNumbersService {

  /**
   * Parameters for updateDid
   */
  export interface UpdateDidParams {

    /**
     * DID number Identifier, the value is the DID. It is in E.164 format
     */
    didNumber: string;
    body: UpdateDidRequest;
  }
}

export { DIDNumbersService }
