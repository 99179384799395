/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RecordingCollection } from '../models/recording-collection';
import { Recording } from '../models/recording';

/**
 * Call recordings generated by calls within the system
 */
@Injectable({
  providedIn: 'root',
})
class RecordingsService extends __BaseService {
  static readonly getRecordingsPath = '/recordings';
  static readonly getRecordingPath = '/recordings/{recordingId}';
  static readonly deleteRecordingPath = '/recordings/{recordingId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves recordings from the system.  Recordings that you don't have permission to view will not be returned.  Media URLs will only be generated for individual getRecording operations.  Recordings cannot be created.  They're created by the system from the call media operations.
   * @param params The `RecordingsService.GetRecordingsParams` containing the following parameters:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$orderby`: Odata filter param
   *
   * - `$filter`: Odata filter param
   *
   * @return Ok
   */
  getRecordingsResponse(params: RecordingsService.GetRecordingsParams): __Observable<__StrictHttpResponse<RecordingCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Orderby != null) __params = __params.set('$orderby', params.Orderby.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/recordings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecordingCollection>;
      })
    );
  }
  /**
   * Retrieves recordings from the system.  Recordings that you don't have permission to view will not be returned.  Media URLs will only be generated for individual getRecording operations.  Recordings cannot be created.  They're created by the system from the call media operations.
   * @param params The `RecordingsService.GetRecordingsParams` containing the following parameters:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$orderby`: Odata filter param
   *
   * - `$filter`: Odata filter param
   *
   * @return Ok
   */
  getRecordings(params: RecordingsService.GetRecordingsParams): __Observable<RecordingCollection> {
    return this.getRecordingsResponse(params).pipe(
      __map(_r => _r.body as RecordingCollection)
    );
  }

  /**
   * Retrieves meta data about the recording.  The media can be retrieved via a time limited URL in the _embedded data.
   * @param params The `RecordingsService.GetRecordingParams` containing the following parameters:
   *
   * - `recordingId`: ID of the recording
   *
   * - `media-format`: Format of the file in the embedded media link.
   *
   * @return Recording metadata
   */
  getRecordingResponse(params: RecordingsService.GetRecordingParams): __Observable<__StrictHttpResponse<Recording>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.mediaFormat != null) __params = __params.set('media-format', params.mediaFormat.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/recordings/${params.recordingId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Recording>;
      })
    );
  }
  /**
   * Retrieves meta data about the recording.  The media can be retrieved via a time limited URL in the _embedded data.
   * @param params The `RecordingsService.GetRecordingParams` containing the following parameters:
   *
   * - `recordingId`: ID of the recording
   *
   * - `media-format`: Format of the file in the embedded media link.
   *
   * @return Recording metadata
   */
  getRecording(params: RecordingsService.GetRecordingParams): __Observable<Recording> {
    return this.getRecordingResponse(params).pipe(
      __map(_r => _r.body as Recording)
    );
  }

  /**
   * Removes the recording from the system.  This operation requires priviledged access.
   * @param recordingId ID of the recording
   */
  deleteRecordingResponse(recordingId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/recordings/${recordingId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes the recording from the system.  This operation requires priviledged access.
   * @param recordingId ID of the recording
   */
  deleteRecording(recordingId: string): __Observable<null> {
    return this.deleteRecordingResponse(recordingId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RecordingsService {

  /**
   * Parameters for getRecordings
   */
  export interface GetRecordingsParams {

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Odata filter param
     */
    Orderby?: string;

    /**
     * Odata filter param
     */
    Filter?: string;
  }

  /**
   * Parameters for getRecording
   */
  export interface GetRecordingParams {

    /**
     * ID of the recording
     */
    recordingId: string;

    /**
     * Format of the file in the embedded media link.
     */
    mediaFormat?: string;
  }
}

export { RecordingsService }
