import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { AppUserClaimsService } from './app-user-claims.service';
import { take, map, catchError } from 'rxjs/operators';
import { UserRole } from './user-adaptor.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(
    private appUserClaimsSvc: AppUserClaimsService,
    private myRoute: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean> {
      return forkJoin(
        // If not already loaded, we will wait for our dependencies to load....
        this.appUserClaimsSvc.getBossAccountCached(),
        this.appUserClaimsSvc.bossRoleIsReady.asObservable().pipe(take(1)),
        this.appUserClaimsSvc.applnIdentityIsLoaded.asObservable().pipe(take(1))
      )
      .pipe(
        map( data => {
          const account = data[0];
          const targetAccount = this.appUserClaimsSvc.getTargetAccount();
          // console.log("The boss account data is ", account);
          if ( account === null  || data[1] !== true || data[2] !== true) {
            return false; // things may not be loaded yet so don't display an error
          }

        // if this account is not mcss enabled, then there is no access
        if (account != null && ((account.ismcssEnabled === undefined) ||
            (account.ismcssEnabled !== undefined && account.ismcssEnabled === false && !targetAccount))) {
          // this is not an mcss account. No role has access
          console.log('THIS IS NOT AN MCSS ACCOUNT');
          // we only show the error if we are sure the user identity is full loaded.
          // We know it is loaded because we waited for it in above forkJoin().
          this.show403Error();
          return false;
        }

          const roles = route.data['roles'] as Array<UserRole>;
          const currentroles = this.appUserClaimsSvc.getBossRoles();
          // console.log("route guard is using roles ", currentroles);
          if ( currentroles ) {
            for ( let i = 0; i < currentroles.length; i++ ) {
              if ( roles.includes(currentroles[i]) ) {
                return true;
              }
            }
            this.show403Error();
            return false;
          } else {
            // this should be a temporary state so we will not show the 403 error
            return false;
          }
        }),
        catchError( error => {
          console.log('Route guard received an error on blocking subjects, automatic 403 message', error);
          this.show403Error();
          return of(false);
        }));
    }

    private show403Error() {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          'nav_error': '403'
        }
      };
      this.myRoute.navigate(['navigationError'], navigationExtras);
    }

    public show500Error() {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                'nav_error': '500'
            }
        };
        this.myRoute.navigate(['navigationError'], navigationExtras);
    }
}
