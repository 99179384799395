/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccountsService } from './services/accounts.service';
import { AddressService } from './services/address.service';
import { BillingService } from './services/billing.service';
import { CallFlowsService } from './services/call-flows.service';
import { ClustersService } from './services/clusters.service';
import { ContractsService } from './services/contracts.service';
import { EmergencyService } from './services/emergency.service';
import { EntitlementsService } from './services/entitlements.service';
import { FeaturesService } from './services/features.service';
import { LocationsService } from './services/locations.service';
import { NotificationsService } from './services/notifications.service';
import { OrdersService } from './services/orders.service';
import { PartnersService } from './services/partners.service';
import { PasswordManagementService } from './services/password-management.service';
import { PhonesService } from './services/phones.service';
import { PhoneSettingsService } from './services/phone-settings.service';
import { ProductsService } from './services/products.service';
import { SystemService } from './services/system.service';
import { TasksService } from './services/tasks.service';
import { TnsService } from './services/tns.service';
import { UsersService } from './services/users.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccountsService,
    AddressService,
    BillingService,
    CallFlowsService,
    ClustersService,
    ContractsService,
    EmergencyService,
    EntitlementsService,
    FeaturesService,
    LocationsService,
    NotificationsService,
    OrdersService,
    PartnersService,
    PasswordManagementService,
    PhonesService,
    PhoneSettingsService,
    ProductsService,
    SystemService,
    TasksService,
    TnsService,
    UsersService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
