import { Component, OnInit, Input } from '@angular/core';
import { NumberRange } from '../models';


@Component({
  selector: 'number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss']
})
export class NumberRangeComponent implements OnInit {
  @Input() numberRange: NumberRange = {range: '', location: ''};
  constructor() { }

  ngOnInit() {
  }

}
