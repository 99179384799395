export interface InvoiceSection {
    header: {
        title: string;
        subtitle?: string;
    };
    items: {
        label: string;
        prefix?: string; // $
        price: string; // 5.99
        suffix?: string; // /mo.
    }[];
    summary: {
        label: string;
        arrowup?: boolean; // if true, place a up arrow in front of prefix
        arrowdown?: boolean; // if true, place a down arrow in front of prefix
        prefix?: string; // $
        price: string; // 5.99   or   5,99 €
        suffix?: string; // /mo.
    };
}

export class InvoiceSmallModel {
    header?: {
        title?: string;
        subtitle?: string;
    };

    sections: InvoiceSection[] = [];

    footers?: string[];
}

export class OrderPreview {
    header?: {
        title?: string;
        subtitle?: string;
    };
    nextInvoice: InvoiceSection;
    changes: InvoiceSection;
    chargeTo = '';
    shipmentDetails = {
        shipTo: '',
        shippingSpeed: 1
    };
    currencyCode = 'USD';
}
