/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LocationDC } from '../models/location-dc';
import { LocationExDC } from '../models/location-ex-dc';
import { LocationDefaults } from '../models/location-defaults';
import { AddressDC } from '../models/address-dc';
import { LocationSummaryDC } from '../models/location-summary-dc';
import { ValidateAreaCodesResultDC } from '../models/validate-area-codes-result-dc';
import { ValidateAreaCodesRequestDC } from '../models/validate-area-codes-request-dc';
import { CountryDC } from '../models/country-dc';
import { CreateLocationDC } from '../models/create-location-dc';
import { OrderDC } from '../models/order-dc';
import { CloseLocationRequestDC } from '../models/close-location-request-dc';
import { BillingImpactDC } from '../models/billing-impact-dc';
import { SOXDetailsDC } from '../models/soxdetails-dc';
import { EditLocationDC } from '../models/edit-location-dc';
@Injectable({
  providedIn: 'root',
})
class LocationsService extends __BaseService {
  static readonly LocationsGetLocationsByAccountPath = '/api/Account/Locations';
  static readonly LocationsGetLocationPath = '/api/Account/Locations/{locationUuid}';
  static readonly LocationsGetLocationDefaultsPath = '/api/Account/Locations/Default';
  static readonly LocationsGetOnboardingLocationsByAccountPath = '/api/Account/Locations/Onboarding';
  static readonly LocationsGetAccountLocationsSummaryPath = '/api/Account/Locations/Summary';
  static readonly LocationsValidateAreaCodesPath = '/api/Account/Locations/ValidateAreaCodes';
  static readonly LocationsGetCountriesPath = '/api/Countries';
  static readonly LocationsCreateLocationPath = '/api/Locations';
  static readonly LocationsCloseLocationPath = '/api/Locations/{locationUuid}/Close';
  static readonly LocationsGetCloseLocationBillingImpactPath = '/api/Locations/{locationUuid}/Close/BillingImpact';
  static readonly LocationsEditLocationPath = '/api/Locations/{uuid}';
  static readonly LocationsGetLocationStatusesPath = '/api/LocationStatuses';
  static readonly LocationsGetLocationTypesPath = '/api/LocationTypes';
  static readonly LocationsGetTermsOfServicePath = '/api/TermsOfService';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: ViewLocations (126), ViewAccountDetails (108), ViewOrganizationDetails (210)
   * @param params The `LocationsService.LocationsGetLocationsByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `typeIds`:
   *
   * - `statusIds`:
   *
   * @return OK
   */
  LocationsGetLocationsByAccountResponse(params: LocationsService.LocationsGetLocationsByAccountParams): __Observable<__StrictHttpResponse<Array<LocationDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.typeIds || []).forEach(val => {if (val != null) __params = __params.append('typeIds', val.toString())});
    (params.statusIds || []).forEach(val => {if (val != null) __params = __params.append('statusIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126), ViewAccountDetails (108), ViewOrganizationDetails (210)
   * @param params The `LocationsService.LocationsGetLocationsByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `typeIds`:
   *
   * - `statusIds`:
   *
   * @return OK
   */
  LocationsGetLocationsByAccount(params: LocationsService.LocationsGetLocationsByAccountParams): __Observable<Array<LocationDC>> {
    return this.LocationsGetLocationsByAccountResponse(params).pipe(
      __map(_r => _r.body as Array<LocationDC>)
    );
  }

  /**
   * Required Permission: ViewLocations (126), ViewAccountDetails (108), ViewOrganizationDetails (210)
   * @param params The `LocationsService.LocationsGetLocationParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetLocationResponse(params: LocationsService.LocationsGetLocationParams): __Observable<__StrictHttpResponse<LocationExDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationExDC>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126), ViewAccountDetails (108), ViewOrganizationDetails (210)
   * @param params The `LocationsService.LocationsGetLocationParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetLocation(params: LocationsService.LocationsGetLocationParams): __Observable<LocationExDC> {
    return this.LocationsGetLocationResponse(params).pipe(
      __map(_r => _r.body as LocationExDC)
    );
  }

  /**
   * Required Permission: ViewLocations (126)
   * @param params The `LocationsService.LocationsGetLocationDefaultsParams` containing the following parameters:
   *
   * - `address`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetLocationDefaultsResponse(params: LocationsService.LocationsGetLocationDefaultsParams): __Observable<__StrictHttpResponse<LocationDefaults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.address;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/Default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationDefaults>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126)
   * @param params The `LocationsService.LocationsGetLocationDefaultsParams` containing the following parameters:
   *
   * - `address`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetLocationDefaults(params: LocationsService.LocationsGetLocationDefaultsParams): __Observable<LocationDefaults> {
    return this.LocationsGetLocationDefaultsResponse(params).pipe(
      __map(_r => _r.body as LocationDefaults)
    );
  }

  /**
   * Required Permission: ViewLocations (126)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetOnboardingLocationsByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<LocationDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/Onboarding`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetOnboardingLocationsByAccount(Authorization: string): __Observable<Array<LocationDC>> {
    return this.LocationsGetOnboardingLocationsByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<LocationDC>)
    );
  }

  /**
   * Required Permission: ViewLocations (126)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetAccountLocationsSummaryResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<LocationSummaryDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/Summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LocationSummaryDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetAccountLocationsSummary(Authorization: string): __Observable<Array<LocationSummaryDC>> {
    return this.LocationsGetAccountLocationsSummaryResponse(Authorization).pipe(
      __map(_r => _r.body as Array<LocationSummaryDC>)
    );
  }

  /**
   * Required Permission: ViewLocations (126)
   * @param params The `LocationsService.LocationsValidateAreaCodesParams` containing the following parameters:
   *
   * - `requests`: A list of StateKey and AreaCodes
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsValidateAreaCodesResponse(params: LocationsService.LocationsValidateAreaCodesParams): __Observable<__StrictHttpResponse<Array<ValidateAreaCodesResultDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.requests;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/ValidateAreaCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ValidateAreaCodesResultDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126)
   * @param params The `LocationsService.LocationsValidateAreaCodesParams` containing the following parameters:
   *
   * - `requests`: A list of StateKey and AreaCodes
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsValidateAreaCodes(params: LocationsService.LocationsValidateAreaCodesParams): __Observable<Array<ValidateAreaCodesResultDC>> {
    return this.LocationsValidateAreaCodesResponse(params).pipe(
      __map(_r => _r.body as Array<ValidateAreaCodesResultDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetCountriesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CountryDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CountryDC>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetCountries(Authorization: string): __Observable<Array<CountryDC>> {
    return this.LocationsGetCountriesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CountryDC>)
    );
  }

  /**
   * Required Permission: AddLocations (220)
   * @param params The `LocationsService.LocationsCreateLocationParams` containing the following parameters:
   *
   * - `data`: {SDApi.Core.DataContracts.Locations.CreateLocationDC}
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsCreateLocationResponse(params: LocationsService.LocationsCreateLocationParams): __Observable<__StrictHttpResponse<LocationDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Locations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationDC>;
      })
    );
  }
  /**
   * Required Permission: AddLocations (220)
   * @param params The `LocationsService.LocationsCreateLocationParams` containing the following parameters:
   *
   * - `data`: {SDApi.Core.DataContracts.Locations.CreateLocationDC}
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsCreateLocation(params: LocationsService.LocationsCreateLocationParams): __Observable<LocationDC> {
    return this.LocationsCreateLocationResponse(params).pipe(
      __map(_r => _r.body as LocationDC)
    );
  }

  /**
   * Required Permission: EditLocations (248)
   * @param params The `LocationsService.LocationsCloseLocationParams` containing the following parameters:
   *
   * - `request`: Request body
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsCloseLocationResponse(params: LocationsService.LocationsCloseLocationParams): __Observable<__StrictHttpResponse<OrderDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Locations/${params.locationUuid}/Close`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDC>;
      })
    );
  }
  /**
   * Required Permission: EditLocations (248)
   * @param params The `LocationsService.LocationsCloseLocationParams` containing the following parameters:
   *
   * - `request`: Request body
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsCloseLocation(params: LocationsService.LocationsCloseLocationParams): __Observable<OrderDC> {
    return this.LocationsCloseLocationResponse(params).pipe(
      __map(_r => _r.body as OrderDC)
    );
  }

  /**
   * Required Permission: EditLocations (248)
   * @param params The `LocationsService.LocationsGetCloseLocationBillingImpactParams` containing the following parameters:
   *
   * - `soxDetailsDC`: SOX Details
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetCloseLocationBillingImpactResponse(params: LocationsService.LocationsGetCloseLocationBillingImpactParams): __Observable<__StrictHttpResponse<BillingImpactDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.soxDetailsDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Locations/${params.locationUuid}/Close/BillingImpact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingImpactDC>;
      })
    );
  }
  /**
   * Required Permission: EditLocations (248)
   * @param params The `LocationsService.LocationsGetCloseLocationBillingImpactParams` containing the following parameters:
   *
   * - `soxDetailsDC`: SOX Details
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetCloseLocationBillingImpact(params: LocationsService.LocationsGetCloseLocationBillingImpactParams): __Observable<BillingImpactDC> {
    return this.LocationsGetCloseLocationBillingImpactResponse(params).pipe(
      __map(_r => _r.body as BillingImpactDC)
    );
  }

  /**
   * Required Permission: EditLocations (248)
   * @param params The `LocationsService.LocationsEditLocationParams` containing the following parameters:
   *
   * - `uuid`: Location UUID
   *
   * - `data`: {SDApi.Core.DataContracts.Locations.EditLocationDC}
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsEditLocationResponse(params: LocationsService.LocationsEditLocationParams): __Observable<__StrictHttpResponse<LocationDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Locations/${params.uuid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LocationDC>;
      })
    );
  }
  /**
   * Required Permission: EditLocations (248)
   * @param params The `LocationsService.LocationsEditLocationParams` containing the following parameters:
   *
   * - `uuid`: Location UUID
   *
   * - `data`: {SDApi.Core.DataContracts.Locations.EditLocationDC}
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsEditLocation(params: LocationsService.LocationsEditLocationParams): __Observable<LocationDC> {
    return this.LocationsEditLocationResponse(params).pipe(
      __map(_r => _r.body as LocationDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetLocationStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LocationStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetLocationStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.LocationsGetLocationStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetLocationTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LocationTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  LocationsGetLocationTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.LocationsGetLocationTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: ViewLocations (126)
   * @param params The `LocationsService.LocationsGetTermsOfServiceParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetTermsOfServiceResponse(params: LocationsService.LocationsGetTermsOfServiceParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locationUuid != null) __params = __params.set('locationUuid', params.locationUuid.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TermsOfService`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Required Permission: ViewLocations (126)
   * @param params The `LocationsService.LocationsGetTermsOfServiceParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  LocationsGetTermsOfService(params: LocationsService.LocationsGetTermsOfServiceParams): __Observable<string> {
    return this.LocationsGetTermsOfServiceResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module LocationsService {

  /**
   * Parameters for LocationsGetLocationsByAccount
   */
  export interface LocationsGetLocationsByAccountParams {

    /**
     * access token
     */
    Authorization: string;
    typeIds?: Array<number>;
    statusIds?: Array<number>;
  }

  /**
   * Parameters for LocationsGetLocation
   */
  export interface LocationsGetLocationParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsGetLocationDefaults
   */
  export interface LocationsGetLocationDefaultsParams {
    address: AddressDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsValidateAreaCodes
   */
  export interface LocationsValidateAreaCodesParams {

    /**
     * A list of StateKey and AreaCodes
     */
    requests: Array<ValidateAreaCodesRequestDC>;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsCreateLocation
   */
  export interface LocationsCreateLocationParams {

    /**
     * {SDApi.Core.DataContracts.Locations.CreateLocationDC}
     */
    data: CreateLocationDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsCloseLocation
   */
  export interface LocationsCloseLocationParams {

    /**
     * Request body
     */
    request: CloseLocationRequestDC;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsGetCloseLocationBillingImpact
   */
  export interface LocationsGetCloseLocationBillingImpactParams {

    /**
     * SOX Details
     */
    soxDetailsDC: SOXDetailsDC;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsEditLocation
   */
  export interface LocationsEditLocationParams {

    /**
     * Location UUID
     */
    uuid: string;

    /**
     * {SDApi.Core.DataContracts.Locations.EditLocationDC}
     */
    data: EditLocationDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for LocationsGetTermsOfService
   */
  export interface LocationsGetTermsOfServiceParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { LocationsService }
