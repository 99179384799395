<div [formGroup]="holidaySection" [hidden]="!showHoliday">
  <!-- schedule section -->
  <div class="row padding-first-block">
    <div class="form-group col-12">
      <div *ngIf="holidaySchedules.length > 0">
        <label for="holiday">{{ 'aa.holiday_schedule' | translate }}</label>
        <cl-dropdown [items]="holidaySchedules"
                     formControlName="holiday"
                     id="holidaySchedulesDropdown"
                     [tabbable]="tabbable"
                     (change)="scheduleOnChange('holidaySection.holiday')"
                     ngDefaultControl
                     (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
      </div>
    </div>
  </div>

  <!-- prompts section -->
  <div class="row form-group col-12 margin-override padding-first-block">
    <label for="prompt">{{ 'aa.prompt' | translate }}</label>
  </div>
  <div class="row form-group"
       *ngIf="this.subMenus && this.subMenus[2]['prompts']['fileName']">
    <div class="col-10 download-block">
      <a #holidayPrompt class="anchor-1 holidayPrompt" href="javascript:" download
         (click)="downloadAudio(holidayFileElement, holidayFileElementPreview,
                                   holidayAudioElement, 3, this.subMenus[2]['prompts']['fileName'])">{{'aa.download'
        | translate }}</a><span class="dot-block">.</span>
      <a #holidayPromptPreview class="anchor-2 holidayPromptPreview" href="javascript:"
         (click)="previewAudio(holidayFileElement, holidayFileElementPreview,
                                   holidayAudioElement, 3, this.subMenus[2]['prompts']['fileName'], true)">
        {{ 'aa.download_preview' | translate}}
      </a>
    </div>
    <div class="col-2">
      <a href="javascript:" class="holiday-prompt-delete" (click)="removePrompt(2, 3)"><img _ngcontent-c29=""
                                                              class="icon-delete-button"
                                                              alt="delete"
                                                              src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAQABADASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAII/8QAHxAAAgICAgMBAAAAAAAAAAAAAQMCEQQSACEiMlET/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANIZCzkFscl85LW8LENIkeQFX137cY6zj/lHGyJhbMiS5R0iBYBuuuvXl5Rgp74tZBQm5bomZoEDW6P3xPGMVteiKWQbo9jpGBsAHarP3yHA/9k="></a>
    </div>
    <div class="col-6" *ngIf="openHolidayAudio">
      <audio controls #holidayAudio src=""></audio>
    </div>
  </div>
  <div class="row form-group padding-left-right mr-2"
       *ngIf="this.subMenus && !this.subMenus[2]['prompts']['fileName']">
    <div class="col-9 pt-2 none-set">
      <label for="none_set" class="none-set-css">{{ 'aa.none_set' | translate }}</label>
    </div>
    <div class="col-3 pull-right actions upload-but">
      <button
              type="button"
              (click)="openUploadPrompt(3)"
              class="btn btn-default">{{ 'aa.upload' | translate }}
      </button>
    </div>
    <div class="col-12 upload-format-text">
                                <span>{{ 'aa.upload_info' | translate }}
                                </span>
      <a target="_blank" href="https://docs.mitel.com/connectcloud/console/AutoAttendantPrompt">{{'aa.learn_more' | translate}}</a>
    </div>
  </div>

  <hr/>

  <!-- interaction: show all or show one -->
  <div class="col-12 form-group members padding-left-right">
    <label for="interaction">{{ 'aa.interaction' | translate }}</label>
    <div class="col-12 delete-action">
      <label class="radio-label col-5 all-times">
        <input type="radio" formControlName="interaction" value="1"
               class="radio-label" id="present_options"
               (click)="interactionOnClick('1', 3)">
        <span
                [ngClass]="{'radio-label-normal': aaForm.get('aaSection.holidaySection.interaction').value !== '1'}"
                class="radio-span">{{ 'aa.present_options' | translate }}</span>
      </label>
      <label class="radio-label col-7 interaction-immediate px-0">
        <input type="radio" formControlName="interaction" value="2"
               class="radio-label" id="take_immediate_action"
               (click)="interactionOnClick('2', 3)">
        <span
                [ngClass]="{'radio-label-normal': aaForm.get('aaSection.holidaySection.interaction').value !== '2'}"
                class="radio-span">{{ 'aa.take_immediate_action' | translate }}</span>
      </label>
    </div>
  </div>
  <div class="row form-group mb-0 padding-left-right" [hidden]="showAllOptions3">
    <label>{{ 'aa.delay_sec' | translate }} </label>
  </div>
  <div class="row padding-left-right many-errors-block-bottom" [hidden]="showAllOptions3">
    <div class="form-group col-3 mb-2">
      <input min="0" max="30" type="number"
             class="timeout-cs"
             formControlName="timeout1">
    </div>
    <div class="form-group col-9 mb-1 opcode-dd1-block">
      <cl-dropdown
              [items]="actionLong"
              id="key_12_opcode1"
              formControlName="key_12_opcode1"
              [manualWidth]="true"
              [tabbable]="tabbable"
              (change)="OpcodeOnChange('holidaySection.key_12_opcode1')"
              (clickEventTrigger)="updateComponentLoaded()"
              ngDefaultControl></cl-dropdown>
    </div>
    <div class="row col-12 justify-content-end">
      <!-- display an input box as opcode destination                    -->
      <div class="px-0 col-9 opcode-dd1-block"
           [hidden]="hideActionDestInput('holidaySection.key_12_opcode1')">
        <app-cl-autocomplete
                [items]="holidayKey12Op1DestList"
                (selectedItem)="updateSelectedItem($event, 'holidaySection.key_12_opcode1_dest')"
                [tabbable]="tabbable"
                formControlName="key_12_opcode1_dest"
                [redBorder]="holidayTimeoutErr != ''"
        ></app-cl-autocomplete>
        <div class="text-danger" *ngIf="holidayTimeoutErr != ''"> {{ holidayTimeoutErr }} </div>
      </div>
      <!-- display HG/AA/extension list dropdown as opcode destination                    -->
      <div class="px-0 col-9 opcode-dd1-block"
           [hidden]="hideActionDestDD('holidaySection.key_12_opcode1')"
           *ngIf="autoAttendants.length > 0 || huntGroups.length > 0 || extensionList.length > 0">
        <cl-dropdown
                [items]="getDD('holidaySection.key_12_opcode1')"
                id="key_12_opcode1_dest_dd"
                formControlName="key_12_opcode1_dest_dd"
                [manualWidth]="true"
                [tabbable]="tabbable"
                (change)="keyDestOnChange('holidaySection.key_12_opcode1')"
                [redBorder]="holidayTimeoutErr != ''"
                ngDefaultControl
                (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
        <div class="text-danger" *ngIf="holidayTimeoutErr != ''"> {{ holidayTimeoutErr }} </div>
      </div>

    </div>

  </div>

  <!-- show all: menu options section begins -->
  <div class="row form-group padding-left-right margin-override" [hidden]="!showAllOptions3">
    <label for="menu_option">{{ 'aa.menu_option' | translate }}</label>
  </div>

  <div class="form-group gray-text-left-margin padding-left-right message-blank-text"
       [hidden]="!showAllOptions3 || !hideOpList3">
    {{ 'aa.no_options_added' | translate }}
  </div>


  <div class="row padding-left-right">
    <div class="form-group col-12"
         [hidden]="!showAllOptions3 || mainOptions3 && mainOptions3.length <= 0">
      <ul class="list-unstyled">
        <li *ngFor="let item of mainOptions3">
          <div class="item row options-block" *ngIf="(item['opcode'] > 0)">
            <div class="col-3 options-block-1"> {{
              item["key"] === 10 ? "Press *" :
              item["key"] === 11 ? "Press #" :
              item["key"] === 15 ? "Extension" :
              "Press " + item["key"]
              }}
            </div>
            <div class="col-8 options-block-1">
              {{item["displayString"]}}
            </div>
            <a href="javascript:" class="pull-right clear-icon mainoptions-block"
               (click)="onRemoveOption(mainOptions3, item.key, $event)">
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row form-group mb-0 padding-left-right" [hidden]="!showAllOptions3">
    <label for="new_option">{{ 'aa.new_option' | translate }}</label>
  </div>

  <div class="form-row padding-left-right" [hidden]="!showAllOptions3">
    <div class="form-group col-3 mb-1">
      <cl-dropdown
              [items]="options"
              id="options_dd1"
              formControlName="options_dd1"
              [manualWidth]="true"
              [manualWidthValue]="'106px'"
              [tabbable]="tabbable"
              (change)="optionDDOnChange('holidaySection.options_dd1')"
              (clickEventTrigger)="optionDDOnClick($event, mainOptions3)"
              ngDefaultControl>
      </cl-dropdown>
    </div>

    <div class="form-group col-9 opcode-dd1-block mb-1">
      <cl-dropdown
              [items]="opcodeDD1"
              id="new_opcode1"
              [tabbable]="tabbable"
              [manualWidth]="true"
              [manualWidthValue]="'275px'"
              formControlName="new_opcode1"
              (change)="OpcodeOnChange('holidaySection.new_opcode1')"
              (clickEventTrigger)="updateComponentLoaded()"
              ngDefaultControl>
      </cl-dropdown>
    </div>
  </div>

  <div class="form-row margin-top-n8 justify-content-end padding-left-right"
       [hidden]="!showAllOptions3">
    <div class="form-group col-12"
         [hidden]="hideActionDestInput('holidaySection.new_opcode1')">
      <app-cl-autocomplete
              [items]="holidayNewOpCodeDestList"
              (selectedItem)="updateSelectedItem($event, 'holidaySection.new_opcode1_dest')"
              [tabbable]="tabbable"
              formControlName="new_opcode1_dest"
      ></app-cl-autocomplete>
    </div>

    <!-- display HG/AA/extension list dropdown as opcode destination                    -->
    <div class="form-group col-12 mb-2"
         [hidden]="hideActionDestDD('holidaySection.new_opcode1')">
      <cl-dropdown
              [items]="getDD('holidaySection.new_opcode1')"
              id="new_opcode1_dest_dd"
              formControlName="new_opcode1_dest_dd"
              [tabbable]="tabbable"
              (change)="keyDestOnChange('holidaySection.new_opcode1')"
              ngDefaultControl (keyup.esc)="$event.preventDefault(); onClose()">
      </cl-dropdown>
    </div>


  </div>
  <div class="row justify-content-end actions pr-2" [hidden]="!showAllOptions3">
    <button
            type="button"
            class="btn btn-default mr-0"
            (click)="onAddOption(3)">
      {{ 'hunt_group.add_member' | translate }}
    </button>
  </div>


  <!-- Next Step section begins -->
  <div class="row" class="padding-top-0px" [hidden]="!showAllOptions3">
    <hr/>
  </div>
  <div class="row" class="padding-top-8px padding-left-right" [hidden]="!showAllOptions3">
    <div class="form-group col-12">
      <label for="nextStep">{{ 'aa.next_step' | translate }}</label>
      <div class="delete-action">
        <label for="repeat3times" class="radio-label">
          <input type="radio" formControlName="nextStep" id="repeat3times" value="1"
                 class="radio-label"
                 (click)="nextStepOnClick('1', 3)">
          <span
                  [ngClass]="{'radio-label-normal': aaForm.get('aaSection.holidaySection').value.nextStep !== '1'}"
                  class="radio-span">{{ 'aa.repeat_3_times_at_errors' | translate }}</span>
        </label>
        <div class="message-blank-text next-step-errors">{{ 'aa.repeat_3_times_at_errors_2'
          | translate }}
        </div>
        <label for="custom" class="radio-label">
          <input type="radio" formControlName="nextStep" id="custom" value="2"
                 class="radio-label"
                 (click)="nextStepOnClick('2', 3)">
          <span
                  [ngClass]="{'radio-label-normal': aaForm.get('aaSection.holidaySection').value.nextStep !== '2'}"
                  class="radio-span">{{ 'aa.custom' | translate }}</span>
        </label>
      </div>
    </div>  <!-- end next step radios-->

    <!-- custom timeout opcode 12-->
    <div class="row form-group mb-0" [hidden]="hideCustomStep3">
      <label>{{ 'aa.timeout_sec' | translate }} </label>
    </div>
    <div class="row mb-0" [hidden]="hideCustomStep3">
      <div class="form-group col-3 mb-2">
        <input min="0" max="30" type="number"
               class="timeout-cs"
               formControlName="timeout2">
      </div>

      <div class="form-group col-9 mb-1 opcode-dd1-block">
        <cl-dropdown
                [items]="actionLong"
                id="key_12_opcode2"
                formControlName="key_12_opcode2"
                [manualWidth]="true"
                [tabbable]="tabbable"
                (change)="OpcodeOnChange('holidaySection.key_12_opcode2')"
                (clickEventTrigger)="updateComponentLoaded()"
                ngDefaultControl>
        </cl-dropdown>
      </div>
      <div class="row col-12 justify-content-end">
        <!-- display an input box as opcode destination                    -->
        <div class="px-0 col-9 opcode-dd1-block"
             [hidden]="hideActionDestInput('holidaySection.key_12_opcode2')">
          <app-cl-autocomplete
                  [items]="holidayKey12Op2DestList"
                  (selectedItem)="updateSelectedItem($event, 'holidaySection.key_12_opcode2_dest')"
                  [tabbable]="tabbable"
                  formControlName="key_12_opcode2_dest"
                  [redBorder]="holidayTimeoutErr != ''"
          ></app-cl-autocomplete>
          <div class="text-danger" *ngIf="holidayTimeoutErr != ''"> {{ holidayTimeoutErr }} </div>
        </div>

        <!-- display HG/AA/extension list dropdown as opcode destination                    -->
        <div class="px-0 col-9 opcode-dd1-block"
             [hidden]="hideActionDestDD('holidaySection.key_12_opcode2')"
             *ngIf="autoAttendants.length > 0 || huntGroups.length > 0 || extensionList.length > 0">
          <cl-dropdown
                  [items]="getDD('holidaySection.key_12_opcode2')"
                  id="key_12_opcode2_dest_dd"
                  formControlName="key_12_opcode2_dest_dd"
                  [manualWidth]="true"
                  [tabbable]="tabbable"
                  (change)="keyDestOnChange('holidaySection.key_12_opcode2')"
                  [redBorder]="holidayTimeoutErr != ''"
                  ngDefaultControl
                  (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
          <div class="text-danger" *ngIf="holidayTimeoutErr != ''"> {{ holidayTimeoutErr }} </div>
        </div>
      </div>
    </div>

    <div class="row mt-2" [hidden]="hideCustomStep3">
      <label for="key_14_opcode">{{ 'aa.invalid_entry' | translate }}</label>
      <div class="form-group col-12 mb-1">
        <cl-dropdown
                [items]="actionLong"
                id="key_14_opcode"
                formControlName="key_14_opcode"
                (change)="OpcodeOnChange('holidaySection.key_14_opcode')"
                (clickEventTrigger)="updateComponentLoaded()"
                [tabbable]="tabbable"
                ngDefaultControl>
        </cl-dropdown>
      </div>
    </div>
    <div class="row justify-content-end" [hidden]="hideCustomStep3">
      <div class="col-12"
           [hidden]="hideActionDestInput('holidaySection.key_14_opcode')">
        <app-cl-autocomplete
                [items]="holidayKey14OpDestList"
                (selectedItem)="updateSelectedItem($event, 'holidaySection.key_14_opcode_dest')"
                [tabbable]="tabbable"
                formControlName="key_14_opcode_dest"
                [redBorder]="holidayInvalidDestErr != ''"
        ></app-cl-autocomplete>
        <div class="text-danger" *ngIf="holidayInvalidDestErr != ''"> {{ holidayInvalidDestErr }} </div>
      </div>

      <!-- display HG/AA/extension list dropdown as opcode destination                    -->
      <div class="col-12"
           [hidden]="hideActionDestDD('holidaySection.key_14_opcode')"
           *ngIf="autoAttendants.length > 0 || huntGroups.length > 0 || extensionList.length > 0">
        <cl-dropdown
                [items]="getDD('holidaySection.key_14_opcode')"
                id="key_14_opcode_dest_dd"
                formControlName="key_14_opcode_dest_dd"
                [manualWidth]="true"
                [tabbable]="tabbable"
                (change)="keyDestOnChange('holidaySection.key_14_opcode')"
                [redBorder]="holidayInvalidDestErr != ''"
                ngDefaultControl
                (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
        <div class="text-danger" *ngIf="holidayInvalidDestErr != ''"> {{ holidayInvalidDestErr }} </div>
      </div>
    </div>

    <div class="row mt-2 many-errors-block-bottom" [hidden]="hideCustomStep3">
      <div class="form-group col-12 many-errors-block pb-1">
        <label for="key_13_opcode">{{ 'aa.too_many_errors' | translate }}</label>
        <cl-dropdown
                [items]="actionTooManyErrors"
                id="key_13_opcode"
                formControlName="key_13_opcode"
                [tabbable]="tabbable"
                (change)="OpcodeOnChange('holidaySection.key_13_opcode')"
                (clickEventTrigger)="updateComponentLoaded()"
                ngDefaultControl>
        </cl-dropdown>
      </div>
      <div class="col-12"
           [hidden]="hideActionDestInput('holidaySection.key_13_opcode')">
        <app-cl-autocomplete
                [items]="holidayKey13OpDestList"
                (selectedItem)="updateSelectedItem($event, 'holidaySection.key_13_opcode_dest')"
                [tabbable]="tabbable"
                formControlName="key_13_opcode_dest"
                [redBorder]="holidayAfterThreeErr != ''"
        ></app-cl-autocomplete>
        <div class="text-danger" *ngIf="holidayAfterThreeErr != ''"> {{ holidayAfterThreeErr }} </div>
      </div>

      <!-- display HG/AA/extension list dropdown as opcode destination                    -->
      <div class="col-12"
           [hidden]="hideActionDestDD('holidaySection.key_13_opcode')"
           *ngIf="autoAttendants.length > 0 || huntGroups.length > 0 || extensionList.length > 0">
        <cl-dropdown
                [items]="getDD('holidaySection.key_13_opcode')"
                id="key_13_opcode_dest_dd"
                formControlName="key_13_opcode_dest_dd"
                [tabbable]="tabbable"
                (change)="keyDestOnChange('holidaySection.key_13_opcode')"
                [redBorder]="holidayAfterThreeErr != ''"
                ngDefaultControl
                (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
        <div class="text-danger" *ngIf="holidayAfterThreeErr != ''"> {{ holidayAfterThreeErr }} </div>
      </div>
    </div>
  </div>

</div>
