import { Injectable } from '@angular/core';
import {ContractsService} from '../modules/boss-api/generated/services';
import {Observable} from 'rxjs';

export interface ContractDetails {
    serviceCommitmentDate?: string;
    contractProfileAmount?: number;
    currentProfileAmount?: number;
    builtProfileAmount?: number;
}

@Injectable({
  providedIn: 'root'
})

export class CommitmentDetailsAdaptorService {

    constructor(private contractService: ContractsService) {
    }

    getCommitmentAmountDetails(): Observable<ContractDetails> {
       return this.contractService.ContractsGetContractCommitmentDetails(null);
    }
}
