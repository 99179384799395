import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Provider, APP_INITIALIZER } from '@angular/core';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { Observable ,  merge } from 'rxjs';
import { reduce } from 'rxjs/operators';
import { ClConsoleComponentsModule, ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ClDropdownComponent } from './cl-dropdown/cl-dropdown.component';
import { ClDropdownWithHeadingsComponent } from './cl-dropdown-with-headings/cl-dropdown-with-headings.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { FlowsComponent } from './flows/flows.component';
import { FlowsDashboardComponent } from './flows/flows-dashboard/flows-dashboard.component';
import { FlowsModelComponent } from './flows/flows-model/flows-model.component';
import { HuntGroupComponent } from './flows/hunt-group/hunt-group.component';
import { AutoAttendantComponent } from './flows/auto-attendant/auto-attendant.component';
import { FlowsService } from './services/flows.service';
import { SortbyPipe } from './pipes/sortby.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavigationConfirmationComponent } from './navigation-confirmation/navigation-confirmation.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import {MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatButtonModule} from '@angular/material';
import { environment } from 'src/environments/environment';
import { ClErrorAlertComponent } from './cl-error-alert/cl-error-alert.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { ApiModule as BossApiModule } from './modules/boss-api/generated/api.module';
import { BossAuthInteceptor } from './interceptors/BossAuth.interceptor';
import { TargetAccountInterceptor } from './interceptors/TargetAccount.interceptor';
import { PacketTraceInteceptor } from './interceptors/PacketTrace.inteceptor';
import { AppInfoService } from './services/app-info.service';
import { AppUserClaimsService } from './services/app-user-claims.service';
import { AdminService } from './services/admin.service';
import { OnHoursScheduleComponent } from './schedules/on-hours-schedule/on-hours-schedule.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HolidayScheduleComponent } from './schedules/holiday-schedule/holiday-schedule.component';
import { CustomScheduleComponent } from './schedules/custom-schedule/custom-schedule.component';
import { FlowVisualizerComponent } from './flows/flow-visualizer/flow-visualizer.component';
import { SchedulesDashboardComponent } from './schedules/schedules-dashboard/schedules-dashboard.component';
import { SchedulesModelComponent } from './schedules/schedules-model/schedules-model.component';
import {SchedulesComponent} from './schedules/schedules.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ExtensionListComponent } from './extension-list/extension-list.component';
import { ExtensionListDashboardComponent } from './extension-list/extension-list-dashboard/extension-list-dashboard.component';
import { ExtensionListRightPanelComponent } from './extension-list/extension-list-right-panel/extension-list-right-panel.component';
import { NotificationComponent } from './notification/notification.component';
import {ClDropdownWithSelectionComponent} from './cl-dropdown-with-selection/cl-dropdown-with-selection.component';
import { FlowsCreateModelComponent } from './flows/flows-create-model/flows-create-model.component';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { PromptUploaderComponent } from './flows/prompt-uploader/prompt-uploader.component';
import { ApiConfiguration as MediaTranscodeConfiguration } from './transcode-modules/boss-api/generated/api-configuration';
import { FocusScrollDirective } from './cl-dropdown-focusscroll-directive/focus-scroll.directive';
import { PhoneNumberFormatService } from './services/phone-number-format.service';
import { ClModalComponent } from './cl-modal/cl-modal.component';
import { TollFreeNumbersComponent } from './toll-free-numbers/toll-free-numbers.component';
import { LocalNumbersComponent } from './local-numbers/local-numbers.component';
import { NewNumberComponent } from './shared/components/new-number/new-number.component';
import { FilterNumbersComponent } from './shared/components/new-number/filter-numbers/filter-numbers.component';
import { NumbersGridComponent } from './shared/components/new-number/numbers-grid/numbers-grid.component';
import { NumberRangeComponent } from './shared/components/new-number/number-range/number-range.component';
import { ModalContentDirective } from './cl-modal/modal-content.directive';
import { ChangePhoneNumberComponent } from './flows/change-phone-number/change-phone-number.component';
import {
  ReleasePreviousNumberDialogComponent
} from './flows/change-phone-number/release-previous-number-dialog/release-previous-number-dialog.component';
import { RemovePhoneComponent } from './flows/remove-phone/remove-phone.component';
// import { HttpErrorInterceptor } from './interceptors/HttpError.interceptor';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { ClTestModalContentComponent } from './cl-modal/cl-test-modal/cl-test-modal-content.component';
import {
  ResetEmergencyRegistrationDialogComponent
} from './flows/change-phone-number/reset-emergency-registration-dialog/reset-emergency-registration-dialog.component';
import { NavigationErrorComponent } from './navigation-error/navigation-error.component';
import { TemporaryNumberComponent } from './flows/temporary-number/temporary-number.component';
import {CommitmentDetailsAdaptorService} from './services/commitment-details-adaptor.service';
import {ShoppingCartService} from './services/shopping-cart.service';
import {ShoppingCartComponent} from './shared/components/shopping-cart/shopping-cart.component';
import {CommitmentChartComponent} from './flows/commitment-chart/commitment-chart.component';
import {InvoiceSmallComponent} from './flows/invoice-small/invoice-small.component';
import { VisualizationErrorComponent } from './flows/flow-visualizer/visualization-error/visualization-error.component';
import { PendoBadgeComponent } from './shared/pendo-badge/pendo-badge.component';
import { ReviewOrderComponent } from './shared/components/review-order/review-order.component';
import {HttpAuthErrorInterceptor} from './interceptors/HttpAuthError.interceptor';
import {Http403Interceptor} from './interceptors/Http403.interceptor';
import {Http500Interceptor} from './interceptors/Http500.interceptor';
import { ClAutocompleteComponent } from './cl-autocomplete/cl-autocomplete.component';
import { OnHoursBlockComponent } from './flows/auto-attendant/on-hours-block/on-hours-block.component';
import {OffHoursBlockComponent} from './flows/auto-attendant/off-hours-block/off-hours-block.component';
import { HolidaysBlockComponent } from './flows/auto-attendant/holidays-block/holidays-block.component';
import { CustomsBlockComponent } from './flows/auto-attendant/customs-block/customs-block.component';
import {PromptCreateInterceptor} from './interceptors/PromptCreate.interceptor';
import { SharedModule } from './shared/shared.module';
import { FeatureService } from './shared/features/features.service';
import { DatePipe } from '@angular/common';

declare var System: any;

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    lang = 'en'; // when we support other languages, remove this line
    console.log('APP MODULE WebpackTranslateLoader is loading the strings for ', lang);
    const loadFile1 = System.import(`../assets/i18n/${lang}.json`);
    const loadFile2 = System.import(`../assets/cl-console-components/i18n/${lang}.json`);
    const reducer = (translations, val) => Object.assign(translations, val);
    return merge(loadFile1, loadFile2).pipe(reduce(reducer, {}));
  }
}




export function initTranscodeConfiguration(config: MediaTranscodeConfiguration): Function {
  return () => {
    config.rootUrl = environment.mediaTranscodeApiBaseUrl;  // eg  'https://some-root-url.com'
  };
}

export const INIT_MEDIA_TRANSCODE_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initTranscodeConfiguration,
  deps: [MediaTranscodeConfiguration],
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    ClDropdownComponent,
    SortbyPipe,
    FlowsComponent,
    FlowsDashboardComponent,
    FlowsModelComponent,
    HuntGroupComponent,
    AutoAttendantComponent,
    NavigationConfirmationComponent,
    DeleteConfirmationComponent,
    ClErrorAlertComponent,
    SpinnerComponent,
    OnHoursScheduleComponent,
    HolidayScheduleComponent,
    CustomScheduleComponent,
    FlowVisualizerComponent,
    SchedulesDashboardComponent,
    SchedulesModelComponent,
    SchedulesComponent,
    ExtensionListComponent,
    ExtensionListDashboardComponent,
    ExtensionListRightPanelComponent,
    NotificationComponent,
    ClDropdownWithSelectionComponent,
    FlowsCreateModelComponent,
    BlankPageComponent,
    PromptUploaderComponent,
    AboutmeComponent,
    ClDropdownWithHeadingsComponent,
    FocusScrollDirective,
    ClModalComponent,
    LocalNumbersComponent,
    TollFreeNumbersComponent,
    NewNumberComponent,
    FilterNumbersComponent,
    NumbersGridComponent,
    NumberRangeComponent,
    ModalContentDirective,
    ChangePhoneNumberComponent,
    ReleasePreviousNumberDialogComponent,
    RemovePhoneComponent,
    ResetEmergencyRegistrationDialogComponent,
    ClTestModalContentComponent,
    NavigationErrorComponent,
    TemporaryNumberComponent,
    ShoppingCartComponent,
    CommitmentChartComponent,
    InvoiceSmallComponent,
    VisualizationErrorComponent,
    PendoBadgeComponent,
    ReviewOrderComponent,
    ClAutocompleteComponent,
    OnHoursBlockComponent,
    OffHoursBlockComponent,
    HolidaysBlockComponent,
    CustomsBlockComponent
  ],
  entryComponents: [
    FlowsModelComponent,
    NavigationConfirmationComponent,
    DeleteConfirmationComponent,
    OnHoursScheduleComponent,
    HolidayScheduleComponent,
    CustomScheduleComponent,
    SchedulesModelComponent,
    ExtensionListRightPanelComponent,
    FlowsCreateModelComponent,
    PromptUploaderComponent,
    ClModalComponent,
    NewNumberComponent,
    ChangePhoneNumberComponent,
    ReleasePreviousNumberDialogComponent,
    RemovePhoneComponent,
    VisualizationErrorComponent,
    ReviewOrderComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClConsoleComponentsModule,
    AppRoutingModule,
    UiSwitchModule,
    DragDropModule,
    HttpClientModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useClass: (WebpackTranslateLoader)
      }
    }),
    BossApiModule,
    NgbModule,
    SharedModule
  ],
  providers: [
    ClHeaderComponent,
    ReviewOrderComponent,
    AppUserClaimsService,
    FlowsService,
    NgbActiveModal,
    INIT_MEDIA_TRANSCODE_API_CONFIGURATION,
    AdminService,
    FeatureService,
    PhoneNumberFormatService,
    CommitmentDetailsAdaptorService,
    ShoppingCartService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http403Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BossAuthInteceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PacketTraceInteceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http500Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TargetAccountInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PromptCreateInterceptor,
      multi: true
    },
    AppInfoService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
