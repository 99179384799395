import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  private countryCode: number;

  constructor() {}

  setCountryCode(code){
    this.countryCode = code;
  }

  getCountryCode(){
    return this.countryCode;
  }


}
