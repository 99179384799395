<div class="backdrop" *ngIf="spinnerStateActive">
  <div class="centered" [ngStyle]="styleSpinner()" [ngClass]="{'externalStyle': externalStyles}">
    <!-- <div class="card shadow" style="width: 280px; height:140px;" > -->
    <div class="card shadow" [ngStyle]="{'width.px': widthPx, 'height.px': heightPx}">
      <img src={{imgname}} alt="loading">
      <div class="card-body">
        <p class="card-text">{{message}}</p>
      </div>
    </div>
  </div>
</div>
