/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ConferenceCollection } from '../models/conference-collection';
import { Conference } from '../models/conference';
import { CreateConferenceRequest } from '../models/create-conference-request';
import { UpdateConferenceRequest } from '../models/update-conference-request';
import { ParticipantCollection } from '../models/participant-collection';
import { Attendee } from '../models/attendee';
import { ParticipantPostSuccessResponse } from '../models/participant-post-success-response';
import { ParticipantPostRequest } from '../models/participant-post-request';
import { ParticipantPutRequest } from '../models/participant-put-request';

/**
 * Operations related to conference resource
 */
@Injectable({
  providedIn: 'root',
})
class ConferencesService extends __BaseService {
  static readonly getConferencesPath = '/conferences';
  static readonly createConferencePath = '/conferences';
  static readonly getConferencePath = '/conferences/{conferenceId}';
  static readonly updateConferencePath = '/conferences/{conferenceId}';
  static readonly deleteConferencePath = '/conferences/{conferenceId}';
  static readonly getParticipantsPath = '/conferences/{conferenceId}/participants';
  static readonly addParticipiantPath = '/conferences/{conferenceId}/participants';
  static readonly getParticipantPath = '/conferences/{conferenceId}/participants/{participantId}';
  static readonly runParticipantCommandPath = '/conferences/{conferenceId}/participants/{participantId}';
  static readonly updateParticipantPath = '/conferences/{conferenceId}/participants/{participantId}';
  static readonly removeParticipantPath = '/conferences/{conferenceId}/participants/{participantId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieve all live conference calls for this account on both premise and cloud. Basic paging and filtering support is available. See above. Historical queries coming soon.
   * @return Ok
   */
  getConferencesResponse(): __Observable<__StrictHttpResponse<ConferenceCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/conferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConferenceCollection>;
      })
    );
  }
  /**
   * Retrieve all live conference calls for this account on both premise and cloud. Basic paging and filtering support is available. See above. Historical queries coming soon.
   * @return Ok
   */
  getConferences(): __Observable<ConferenceCollection> {
    return this.getConferencesResponse().pipe(
      __map(_r => _r.body as ConferenceCollection)
    );
  }

  /**
   * Create a conference by using the provided details in this request body.
   * @param body undefined
   * @return Created
   */
  createConferenceResponse(body: CreateConferenceRequest): __Observable<__StrictHttpResponse<Conference>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/conferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Conference>;
      })
    );
  }
  /**
   * Create a conference by using the provided details in this request body.
   * @param body undefined
   * @return Created
   */
  createConference(body: CreateConferenceRequest): __Observable<Conference> {
    return this.createConferenceResponse(body).pipe(
      __map(_r => _r.body as Conference)
    );
  }

  /**
   * Gets the Meta data for this conference. The result is limited by ownership of the conference.
   * @param conferenceId conferenceId
   * @return Ok
   */
  getConferenceResponse(conferenceId: string): __Observable<__StrictHttpResponse<Conference>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/conferences/${conferenceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Conference>;
      })
    );
  }
  /**
   * Gets the Meta data for this conference. The result is limited by ownership of the conference.
   * @param conferenceId conferenceId
   * @return Ok
   */
  getConference(conferenceId: string): __Observable<Conference> {
    return this.getConferenceResponse(conferenceId).pipe(
      __map(_r => _r.body as Conference)
    );
  }

  /**
   * Manipulate current conference state by issuing an appropriate action to take on the active conference. Some actions require additional arguments.
   *For additional actions and arguments see object model definition.
   * @param params The `ConferencesService.UpdateConferenceParams` containing the following parameters:
   *
   * - `putCallRequest`:
   *
   * - `conferenceId`: conferenceId
   *
   * @return Ok
   */
  updateConferenceResponse(params: ConferencesService.UpdateConferenceParams): __Observable<__StrictHttpResponse<Conference>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.putCallRequest;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/conferences/${params.conferenceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Conference>;
      })
    );
  }
  /**
   * Manipulate current conference state by issuing an appropriate action to take on the active conference. Some actions require additional arguments.
   *For additional actions and arguments see object model definition.
   * @param params The `ConferencesService.UpdateConferenceParams` containing the following parameters:
   *
   * - `putCallRequest`:
   *
   * - `conferenceId`: conferenceId
   *
   * @return Ok
   */
  updateConference(params: ConferencesService.UpdateConferenceParams): __Observable<Conference> {
    return this.updateConferenceResponse(params).pipe(
      __map(_r => _r.body as Conference)
    );
  }

  /**
   * Terminates an active conference
   * @param conferenceId conferenceId
   */
  deleteConferenceResponse(conferenceId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/conferences/${conferenceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Terminates an active conference
   * @param conferenceId conferenceId
   */
  deleteConference(conferenceId: string): __Observable<null> {
    return this.deleteConferenceResponse(conferenceId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the participants for a conference with the provided ID.
   * @param conferenceId Conference Identifier. It is video ID for a Vidyo conference.
   * @return Ok
   */
  getParticipantsResponse(conferenceId: string): __Observable<__StrictHttpResponse<ParticipantCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/conferences/${conferenceId}/participants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParticipantCollection>;
      })
    );
  }
  /**
   * Get the participants for a conference with the provided ID.
   * @param conferenceId Conference Identifier. It is video ID for a Vidyo conference.
   * @return Ok
   */
  getParticipants(conferenceId: string): __Observable<ParticipantCollection> {
    return this.getParticipantsResponse(conferenceId).pipe(
      __map(_r => _r.body as ParticipantCollection)
    );
  }

  /**
   * Add a participant into the conference with the provided ID.
   * @param conferenceId Conference Identifier. It is video ID for a Vidyo conference.
   * @return Ok
   */
  addParticipiantResponse(conferenceId: string): __Observable<__StrictHttpResponse<Attendee>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/conferences/${conferenceId}/participants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Attendee>;
      })
    );
  }
  /**
   * Add a participant into the conference with the provided ID.
   * @param conferenceId Conference Identifier. It is video ID for a Vidyo conference.
   * @return Ok
   */
  addParticipiant(conferenceId: string): __Observable<Attendee> {
    return this.addParticipiantResponse(conferenceId).pipe(
      __map(_r => _r.body as Attendee)
    );
  }

  /**
   * Get the participant for the provided ID.
   * @param params The `ConferencesService.GetParticipantParams` containing the following parameters:
   *
   * - `participantId`: Participant Identifier
   *
   * - `conferenceId`: Conference Identifier
   *
   * @return Ok
   */
  getParticipantResponse(params: ConferencesService.GetParticipantParams): __Observable<__StrictHttpResponse<Attendee>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/conferences/${params.conferenceId}/participants/${params.participantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Attendee>;
      })
    );
  }
  /**
   * Get the participant for the provided ID.
   * @param params The `ConferencesService.GetParticipantParams` containing the following parameters:
   *
   * - `participantId`: Participant Identifier
   *
   * - `conferenceId`: Conference Identifier
   *
   * @return Ok
   */
  getParticipant(params: ConferencesService.GetParticipantParams): __Observable<Attendee> {
    return this.getParticipantResponse(params).pipe(
      __map(_r => _r.body as Attendee)
    );
  }

  /**
   * Run a command against the participant for the provided participant ID, in the conference with the provided conference ID. Some actions require additional arguments.
   *Examples
   *To join via web by using a Vidyo token
   *`{ "action": "getToken"}`
   *To dial out from Vidyo
   *`{ "action": "joinSip"}`
   *To leave conference
   *`{ "action": "leave"}`
   *For additional actions and arguments see object model definition.
   * @param params The `ConferencesService.RunParticipantCommandParams` containing the following parameters:
   *
   * - `participantId`: Participant Identifier
   *
   * - `conferenceId`: Conference Identifier
   *
   * - `body`:
   *
   * @return Ok
   */
  runParticipantCommandResponse(params: ConferencesService.RunParticipantCommandParams): __Observable<__StrictHttpResponse<ParticipantPostSuccessResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/conferences/${params.conferenceId}/participants/${params.participantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ParticipantPostSuccessResponse>;
      })
    );
  }
  /**
   * Run a command against the participant for the provided participant ID, in the conference with the provided conference ID. Some actions require additional arguments.
   *Examples
   *To join via web by using a Vidyo token
   *`{ "action": "getToken"}`
   *To dial out from Vidyo
   *`{ "action": "joinSip"}`
   *To leave conference
   *`{ "action": "leave"}`
   *For additional actions and arguments see object model definition.
   * @param params The `ConferencesService.RunParticipantCommandParams` containing the following parameters:
   *
   * - `participantId`: Participant Identifier
   *
   * - `conferenceId`: Conference Identifier
   *
   * - `body`:
   *
   * @return Ok
   */
  runParticipantCommand(params: ConferencesService.RunParticipantCommandParams): __Observable<ParticipantPostSuccessResponse> {
    return this.runParticipantCommandResponse(params).pipe(
      __map(_r => _r.body as ParticipantPostSuccessResponse)
    );
  }

  /**
   * Update the participant for the provided ID. Use `{ "action": "heartBeat"}`
   * to send a heartbeat.
   * @param params The `ConferencesService.UpdateParticipantParams` containing the following parameters:
   *
   * - `participantId`: Pariticipant Identifier
   *
   * - `conferenceId`: Conference Identifier
   *
   * - `body`:
   */
  updateParticipantResponse(params: ConferencesService.UpdateParticipantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/conferences/${params.conferenceId}/participants/${params.participantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update the participant for the provided ID. Use `{ "action": "heartBeat"}`
   * to send a heartbeat.
   * @param params The `ConferencesService.UpdateParticipantParams` containing the following parameters:
   *
   * - `participantId`: Pariticipant Identifier
   *
   * - `conferenceId`: Conference Identifier
   *
   * - `body`:
   */
  updateParticipant(params: ConferencesService.UpdateParticipantParams): __Observable<null> {
    return this.updateParticipantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Remove participant form this conference.
   * @param params The `ConferencesService.RemoveParticipantParams` containing the following parameters:
   *
   * - `participantId`: Pariticipant Identifier
   *
   * - `conferenceId`: Conference Identifier
   */
  removeParticipantResponse(params: ConferencesService.RemoveParticipantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/conferences/${params.conferenceId}/participants/${params.participantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove participant form this conference.
   * @param params The `ConferencesService.RemoveParticipantParams` containing the following parameters:
   *
   * - `participantId`: Pariticipant Identifier
   *
   * - `conferenceId`: Conference Identifier
   */
  removeParticipant(params: ConferencesService.RemoveParticipantParams): __Observable<null> {
    return this.removeParticipantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ConferencesService {

  /**
   * Parameters for updateConference
   */
  export interface UpdateConferenceParams {
    putCallRequest: UpdateConferenceRequest;

    /**
     * conferenceId
     */
    conferenceId: string;
  }

  /**
   * Parameters for getParticipant
   */
  export interface GetParticipantParams {

    /**
     * Participant Identifier
     */
    participantId: string;

    /**
     * Conference Identifier
     */
    conferenceId: string;
  }

  /**
   * Parameters for runParticipantCommand
   */
  export interface RunParticipantCommandParams {

    /**
     * Participant Identifier
     */
    participantId: string;

    /**
     * Conference Identifier
     */
    conferenceId: string;
    body: ParticipantPostRequest;
  }

  /**
   * Parameters for updateParticipant
   */
  export interface UpdateParticipantParams {

    /**
     * Pariticipant Identifier
     */
    participantId: string;

    /**
     * Conference Identifier
     */
    conferenceId: string;
    body: ParticipantPutRequest;
  }

  /**
   * Parameters for removeParticipant
   */
  export interface RemoveParticipantParams {

    /**
     * Pariticipant Identifier
     */
    participantId: string;

    /**
     * Conference Identifier
     */
    conferenceId: string;
  }
}

export { ConferencesService }
