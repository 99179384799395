import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FlowsComponent } from './flows/flows.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { ExtensionListComponent } from './extension-list/extension-list.component';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { RouteGuard } from './services/route.guard';
import { UserRole } from './services/user-adaptor.service';
import { NavigationErrorComponent } from './navigation-error/navigation-error.component';

const routes: Routes = [
  { path: '', component: BlankPageComponent },   // need thed default to be a fast loading paged because it will be quickly replaced by another (whne called via accounts app iframe). See handleAuthConfirmedInvokingUrl()
  { path: 'flows', component: FlowsComponent, canActivate: [RouteGuard], data: { roles: [UserRole.ADMIN, UserRole.BILLING] }  },
  { path: 'schedules', component: SchedulesComponent, canActivate: [RouteGuard], data: { roles: [UserRole.ADMIN, UserRole.BILLING] } },
  { path: 'extensiongroups', component: ExtensionListComponent, canActivate: [RouteGuard], data: { roles: [UserRole.ADMIN, UserRole.BILLING] } },
  { path: 'navigationError', component: NavigationErrorComponent },
  { path: 'about', component: AboutmeComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true /*, enableTracing: true*/})],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }

