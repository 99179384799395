<span class="new-number-filter-container row">
  <span *ngIf="phSvc.tnIntegration.filterByCity && numberType!==numberTypes.tollFree" class="filter-by-city-container">
    <form [formGroup]="citySearchForm">
      <span class="search-box-group-container" [ngClass]="{'local-number-search-container': numberType !== 'default'}">
        <!-- <span class="input-container"> -->
          <label *ngIf="isUS">{{'choose_new_number.city_state' | translate}}</label>
          <label *ngIf="!isUS">{{'choose_new_number.city_province' | translate}}</label>

          <span class="search-box-group">
            <span class="search-icon"></span>
            <input *ngIf="isUS" formControlName="rcSearch" #rcSearch class="search-box" [ngClass]="{'small': isLocalNumber}"
            type="text" placeholder="{{'choose_new_number.searchbox_placeholder' | translate}}" [attr.tabindex]="0"
            (input)="typeAheadSearch($event)" tabindex="0">
            <input *ngIf="!isUS" formControlName="rcSearch" #rcSearch class="search-box" [ngClass]="{'small': isLocalNumber}"
            type="text" placeholder="{{'choose_new_number.searchbox_placeholder_CAN' | translate}}" [attr.tabindex]="0"
            (input)="typeAheadSearch($event)" tabindex="0">
            <span class="clear-icon" (click)="clearSearchRC()" (keyup.enter)="clearSearchRC()" tabindex= 0 role="button"></span>
          </span>

        <!-- </span> -->
      </span>

    </form>
    <span class="search-results-box row" *ngIf="showResults" [ngClass]="{'small': isLocalNumber, 'large': !isLocalNumber}">
      <ul class="col-md-12 matched-locations-container">
        <li class="row matched-locations" *ngFor="let rateCenter of rateCenters"
          (click)="onCityFilterSelected(rateCenter)" (keyup.enter)="onCityFilterSelected(rateCenter)" tabindex="0">
          <span class="col-md-12 matched-locations-address" [innerHTML]="rateCenter.address"></span>
          <span class="col-md-12 matched-locations-numbers" [innerHTML]="rateCenter.areaCodesString"></span>
        </li>
      </ul>
    </span>
  </span>
  <span *ngIf="numberType===numberTypes.tollFree" class="toll-free-container">
    <form [formGroup]="tollFreeSearchForm">
      <span class="search-box-group-container">
        <span class="area-code-container">
          <label>{{'choose_new_number.x_area_code' | translate}}</label><br>
          <cl-dropdown [items]="tollFreeAreaCodes" formControlName="areaCodeTollFree" [tabbable]="true"
            class="toll-free-area-code-dropdown" ngDefaultControl>
          </cl-dropdown>
        </span>
        <span class="containing-container">
          <label>{{'choose_new_number.containing' | translate}}</label><br>
          <input formControlName="containing" class="containing-search-box" [attr.tabindex]="0" type="text" maxlength="7"
            placeholder="{{'choose_new_number.enter_4_to_7' | translate}}" (input)="onContainingInput($event)">
          <div *ngIf="containingErr" class="alert alert-danger">
            {{'choose_new_number.enter_4_to_7' | translate}}
          </div>
        </span>
        <span class="search-container">
          <button class="btn btn-default" tabindex="0" (click)='onTollFreeSearch()' [disabled]="!tollFreeSearchForm.valid || containingErr">
            {{'choose_new_number.search' | translate}}
          </button>
        </span>
      </span>
    </form>
  </span>
  <span *ngIf="!phSvc.tnIntegration.filterByCity && numberType!==numberTypes.tollFree"
    class="filter-by-number-container" [ngClass]="{'full-width': !isLocalNumber}">
    <form [formGroup]="numbersForm" class="numbers-form">
      <!-- </form> class="numbers-form"> -->
      <span class="search-box-group-container" [ngClass]="{'local-number-search-container': numberType !== 'default'}">
        <span class="search-box-group">
          <span class="area-code-elem fragment">
            <label>{{'choose_new_number.x_area_code' | translate}}</label><br>
            <span class="area-code-brace-left">(</span>
            <input formControlName="areaCode" id="new-number-search-area-code" class="search-box area-code col-md-12"
              type="text" [attr.tabindex]="0" maxlength="3" (input)="onUserInputNumber($event)">
            <span class="area-code-brace-right">)</span>
          </span>
          <span class="prefix-elem fragment">
            <label>{{'choose_new_number.x_prefix' | translate}}</label><br>
            <input formControlName="prefix" #prefixElement id="new-number-search-prefix" class="search-box" type="text"
              [attr.tabindex]="0" maxlength="3" (input)="onUserInputNumber($event)">
          </span>
          <span class="phone-next-digit">
            <label>{{'choose_new_number.x_next_digit' | translate}}</label><br>
            <span class="digit-elem">
              <span class="phone-hyphen">-</span>
              <span class="next-digit-elem">
                <input formControlName="nextDigit" #nextDigitElement id="new-number-search-next-digit" class="search-box small-box"
                  type="text" [attr.tabindex]="0" maxlength="1" (input)="onUserInputNumber($event)">
                <span class="x-digits">XXX</span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </form>
    <span class="search-btn-container" *ngIf="!isLocalNumber">
      <button class="btn" type="button" tabindex="0" (click)='onNumberFilterSelected()'
      [ngClass]="emptyInputTN ? 'btn-disabled' : 'btn-default'" [disabled]="emptyInputTN">
        {{'choose_new_number.search' | translate}}
      </button>
    </span>
  </span>
  <!-- this is for new local numbers -->
  <form class="row number-range-form" [formGroup]="numberRangeForm" *ngIf="isLocalNumber"
    [ngClass]="phSvc.tnIntegration.filterByCity ? 'col-md-6' : 'col-md-8'">
    <div class="quantity-container">
      <label>{{'choose_new_number.quantity' | translate}}</label><br>
      <ng-template #quantityMsg>
        <p class="quantity-popup">{{'choose_new_number.enter_1_to_110' | translate}}</p>
      </ng-template>
      <input class="quantity-input" formControlName="quantity" [(ngModel)]="quantityModel" [ngbTooltip]="quantityMsg"
        (blur)="quantityBlur()" [attr.tabindex]="0" type="number" [maxLength]="3" (input)="onQuantityInput($event)">
      <span class="value-btn-container">
        <button class="quantity-btn btn-plus" (click)="increaseValue()">
          <span class="plus-icon">+</span>
        </button>
        <button class="quantity-btn btn-minus" (click)="decreaseValue()">
          <span class="minus-icon">-</span>
        </button>
      </span>
    </div>
    <div class="range-search-div col-md-9">
      <span class="range-container pull-left">
        <label class="range-label">
          <input [checked]="true" formControlName="continuousRange" type="checkbox" (click)="onClickContinousRange($event)">
          {{'choose_new_number.continuous_range_label' | translate}}
        </label>
      </span>

      <span class="search-container pull-right">
        <button *ngIf="phSvc.tnIntegration.filterByCity" class="btn" tabindex="0" (click)='searchLocalNumber()'
          [disabled]="emptyInputRC || !numberRangeForm.valid" [ngClass]="emptyInputRC || !numberRangeForm.valid ? 'btn-disabled' : 'btn-default'">
          {{'choose_new_number.search' | translate}}
        </button>

        <button *ngIf="!phSvc.tnIntegration.filterByCity" class="btn" tabindex="0" (click)='onNumberFilterSelected(true)'
          [disabled]="emptyInputTN || !numberRangeForm.valid" [ngClass]="emptyInputTN || !numberRangeForm.valid ? 'btn-disabled' : 'btn-default'">
          {{'choose_new_number.search' | translate}}
        </button>

      </span>
    </div>
  </form>
</span>

<!-- <button class="btn" tabindex="0" [ngClass]="(emptyInputRC || emptyInputTN) ? 'btn-disabled' : 'btn-default'"
  [disabled]="phSvc.tnIntegration.filterByCity ? emptyInputRC : emptyInputTN"
  (click)='phSvc.tnIntegration.filterByCity ? searchLocalNumber() : onNumberFilterSelected(true)'>
  {{'choose_new_number.search' | translate}}
</button> -->