
<div class="container">
  <div class="row">
    <div class="col-2">
      <img alt="Information" src="../../../../../../assets/images/Exclamation_in_circle_Icon_1x.svg">
    </div>
    <div class="col-10">
      <div class="subtitle">{{ 'change_number_modal.release_previous_number_headline' | translate }}</div>
      <br>
      <p>{{ 'change_number_modal.release_previous_number_body' | translate }}</p>
    </div>
  </div>
</div>
<hr>
