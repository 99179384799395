/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FeatureDC } from '../models/feature-dc';
@Injectable({
  providedIn: 'root',
})
class FeaturesService extends __BaseService {
  static readonly FeaturesGetFeaturesPath = '/api/Features';
  static readonly FeaturesGetFeaturePath = '/api/Features/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  FeaturesGetFeaturesResponse(Authorization: string): __Observable<__StrictHttpResponse<FeatureDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Features`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  FeaturesGetFeatures(Authorization: string): __Observable<FeatureDC> {
    return this.FeaturesGetFeaturesResponse(Authorization).pipe(
      __map(_r => _r.body as FeatureDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `FeaturesService.FeaturesGetFeatureParams` containing the following parameters:
   *
   * - `id`: Feature Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeatureResponse(params: FeaturesService.FeaturesGetFeatureParams): __Observable<__StrictHttpResponse<FeatureDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Features/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `FeaturesService.FeaturesGetFeatureParams` containing the following parameters:
   *
   * - `id`: Feature Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeature(params: FeaturesService.FeaturesGetFeatureParams): __Observable<FeatureDC> {
    return this.FeaturesGetFeatureResponse(params).pipe(
      __map(_r => _r.body as FeatureDC)
    );
  }
}

module FeaturesService {

  /**
   * Parameters for FeaturesGetFeature
   */
  export interface FeaturesGetFeatureParams {

    /**
     * Feature Id
     */
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { FeaturesService }
