import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

declare var require:any;

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit, OnChanges {


  @Input() externalStyles;
  /**
   * true to make the loading spinner visible
   * false to remove the loading spinner
   *
   * @type {boolean}
   * @memberof SpinnerComponent
   */
  @Input() showSpinner:boolean = false;  // requested spinner state

  /**
   * Optional message to display under the spinner.
   *
   * @type {string}
   * @memberof SpinnerComponent
   */
  @Input() message:string = '';

  /**
   * (optional) Delay displaying the spinner by n milliseconds. If isVisible is cancelled during this interval, never display.
   *
   * @type {number}
   * @memberof SpinnerComponent
   */
  @Input() delay:number = 0;

  /**
   * (optional) Width of card containing spinner, in px. The height is auto-set tto 50% of width.
   *
   * @type {number}
   * @memberof SpinnerComponent
   */
  @Input() widthPx:number = 280;
  heightPx = this.widthPx/2;

  /**
   * (optional) image ID. We have several images that may be displayed.
   *   0 (default)  a circle spinner
   *   1 has mitel logo and 3 spinning guitar pik shaped things
   *
   * @type {number}
   * @memberof SpinnerComponent
   */
  @Input() imageId: number = 0;

  /**
    * (optional) true says component is a popup, changes placement of spinner
    * false says component is not a popup, uses default spinner placement
    *
    * @type {boolean}
    * @memberof SpinnerComponent
    */
  @Input() isPopUp: boolean = false;


  /**
    * (optional) custom placement of the spinner on the page
    * using css properties 'top' and 'left'
    *
    * @memberof SpinnerComponent
    */
  @Input() customPosition: { position?: string, top?: string , left?: string };


  // actual spinner state
  spinnerStateActive = this.showSpinner;

  // spinning images;
  images: any[] = [];
  imgname: any;

  constructor() {
    this.images.push( require("./loading_360.gif") );
    this.images.push(require("./with-mitel-logo-high-resolution.gif"));

    this.imgname = this.images[ this.imageId ];
  }

  ngOnInit() {
  }


  /**
   * Called when Inputs() change.
   * 
   */
  ngOnChanges(changes: SimpleChanges) {

    this.showSpinner = changes.showSpinner ? changes.showSpinner.currentValue : false;
    this.heightPx = changes.widthPx ? changes.widthPx.currentValue / 2 : this.widthPx/2;
    this.imageId = changes.imageId && (changes.imageId.currentValue < this.images.length) ? changes.imageId.currentValue : this.imageId;
    this.imgname = this.images[ this.imageId ];

    if ( changes.showSpinner && changes.showSpinner.currentValue === true){
      // delay spinner start
      setTimeout( ()=>{
        this.spinnerStateActive = this.showSpinner;
      }, this.delay);  // optional delay
    } else {
      this.spinnerStateActive = this.showSpinner;
    }
  }

  styleSpinner() {
    let styles;
    if (this.customPosition) {
      styles = {
        'position': this.customPosition.position ? this.customPosition.position : 'absolute',
        'top':  this.customPosition.top ? this.customPosition.top : null,
        'left':  this.customPosition.left ? this.customPosition.left : null
      };
    } else {
      styles = {
        'top':  this.isPopUp ? '45%' : null,
        'left':  this.isPopUp ? '26%' : null
      };
    }

    return styles;
  }

}
