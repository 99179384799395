import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-visualization-error',
  templateUrl: './visualization-error.component.html',
  styleUrls: ['./visualization-error.component.scss']
})
export class VisualizationErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
