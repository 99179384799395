import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessMessageService {

  private subject = new Subject<any>();
  constructor() { }

  sendMessage(message: string, open: boolean, type: string = 'success') {
    this.subject.next({message, open, type});
  }

  getMessage() {
    return this.subject.asObservable();
  }
}
