import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {InteractiveModalResult} from '../cl-modal/modal-template';

@Injectable({
  providedIn: 'root'
})
export class ClModalService {

  private subject = new Subject<any>();
  public turnOnOffOKBtn = new Subject<boolean>();
  public turnOnOffOptionBtn = new Subject<boolean>();
  private okClick = new Subject<boolean>();
  private optionsClick = new Subject<boolean>();
  private okActionResults = new Subject<InteractiveModalResult>();
  private optionsActionResults = new Subject<InteractiveModalResult>();

  constructor() { }

  sendIsModalOpen(isOpen: boolean) {
    return this.subject.next(isOpen);
  }

  getIsModalOpen() {
    return this.subject.asObservable();
  }

  toggleOKBtn (on: boolean) {
    this.turnOnOffOKBtn.next(on);
  }

  getToggledStateForOKBtn (): Observable<boolean>{
    return this.turnOnOffOKBtn.asObservable();
  }

  toggleOptionsBtn (on: boolean) {
    this.turnOnOffOptionBtn.next(on);
  }

  getToggledStateForOptionsBtn (): Observable<boolean>{
    return this.turnOnOffOptionBtn.asObservable();
  }

  registerOkAsyncAction (action: InteractiveModalResult) {
    this.okActionResults.next(action);
  }

  getOkAsyncActionResults (): Observable<InteractiveModalResult> {
    return this.okActionResults.asObservable();
  }

  sendOkClick (isClicked: boolean) {
    this.okClick.next(isClicked);
  }

  getOkClick (): Observable<boolean>{
    return this.okClick.asObservable();
  }

  registerOptionsAsyncAction (action: InteractiveModalResult) {
    this.optionsActionResults.next(action);
  }

  getOptionsAsyncActionResults (): Observable<InteractiveModalResult> {
    return this.optionsActionResults.asObservable();
  }

  sendOptionsClick (isClicked: boolean) {
    this.optionsClick.next(isClicked);
  }

  getOptionsClick (): Observable<boolean>{
    return this.optionsClick.asObservable();
  }
}
