<div class="container">
	<div class="row">
		<div class="col-md-12 title">
			{{ 'navigation_confirmation.title' | translate }}
			<button id="navigation-confirm-close" type="button" class="close" aria-label="Close" (click)="stayOnPage()" aria-hidden="true" >
				&#10005;
			</button>
		</div>
	</div>
	<hr>
	<div class="content">
		<span>{{ 'navigation_confirmation.content' | translate }}</span>
	</div>
  <div class="row">
  	<div class="col-md-12 actions">
      <button id="nav-confirm-leave" [ngStyle]="{'width.px': 145}" type="button" class="btn btn-default" (click)="leavePage()">{{ 'navigation_confirmation.leave' | translate }}</button>
      <button id="nav-confirm-stay" [ngStyle]="{'width.px': 148}" type="button" class="btn btn-primary" (click)="stayOnPage()">{{ 'navigation_confirmation.stay' | translate }}</button>
    </div>
  </div>
</div>
