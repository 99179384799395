// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cloud:  'dev',
  bossApiBaseUrl: 'https://portal-can01.mitellabs.com:8443',
  mediaTranscodeApiBaseUrl: 'https://media.dev.api.mitel.io/2017-09-01',
  clientId: 'ef0129223a2e3bf76e7c3d8422b15b53',
  packetTrace: true,
  thisFilesName: 'environment.ts',
  
  /**
   * This is the UAT Live Agent Salesforce script
  */
  salesForceURL: 'https://c.la1-c1cs-phx.salesforceliveagent.com/content/g/js/46.0/deployment.js'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
