    <div class="btn-group" [ngClass]="{'schedules-non-popup-width': manualWidth}" #myDropdownWithHeading [attr.tabindex]="tabbable && !disabled ? 0 : -1">
  <a class="button" class="btn dropdown-toggle truncate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<!--    {{ value ? (value.displayName) : ('cl_dropdown.please_select' | translate) }}-->
      <div class="checkbox-block">
          <div class="minus-block" *ngIf="minusBlock">
                  <div class="minus-icon" (click)="updateCheckBoxValueByMinusBlock()">

              </div>
              <i class="fa fa-minus minus-position" (click)="updateCheckBoxValueByMinusBlock()" aria-hidden="true"></i>
          </div>
          <label for="dropdownHeading" class="form-check-label" *ngIf="!minusBlock">
          <div class="checkbox">
              <input type="checkbox"
                     id="dropdownHeading"
                     class="form-control"
                     [attr.tabindex]="tabbable ? 0 : -1"
                     [ngModel]="checkboxValue"
                     (click)="updateCheckBoxValue($event)"
                     style="pointer-events: visible;cursor: pointer"
                     class="form-control">
              <span class="checkmark"></span>
          </div>
          </label>
          <div class="value-block">{{selectedValue}}</div>
      </div>
  </a>
</div>
