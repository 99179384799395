import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SchedulesService } from 'src/app/services/schedules.service';

@Component({
  selector: 'app-schedules-model',
  templateUrl: './schedules-model.component.html',
  styleUrls: ['./schedules-model.component.scss']
})
export class SchedulesModelComponent implements OnInit {

  @Output() close: EventEmitter<any> = new EventEmitter();
  tabbable = true;
  constructor(public activeModal: NgbActiveModal,
              public translateSvc: TranslateService,
              private schedulesSvc: SchedulesService) { }

  ngOnInit() {

  }
  onClose() {
    this.schedulesSvc.setClickedSchedule(null);
    this.activeModal.close();
  }

  onClickOption(data) {
    console.log('user clicked', data);
    this.activeModal.close(data);
  }

}
