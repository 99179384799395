<div class="wrapper" *ngIf="rightPanelState === 'out'">

    <div *ngIf="showFormError">
        <cl-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></cl-error-alert>
    </div>

    <div class="flows-title">
        <div class="title">{{ 'flows_dashboard.title' | translate }}</div>
    </div>
    <div class="flow-dashboard-controls">
        <div class="flows-controls">
            <div class="search-box-group">
                <span class="search-icon">
                    <img
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ2ICg0NDQyMykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbi9TZWFyY2gvR3JleTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9TZWFyY2gvR3JleSIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjQkZCRkMwIj4KICAgICAgICAgICAgPHBhdGggZD0iTTgsMiBDNC42ODYyOTE1LDIgMiw0LjY4NjI5MTUgMiw4IEMyLDExLjMxMzcwODUgNC42ODYyOTE1LDE0IDgsMTQgQzExLjMxMzcwODUsMTQgMTQsMTEuMzEzNzA4NSAxNCw4IEMxNCw0LjY4NjI5MTUgMTEuMzEzNzA4NSwyIDgsMiBaIE04LDAgQzEyLjQxODI3OCwtOC4xMTYyNDUwMWUtMTYgMTYsMy41ODE3MjIgMTYsOCBDMTYsMTIuNDE4Mjc4IDEyLjQxODI3OCwxNiA4LDE2IEMzLjU4MTcyMiwxNiA1LjQxMDgzMDAxZS0xNiwxMi40MTgyNzggMCw4IEMtNS40MTA4MzAwMWUtMTYsMy41ODE3MjIgMy41ODE3MjIsOC4xMTYyNDUwMWUtMTYgOCwwIFoiIGlkPSJSZWN0YW5nbGUtMiI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMTIuMjkyODkzMiwxMy43MDcxMDY4IEwxOC4yOTI4OTMyLDE5LjcwNzEwNjggQzE4LjY4MzQxNzUsMjAuMDk3NjMxMSAxOS4zMTY1ODI1LDIwLjA5NzYzMTEgMTkuNzA3MTA2OCwxOS43MDcxMDY4IEMyMC4wOTc2MzExLDE5LjMxNjU4MjUgMjAuMDk3NjMxMSwxOC42ODM0MTc1IDE5LjcwNzEwNjgsMTguMjkyODkzMiBMMTMuNzA3MTA2OCwxMi4yOTI4OTMyIEMxMy4zMTY1ODI1LDExLjkwMjM2ODkgMTIuNjgzNDE3NSwxMS45MDIzNjg5IDEyLjI5Mjg5MzIsMTIuMjkyODkzMiBDMTEuOTAyMzY4OSwxMi42ODM0MTc1IDExLjkwMjM2ODksMTMuMzE2NTgyNSAxMi4yOTI4OTMyLDEzLjcwNzEwNjggWiIgaWQ9IlBhdGgtMyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+" />
                </span>
                <input type="text" placeholder="{{'flows_dashboard.search_flows' | translate}}" id="search-input"
                    [attr.tabindex]="isTabbable() ? 0 : -1"
                       (ngModelChange)="this.searchValue.next($event)"
                       [(ngModel)]="searchItem">
            </div>
            <div *ngIf="locations.length > 1" class="search-location-group">
				<location-dropdown 
					[dropdownLabel]="locationDropdownLabel"
					[locationType]="locationType"
					[valueType]="locationValueType"
					[accountId]="claims.accountId"
					(onChange)="onLocationChange($event.value)"></location-dropdown>
			</div>
            <div class="actions">
                <!--<button [ngStyle]="{'width.px': (translateSvc.currentLang == 'en' ? 75: 220)}" type="button" class="edit-flows-button m-1" id="edit-flow-button" [disabled]="!hasSelectedFlows()" (click)="onEditSelectedFlows()" [attr.tabindex]="isTabbable() ? 0 : -1">
                  {{ 'flows_dashboard.edit_selected_flow' | translate }}
                </button> -->
                <button type="button" class="add-flows-button" id="add-flow-button" (click)="onAddFlow()"
                    [attr.tabindex]="isTabbable() ? 0 : -1">
                    {{ 'flows_dashboard.add_flow' | translate }}
                </button>
            </div>
        </div>
    </div>
    <cux-table #cuxtable
               [cols]="columns"
               [data]="flows"
               [rows]="rows"
               [sortField] ='defaultSortField'
               [scrollable]="scrollable"
               [scrollHeight]="scrollHeight"
               [lazy]="lazy"
               [virtualScroll]="virtualScroll"
               [virtualRowHeight]="virtualRowHeight"
               [totalRecords]="totalRecords"
               [loading]="loading"
               (loadChunk)="loadChunckData($event)"
               (selectedDataRow)=onSelectedFlowsChanged($event);
    ></cux-table>
</div>
<div *ngIf="rightPanelState === 'in'" class="component-content-container" >
    <span #visualizerContainer class="flow-viz-container" >
        <div class="exit-to-flows" (click)="onBackToFlows($event)"> <div class="exit-to-flows-icon"></div> Exit To Flows</div>
        <flow-visualizer [fullScreenView]="visualizerRightPanelState === 'out'" (close)="onCloseRightPanel($event)"></flow-visualizer>
    </span>
    <span>
        <div *ngIf="flowSelected ==='hg' && visualizerRightPanelState === 'in'" class="transparent-layer">
            <app-hunt-group [ngClass]="{'flows-iframe': loadedByIframe}" [@slideInOut]="visualizerRightPanelState"
                (close)="onCloseRightPanel($event)" (collapse)="onCollapseRightPanel($event)" [accountId]="accountId" [tabbable]="visualizerRightPanelState === 'in'"
                [toOpen]="toOpenRightPanel" (resetToOpen)="toOpenRightPanel = false"></app-hunt-group>
        </div>
    
        <div *ngIf="flowSelected ==='aa' && visualizerRightPanelState === 'in'" class="transparent-layer">
            <app-auto-attendant [ngClass]="{'flows-iframe': loadedByIframe}" [@slideInOut]="rightPanelState"
                (close)="onCloseRightPanel($event)" (collapse)="onCollapseRightPanel($event)" [accountId]="accountId" [tabbable]="rightPanelState === 'in'"
                [toOpen]="toOpenRightPanel" (resetToOpen)="toOpenRightPanel = false"></app-auto-attendant>
        </div>
    </span>
</div>
<app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" [customPosition]="{ position: 'fixed' }" message="{{ 'app.loading' | translate}}"></app-spinner>
