import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PhoneServicesService } from 'src/app/services/phone-services.service';
import { ItemsWithHeading } from 'src/app/shared/common.interface';
import { User, UserAdaptorService } from 'src/app/services/user-adaptor.service';
import { Subscription } from 'rxjs';
import { UserPhoneService } from 'src/app/services/phone-service';
import { CommonUtils } from 'src/app/shared/common-utils';
import { PhoneNumber } from 'src/app/services/tn-adaptor';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalTemplate } from 'src/app/cl-modal/modal-template';
import { ReleasePreviousNumberDialogComponent } from './release-previous-number-dialog/release-previous-number-dialog.component';
import { ClModalComponent } from 'src/app/cl-modal/cl-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { UsersService, ProductsService } from 'src/app/modules/boss-api/generated/services';
import { EditProfileDC, ProfileAddressDC, QuotePriceProductDC, QuotePriceRequestDC, BillingImpactDC,
   LocationDC } from 'src/app/modules/boss-api/generated/models';
import { PhoneNumberFormatService } from 'src/app/services/phone-number-format.service';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import { NewNumberComponent } from '../../shared/components/new-number/new-number.component';
import { TnAdapterService } from 'src/app/services/tn-adapter.service';
import {
  ResetEmergencyRegistrationDialogComponent } from './reset-emergency-registration-dialog/reset-emergency-registration-dialog.component';

import {LocationAdaptorService} from 'src/app/services/location-adaptor.service';
import * as _ from 'lodash';
import { concatMap } from 'rxjs/operators';
import {HuntGroup} from 'src/app/services/callflow-adaptor.service';
import { TemporaryNumber } from '../temporary-number/temporary-number.component';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import { FeatureService, FeatureType } from 'src/app/shared/features/features.service';
import { GeographicFeatures } from 'src/app/shared/features/geographic-feature.const';

 export enum ChangeNumberType {
  replace_with_existing = 'replace_with_existing',
  replace_with_new = 'replace_with_new',
  unassign = 'unassign',
  no_change = 'no_change'
}
export enum OldNumberAction {
  KEEP_ON_ACCOUNT = 'KEEP_NUMBER',
  RELEASE = 'RETURN_NUMBER',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum ResetEmergencyRegistration {
  REMOVE_NUMBER = 'REMOVE_NUMBER',
  KEEP_NUMBER = 'RETURN_NUMBER'
}
 export class ChangePhoneNumberData {
  changeType: ChangeNumberType = ChangeNumberType.no_change;
  phoneNumber: PhoneNumber = null;
  updateExtension = false;
  extension = '';
  oldNumberAction: OldNumberAction = OldNumberAction.RELEASE;
  rcfTarget = null;
   managementGUID = null;
   temporaryTn: TemporaryNumber = null;
 }

/**
 * This component will handle Change Phone number workflow. It is intended to be presented in a Modal.
 * Ref: Drop 3 Add Edit Tool changes: Change Phone number section. https://ottjira01.mitel.com/browse/SBOSS-9222
 */
@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.css']
})
export class ChangePhoneNumberComponent implements OnInit, OnDestroy {

  @Input() hg: HuntGroup;
  @Input() currentUserPhoneService: UserPhoneService;
  FeatureType = FeatureType;
  GeographicFeatures = GeographicFeatures;
  /**
   * If true, the newPhoneNumberDigits (replace-with-new-number) input will be always disabled
   *
   * @memberof ChangePhoneNumberComponent
   */
  @Input() userPendingClose = false; //
  /**
   * Fires when user submits form data
   *
   * @type {EventEmitter<ChangePhoneNumberData>}
   * @memberof ChangePhoneNumberComponent
   */
  @Output() outputChangePhoneNumberData: EventEmitter<ChangePhoneNumberData> = new EventEmitter();
  @Output() newPhoneNumberSelected: EventEmitter<number> = new EventEmitter<number>();
  changeNumberData: ChangePhoneNumberData = new ChangePhoneNumberData();

  showFormError = false;
  errMsg = '';
  displayLoadingSpinner = false;

  changeNumberForm: FormGroup;
  availableExistingPhoneNumbers: ItemsWithHeading[];
  subscriptions: Subscription[] = [];
  tabbable = true;
  disable_existingPhoneNumbers = false;  // default has existing-numbers radio selected
  disable_newPhoneNumbers = true;
  modalRef: NgbModalRef;
  translatedStrings = {};
  enableSubmit = false;  // if false, disable the submit nutton
  newPhoneNumber: any /* this needs a type */;  // this is a user selected number as returned by the 'New' phone number dialog
  hasTelephoneNumber = true; // indicates if a user has a telephone number (in addition to a extension)
  termsAndServiceUrl;
  loadingNewNumber = false;
  imgname: string;
  translated;
  originalSubscription;
  currentSelectedUserAddOns;
  mobilityAddOns;
  listOfAddOnsWhichRequireTN = [];
  tooltipString;
  tnRequiredAddOnsString;
  disableUnassign;
  temporaryTn: TemporaryNumber = {rcfTarget: null, mgmtGUID: null};
  hasExistingRcfTarget = false;
  tnPortedOrTransferred = false;
  showTemporaryNumber = false;
  defaultUserCountryCode = 840;
  isCartOpen = false;

  constructor(
    public phSvc: PhoneServicesService,
    private modalSvc: NgbModal,
    private translateSvc: TranslateService,
    private bossApiUsersService: UsersService,
    private phoneNumberFormatService: PhoneNumberFormatService,
    public tnAdapterSvc: TnAdapterService,
    private userAdaptorSvc: UserAdaptorService,
    private shoppingCartService: ShoppingCartService,
    private productsServices: ProductsService,
    public featureService: FeatureService,
    private locationService: LocationAdaptorService,
    ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach( s => s.unsubscribe() );
  }

  ngOnInit() {
    this.subscriptions.push(this.translateSvc.get('base64_images').subscribe(strings => {
      this.translated = strings;
      // console.log('Translated Strings', this.translated);
    }));
    this.imgname = this.translated.loading_360;

    this.changeNumberForm = new FormGroup({
      'changeNumberType': new FormControl('replace_with_existing'),
      'updateExtension': new FormControl(true),
      'extension': new FormControl({value: '1234', disabled: false},
       Validators.pattern('^\\d{4}$')), /* disable only if update_extension checkbox is un-checked */
      'existingPhoneNumbers': new FormControl(''),
      'newPhoneNumberDigits': new FormControl({value: '', disabled: true})
    });
    // this.originalSubscription = this.phSvc.getEditPhoneServiceData().currentUserPhoneService.serviceBundle;
    // this.currentSelectedUserAddOns = this.phSvc.getEditPhoneServiceData().currentUserAddOns;
    // this.currentSelectedUserAddOns.forEach((item) => {
    // });
    this.termsAndServiceUrl = this.locationService.termsAndServiceLink;
    this.locationService.getTermsAndServiceLinkWhenBillingImpact(this.hg.locationUUID).toPromise().then(response => {
      this.termsAndServiceUrl = response.toString().split('"').join('');
      this.locationService.sendTermsAndServiceLink(this.termsAndServiceUrl);
    });
    this.subscriptions.push(this.phSvc.getAvailPhoneNumbers(this.hg, this.hg.locationUUID).subscribe(availablePhoneNumbers => {
      this.availableExistingPhoneNumbers = availablePhoneNumbers;
      if ( (availablePhoneNumbers[0].heading === 'cl_dropdown_headers.ported')  ||
          (availablePhoneNumbers[0].heading === 'cl_dropdown_headers.transferRequested') ) {
      }
    }));

    this.subscriptions.push(this.translateSvc.get(['change_number_modal', 'users_right_panel'])
    .subscribe(translated => this.translatedStrings = translated ));
    this.subscriptions.push(this.translateSvc.get(['change_number_modal', 'users_right_panel'])
        .subscribe(translated => this.tnRequiredAddOnsString = translated ));

    // In the event that user selects a 'New' phone number, we need to prime phSvc with a location that will get
    // fetched by the new-number modal.
    // Needed because of a dependency in new-number.component.ts => tnadaptor-service
    this.subscriptions.push(this.phSvc.getLocationDC(this.hg.locationUUID).subscribe());

    this.changeNumberForm.patchValue({
      extension: this.hg ? this.hg.extensionFormatted : ''
    });
    this.changeNumberForm.markAsPristine();
    if (this.hg) {
      if (this.hg.rcfTarget) {
        this.temporaryTn.rcfTarget = this.hg.rcfTarget;
        if (this.temporaryTn.rcfTarget && this.temporaryTn.rcfTarget.length > 0) {
          this.hasExistingRcfTarget = true;
        }
      }
    }
    this.hasTelephoneNumber = CommonUtils.flowHasPhoneNumber(this.hg);
    this.subscriptions.push(
        this.shoppingCartService.cartButtonVisibilityInChangeNumberComponent.subscribe(
            cartButtonVisibile => this.isCartOpen = cartButtonVisibile));
    // this.shoppingCartService.init();
  }

  // close on esc key
  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.onCancel(event);
    event.stopPropagation();
  }

  // close on click outside of this modal
  // @HostListener('document:touchend', ['$event'])
  // @HostListener('document:click', ['$event'])
  // clickedOutside(event) {
  //   if ( this.modalRef) {
  //     // our child modal is open, ignore
  //     return;
  //   }

  //   // is this a button click? ignore
  //   if (event && event.target && event.target.type == "button") {
  //     return;
  //   }

  //   // did user click outside of this element?
  //   if (!this.elRef.nativeElement.contains(event.target)) {
  //     // yes, clicked outside
  //     this.onCancel();
  //     event.stopPropagation();
  //   }
  // }
  /**
   * Called when a 'existing' phone number is selected
   * @param event
   */
  onChangedExistingPhoneNumberDigits(event: {value: PhoneNumber, reason: string}) {

    this.clearServerErrorOnForm();

    // If event due to value set programmatically(eg at init) or if the new value is the same as the old value, pristinify and return
    if (event.reason === 'set' ||
     (CommonUtils.flowHasPhoneNumber(this.hg) && event.value.value === this.hg.tnId)) { // ??
      this.changeNumberForm.markAsPristine();
      this.enableSubmit = false;
      return;
    }

    this.tnPortedOrTransferred = this.phoneNumberIsPortedOrTransferred(event.value);
    if (this.tnPortedOrTransferred || this.hasExistingRcfTarget) {
      this.showTemporaryNumber = true;
    }

    this.enableSubmit = true;

    // if (!this.modalRef && CommonUtils.userHasPhoneNumber(this.currentUserPhoneService)
    // && event.value !== this.currentUserPhoneService.phoneNumber) {
    //   this.openReleaseNumberModal();
    // }

    // this.devicesAndServices.get('extension').patchValue(this.phSvc.convertPhoneNumberToExtension(event.value.value));
  }

  onTemporaryNumberUpdate(tempTn: TemporaryNumber) {
    this.temporaryTn = tempTn;
  }

  phoneNumberIsPortedOrTransferred(number: PhoneNumber): boolean {
    let isPortedOrTransferred = false;

    if (number && number.displayName && number.displayName.length > 0 && this.availableExistingPhoneNumbers) {
      for (const numberList of this.availableExistingPhoneNumbers) {
        if (numberList.heading === 'cl_dropdown_headers.ported') {
          if (numberList.items) {
            const foundNum = numberList.items.find(portedNum => portedNum.displayName === number.displayName);
            if (foundNum !== undefined) {
              isPortedOrTransferred = true;
            }
            break;
          }
        }
      }
    }
    return (isPortedOrTransferred);
  }

  /**
   * Called when user clicks one of the radio buttons or checkbox.
   * Used to enable/disable html elements based on itemName
   * @param itemName local identifier. What was checked?
   */
  onOptionChange(itemName: string) {
    // console.log(radioItemValue);
    this.clearServerErrorOnForm();
    if (this.hasExistingRcfTarget) {
      this.showTemporaryNumber = true;
    } else {
      this.showTemporaryNumber = false;
    }
    switch (itemName.toLowerCase()) {
      case ChangeNumberType.replace_with_existing: {
        this.shoppingCartService.cartButtonVisibilityInChangeNumberComponent.next(false);
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
        this.changeNumberData.changeType = ChangeNumberType.replace_with_existing;
        this.disable_existingPhoneNumbers = false;
        this.disable_newPhoneNumbers = true;
        this.enableSubmit = false;
        this.showTemporaryNumber = false;  // always set this to false here as the number
        // selection will drive the visibility of the temp number display
        // cleanup new-phone-number (in case it was previously selected)
        const currentTn = this.hg.tnId;
        const formTnValue = this.changeNumberForm.controls['existingPhoneNumbers'] ?
         this.changeNumberForm.controls['existingPhoneNumbers'].value : undefined;

        if (currentTn !== null && formTnValue && currentTn !== formTnValue.value) {
          this.enableSubmit = true;
        } else if (currentTn === null && formTnValue && formTnValue.value !== '') {
          this.enableSubmit = true;
        }

        this.changeNumberForm.controls['newPhoneNumberDigits'].disable();
        this.changeNumberForm.patchValue({
          newPhoneNumberDigits: ''
        });
        this.newPhoneNumber = undefined;
        break;
      }
      case ChangeNumberType.replace_with_new: {
        this.changeNumberData.changeType = ChangeNumberType.replace_with_new;
        this.shoppingCartService.cartButtonVisibilityInChangeNumberComponent.next(true);
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
        this.disable_existingPhoneNumbers = true;
        this.enableSubmit = false;
        // Enabled only if not blocked by @Input() userPendingClose  prop
        if (!this.userPendingClose) {
          this.changeNumberForm.controls['newPhoneNumberDigits'].enable();
          this.changeNumberForm.controls['newPhoneNumberDigits'].markAsTouched();
          this.disable_newPhoneNumbers = false;
          this.tnPortedOrTransferred = false;
          // if (this.hasExistingRcfTarget) {
          //   this.showTemporaryNumber = true;
          // } else {
          //   this.showTemporaryNumber = false;
          // }

          if (this.phSvc.getSelectedLocation()) {
            this.loadingNewNumber = true;

            this.subscriptions.push(this.phSvc.getLocationDC(this.phSvc.getSelectedLocation()).subscribe((location: LocationDC) => {
              this.subscriptions.push(this.tnAdapterSvc.getAvailableNumbersFromSearchCriteria(location, 1)
              .subscribe((availNumber) => {
                this.loadingNewNumber = false;
                this.clearServerErrorOnForm();
                let openModal = false;
                  if (availNumber && availNumber.length) {
                    if (availNumber.length === 1 && Object.keys(availNumber[0]).length === 0) {
                      openModal = true;
                    }
                  } else {
                    openModal = true;
                  }
                  if (openModal) {
                    this.openNewNumberModal();
                  } else {
                    this.addPhoneNumber(availNumber[0]);
                    this.displayShoppingCart(availNumber[0].turnupProductId);
                    // this.newPhoneNumberSelected.emit(availNumber[0].turnupProductId);
                  }
                },
                (error) => {
                  this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                    this.showServerErrorOnForm(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                    this.loadingNewNumber = false;
                  }));
                }));
              },
              (error) => {
                this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                  this.showServerErrorOnForm(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  this.loadingNewNumber = false;
                }));
              }));
          }
        }
        break;
      }
      case ChangeNumberType.unassign : {
        this.changeNumberData.changeType = ChangeNumberType.unassign;
        this.shoppingCartService.cartButtonVisibilityInChangeNumberComponent.next(false);
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
        this.disable_existingPhoneNumbers = true;
        this.disable_newPhoneNumbers = true;
        this.enableSubmit = true;
        this.tnPortedOrTransferred = false;
        // if (this.hasExistingRcfTarget) {
        //   this.showTemporaryNumber = true;
        // } else {
        //   this.showTemporaryNumber = false;
        // }
        // cleanup new-phone-number (in case it was previously selected)
        this.changeNumberForm.controls['newPhoneNumberDigits'].disable();
        this.changeNumberForm.patchValue({
          newPhoneNumberDigits: ''
        });
        this.newPhoneNumber = undefined;

        break;
      }
      case 'update_extension': {
        // disable 'extension' input if update_extension checkbox is un-checked
        const isTicked_update_extension = this.changeNumberForm.controls['updateExtension'].value;
        isTicked_update_extension ?
        this.changeNumberForm.controls['extension'].enable() : this.changeNumberForm.controls['extension'].disable();
        break;
      }
      default: {
        this.disable_existingPhoneNumbers = true;
      }
    }
  }

  /**
   * Put up the 'Release Previous Number' dialog.
   */
  popModalReleasePreviousNumber(): Promise<OldNumberAction> {
    if (!this.modalRef) {
      // Create & display the modal.
      const modalTemplate: ModalTemplate = {
        title: 'change_number_modal.release_previous_number_title',
        content: ReleasePreviousNumberDialogComponent,
        isContentHTML: true,
        optionalBtn: {
          value: OldNumberAction.KEEP_ON_ACCOUNT,
          label: this.translatedStrings['change_number_modal']['keep_number'],
          width: this.translatedStrings['change_number_modal']['keep_number'].length * 10 + 15
        },
        primaryBtn: {
          value: OldNumberAction.RELEASE,
          label: this.translatedStrings['change_number_modal']['return_number'],
          width: this.translatedStrings['change_number_modal']['return_number'].length * 10 + 15
        },
        data: null    /* data passed into modal component */
      };

      const options: NgbModalOptions = {
        windowClass: 'sm-modal',
        backdrop: 'static'
      };

      // Pop open the modal
      this.modalRef = this.modalSvc.open(ClModalComponent, options);
      this.modalRef.componentInstance.modalTemp = modalTemplate;
    }

    return this.modalRef.result;
  }

  /**
   * Put up the 'Reset Emergency Registration' dialog.
   */
  popModalResetEmergencyRegistration(): Promise<ResetEmergencyRegistration> {
    if (!this.modalRef) {
      // Create & display the modal.
      const modalTemplate: ModalTemplate = {
        title: 'change_number_modal.reset_emergency_registration_title',
        content: ResetEmergencyRegistrationDialogComponent,
        isContentHTML: true,
        optionalBtn: {
          value: ResetEmergencyRegistration.REMOVE_NUMBER,
          label: this.translatedStrings['change_number_modal']['btn_remove_number_and_registration'],
          width: this.translatedStrings['change_number_modal']['btn_remove_number_and_registration'].length * 9
        },
        primaryBtn: {
          value: ResetEmergencyRegistration.KEEP_NUMBER,
          label: this.translatedStrings['change_number_modal']['btn_keep_number'],
          width: this.translatedStrings['change_number_modal']['btn_keep_number'].length * 11
        },
        data: null    /* data passed into modal component */
      };

      const options: NgbModalOptions = {
        windowClass: 'sm-modal',
        backdrop: 'static'
      };

      // Pop open the modal
      this.modalRef = this.modalSvc.open(ClModalComponent, options);
      this.modalRef.componentInstance.modalTemp = modalTemplate;
    }

    return this.modalRef.result;
  }

  onCancel(event) {
    this.outputChangePhoneNumberData.complete();
    this.clearServerErrorOnForm();
    // close child modal, if open
    this.closeModal();
    event.stopPropagation();
  }

  async assembleUsersEditProfileParams(oldNumberAction: OldNumberAction, formData: any): Promise<UsersService.UsersEditProfileParams> {
    this.changeNumberData = {
      changeType: formData.changeNumberType,
      phoneNumber: null,
      updateExtension: formData.updateExtension,
      extension: formData.updateExtension ? formData.extension : null,
      oldNumberAction: oldNumberAction,
      rcfTarget: null,
      managementGUID: null,
      temporaryTn: this.temporaryTn,
    };
    switch (formData.changeNumberType) {
      case ChangeNumberType.replace_with_existing: {
        this.changeNumberData.phoneNumber = formData.existingPhoneNumbers;
        break;
      }
      case ChangeNumberType.replace_with_new : {
        // this.changeNumberData.phoneNumber = formData.newPhoneNumbers;
        this.changeNumberData.phoneNumber = this.newPhoneNumber;
        break;
      }
    }

    const editProfileDC: EditProfileDC = {
      /**
       * New profile TN (E164 format)
       * Blank to unassign
       */
      tn: this.changeNumberData.changeType === ChangeNumberType.unassign ? '' : this.changeNumberData.phoneNumber.value,
    };

    /**
      * Indicates whether to release current profile TN into system inventory (true), or keep on account (false)
    */
    switch (this.changeNumberData.oldNumberAction) {
      case OldNumberAction.KEEP_ON_ACCOUNT: {
        editProfileDC.releaseTn = false;
        break;
      }
      case OldNumberAction.RELEASE: {
        editProfileDC.releaseTn = true;
        break;
      }
      /* default: do not include releaseTn property */
    }

    if (this.changeNumberData.updateExtension === true) {
        editProfileDC.extension = this.changeNumberData.extension;
    }

    if (editProfileDC.tn !== '') {
      editProfileDC.tn = await this.phoneNumberFormatService
      .formatE164PhoneNumberUsingIso3166NumericCountryCode(editProfileDC.tn, this.changeNumberData.phoneNumber.countryId);
    }

    // Case 1 - we have an existing rcfTarget that needs to be unassigned
    if (this.hasExistingRcfTarget && !this.tnPortedOrTransferred) {
      editProfileDC.rcfTarget = null;
      editProfileDC.managementGUID = null;
      this.changeNumberData.temporaryTn.rcfTarget = null;
      this.changeNumberData.temporaryTn.mgmtGUID = null;
      this.changeNumberData.rcfTarget = null;
      this.changeNumberData.managementGUID = null;
    } else if (!this.hasExistingRcfTarget && this.tnPortedOrTransferred) {
      console.log('Apply new Temp num in profile update');
      editProfileDC.rcfTarget = this.temporaryTn.rcfTarget;
      editProfileDC.managementGUID = this.temporaryTn.mgmtGUID;
      this.changeNumberData.rcfTarget = this.temporaryTn.rcfTarget;
      this.changeNumberData.managementGUID = this.temporaryTn.mgmtGUID;
      this.changeNumberData.temporaryTn.rcfTarget = this.temporaryTn.rcfTarget;;
      this.changeNumberData.temporaryTn.mgmtGUID = this.temporaryTn.mgmtGUID;;
    }

    if ( (editProfileDC.rcfTarget) && (editProfileDC.rcfTarget !== '') ) {
      editProfileDC.rcfTarget = await this.phoneNumberFormatService.formatE164PhoneNumberUsingIso3166NumericCountryCode(
          editProfileDC.rcfTarget, this.changeNumberData.phoneNumber.countryId);
    }

    const params: UsersService.UsersEditProfileParams = {
      Authorization: null,
      userUuid: this.hg.id,
      profileId: +this.hg.id,
      profileData: editProfileDC
    };

    return params;
  }

  /**
   * User submitted the form
   */
  onChange() {

    const formData = this.changeNumberForm.value;
    this.clearServerErrorOnForm();

    // validate form; is replace with existing selected TN actual same as current TN ?
    if (formData.changeNumberType === ChangeNumberType.replace_with_existing
      && this.hg.tnId === formData.existingPhoneNumbers.value) {
      this.showServerErrorOnForm(this.translatedStrings['change_number_modal']['error_tn_same']);
      return;
    }

    if (formData.changeNumberType === ChangeNumberType.replace_with_new && !this.newPhoneNumber) {
      this.showServerErrorOnForm('You did not select a new phone number');
      return;
    }

    if (this.temporaryTn.rcfTarget && this.temporaryTn.rcfTarget.length > 0) {
      this.submitData(OldNumberAction.NOT_APPLICABLE, formData);
    } else {
      if (this.hg.tnId) {
        if (this.changeNumberData.changeType === ChangeNumberType.unassign) {
          // Emergency registration is not required in callflow. We just need to display the Release Previous Number
          // popup and blank out the phone number
          this.handleModalReleasePreviousNumber(formData);
        } else {
          this.popModalReleasePreviousNumber()
              .then( oldNumberAction => {
                if (oldNumberAction) {
                  this.submitData(oldNumberAction, formData);
                } else {
                  // null means user canceled
                  // close the releasePreviousNumber modal
                  this.closeModal();
                }
              });
        }
      } else { this.submitData(OldNumberAction.NOT_APPLICABLE, formData); }
    }
    // if (this.hg.tnId) {
    //   if (this.changeNumberData.changeType === ChangeNumberType.unassign) {
    //     // Emergency registration is not required in callflow. We just need to display the Release Previous Number
    //     // popup and blank out the phone number
    //       this.handleModalReleasePreviousNumber(formData);
    //   } else { this.handleModalReleasePreviousNumber(formData); }
    //     // ask the user what they want to do with the old number
    //     this.popModalReleasePreviousNumber()
    //       .then( oldNumberAction => {
    //         if (oldNumberAction) {
    //           this.submitData(oldNumberAction, formData);
    //         } else {
    //           // null means user canceled
    //           // close the releasePreviousNumber modal
    //           this.closeModal();
    //         }
    //       });
    // } else { this.submitData(OldNumberAction.NOT_APPLICABLE, formData); }

  }

  handleModalReleasePreviousNumber(formData) {
    // ask the user what they want to do with the old number
    this.popModalReleasePreviousNumber()
      .then( oldNumberAction => {
        if (oldNumberAction) {
          this.submitData(oldNumberAction, formData);
        } else {
          // null means user canceled
          // close the releasePreviousNumber modal
          this.closeModal();
        }
      });
  }

  /**
   * Send data to SDApi back end.
   * @param oldNumberAction
   * @param formData
   */
  async submitData(oldNumberAction: OldNumberAction, formData: any) {

    try {
      // collect and emit user entered data
      const params = await this.assembleUsersEditProfileParams(oldNumberAction, formData);
      this.displayLoadingSpinner = true;

      if (this.newPhoneNumber) {
        // TODO place order code
        // a new number was reserved; need to purchase it
        // await this.tnAdapterSvc.placeOrder([this.newPhoneNumber], this.newPhoneNumber.locationUuid).toPromise()
        // .then (
        //   response => {
        //     // console.log('call /api/Carrier/Order resp', response);
        //   },
        //   error => {
        //     console.error('submitData tnAdapterSvc.placeOrder()', error);
        //     throw(new Error(BossApiUtils.extractErrorMessage(error)));
        //   }
        // );
      }

      this.outputChangePhoneNumberData.emit(this.changeNumberData);
      this.phSvc.changeInModalInRightPanel.next(true);

      // close the releasePreviousNumber modal
      this.closeModal();
    } catch (error) {
      console.error('submitData popModalReleasePreviousNumber handler', error);
      this.showServerErrorOnForm(error.message);
      this.closeModal();

    } finally {
      this.displayLoadingSpinner = false;
    }
  }

  /**
   * close the releasePreviousNumber modal
   */
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  showServerErrorOnForm( message: string ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerErrorOnForm() {
    this.errMsg = '';
    this.showFormError = false;
  }

  onNewPhoneInputClicked(event) {
    if (!this.loadingNewNumber){
      this.openNewNumberModal();
    }
  }

  /**
   * Use the new phone number modal to get a 'new' phone number from carrier.
   * Process result through addPhoneNumber().
   */
  openNewNumberModal() {
    const modTemp: ModalTemplate = {
      title: 'choose_new_number.title',
      content: NewNumberComponent,
      isContentHTML: true,
      primaryBtn: {
        value: true,
        label: 'choose_new_number.assign',
        width: 95,
        disabled: true
      },
      defaultBtn: {
        value: false,
        label: 'choose_new_number.cancel',
        width: 95
      }
    };

    const options: NgbModalOptions = {
      windowClass: 'lg-modal',
      centered: true
    };

    this.modalRef = this.modalSvc.open(ClModalComponent, options);
    this.modalRef.componentInstance.modalTemp = modTemp;

    this.modalRef.result.then(
      result => {
        if (result === true) {
          // a new phone number was selected
          this.addPhoneNumber(this.phSvc.tnIntegration.currentSelectedPhoneNumber);
          this.displayShoppingCart(this.phSvc.tnIntegration.currentSelectedPhoneNumber.turnupProductId);
          if (this.phSvc.tnIntegration.saveSearchCriteria) {
            this.tnAdapterSvc.updateTNSearchCriteria();
          }
          this.clearServerErrorOnForm();
        }
        // this.toOpen.emit(true);  <<<<<<<<<<<<<< ?
        this.modalRef = null;
      },
      reason => {
        // modal was dismissed
        this.modalRef = null;
      });
  }

  async addPhoneNumber(phoneNumberObj: any) {
    if (phoneNumberObj) {
      this.newPhoneNumber = {
        value: phoneNumberObj.value,
        displayName: phoneNumberObj.displayName,
        locationUuid: phoneNumberObj.locationUuid,
        countryId: phoneNumberObj.countryId,
        carrier: phoneNumberObj.carrier,
        reservation_id: phoneNumberObj.reservation_id,
        turnupProductId: phoneNumberObj.turnupProductId,
        address: phoneNumberObj.address
      };

      // dislay TN in form
      this.changeNumberForm.patchValue({
        newPhoneNumberDigits: this.newPhoneNumber.displayName
      });
      this.enableSubmit = true;

      // Workaround in order to trigger change detection in cl-dropdown-wtih-headings
      // const phoneNumbers = [...this.availablePhoneNumbers];
      // phoneNumbers[0].items.push(availNumber);
      // this.availablePhoneNumbers = phoneNumbers;
      // setTimeout(() => { // Circumvent the timing issue of change detection in cl-dropdown-wtih-headings
      // and calling patchValue on the dropdown
      //   this.newPhoneNumberDigits.nativeElement.value = availNumber.displayName;
      //   this.devicesAndServices.patchValue({phoneNumberDigits: availNumber });
      //   console.log('Phone Number Added');
      // });
    }
  }

  displayShoppingCart(turnupProductId: number) {
    if (!turnupProductId) {return; }

    // put up the shopping cart

    const products: Array<QuotePriceProductDC> = [];
    products.push({
      quantity: 1,
      productId: turnupProductId
      /**
       * Parent product ID
       * To quote profile service addon price, this field must be set to phone bundle product ID
       */
      // dependentOnProductId?: number;
    });

    const quotePriceRequestDC: QuotePriceRequestDC = {
      /**
       * Purchase effective date
       * Optional
       * If omitted, defaults to today
       */
      // effectiveDate?: string;

      /**
       * Products to quote
       * Array<QuotePriceProductDC>
       */
      products: products
    };

    this.subscriptions.push(
        this.productsServices.ProductsQuotePurchasePrice({
          request: quotePriceRequestDC, locationUuid: this.phSvc.getSelectedLocation(), Authorization: null }).subscribe(
        (billingImpactDC: BillingImpactDC) => {
          // console.log('displayShoppingCart  billingImpactDC=', billingImpactDC);
          this.shoppingCartService.placeOrderDetails.next(billingImpactDC);
          this.shoppingCartService.generateOrderInvoice((<BillingImpactDC>billingImpactDC),
              this.translateSvc.instant('cart_invoice_messages.add_order_details'));
        },
        error => {
          console.error('ProductsQuotePurchasePrice() failed1: ', error);
          this.showServerErrorOnForm(BossApiUtils.extractErrorMessage(error));
        }
    ));
  }
}
