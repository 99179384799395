/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Extension } from '../models/extension';
import { CreateExtensionRequest } from '../models/create-extension-request';
import { ExtensionCollection } from '../models/extension-collection';
import { UpdateExtensionRequest } from '../models/update-extension-request';

/**
 * Operations related to extensions
 */
@Injectable({
  providedIn: 'root',
})
class ExtensionsService extends __BaseService {
  static readonly createExtensionPath = '/extensions';
  static readonly getExtensionsPath = '/extensions';
  static readonly getExtensionPath = '/extensions/{extensionNumber}';
  static readonly updateExtensionPath = '/extensions/{extensionNumber}';
  static readonly deleteExtensionPath = '/extensions/{extensionNumber}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates extension. It can be assigned to an entity, i.e to user, app, and group etc. through creation.
   * @param body undefined
   * @return Ok
   */
  createExtensionResponse(body: CreateExtensionRequest): __Observable<__StrictHttpResponse<Extension>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/extensions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Extension>;
      })
    );
  }
  /**
   * Creates extension. It can be assigned to an entity, i.e to user, app, and group etc. through creation.
   * @param body undefined
   * @return Ok
   */
  createExtension(body: CreateExtensionRequest): __Observable<Extension> {
    return this.createExtensionResponse(body).pipe(
      __map(_r => _r.body as Extension)
    );
  }

  /**
   * Gets all extensions from your account.
   *When ?$orderby=extensionNumber desc&$top=1, it return the last extension that has been assigned in your account.
   * @param params The `ExtensionsService.GetExtensionsParams` containing the following parameters:
   *
   * - `$top`: Get number of extensions
   *
   * - `$orderby`: Odata orderby param. Uses the format `$orderby=extensionNumber (asc|desc)`. If neither `asc` nor `desc` is defined, `asc is used.
   *
   * @return Ok
   */
  getExtensionsResponse(params: ExtensionsService.GetExtensionsParams): __Observable<__StrictHttpResponse<ExtensionCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Orderby != null) __params = __params.set('$orderby', params.Orderby.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/extensions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtensionCollection>;
      })
    );
  }
  /**
   * Gets all extensions from your account.
   *When ?$orderby=extensionNumber desc&$top=1, it return the last extension that has been assigned in your account.
   * @param params The `ExtensionsService.GetExtensionsParams` containing the following parameters:
   *
   * - `$top`: Get number of extensions
   *
   * - `$orderby`: Odata orderby param. Uses the format `$orderby=extensionNumber (asc|desc)`. If neither `asc` nor `desc` is defined, `asc is used.
   *
   * @return Ok
   */
  getExtensions(params: ExtensionsService.GetExtensionsParams): __Observable<ExtensionCollection> {
    return this.getExtensionsResponse(params).pipe(
      __map(_r => _r.body as ExtensionCollection)
    );
  }

  /**
   * Get the extension for the provided extension number in your account.
   * @param extensionNumber Extension number Identifier, the value is the extension. It is a dialable number including * and #
   * @return Success. Return the details of the extension
   */
  getExtensionResponse(extensionNumber: string): __Observable<__StrictHttpResponse<Extension>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/extensions/${extensionNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Extension>;
      })
    );
  }
  /**
   * Get the extension for the provided extension number in your account.
   * @param extensionNumber Extension number Identifier, the value is the extension. It is a dialable number including * and #
   * @return Success. Return the details of the extension
   */
  getExtension(extensionNumber: string): __Observable<Extension> {
    return this.getExtensionResponse(extensionNumber).pipe(
      __map(_r => _r.body as Extension)
    );
  }

  /**
   * Update the extension for the provided extension number.
   * @param params The `ExtensionsService.UpdateExtensionParams` containing the following parameters:
   *
   * - `extensionNumber`: Extension number Identifier, the value is the extension. It is a dialable number including * and #
   *
   * - `body`:
   *
   * @return Ok
   */
  updateExtensionResponse(params: ExtensionsService.UpdateExtensionParams): __Observable<__StrictHttpResponse<Extension>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/extensions/${params.extensionNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Extension>;
      })
    );
  }
  /**
   * Update the extension for the provided extension number.
   * @param params The `ExtensionsService.UpdateExtensionParams` containing the following parameters:
   *
   * - `extensionNumber`: Extension number Identifier, the value is the extension. It is a dialable number including * and #
   *
   * - `body`:
   *
   * @return Ok
   */
  updateExtension(params: ExtensionsService.UpdateExtensionParams): __Observable<Extension> {
    return this.updateExtensionResponse(params).pipe(
      __map(_r => _r.body as Extension)
    );
  }

  /**
   * Delete the extension for the provided extension number from your account.
   * @param extensionNumber Extension number Identifier, the value is the extension. It is a dialable number including * and #
   */
  deleteExtensionResponse(extensionNumber: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/extensions/${extensionNumber}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the extension for the provided extension number from your account.
   * @param extensionNumber Extension number Identifier, the value is the extension. It is a dialable number including * and #
   */
  deleteExtension(extensionNumber: string): __Observable<null> {
    return this.deleteExtensionResponse(extensionNumber).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ExtensionsService {

  /**
   * Parameters for getExtensions
   */
  export interface GetExtensionsParams {

    /**
     * Get number of extensions
     */
    Top?: number;

    /**
     * Odata orderby param. Uses the format `$orderby=extensionNumber (asc|desc)`. If neither `asc` nor `desc` is defined, `asc is used.
     */
    Orderby?: string;
  }

  /**
   * Parameters for updateExtension
   */
  export interface UpdateExtensionParams {

    /**
     * Extension number Identifier, the value is the extension. It is a dialable number including * and #
     */
    extensionNumber: string;
    body: UpdateExtensionRequest;
  }
}

export { ExtensionsService }
