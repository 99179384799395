<div class="container" [ngClass]="{'container_non_pop': !popUp}">
    <div class="row top-header" [ngClass]="{'title-row-non-pop': !popUp}">
        <div class="col-12 title_non_popup" *ngIf="!popUp">
            {{ schedule?  schedule.name : ('holiday.new_title'| translate)}}
        </div>
        <div class="col-10 title" *ngIf="popUp">
            {{'holiday.add_title' | translate}}
        </div>
        <div class="col-2 header-close" *ngIf=popUp>
            <button id="on-hours-close" type="button" class="close closeX" aria-label="Close"
                    (click)="cancel()"
                    aria-hidden="true">
                &#10005;
            </button>
        </div>
    </div>
  <div *ngIf="showFormError" class="errorPlacement">
    <cl-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></cl-error-alert>
  </div>
    <div *ngFor="let err of otherErr" class="text-danger error-align"> {{err}} </div>
  <form [formGroup]="holidayForm" (ngSubmit)="onSubmit()" id="holidayPanel">
      <div formGroupName="holidaySection" class="content-scroll-block" [ngClass]="popUp===true ? 'schedule-content': 'schedule-content-non-popup'">
          <div class="row content-1">
              <div class="row">
                  <div class="form-group col-11 name">
                      <label for="name">{{ 'holiday.name' | translate }}</label>
                      <input
                              type="text"
                              formControlName="name"
                              [attr.tabindex]="tabbable ? 0 : -1"
                              maxlength="50"
                              class="form-control schedules-non-popup-width">
                              
                      <div class="text-danger"
                           *ngIf="holidayForm.get('holidaySection.name').touched && holidayForm.get('holidaySection.name').hasError('required')">
                          {{ 'holiday.name_required' | translate }}
                      </div>
                      <div class="text-danger" *ngIf="scheduleNameError != ''"> {{ scheduleNameError }} </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-11 timezone" *ngIf="timeZones.length > 0">
                      <label for="timeZone">{{ 'holiday.time_zone' | translate }}</label>
                      <cl-dropdown [items]="timeZones" formControlName="timeZone" id="holidayTimeZoneDropdown"
                                   [manualWidth]="true"
                                   [manualWidthValue]="'412px'"
                                   [tabbable]="tabbable"
                                   ngDefaultControl (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
                      <div class="text-danger" id="timeZoneTextDanger"
                           *ngIf="holidayForm.get('holidaySection.timeZone').touched && holidayForm.get('holidaySection.timeZone').hasError('required')">
                          {{ 'holiday.time_zone_required' | translate }}
                      </div> 
                  </div>
              </div>
              <div class="row" [hidden]="showHolidaySchedule">
              <div class="no-holiday-text form-group no-holiday-text-non-popup">
                  {{ 'holiday.empty_holiday_message' | translate }}</div>
              </div>
              <div class="row" [hidden]="showHolidaySchedule">
                  <div class="form-group col-2">
                      <button
                      type="button"
                      class="btn btn-default btn-add"
                      [attr.tabindex]="tabbable ? 0 : -1"
                      (click)="addHolidaySchedule(-1)">{{ 'holiday.add' | translate }}</button>
                  </div>
              </div>
          </div>

              <div class="row content-2" [ngClass]="{'content-2-non-popup': !popUp}" [hidden]="!showHolidaySchedule">
                    <div formArrayName="holidaySchedule" *ngFor="let schedule of holidayForm.get('holidaySection').get('holidaySchedule')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                                <div class="form-group col-9 schedule-name">
                                    <input
                                    type="text"
                                    formControlName="scheduleName"
                                    [attr.tabindex]="tabbable ? 0 : -1"
                                    maxlength="50"
                                    class="form-control schedule-input-non-pop">
                                    <div class="text-danger"
                                    *ngIf="holidayForm.get('holidaySection').get('holidaySchedule').controls[i].controls.scheduleName.touched && holidayForm.get('holidaySection').get('holidaySchedule').controls[i].controls.scheduleName.hasError('required')">
                                                {{ 'holiday.schedule_name_required' | translate }}
                                    </div>
                                </div>
                                <div class="form-group col-1 ">
                                    <a href="javascript:" class="hours-add hours-delete"
                                        (click)="removeHolidaySchedule(i)">
                                        <span class="clear-icon" [ngClass]="{'clear-icon-pos': popUp}"></span></a>
                                </div>
                                <div class="form-group col-1">
                                    <a href="javascript:" class="hours-add schedule-add-non-pop"
                                        (click)="addHolidaySchedule(i)"> {{'holiday.add' | translate}}</a>
                                </div>
                                <br>
                            <div class="form-group col-4" [ngClass]="{'schedule-date-field': !popUp}">                                    
                                <cux-datepicker
                                    id="{{i}}scheduleDate"
                                    formControlName="scheduleDate"
                                    [attr.tabindex]="tabbable ? 0 : -1"
                                    (onSelect)="updateScheduleDate($event, i)"
                                    (onBlur)="updateScheduleDate($event, i)"
                                    [appendTo]="'body'">
                                </cux-datepicker>
                            </div>
                            <div class="form-group col-7 repeatYearly" [ngClass]="popUp === true ? 'schedule-repeat-popup':'schedule-repeat-non-popup'">
                                <div class="yearly-repeat">
                                    <div class="checkbox" [ngClass]="{'checkbox-popUp': popUp}">
                                    <input type="checkbox" 
                                    formControlName="yearly"
                                    id="repeatYearly" 
                                    class="form-control"  
                                    [attr.tabindex]="tabbable ? 0 : -1"
                                    (keyup.esc)="$event.preventDefault(); onClose()">
                                    <span class="checkmark"></span>
                                </div>
                                    <label for="repeatYearly" class="form-check-label schedule-label-non-popup"
                                           [ngClass]="{'label-highlight': holidayForm.get('holidaySection').get('holidaySchedule').controls[i].value.yearly}">
                                            {{ 'holiday.yearly_repeat' | translate }}
                                    </label>
                                </div>
                            </div>
              </div>
              </div>
            </div> 
          </div>
      <div class="row" [ngClass]="{'footer-non-popup': !popUp}">
          <div class="form-group col-12 footer-block">
              <div *ngIf="updateSchedule" class="delete-action">
                  <button
                          type="button"
                          class="btn btn-default delete-hg"
                          (click)="onDelete()">
                      <img class="icon-delete-button" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAQABADASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAII/8QAHxAAAgICAgMBAAAAAAAAAAAAAQMCEQQSACEiMlET/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANIZCzkFscl85LW8LENIkeQFX137cY6zj/lHGyJhbMiS5R0iBYBuuuvXl5Rgp74tZBQm5bomZoEDW6P3xPGMVteiKWQbo9jpGBsAHarP3yHA/9k="
                           alt="delete">{{ 'holiday.delete' | translate }}
                  </button>
              </div>

              <div class="pull-right actions">
                  <button
                          type="button"
                          class="btn btn-default"
                          [attr.tabindex]="tabbable ? 0 : -1"
                          (click)="cancel()">{{ 'holiday.cancel' | translate }}</button>
                  <button *ngIf="!updateSchedule"
                          type="submit"
                          class="btn btn-primary" style="width:148px"
                          [attr.tabindex]="tabbable ? 0 : -1">{{ 'holiday.add_schedule' | translate }}</button>
                  <button *ngIf="updateSchedule"
                          type="submit"
                          class="btn btn-primary"
                          [attr.tabindex]="tabbable ? 0 : -1">{{ 'holiday.update' | translate }}</button>
              </div>
          </div>
      </div>
  </form>
</div>


<app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" [customPosition]="{ top: '45%', left: '22%' }" message="{{ 'app.loading' | translate}}"></app-spinner>
