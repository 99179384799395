export const GeographicFeatures = {

  COUNTRY_MAPPING :{
    840: 'US',
    124: 'CANADA',
    826: 'UK'
  },

  FEATURE: {
    ADD_EMERGENCY_ADDRESS: 'ADD_EMERGENCY_ADDRESS',
    EDIT_SUBMITTED_TR: 'EDIT_SUBMITTED_TR',
    CALL_FLOW_ADD_NEW_PHONE_NO: 'CALL_FLOW_ADD_NEW_PHONE_NO',
    CALENDAR_FORMAT: 'CALENDAR_FORMAT'
  },

  /** Enabling/Disabling the Features for US */
  'US': {
    ENABLED: {
      ADD_EMERGENCY_ADDRESS: 'Feature to Add a new emergeny address in users page',
      CALL_FLOW_ADD_NEW_PHONE_NO: 'Adding a new phone numbers field in call flow'
    },
    DISABLED: {
      EDIT_SUBMITTED_TR: 'Feature to Edit the submitted the Tranfer Requests',
    }
  },

  /** Enabling/Disabling the Features for CANADA */
  'CANADA': {
    ENABLED: {
      ADD_EMERGENCY_ADDRESS: 'Feature to Add a new emergeny address in users page',
      CALL_FLOW_ADD_NEW_PHONE_NO: 'Adding a new phone numbers field in call flow'
    },
    DISABLED: {
      EDIT_SUBMITTED_TR: 'Feature to Edit the submitted the Tranfer Requests',
    }
  },


  /** Enabling/Disabling the Features for UK */
  'UK': {
    ENABLED: {
      CALENDAR_FORMAT: 'Calendar display format'
    },
    DISABLED: {
      ADD_EMERGENCY_ADDRESS: 'Feature to Add a new emergeny address in users page',
      CALL_FLOW_ADD_NEW_PHONE_NO: 'Adding a new phone numbers field in call flow'
    }
  },

};
