import { Injectable } from '@angular/core';

import { AppUserClaimsService } from 'src/app/services/app-user-claims.service';
import { GeographicFeatures } from './geographic-feature.const'

@Injectable({providedIn: 'root'})
export class GeographicFeatureService {

  currentCountryCode: number;
  private locationsFeatures = GeographicFeatures;
  constructor(private appService: AppUserClaimsService) {}
  
  
  isEnabled(featureKey: string) {
    const countryKey = this.getCountryKey();
    const isEnabled = !!(this.locationsFeatures[countryKey]['ENABLED'][featureKey]);
    return isEnabled;    
  }

  isDisabled(featureKey: string) {
    const countryKey = this.getCountryKey();
    const isDisabled = !!(this.locationsFeatures[countryKey]['DISABLED'][featureKey]);    
    return isDisabled;    
  }

  private getCountryKey() {
    const currentCountryCode = this.getCurrentUserCountryCode();
    return this.locationsFeatures.COUNTRY_MAPPING[currentCountryCode];
  }

  private getCurrentUserCountryCode() {
    /* istanbul ignore else */
    if (!this.currentCountryCode) {
      this.currentCountryCode = this.appService.getCountryCode();
    }
    return this.currentCountryCode;
  }
}