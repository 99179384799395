import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { SystemService as BossApiSystemService } from '../modules/boss-api/generated/services';
import { BootstrapperService } from '../services/bootstrapper.service';
import { AppUserClaimsService } from '../services/app-user-claims.service';
import { filter } from 'rxjs/operators';

declare var require: any;
const aboutbuild = require('../../../about_build.json');
const packageJSON = require('../../../package.json');

@Component({
  selector: 'app-aboutme',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.css']
})
export class AboutmeComponent implements OnInit {

  aboutme: any = {};

  constructor(
    private bossApi: BossApiSystemService,
    private bootstrapperService: BootstrapperService,
    private appSvc: AppUserClaimsService
  ) {
    this.aboutme = aboutbuild;
  }

  ngOnInit() {
    this.appSvc.claimsChanged.asObservable()
      .pipe(filter(claims => !!claims))
      .subscribe(
        claims => {
          this.aboutme.login = claims['loginId'];
          const d = new Date(this.aboutme.time);
          this.aboutme.UTC = d.toUTCString();
          this.aboutme.url = window.location.href;
          this.aboutme.env = environment.thisFilesName;
          this.aboutme['CCCVersion'] = packageJSON.dependencies['@mitel/cloudlink-console-components'];
          this.aboutme.api = this.bootstrapperService.getBootstrap().bossApiBaseUrl;
          this.aboutme.boss = this.bootstrapperService.getBootstrap().commonName;
          this.aboutme['swaggerVersion'] = packageJSON.config.swaggerFile.replace(/swagger\//g, '').replace(/\.json/g, '');
          this.bossApi.SystemGetVersion().toPromise().then(
            version => {
              this.aboutme.SDApiVersion = version.replace(/\"/g, '');
            },
            error => {
              console.error('bossApi.SystemGetVersion(): ', error);
            }
          );
        }
      );
  }

}
