/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CallHistoryCollection } from '../models/call-history-collection';

/**
 * Operations related to call history
 */
@Injectable({
  providedIn: 'root',
})
class CallHistoryService extends __BaseService {
  static readonly getUserCallHistoryPath = '/call-history/call-records';
  static readonly deleteAllUserCallHistoryRecordsPath = '/call-history/call-records';
  static readonly deleteUserCallHistoryRecordPath = '/call-history/call-record/{callId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves a User's most recent calls.
   * @param userId User Identifier
   * @return Success. Returns User call history.
   */
  getUserCallHistoryResponse(userId?: string): __Observable<__StrictHttpResponse<CallHistoryCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/call-history/call-records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CallHistoryCollection>;
      })
    );
  }
  /**
   * Retrieves a User's most recent calls.
   * @param userId User Identifier
   * @return Success. Returns User call history.
   */
  getUserCallHistory(userId?: string): __Observable<CallHistoryCollection> {
    return this.getUserCallHistoryResponse(userId).pipe(
      __map(_r => _r.body as CallHistoryCollection)
    );
  }

  /**
   * Delete all User's call history records.
   */
  deleteAllUserCallHistoryRecordsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/call-history/call-records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete all User's call history records.
   */
  deleteAllUserCallHistoryRecords(): __Observable<null> {
    return this.deleteAllUserCallHistoryRecordsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete a User's call history record.
   * @param callId Call Identifier
   */
  deleteUserCallHistoryRecordResponse(callId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/call-history/call-record/${callId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a User's call history record.
   * @param callId Call Identifier
   */
  deleteUserCallHistoryRecord(callId: string): __Observable<null> {
    return this.deleteUserCallHistoryRecordResponse(callId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CallHistoryService {
}

export { CallHistoryService }
