/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProductUserGroupsDC } from '../models/product-user-groups-dc';
import { PhoneSettingDC } from '../models/phone-setting-dc';
import { UserGroupDC } from '../models/user-group-dc';
import { ResetPinDC } from '../models/reset-pin-dc';
@Injectable({
  providedIn: 'root',
})
class PhoneSettingsService extends __BaseService {
  static readonly PhoneSettingsGetUserGroupsByProductPath = '/api/Account/Products/{productId}/UserGroups';
  static readonly PhoneSettingsGetPhoneSettingsPath = '/api/Account/Profile/GetPhoneSettings';
  static readonly PhoneSettingsGetUserGroupsPath = '/api/Account/Profile/GetUserGroups';
  static readonly PhoneSettingsResetPinPath = '/api/Account/Profile/PhoneSettings/ResetPin';
  static readonly PhoneSettingsUpdatePhoneSettingsPath = '/api/Account/Profile/UpdatePhoneSettings';
  static readonly PhoneSettingsUpdateUserGroupsPath = '/api/Account/Profile/UpdateUserGroups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: EditGroups (123)
   * @param params The `PhoneSettingsService.PhoneSettingsGetUserGroupsByProductParams` containing the following parameters:
   *
   * - `productId`: BOSS Product ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsGetUserGroupsByProductResponse(params: PhoneSettingsService.PhoneSettingsGetUserGroupsByProductParams): __Observable<__StrictHttpResponse<ProductUserGroupsDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Products/${params.productId}/UserGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductUserGroupsDC>;
      })
    );
  }
  /**
   * Required Permission: EditGroups (123)
   * @param params The `PhoneSettingsService.PhoneSettingsGetUserGroupsByProductParams` containing the following parameters:
   *
   * - `productId`: BOSS Product ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsGetUserGroupsByProduct(params: PhoneSettingsService.PhoneSettingsGetUserGroupsByProductParams): __Observable<ProductUserGroupsDC> {
    return this.PhoneSettingsGetUserGroupsByProductResponse(params).pipe(
      __map(_r => _r.body as ProductUserGroupsDC)
    );
  }

  /**
   * Required Permission: EditPBX (190)
   * @param params The `PhoneSettingsService.PhoneSettingsGetPhoneSettingsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsGetPhoneSettingsResponse(params: PhoneSettingsService.PhoneSettingsGetPhoneSettingsParams): __Observable<__StrictHttpResponse<PhoneSettingDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Profile/GetPhoneSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PhoneSettingDC>;
      })
    );
  }
  /**
   * Required Permission: EditPBX (190)
   * @param params The `PhoneSettingsService.PhoneSettingsGetPhoneSettingsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsGetPhoneSettings(params: PhoneSettingsService.PhoneSettingsGetPhoneSettingsParams): __Observable<PhoneSettingDC> {
    return this.PhoneSettingsGetPhoneSettingsResponse(params).pipe(
      __map(_r => _r.body as PhoneSettingDC)
    );
  }

  /**
   * Required Permission: EditGroups (123)
   * @param params The `PhoneSettingsService.PhoneSettingsGetUserGroupsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsGetUserGroupsResponse(params: PhoneSettingsService.PhoneSettingsGetUserGroupsParams): __Observable<__StrictHttpResponse<UserGroupDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Profile/GetUserGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserGroupDC>;
      })
    );
  }
  /**
   * Required Permission: EditGroups (123)
   * @param params The `PhoneSettingsService.PhoneSettingsGetUserGroupsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsGetUserGroups(params: PhoneSettingsService.PhoneSettingsGetUserGroupsParams): __Observable<UserGroupDC> {
    return this.PhoneSettingsGetUserGroupsResponse(params).pipe(
      __map(_r => _r.body as UserGroupDC)
    );
  }

  /**
   * Required Permission: EditProfiles (117)
   * @param params The `PhoneSettingsService.PhoneSettingsResetPinParams` containing the following parameters:
   *
   * - `useDefaultPin`:
   *
   * - `reset`:
   *
   * - `profileId`:
   *
   * - `notifyUser`:
   *
   * - `Authorization`: access token
   *
   * - `newPin`:
   *
   * - `emailList`:
   *
   * @return OK
   */
  PhoneSettingsResetPinResponse(params: PhoneSettingsService.PhoneSettingsResetPinParams): __Observable<__StrictHttpResponse<ResetPinDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useDefaultPin != null) __params = __params.set('useDefaultPin', params.useDefaultPin.toString());
    if (params.reset != null) __params = __params.set('reset', params.reset.toString());
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.notifyUser != null) __params = __params.set('notifyUser', params.notifyUser.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.newPin != null) __params = __params.set('newPin', params.newPin.toString());
    if (params.emailList != null) __params = __params.set('emailList', params.emailList.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Profile/PhoneSettings/ResetPin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResetPinDC>;
      })
    );
  }
  /**
   * Required Permission: EditProfiles (117)
   * @param params The `PhoneSettingsService.PhoneSettingsResetPinParams` containing the following parameters:
   *
   * - `useDefaultPin`:
   *
   * - `reset`:
   *
   * - `profileId`:
   *
   * - `notifyUser`:
   *
   * - `Authorization`: access token
   *
   * - `newPin`:
   *
   * - `emailList`:
   *
   * @return OK
   */
  PhoneSettingsResetPin(params: PhoneSettingsService.PhoneSettingsResetPinParams): __Observable<ResetPinDC> {
    return this.PhoneSettingsResetPinResponse(params).pipe(
      __map(_r => _r.body as ResetPinDC)
    );
  }

  /**
   * Required Permission: EditPBX (190)
   * @param params The `PhoneSettingsService.PhoneSettingsUpdatePhoneSettingsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `phoneSettingDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsUpdatePhoneSettingsResponse(params: PhoneSettingsService.PhoneSettingsUpdatePhoneSettingsParams): __Observable<__StrictHttpResponse<PhoneSettingDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    __body = params.phoneSettingDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Profile/UpdatePhoneSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PhoneSettingDC>;
      })
    );
  }
  /**
   * Required Permission: EditPBX (190)
   * @param params The `PhoneSettingsService.PhoneSettingsUpdatePhoneSettingsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `phoneSettingDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsUpdatePhoneSettings(params: PhoneSettingsService.PhoneSettingsUpdatePhoneSettingsParams): __Observable<PhoneSettingDC> {
    return this.PhoneSettingsUpdatePhoneSettingsResponse(params).pipe(
      __map(_r => _r.body as PhoneSettingDC)
    );
  }

  /**
   * Required Permission: EditGroups (123)
   * @param params The `PhoneSettingsService.PhoneSettingsUpdateUserGroupsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `UserGroupId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsUpdateUserGroupsResponse(params: PhoneSettingsService.PhoneSettingsUpdateUserGroupsParams): __Observable<__StrictHttpResponse<UserGroupDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.UserGroupId != null) __params = __params.set('UserGroupId', params.UserGroupId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Profile/UpdateUserGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserGroupDC>;
      })
    );
  }
  /**
   * Required Permission: EditGroups (123)
   * @param params The `PhoneSettingsService.PhoneSettingsUpdateUserGroupsParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `UserGroupId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  PhoneSettingsUpdateUserGroups(params: PhoneSettingsService.PhoneSettingsUpdateUserGroupsParams): __Observable<UserGroupDC> {
    return this.PhoneSettingsUpdateUserGroupsResponse(params).pipe(
      __map(_r => _r.body as UserGroupDC)
    );
  }
}

module PhoneSettingsService {

  /**
   * Parameters for PhoneSettingsGetUserGroupsByProduct
   */
  export interface PhoneSettingsGetUserGroupsByProductParams {

    /**
     * BOSS Product ID
     */
    productId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PhoneSettingsGetPhoneSettings
   */
  export interface PhoneSettingsGetPhoneSettingsParams {
    profileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PhoneSettingsGetUserGroups
   */
  export interface PhoneSettingsGetUserGroupsParams {
    profileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PhoneSettingsResetPin
   */
  export interface PhoneSettingsResetPinParams {
    useDefaultPin: boolean;
    reset: boolean;
    profileId: number;
    notifyUser: boolean;

    /**
     * access token
     */
    Authorization: string;
    newPin?: string;
    emailList?: string;
  }

  /**
   * Parameters for PhoneSettingsUpdatePhoneSettings
   */
  export interface PhoneSettingsUpdatePhoneSettingsParams {
    profileId: number;
    phoneSettingDC: PhoneSettingDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PhoneSettingsUpdateUserGroups
   */
  export interface PhoneSettingsUpdateUserGroupsParams {
    profileId: number;
    UserGroupId: string;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { PhoneSettingsService }
