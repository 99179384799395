import { messageTypes, numberTypes } from './constants';
import { PhoneServicesService } from '../../../services/phone-services.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TnAdapterService } from '../../../services/tn-adapter.service';
import { AvailableNumbersModel, NumberRange } from './models';
import { processNumbers, highlightNumber } from './new-number-utils';
import { LocationDC } from 'src/app/modules/boss-api/generated/models';
import { Location, LocationAdaptorService } from '../../../services/location-adaptor.service';
import { AppUserClaimsService } from 'src/app/services/app-user-claims.service';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { Subscription } from 'rxjs';
import { NewNumberService } from 'src/app/services/newNumber.service';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';

import * as _ from 'lodash';
import { FilterCriteria } from './filter-numbers/filter-numbers.component';

interface TabState {
  filterByCity: boolean;
}

@Component({
  selector: 'new-number',
  templateUrl: './new-number.component.html',
  styleUrls: ['./new-number.component.scss']
})
export class NewNumberComponent implements OnInit, OnDestroy {
  @Input() numberType?: string;
  @Input() locations?: Location[];
  @Input() currentUserLocation?: LocationDC;

  public availableNumbers: AvailableNumbersModel[] = [];
  public searchItem: [] = [];
  public accountId: string;
  public searchedTerm: string;
  public loading = false;
  public showMessage = false;
  public messageStyle: string;
  public messageType = '';
  public messageTypes = messageTypes;
  public numberTypes = numberTypes;
  public isLocalNumber = false;
  public showContinuousNumberRangeComponent = false;
  public showNumbersGridComponent = false;
  public continuousRange = false;
  public numberRange: NumberRange = {
    range: '',
    location: ''
  };
  public filtered: {
    filteredByCity: boolean,
    title: string,
    content: string,
    subtext: string
  };

  // used for cl-dropdown for SBOSS-5243. This could change depending on whether it is the same locations that are shown
  // in the dropdown or not
  public costCenterLocations: Location[];
  public tollFreeAreaCodes: any[] = [];
  public company: Account = null;
  private subscriptions: Subscription[] = [];
  public errMsg = '';
  public showServerError  = false;
  public filterByCity: boolean = this.phSvc.tnIntegration.filterByCity;
  private quantity = 10;
  public availableNumsBelowTen = false;
  public loadingText = 'app.loading';
  public searchCriteriaRateCenter = '';
  public searchCriteriaDigits = '';
  public containingNumber = '';
  public areaCodeDigits = '';
  public prefixDigits = '';
  public nextDigits = '';
  public pageReloaded = false;

  constructor(public translateSvc: TranslateService,
    public phSvc: PhoneServicesService,
    public tnAdapterSvc: TnAdapterService,
    private locAdapterSvc: LocationAdaptorService,
    private appSvc: AppUserClaimsService,
    private newNumberSvc: NewNumberService
  ) { }

  ngOnInit() {
    this.newNumberSvc.pageReloaded.subscribe(val => {
      this.pageReloaded = val;
      this.availableNumbers = [];
    });

    this.currentUserLocation = (this.currentUserLocation) ? this.currentUserLocation : this.phSvc.tnIntegration.currentUserLocation;
    if (!this.numberType || this.numberType === numberTypes.default) {
      this.numberType = !this.numberType && numberTypes.default;
      this.subscriptions.push(this.tnAdapterSvc.getTnSearchCriteria(this.currentUserLocation).subscribe((searchCriteria) => {
        if (searchCriteria.rc) {
          this.phSvc.tnIntegration.filterByCity = true;
          this.filterByCity = true;
          this.searchCriteriaRateCenter = (searchCriteria.prefix) ? `${searchCriteria.cityName},
           ${searchCriteria.stateCode} (${searchCriteria.prefix})` : (searchCriteria.cityName === null) ?
            `${searchCriteria.rc}, ${searchCriteria.stateCode}` : `${searchCriteria.cityName}, ${searchCriteria.stateCode}`;
          this.searchedTerm = ` for "${this.searchCriteriaRateCenter}"`;
        } else if (!searchCriteria.rc && searchCriteria.prefix) {
          this.phSvc.tnIntegration.filterByCity = false;
          this.filterByCity = false;
          this.searchCriteriaDigits = searchCriteria.prefix;

          this.areaCodeDigits = this.searchCriteriaDigits.slice(0, 3);
          this.prefixDigits = this.searchCriteriaDigits.slice(3, 6);
          this.nextDigits = this.searchCriteriaDigits.slice(6, 7);
          const msgStarting = this.translateSvc.instant('choose_new_number.available_num_starting');
          this.searchedTerm = `${msgStarting} "(${this.areaCodeDigits}) ${this.prefixDigits}-${this.nextDigits}"`;
        }
      }));
      this.subscriptions.push(this.phSvc.getLocationDC(this.phSvc.getSelectedLocation()).subscribe((location: LocationDC) => {
        this.getNumbersBySearchCriteria(location, 10);
      },
        (error) => {
          this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
            this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
            this.loading = false;
          }));
        }));
    } else if (this.numberType === numberTypes.localNumber) {
      this.loadingText = 'choose_new_number.finding_local_numbers';
      this.isLocalNumber = true;
    } else if (this.numberType === numberTypes.tollFree) {
      this.loadingText = 'choose_new_number.finding_toll_free_numbers';
      this.loading= true;
      this.subscriptions.push(this.appSvc.companyChanged.subscribe(company => {
        this.company = company;
        this.getLocationsForAccount(this.company.accountId);
      }));
      this.company = this.appSvc.getCompany();
      this.getLocationsForAccount(this.company.accountId);
    }
  }

  private processTNs(availableNumbers: AvailableNumbersModel[], quantity?: number) {
    let processedTN = processNumbers(availableNumbers, this.continuousRange, quantity, this.numberType,
       this.translateSvc, this.currentUserLocation);
    this.showMessage = processedTN.showMessage;
    this.messageType = processedTN.messageType;
    this.messageStyle = processedTN.messageStyle;
    this.availableNumbers = processedTN.availableNumbers || [];
    this.numberRange = processedTN.numberRange;
    this.loading = false;
    this.showNumbersGridComponent = (this.continuousRange) ? false : true;
    this.showContinuousNumberRangeComponent = (this.continuousRange) ? true : false;
    this.continuousRange && this.newNumberSvc.selectNewNumber.next({selectedRange: this.availableNumbers});
  }

  public onTabClicked(tabState: TabState) {
    this.phSvc.tnIntegration.filterByCity = tabState.filterByCity;
    this.phSvc.tnIntegration.tabChanged.next(tabState.filterByCity);
    this.newNumberSvc.selectNewNumber.next({deselectAll: true});
  }

  public newPhoneNumberSelected(phoneNumberObj) {
    if (!this.numberType || this.numberType === numberTypes.default) {
      this.phSvc.tnIntegration.currentSelectedPhoneNumber = phoneNumberObj;
    }
  }

  private getNumbersBySearchCriteria(currentUserLocation: LocationDC, quantity: number) {
    this.loading = true;
    this.subscriptions.push(this.tnAdapterSvc.getAvailableNumbersFromSearchCriteria(currentUserLocation, quantity)
    .subscribe(
      (availableNumbers) => {
        this.clearServerErrorOnForm();
        this.processTNs(availableNumbers, quantity);
        this.newNumberSvc.selectNewNumber.next({deselectAll: true});
      },
      (error)=>{
        this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
          this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
          this.loading = false;
        }));
      }
    ));
  }

  public searchByRateCenter(rcSearchCriteria: FilterCriteria) {
    this.loading = true;
    this.filterByCity = true;
    this.availableNumbers = [];
    this.searchedTerm = rcSearchCriteria.searchedTerm;
    this.continuousRange = rcSearchCriteria.continuousRange;
    let quantity = rcSearchCriteria.quantity || 10;
    this.quantity = quantity;
    this.subscriptions.push(this.tnAdapterSvc
      .getAvailableNumbersFromRC(rcSearchCriteria.rateCenter, quantity, rcSearchCriteria.filterByAreaCode,
         this.phSvc.tnIntegration.currentUserLocation, this.continuousRange)
    .subscribe(
      (availableNumbers) => {
        this.clearServerErrorOnForm();
        this.processTNs(availableNumbers, quantity);
        this.newNumberSvc.selectNewNumber.next({deselectAll: true});
      },
      (error)=>{
        this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
          this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
          this.loading = false;
        }));
      }
    ));
  }

  public searchByNumber(numberSearchCriteria?: FilterCriteria) {
    this.loading = true;
    this.filterByCity = false;
    this.availableNumbers = [];
    this.searchedTerm = numberSearchCriteria.searchedTerm;
    this.continuousRange = numberSearchCriteria.continuousRange;
    let quantity = numberSearchCriteria.quantity || 10;
    this.quantity = quantity;
    let partialNumber = `${numberSearchCriteria.areaCode}${numberSearchCriteria.prefix}${numberSearchCriteria.nextDigit}`;
    this.subscriptions.push(this.tnAdapterSvc.searchAvailableNumberswithPartials(partialNumber, quantity,
       this.phSvc.tnIntegration.currentUserLocation, this.continuousRange)
    .subscribe(
      (availableNumbers) => {
        this.clearServerErrorOnForm();
        this.processTNs(availableNumbers, quantity);
        this.newNumberSvc.selectNewNumber.next({deselectAll: true});
    },
      (error)=>{
        this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
          this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
          this.loading = false;
      }));
    }));
  }

  public onFetchMoreNumbers() {
    this.loading = true;
    this.subscriptions.push(this.tnAdapterSvc.getMoreNumbers(this.quantity).subscribe(
      (availableNumbers) => {
        this.clearServerErrorOnForm();
        if(this.containingNumber){
          this.processTNs(highlightNumber(this.containingNumber, availableNumbers));
        } else {
          this.processTNs(availableNumbers);
        }
      },
      (error) => {
        this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
          this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
          this.loading = false;
        }));
      }
    ));
  }

  public onClickSaveSearch($event) {
    this.phSvc.tnIntegration.saveSearchCriteria = $event.target.checked;
  }

  private getLocationsForAccount(accountId: string) {
    this.subscriptions.push(this.locAdapterSvc.getLocations(accountId).subscribe((locations: Location[]) => {
      this.locations = locations;
      this.subscriptions.push(this.phSvc.getLocationDC(this.locations[0].value).subscribe(
        (loc) => {
          this.clearServerErrorOnForm();
          this.currentUserLocation = loc;
          this.getTollFreeAreaCodes(loc.countryId);
        },
        (error) => {
          this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
            this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
            this.loading = false;
          }));
        }));
      }
    ));
  }

  public getTollFreeAreaCodes(countryId: number) {
    this.subscriptions.push(this.tnAdapterSvc.getTollFreeAreaCodes(countryId).subscribe(areaCodes => {
      this.clearServerErrorOnForm();

      let dropDownItemObjs = areaCodes.map(code => {
        return {
          value: code,
          displayName: code
        }
      });

      this.tollFreeAreaCodes.push(...dropDownItemObjs);
      let defaultVal = this.randomTollFreeAreaCode(this.tollFreeAreaCodes);
      this.newNumberSvc.defaultAreaCode.next(defaultVal);
    },
    (error)=>{
      this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
        this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
        this.loading = false;
    }));
  }));
  }

  randomTollFreeAreaCode(areaCodes: Array<{value: string, displayName: string}>):{value: string, displayName: string}{
    // returns a random area code from the array, but not 800
    let filtered = areaCodes.filter((num => num.value !== '800'));
    let randomIndex = Math.floor((Math.random() * filtered.length));
    return filtered[randomIndex];
  }


  // With VANITY FIELD
  public onSearchTollFree(criteria: FilterCriteria) {
    this.loading = true;
    this.searchedTerm = criteria.areaCode === 'any' ? 'choose_new_number.available_toll_free' : criteria.searchedTerm;
    this.continuousRange = criteria.continuousRange;
    let quantity = criteria.quantity || 10;
    this.quantity = quantity;
    // let partialNumber = `${criteria.areaCode}${criteria.prefix}${criteria.nextDigit}`;
    let partialNumber = criteria.areaCode;
    let vanityNumber = criteria.prefix;

    this.containingNumber = vanityNumber;

    this.subscriptions.push(this.tnAdapterSvc.searchAvailableNumberswithVanity(vanityNumber,
       partialNumber, quantity, this.currentUserLocation, this.continuousRange)
    .subscribe((availableNumbers) => {
      this.clearServerErrorOnForm();
      // this.processTNs(this.highlightNumber(criteria.prefix, availableNumbers));
      this.processTNs(highlightNumber(vanityNumber, availableNumbers));
      this.loading= false;
      this.newNumberSvc.tollFreeSearchCompleted.next(availableNumbers);
      if (!availableNumbers || availableNumbers.length < 10) {
        this.availableNumsBelowTen = true;
      } else {
        this.availableNumsBelowTen = false;
      }
    },
    (error)=>{
      this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
        this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
        this.loading = false;
    }));
  }));

  }

  //NO VANITY FIELD
  // public onSearchTollFree(criteria: FilterCriteria) {
  //   this.loading = true;
  //   this.searchedTerm = criteria.areaCode === 'any' ? 'choose_new_number.available_toll_free' : criteria.searchedTerm;
  //   this.continuousRange = criteria.continuousRange;
  //   let quantity = criteria.quantity || 10;
  //   this.quantity = quantity;
  //   let partialNumber = `${criteria.areaCode}${criteria.prefix}${criteria.nextDigit}`;
  //   this.subscriptions.push(this.tnAdapterSvc.searchAvailableNumberswithPartials(partialNumber, quantity,
  // this.currentUserLocation, this.continuousRange)
  //   .subscribe((availableNumbers) => {
  //     this.clearServerErrorOnForm();
  //     this.processTNs(this.highlightNumber(criteria.prefix, availableNumbers));
  //     this.loading= false;
  //     this.newNumberSvc.tollFreeSearchCompleted.next(availableNumbers);
  //   },
  //   (error)=>{
  //     this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
  //       this.showServerErrorOnForm( err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
  //       this.loading = false;
  //   }));
  // }));
  // }


  public clearMessage() {
    this.showMessage = false;
    this.showNumbersGridComponent = false;
    this.showContinuousNumberRangeComponent = false;
  }

  ngOnDestroy(): void {
    this.phSvc.tnIntegration.filterByCity = true;
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  showServerErrorOnForm( message: string ) {
    this.errMsg = message;
    this.showMessage = true;
    this.showServerError = true;
    this.showNumbersGridComponent = false;
    this.showContinuousNumberRangeComponent = false;
    this.messageStyle = 'error';
  }

  clearServerErrorOnForm() {
    this.errMsg = '';
    this.showServerError = false;
    this.showMessage = false;
    this.showNumbersGridComponent = true;
    this.showContinuousNumberRangeComponent = true;
  }
}