<div id="container">
  <cl-header
    appLabelName="Flows"
    [cloud]="cloudType"
    [enableCompanySwitcher]="false"
    (userClaimsUpdated)="handleUserClaims($event)"
    (goHomePage)="goHomePage()"
    (authConfirmedInvokingUrl)="handleAuthConfirmedInvokingUrl($event)"
    [enableCurrentAccountDisplay]="true">
  </cl-header>
  <app-notification></app-notification>
  <div id="content">
    <!-- <cl-side-nav [claims]="claims" [menuItems]='menuItems' (itemClicked)="onMenuItemClicked($event)"></cl-side-nav> -->
    <router-outlet></router-outlet>
  </div>
</div>

