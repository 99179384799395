/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CallCollection } from '../models/call-collection';
import { Call } from '../models/call';
import { CreateCallRequest } from '../models/create-call-request';
import { PostCallControlCommandResponse } from '../models/post-call-control-command-response';
import { PostCallControlCommand } from '../models/post-call-control-command';
import { PostCallMediaResponse } from '../models/post-call-media-response';
import { PostCallMediaRequest } from '../models/post-call-media-request';

/**
 * Operations related to call resource
 */
@Injectable({
  providedIn: 'root',
})
class CallsService extends __BaseService {
  static readonly getCallsPath = '/calls';
  static readonly createCallPath = '/calls';
  static readonly getCallPath = '/calls/{callId}';
  static readonly runCallControlCommandPath = '/calls/{callId}';
  static readonly terminateCallPath = '/calls/{callId}';
  static readonly startMediaPath = '/calls/{callId}/media';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieve all live calls for this account on both premise and cloud. Basic paging and filtering support is available. See above. Historical queries coming soon.
   * @param params The `CallsService.GetCallsParams` containing the following parameters:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$filter`: Odata filter param
   *
   * @return Ok
   */
  getCallsResponse(params: CallsService.GetCallsParams): __Observable<__StrictHttpResponse<CallCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CallCollection>;
      })
    );
  }
  /**
   * Retrieve all live calls for this account on both premise and cloud. Basic paging and filtering support is available. See above. Historical queries coming soon.
   * @param params The `CallsService.GetCallsParams` containing the following parameters:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$filter`: Odata filter param
   *
   * @return Ok
   */
  getCalls(params: CallsService.GetCallsParams): __Observable<CallCollection> {
    return this.getCallsResponse(params).pipe(
      __map(_r => _r.body as CallCollection)
    );
  }

  /**
   * Make an outbound call from your account registered endpoint to a destination.
   *Depending on the type of token being used the behaviour may be different. User tokens are based on your endpoints registered to you and make call will result in a call being made from or to one of your specified devices. You must have the from devices registered to you in order to make this call.
   *Application tokens on the other hand will make a call on behalf of the media controllable gateway in the cloud. You use this method to build IVR / Voice mail applications when machine to human interactions are desired.
   *
   *make call user token. from is implied by sipaddress in user token
   *`{ "to": "+16134444444" }`
   *make call with recording
   *`{ "to": "+16134444444", "record": true }`
   *make call from personal desktop phone
   *`{ "from": "1000", "to": "+16134444444" }`
   *Async make call with callback as application token Returns accepted on response
   *`{ "from": "+16135990045", "fromName": "Sales Department", "to": "+16134444444", "successUrl": "http://server/success", "failureUrl": "http://server/failure" }`
   * @param body undefined
   * @return Created
   */
  createCallResponse(body: CreateCallRequest): __Observable<__StrictHttpResponse<Call>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Call>;
      })
    );
  }
  /**
   * Make an outbound call from your account registered endpoint to a destination.
   *Depending on the type of token being used the behaviour may be different. User tokens are based on your endpoints registered to you and make call will result in a call being made from or to one of your specified devices. You must have the from devices registered to you in order to make this call.
   *Application tokens on the other hand will make a call on behalf of the media controllable gateway in the cloud. You use this method to build IVR / Voice mail applications when machine to human interactions are desired.
   *
   *make call user token. from is implied by sipaddress in user token
   *`{ "to": "+16134444444" }`
   *make call with recording
   *`{ "to": "+16134444444", "record": true }`
   *make call from personal desktop phone
   *`{ "from": "1000", "to": "+16134444444" }`
   *Async make call with callback as application token Returns accepted on response
   *`{ "from": "+16135990045", "fromName": "Sales Department", "to": "+16134444444", "successUrl": "http://server/success", "failureUrl": "http://server/failure" }`
   * @param body undefined
   * @return Created
   */
  createCall(body: CreateCallRequest): __Observable<Call> {
    return this.createCallResponse(body).pipe(
      __map(_r => _r.body as Call)
    );
  }

  /**
   * Gets the current state of an active call.
   * @param callId callId
   * @return Ok
   */
  getCallResponse(callId: string): __Observable<__StrictHttpResponse<Call>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/calls/${callId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Call>;
      })
    );
  }
  /**
   * Gets the current state of an active call.
   * @param callId callId
   * @return Ok
   */
  getCall(callId: string): __Observable<Call> {
    return this.getCallResponse(callId).pipe(
      __map(_r => _r.body as Call)
    );
  }

  /**
   * Manipulate current call state by issuing an appropriate action to take on the call for the provided ID. Some actions require additional arguments.
   *Examples
   *To answer or clear a call
   *`{ "action": "answer"}`
   *`{ "action": "end"}`
   *To toggle the holding state of the call
   *`{ "action": "hold", "options": { "destination": "1000" } }`
   *
   *`{ "action": "retrieve" "options": { "destination": "1000" } }`
   *To transfer a call
   *`{ "action": "transfer", "options": { "destination": "+15555555" } }`
   *`{ "action": "transfer", "options": { "destination": "+15555555", "leg": "b" } }`
   *`{ "action": "transfer", "options": { "ringAlgorithm": "ringAll", "destination": "+15555555" } }`
   *To send dtmf digits. Tone duration is optional and specified in ms. Default is 2000
   *`{ "action": "dtmf", "options": { "tones": "1234", "duration": 2000 } }`
   *To start or stop recording
   *`{ "action": "record", "options": { "recording": true } }`
   * @param params The `CallsService.RunCallControlCommandParams` containing the following parameters:
   *
   * - `callId`: callId
   *
   * - `body`:
   *
   * @return Ok
   */
  runCallControlCommandResponse(params: CallsService.RunCallControlCommandParams): __Observable<__StrictHttpResponse<PostCallControlCommandResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/calls/${params.callId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostCallControlCommandResponse>;
      })
    );
  }
  /**
   * Manipulate current call state by issuing an appropriate action to take on the call for the provided ID. Some actions require additional arguments.
   *Examples
   *To answer or clear a call
   *`{ "action": "answer"}`
   *`{ "action": "end"}`
   *To toggle the holding state of the call
   *`{ "action": "hold", "options": { "destination": "1000" } }`
   *
   *`{ "action": "retrieve" "options": { "destination": "1000" } }`
   *To transfer a call
   *`{ "action": "transfer", "options": { "destination": "+15555555" } }`
   *`{ "action": "transfer", "options": { "destination": "+15555555", "leg": "b" } }`
   *`{ "action": "transfer", "options": { "ringAlgorithm": "ringAll", "destination": "+15555555" } }`
   *To send dtmf digits. Tone duration is optional and specified in ms. Default is 2000
   *`{ "action": "dtmf", "options": { "tones": "1234", "duration": 2000 } }`
   *To start or stop recording
   *`{ "action": "record", "options": { "recording": true } }`
   * @param params The `CallsService.RunCallControlCommandParams` containing the following parameters:
   *
   * - `callId`: callId
   *
   * - `body`:
   *
   * @return Ok
   */
  runCallControlCommand(params: CallsService.RunCallControlCommandParams): __Observable<PostCallControlCommandResponse> {
    return this.runCallControlCommandResponse(params).pipe(
      __map(_r => _r.body as PostCallControlCommandResponse)
    );
  }

  /**
   * Terminates an active call for the provided ID.
   * @param callId callId
   */
  terminateCallResponse(callId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/calls/${callId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Terminates an active call for the provided ID.
   * @param callId callId
   */
  terminateCall(callId: string): __Observable<null> {
    return this.terminateCallResponse(callId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Starts a new media stream to an active call. NOTE you can only interface with a call that the cloud call control is in the media path. For calls existing purely on premise PBX systems you must transfer these calls to a media controllable endpoint.
   *Two speech engines are used for tts. Microsoft Bing and Amazon Polly
   *Examples
   *Start media
   *`{ "play": [ { "resourceUri": "welcome.en" }], "minCollectedDigits": 1 }`
   *Start audio with digit collection and repeat logic
   *`{ "play": [ { "resourceUri": "welcome.en" }], "minCollectedDigits": 4, "maxCollectedDigits": 4, "tries": 2 }`
   *Start audio with a chain set of instructions including TTS
   *`{ "play": [ { "resourceUri": "welcome.en" }, { "ttsPhrase": "This is a message", "ttsVoice": "en-ca:female" }], "minCollectedDigits": 4, "maxCollectedDigits": 4, "tries": 2 }`
   * @param params The `CallsService.StartMediaParams` containing the following parameters:
   *
   * - `callId`: callId
   *
   * - `body`:
   *
   * @return Call media response
   */
  startMediaResponse(params: CallsService.StartMediaParams): __Observable<__StrictHttpResponse<PostCallMediaResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/calls/${params.callId}/media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostCallMediaResponse>;
      })
    );
  }
  /**
   * Starts a new media stream to an active call. NOTE you can only interface with a call that the cloud call control is in the media path. For calls existing purely on premise PBX systems you must transfer these calls to a media controllable endpoint.
   *Two speech engines are used for tts. Microsoft Bing and Amazon Polly
   *Examples
   *Start media
   *`{ "play": [ { "resourceUri": "welcome.en" }], "minCollectedDigits": 1 }`
   *Start audio with digit collection and repeat logic
   *`{ "play": [ { "resourceUri": "welcome.en" }], "minCollectedDigits": 4, "maxCollectedDigits": 4, "tries": 2 }`
   *Start audio with a chain set of instructions including TTS
   *`{ "play": [ { "resourceUri": "welcome.en" }, { "ttsPhrase": "This is a message", "ttsVoice": "en-ca:female" }], "minCollectedDigits": 4, "maxCollectedDigits": 4, "tries": 2 }`
   * @param params The `CallsService.StartMediaParams` containing the following parameters:
   *
   * - `callId`: callId
   *
   * - `body`:
   *
   * @return Call media response
   */
  startMedia(params: CallsService.StartMediaParams): __Observable<PostCallMediaResponse> {
    return this.startMediaResponse(params).pipe(
      __map(_r => _r.body as PostCallMediaResponse)
    );
  }
}

module CallsService {

  /**
   * Parameters for getCalls
   */
  export interface GetCallsParams {

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Odata filter param
     */
    Filter?: string;
  }

  /**
   * Parameters for runCallControlCommand
   */
  export interface RunCallControlCommandParams {

    /**
     * callId
     */
    callId: string;
    body: PostCallControlCommand;
  }

  /**
   * Parameters for startMedia
   */
  export interface StartMediaParams {

    /**
     * callId
     */
    callId: string;
    body: PostCallMediaRequest;
  }
}

export { CallsService }
