<div class="container">
    <div class="row header-block">
        <div class="col-md-12 title">
            {{ title | translate }}
        </div>
    </div>
    <div class="content">
        <div class="col-12" [ngStyle]="{'visibility': (fileSelect ? 'visible' : 'hidden')}">
      <span class="btn" style="border: none;">
      <input #myFile type="file"
             (change)="fetchFile($event.target.files)"
             name="myFile">
    </span>
        </div>
        <div *ngIf="showConverter">
            <div class="col-12 uploader-file-name">
                <span>Converting file...</span>
            </div>
            <div class="col-12 loading-block">
                <div id="loadingConversionProgress">
                    <div id="loadingConversionProgress_1" class="loadingConversionProgress"></div>
                </div>
            </div>
        </div>
        <div *ngIf="showUploader">
            <div class="col-12 uploader-file-name">
                <span>Uploading {{fileName}}</span>
            </div>
            <div class="col-12 loading-block">
                <div id="loadingProgressG">
                    <div id="loadingProgressG_1" class="loadingProgressG"></div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="enableContent1">
            <div class="col-12 first-block text-danger">
                {{ 'prompt_model.error_1' | translate }}
            </div>
            <div class="col-12 second-block-1">
                {{ 'prompt_model.ul_heading' | translate }}
                <ul class="ul-align">
                    <li>{{ 'prompt_model.li_1' | translate }}</li>
                    <li>{{ 'prompt_model.li_2' | translate }}</li>
                    <li>{{ 'prompt_model.li_3' | translate }}</li>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="enableContent2">
            <div class="col-12 first-block text-danger">
                {{ 'prompt_model.error_2' | translate }}
            </div>
            <div class="col-12 second-block">
                {{ 'prompt_model.problem_text' | translate }}
            </div>
        </ng-container>
        <!--      <div class="text-danger col-12" *ngIf="error !== ''"> {{ error }}</div>-->
    </div>
    <div class="row footer-block">
        <div class="col-md-12 actions">
            <button *ngIf="enableCancel"
                    [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: 83)}" type="button" class="btn btn-default" (click)="cancel()">{{ cancelText | translate }}</button>
            <button *ngIf="!enableCancel"
                    [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: 83)}" type="button" class="btn btn-default" (click)="retry()">{{ retryText | translate }}</button>
        </div>
    </div>
</div>
