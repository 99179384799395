export class  AppInfo {
    name: string;
    id: string;
    minRole: string;
    navigation: string;
    private iconActive: string;
    private iconInactive: string;
    private children: AppInfoChild[];

    // returns base64 encoded icon string
    getIconActive(): string {
        return this.iconActive;
    }

    // returns base64 encoded icon string
    getIconInactive(): string {
        return this.iconActive;
    }

    getChildren(): AppInfoChild[] {
        return this.children;
    }
}

export class AppInfoChild extends AppInfo {
}

export const APP_INFO = [
    {
        name: 'Dashboard',
        translationKey: 'Dashboard',
        id: 'dashboard',
        minRole: 'USERS',
        navigation: '/',
    },
    {
        name: 'Billing',
        translationKey: 'Billing',
        id: 'billing',
        minRole: 'BILLING_ADMIN',
        navigation: '/billing',
        children: [
            {
                name: 'Invoices',
                translationKey: 'Invoices',
                id: 'invoices',
                minRole: 'BILLING_ADMIN',
                navigation: '/billing',
            },
            {
                name: 'Payment Methods',
                translationKey: 'Payment Methods',
                id: 'paymentmethods',
                minRole: 'BILLING_ADMIN',
                navigation: '/billing',
            },
            {
                name: 'Usage',
                translationKey: 'Usage',
                id: 'usage',
                minRole: 'BILLING_ADMIN',
                navigation: '/billing',
            }
        ]
    },
    {
        name: 'Line Handling',
        translationKey: 'Line Handling',
        id: 'linehandling',
        minRole: 'ACCOUNT_ADMIN',
        navigation: '/linehandling',
        children: [
            {
                name: 'Flows',
                translationKey: 'Flows',
                id: 'flows',
                minRole: 'ACCOUNT_ADMIN',
                navigation: '/flows',
            },
            {
                name: 'Schedules',
                translationKey: 'Schedules',
                id: 'Schedules',
                minRole: 'ACCOUNT_ADMIN',
                navigation: '/linehandling',
            },
            {
                name: 'Extension Groups',
                translationKey: 'Extension Groups',
                id: 'extensiongroups',
                minRole: 'ACCOUNT_ADMIN',
                navigation: '/linehandling',
            }
        ]
    },
    {
        name: 'Reports',
        translationKey: 'Reports',
        id: 'Reports',
        minRole: 'USERS',
        navigation: '/reports',
    },
    {
        name: 'Users',
        translationKey: 'Users',
        id: 'Users',
        minRole: 'ACCOUNT_ADMIN',
        navigation: '/users',
    },
];
