<div class="number-grid-container row">
  <span *ngIf="isLocalNumber" class="number-grid-select-dropdown">
    <span class="row number-grid-select-dropdown-selector" (click)="gridFilterOpen = !gridFilterOpen" (keyup.enter)="gridFilterOpen = !gridFilterOpen" [ngClass]="{'grid-filter-open': gridFilterOpen}" tabindex="0">
      <input type="checkbox" class="number-grid-select-dropdown-checkbox number-grid-select-dropdown-fragment" [ngClass]="{'hidden': partialResultsSelected}" [checked]="allResultsSelected">
      <span type="checkbox" class="number-grid-select-dropdown-checkbox-partial number-grid-select-dropdown-fragment" [ngClass]="{'hidden': !partialResultsSelected}">
        <span class="checkbox-partial"> - </span>
      </span>
      <span *ngIf="numberOfItemsSelected === 0" class="selection-text">{{'choose_new_number.no_selection' | translate}}{{'choose_new_number.selected' | translate}}</span>
      <span class="selection-text">
        <span *ngIf="numberOfItemsSelected === totalResults">{{'choose_new_number.all_selection' | translate}}</span>
        <span *ngIf="numberOfItemsSelected > 0">{{numberOfItemsSelected}}{{'choose_new_number.selected' | translate}}</span>
      </span>
      <span class="dropdown-arrow"></span>
    </span>
    <span *ngIf="gridFilterOpen" class="row number-grid-select-dropdown-result">
      <span class="item col-md-12" (click)="onSelectAll()" (keyup.enter)="onSelectAll()" tabindex="0">{{'choose_new_number.all' | translate}}</span>
      <span class="item col-md-12" (click)="onSelectNone()" (keyup.enter)="onSelectNone()" tabindex="0">{{'choose_new_number.none' | translate}}</span>
    </span>
  </span>
  <ul class="col-md-12 number-grid">
    <li class="row number-grid-row" *ngFor="let availNum of massagedNumbers; let i = index;"
      [ngClass]="{'hidden': !availNum.left}">

      <span *ngIf="availNum.left" class="left-number"
        [ngClass]="{'active': (activeIndex === i && activeColumn === 'left') || (activeIndexes[currentPage] && activeIndexes[currentPage]['left'].indexOf(i) >=0)}"
        (click)="onNumberSelected(availNum.left, 'left', i, $event)" (keyup.enter)="onNumberSelected(availNum.left, 'left', i, $event)" tabindex="0">
        <input *ngIf="!isLocalNumber" type="radio" class="number-grid-col-radio"
          [checked]="activeIndex === i && activeColumn === 'left'">
        <input *ngIf="isLocalNumber" type="checkbox" class="number-grid-col-radio"
          [checked]="activeIndexes[currentPage] && activeIndexes[currentPage]['left'].indexOf(i) >=0">
        <span class="number-grid-col-num" [innerHTML]="availNum.left.displayName"></span>
        <span class="number-grid-col-location">{{availNum.left.address}}</span>
      </span>

      <span *ngIf="availNum.right" class="right-number"
        [ngClass]="{'active': (activeIndex === i && activeColumn === 'right') || (activeIndexes[currentPage] && activeIndexes[currentPage]['right'].indexOf(i) >=0)}"
        (click)="onNumberSelected(availNum.right, 'right', i, $event)" (keyup.enter)="onNumberSelected(availNum.right, 'right', i, $event)" tabindex="0">
        <input *ngIf="!isLocalNumber" type="radio" class="number-grid-col-radio"
          [checked]="activeIndex === i && activeColumn === 'right'">
        <input *ngIf="isLocalNumber" type="checkbox" class="number-grid-col-radio"
        [checked]="activeIndexes[currentPage] && activeIndexes[currentPage]['right'].indexOf(i) >=0">
        <span class="number-grid-col-num" [innerHTML]="availNum.right.displayName"></span>
        <span class="number-grid-col-location">{{availNum.right.address}}</span>
      </span>
    </li>
  </ul>
  <div class="number-pagination" *ngIf="isLocalNumber && totalPages >= currentPage">
    <div class="pagination-text">
      {{'choose_new_number.page' | translate}} {{currentPage}} {{'choose_new_number.of' | translate}} {{totalPages}}
    </div>
    <div class="page-left" (click)="getPrevPage($event)" (keyup.enter)="getPrevPage($event)" [ngClass]="{'disabled': currentPage === 1}" tabindex="0">
      <span class="icon"></span>
    </div>
    <div class="page-right" (click)="getNextPage($event)" (keyup.enter)="getNextPage($event)" [ngClass]="{'disabled': currentPage === totalPages}" tabindex="0">
      <span class="icon"></span>
    </div>
  </div>
</div>