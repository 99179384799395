import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ODataBuilderService {

  constructor() { }

  // common for search input; returns constructed fitler query
  buildSearchQuery(columns: any[], searchKey: string) {
    if (!!searchKey) {
      const filters: string[] = [];

      columns.forEach(column => {
        let query = this.buildSearchfilter(column, searchKey);

        const updatedQuery = this.buildArraySearch(column, query);
        if (!!updatedQuery) {
          query = updatedQuery;
        }

        filters.push(query);
      });
      return filters.join(' or ');
    } else {
      return undefined;
    }
  }

  // To search for SubString in a field
  buildSearchfilter(fieldName: string, searchVal: string): string {
    return `Contains(tolower(cast(${fieldName} , Edm.String)),tolower('${searchVal}')) eq true`;
  }

  // To search for equivalent
  buildEQFilter(field: string, value: any) {
    return `tolower(${field}) eq '${value}'`;
  }

  // To search for a value in a sub-array
  buildArraySearch(column, query) {
    if (column.includes('-')) {
      const splits = column.split('-');
      const text = query.replace(new RegExp(splits[0], 'gi'), 'item').replace(new RegExp('-', 'gi'), '/');
      query = `${splits[0]}/any(item: ${text})`;
      return query;
    }
    return undefined;
  }
}
