import {UserPhoneService, PhoneService} from '../services/phone-service';

export class CommonUtils {

  // static userHasPhoneService(userPhoneService: UserPhoneService): boolean {
  //   return !!userPhoneService;
  // }

  // static userHasPhoneNumber(userPhoneService: UserPhoneService): boolean {
  //   return (userPhoneService && userPhoneService.phoneNumber && userPhoneService.phoneNumber.value !== '');
  //
  // }

  static flowHasPhoneNumber(flow: any): boolean {
    return (flow && flow.tnId !== null && flow.id !== null);
  }



  // static comparePhoneServices(a: PhoneService, b: PhoneService): number {
  //   // return a.name.includes(b.name) ? 0 : -1;
  //   return a.uuid === b.uuid ? 0 : -1;
  // }

  // static isNoneBundlePhoneService(phoneService: PhoneService): boolean {
  //   return phoneService ? phoneService.name.includes('None') : true;
  // }

  // static getBundle(devicesAndServices): string {

  //   if (devicesAndServices && devicesAndServices.get('phoneBundle') && devicesAndServices.get('phoneBundle').value)
  //   {
  //     return devicesAndServices.get('phoneBundle').value.name;
  //   } else {
  //     return 'None'; // None bundle.
  //   }
  // }

  // static isNoneBundle(devicesAndServices): boolean {
  //   return this.getBundle(devicesAndServices) === 'None';
  // }

  // static getPhoneNumberDigitsValue(devicesAndServices) {
  //   // just return only the phone number
  //   return this.getPhoneNumberDigits(devicesAndServices) ? this.getPhoneNumberDigits(devicesAndServices).value : null;
  // }

  // static getPhoneNumberWithFormat(devicesAndServices) {
  //   // just return only the phone number but formatted
  //   return this.getPhoneNumberDigits(devicesAndServices) ? this.getPhoneNumberDigits(devicesAndServices).displayName : null;
  //   }

  // static getPhoneNumberDigits(devicesAndServices) {
  //   if (devicesAndServices && devicesAndServices.get('phoneNumberDigits') && devicesAndServices.get('phoneNumberDigits').value)
  //   {
  //     return devicesAndServices.get('phoneNumberDigits').value; // get from the object
  //   } else {
  //     return null; // None bundle.
  //   }
  // }

  // static getExtension(devicesAndServices) {
  //   if (devicesAndServices && devicesAndServices.get('extension'))
  //   {
  //     return devicesAndServices.get('extension').value;
  //   } else {
  //     return ''; // None bundle.
  //   }
  // }

  static convertCurrency(input: string): string {
    if (input === 'USD') { return '$'; }
    if (input === 'CAD') { return 'CA$'; }
    if (input === 'GBP') { return '£'; }
    if (input === 'EUR') { return '€'; }
    if (input === 'AUD') { return '$'; }

    // In case there is no matching currency, use the string from the server.
    console.log('convertCurrency: could not convert currency ' + input);
    return input;
  }

}
