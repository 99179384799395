import * as _ from 'lodash';
import {Renderer2, Component, OnInit, Input, Output, EventEmitter, forwardRef, HostListener, ElementRef,
   SimpleChanges, OnChanges} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ItemsWithHeading} from '../shared/common.interface';
import {ClDropdownService} from '../services/cl-dropdown.service';

export enum KEY_CODE { /*  https://keycode.info/ */
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  DOWN_ARROW = 40,
  UP_ARROW = 38,
  SPACE = 32,
  ENTER = 13,
  TAB = 9
}

@Component({
  selector: 'cl-dropdown-with-headings',
  templateUrl: './cl-dropdown-with-headings.component.html',
  styleUrls: ['./cl-dropdown-with-headings.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClDropdownWithHeadingsComponent),
      multi: true
    }
  ]
})

export class ClDropdownWithHeadingsComponent implements OnInit, OnChanges, ControlValueAccessor {

  // @Input() items: Array<ItemsWithHeading>;
  @Input() openModal = false;
  private _items:  Array<ItemsWithHeading>;

  @Input() get items() {
    return this._items;
  }

  set items (i: Array<ItemsWithHeading>) {
    this._items = i;
    this.itemValues = _.reduce(this._items, (nums, item) => {
      return nums.concat(item.items);
    }, []);
  }

  @Input() placeholder = '';
  @Input('disabledropdown') disabled = false; // With an input named 'disabled' we get browser WARN msgs like "It looks like you're using the disabled attribute with a reactive form directive. If you set disabled..."
  @Input() tabbable = true;
  @Input() emptyHeadingOnNoValue = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  public onChange: any = Function.prototype; // Trascend the onChange event
  public onTouched: any = Function.prototype; // Trascent the onTouch event
  private _value: any;
  private itemValues: Array<any>;
  selectedItem: any;
  selectedItemIdx = 0;
  showMenu = false;
  onFocused = false;
  prevIndex;
  prevSelectedItem;

  constructor(private renderer: Renderer2, private el: ElementRef, private dpService: ClDropdownService) {}

  ngOnInit() {
    this.itemValues = _.reduce(this.items, (nums, item) => {
      return nums.concat(item.items);
    }, []);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && !changes.items.firstChange && changes.items.currentValue) {
      console.log('cl-dropdown-with-headings: OnChange triggered');
      this.itemValues = _.reduce(changes.items.currentValue, (nums, item) => {
        return nums.concat(item.items);
      }, []);
    }
  }

  @HostListener('document:touchend', ['$event'])
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  keyEvent(event: KeyboardEvent) {
    if (this.disabled != true) {
      if (this.showMenu) {
        if (event.keyCode === KEY_CODE.DOWN_ARROW) {
          event.preventDefault();
          this.moveSelectedItem(1);
        } else if (event.keyCode === KEY_CODE.UP_ARROW) {
          event.preventDefault();
          this.moveSelectedItem(-1);
        } else if (event.keyCode === KEY_CODE.SPACE || event.keyCode === KEY_CODE.ENTER) {
          event.preventDefault();
          this.onItemSelect(this.selectedItem);
        } else if (event.keyCode === KEY_CODE.TAB) {
          this.showMenu = false;  // close the dropdown on tab press
        }
      } else if (this.onFocused) {
        if (event.keyCode === KEY_CODE.DOWN_ARROW && this.items) {
          event.preventDefault();
          this.showMenu = true;
          this.selectedItem = this.items[this.selectedItemIdx];
        }
      }
    }
  }

  onOutsideClick(event) {
    if ( this.showMenu == true ) {
      this.onBlur();
    }
  }

  setSelectedItemTo(item: any) {
    this.selectedItem = item;
    this.selectedItemIdx = this.itemValues.indexOf(item);
  }

  moveSelectedItem(step: number) {
    this.selectedItemIdx += step;
    this.selectedItemIdx = this.selectedItemIdx < 0 ? this.itemValues.length + this.selectedItemIdx
                                                  : this.selectedItemIdx >= this.itemValues.length ? 0 : this.selectedItemIdx;
    this.selectedItem = _.nth(this.itemValues, this.selectedItemIdx);
  }

  onClick() {
    this.showMenu = !this.showMenu;
    if (!this.showMenu && !this._value) {
      this.resetSelectedItemIdx();
    } else if (this.showMenu && this._value) {
      this.selectedItem = this._value;
    }
  }

  resetSelectedItemIdx() {
    this.selectedItemIdx = 0;
    if (this.itemValues.length > 0) {
      this.selectedItem = this.itemValues[0];
      this._value = this.selectedItem;
    }
  }

  onFocus() {
    if (this.disabled !== true) {
      this.onFocused = true;
    }
  }

  onBlur() {
    if (this.disabled !== true) {
      this.showMenu = false;
      this.onTouched();
      this.onFocused = false;
      if (!this._value) {
        this.resetSelectedItemIdx();
      } else {
        this.selectedItem = this._value;
      }
    }
  }

  /**
   *  A user selected an item in the dropdown list
   */
  onItemSelect(v: any) {
    if (v.action) {
      // this is an action item instead call on the execute action call
      this.onClickExecuteActionDropdownItem();
    } else {
      // console.log("select this item ", v);
      // console.log("this._value is", this._value);
      if (this._value === undefined || v.value !== this._value.value) {
          this._value = v;
          this.onChange(v);
          this.change.emit({value: v, reason: 'user'});
      }
      this.showMenu = false;
    }
  }

  public validate(c: FormControl) {
    // TODO: add custom validation here
    // return (this._value && this._value.length > 0) ? null : {
    //   required: {
    //     valid: false,
    //   },
    // };
    return null;
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    // console.log("Dropdown, setting ", v);
  	if (!this._value || v.value !== this._value.value) {
  	  this._value = v;
      // console.log("Value set to ", v);
      this.onChange(v);
  	}
  }

  /**
   * Software programatically selected an item on the list.  Typically done when list is initialized and default item is selected.
   */
  // Required for ControlValueAccessor interface
  writeValue(value: any) {
    console.log('dropdown head write value called with', value);
    console.log('###### -- itemvalues', this.itemValues);
    value = _.isUndefined(value) ? {} : value;
    if (value != null && value !== '')  {
      if (this.itemValues.length === 0 ) {
        // console.log("There are no items in this list");
      } else {
        const dditem = this.itemValues.filter(item => {
          return _.isEqual(value.value, item.value);
        });

         console.log('###### -- dditem', dditem);
        this._value = dditem[0];
        this.setSelectedItemTo(dditem[0]);
        this.onChange(dditem[0]);
        this.change.emit({value: dditem[0], reason: 'set'}); // Add here!
      }
  	}
  }

  // Required for ControlValueAccessor interface
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  // Required for ControlValueAccessor interface
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getItemValues(): Array<any> {
    return this.itemValues;
  }

  onClickExecuteActionDropdownItem() {
    this.dpService.sendAction(true);
  }
}
