import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CloudlinkAuthService } from './cloudlink-auth.service';
import { environment } from 'src/environments/environment';


export class Bootstrapper {
  commonName: string
  callflowBaseUrl: string;
  bossApiBaseUrl: string;
  bossWebPortalBaseUrl: string;
}

/**
 * Service to provide URIs of back-end service end-points.  The back-end chosen is determined by the logged in account's partner ID.
 */
@Injectable({
  providedIn: 'root'
})
export class BootstrapperService {

  private partnerId: string;
  bootstrapUpdated  = new ReplaySubject<Bootstrapper>(1);// Last 1 and future emits are broadcasted to all subscribed.

  /**
   * Mapping of partnerId to BOSS service URIs.
   * Every account has a partnerId. The partnerId is used to indicate the BOSS system which the account
   * is assigned to. This code contains a hard coded mapping of account partner ID to back-end servers.
   *
   * Some of this data is from https://github.com/mitel-networks/cloudlink-auth-portal/issues/549
   *
   * @private
   * @type {{[key:string]:Bootstrapper}}
   * @memberof BootstrapperService
   */
  private backEndUris:{[key:string]:Bootstrapper} = {
    '992000002': {
      commonName: 'Production US',
      callflowBaseUrl: 'https://callflow.connect.mitel.io',
      bossApiBaseUrl: 'https://sdapi.shoretelsky.com',
      bossWebPortalBaseUrl: 'https://portal.connect.mitel.io'
    },
    '992000001': {
      commonName: 'Alpha',
      callflowBaseUrl: 'https://callflow.connect-stage.mitel.io',
      bossApiBaseUrl: 'https://sdapi.alpha.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal.connect-stage.mitel.io'
    },
    '527812169': {
      commonName: 'CAN01',
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://portal-can01.mitellabs.com:8443',
      bossWebPortalBaseUrl: 'https://portal-can01.connect.dev.mitel.io'
    },
    '441870381': {
      commonName: 'MIFT',
      callflowBaseUrl: 'https://callflow-connect-latest.dev.mitel.io',
      bossApiBaseUrl: 'https://portal-mift.mitellabs.com:8443',
      bossWebPortalBaseUrl: 'https://portal-mift.connect.dev.mitel.io'
    },
    '991000002': {
      commonName: 'SIT',
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.sit.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-sit.connect.dev.mitel.io'
    },
    '991000001': {
      commonName: 'UTIT', // UT/IT
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://utit-sdapi.sit.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-utit.connect.dev.mitel.io'
    },
    '995500001' : {
      commonName: 'Performance',
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi-perf.mitellabs.com',
      bossWebPortalBaseUrl: 'https://portal-perf.mitellabs.com'
    },
    '991000008': {
      commonName: 'BOSS US QA',
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi-qa.shoretelsky.com',
      bossWebPortalBaseUrl: 'https://portal-qa.connect.dev.mitel.io'
    },
    '991000006': {
      commonName: 'BOSS SCO QA',
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://ba1mcss01qav.stsky.biz:50200',
      bossWebPortalBaseUrl: 'https://10.198.105.68'
    },
    '991000022': {
      commonName: 'UKD-MAC',
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.mac.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-mac.connect.dev.mitel.io'
    },
    '991000023': {
      commonName: 'UKQ-WIN',
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.win.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-win.connect.dev.mitel.io'
    },
    '992000005': {
      commonName: 'PRODUCTION - UK',
      callflowBaseUrl: 'https://callflow.connect.mitel.io',
      bossApiBaseUrl: 'https://sdapi.sd.mitelcloud.co.uk',
      bossWebPortalBaseUrl: 'https://portal.mac.shoretel.com' // TO BE UPDATED TO PROD
    }
  };


  constructor(private authenticationService: CloudlinkAuthService) {

    this.authenticationService.getToken().then(
      token => {
        try {
          const decodeAuth = JSON.parse(atob(token.access_token));
          const decodeBearerToken = JSON.parse(atob(decodeAuth.bearerToken.split('.')[1])); // get the JWT payload
          this.setPartnerId(decodeBearerToken.partnerId);
        } catch (e) {
          // ignore; likely no token
        }
      }
    )
  }


  getBootstrap(): Bootstrapper | undefined {

    let bootstrapper = this.backEndUris[this.getPartnerId()];
    if (!bootstrapper) {
      console.error('BootstrapperService does not have a configuration for partner id='+this.getPartnerId());
      bootstrapper = {
        commonName: 'Empty Bootstrap Configuration',
        callflowBaseUrl: 'Empty Bootstrap Configuration',
        bossApiBaseUrl: 'Empty Bootstrap Configuration',
        bossWebPortalBaseUrl: 'Empty Bootstrap Configuration',
      }
    }
    if (environment['mockApiBaseUrl']) {
      bootstrapper.bossApiBaseUrl = environment['mockApiBaseUrl'];
    }
    return bootstrapper;
  }


  setPartnerId(partnerId: string) {
    if (partnerId && partnerId !== this.partnerId) {
      this.partnerId = partnerId;
      this.bootstrapUpdated.next(this.getBootstrap());
    }
  }

  getPartnerId() {
    return this.partnerId;
  }

}
