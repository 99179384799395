import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-release-previous-number-dialog',
  templateUrl: './release-previous-number-dialog.component.html',
  styleUrls: ['./release-previous-number-dialog.component.css']
})
export class ReleasePreviousNumberDialogComponent {

}
