import { Injectable } from '@angular/core';
import { AuthenticationService as ClAuthenticationService, Claims, Token} from '@mitel/cloudlink-sdk';
import { environment } from '../../environments/environment';

/**
 * Simply wrapps CloudLink's Authentication service for DI
 */
@Injectable({
  providedIn: 'root'
})
export class CloudlinkAuthService {

  authenticationService: ClAuthenticationService;

  constructor() {
      this.authenticationService = new ClAuthenticationService({cloud: environment.cloud});
  }

  getToken(): Promise<Token> {
    return this.authenticationService.getToken();
  }
}
