import { Component, OnInit, Input } from '@angular/core';
import { InvoiceSmallModel } from './invoice-small.model';

@Component({
  selector: 'user-invoice-small',
  templateUrl: './invoice-small.component.html',
  styleUrls: ['./invoice-small.component.css']
})
export class InvoiceSmallComponent {

  @Input() invoice: InvoiceSmallModel;

}
