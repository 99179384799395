import {Component, Input, forwardRef, OnInit, OnChanges, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';

@Component({
  selector: 'app-cl-autocomplete',
  templateUrl: './cl-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClAutocompleteComponent),
      multi: true
    }
  ],
  styleUrls: ['./cl-autocomplete.component.scss']
})
export class ClAutocompleteComponent implements ControlValueAccessor, OnInit {
  @Input() items: any = [];
  @Input() placeholder = '';
  @Input() tabbable = true;
  @Input() showClearIcon = false;
  @Output() keyUp: EventEmitter<any> = new EventEmitter();
  @Output() clearTrigger: EventEmitter<any> = new EventEmitter();
  @Output() selectedItem: EventEmitter<any> = new EventEmitter();
  public onTouched: any = Function.prototype; // Trascent the onTouch event
  @Input() showSuggestion = false;
  @Input() redBorder = false;
  value = '';
  // this is the initial value set to the component

  ngOnInit() {
  }

  onOutsideClick(event) {
    this.showSuggestion = false;
  }

  clearSearch() {
    this.clearTrigger.emit();
  }

  onSelectItem(event) {
    this.selectedItem.emit(event);
    this.showSuggestion = false;
  }

  writeValue(val: any) {
    if (val || val === '') {
      this.value = val;
    }
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }


  // not used, used for touch input
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  // change events from the textarea
  onChange(event) {

    // get value from text area
    const newValue = event.target.value;
    this.propagateChange(newValue);
    this.showSuggestion = true;
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange = (_: any) => { };
}
