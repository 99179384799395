import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pendo-badge',
  templateUrl: './pendo-badge.component.html',
  styleUrls: ['./pendo-badge.component.css']
})
export class PendoBadgeComponent implements OnInit {
  /**
   * (optional) custom placement of the pendo badge on the page
   * using css property 'right'
   *
   * @memberof PendoBadgeComponent
   */
  @Input() customPendoPosition: { right?: string, zindex?: number };
  @Input() rightPanelOpened: boolean;
  pendoOpened = false;
  constructor() {}

  ngOnInit() {}

  openPendo() {
    this.pendoOpened = !this.pendoOpened;
  }

  stylePendoBadge() {
    let styles;
    if (this.rightPanelOpened && this.customPendoPosition) {
      styles = {
        'right':  this.customPendoPosition.right ? this.customPendoPosition.right : null,
        'z-index': this.customPendoPosition.zindex ? this.customPendoPosition.zindex : null
      };
    }
    return styles;
  }
}
