import {Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {CommitmentDetailsAdaptorService} from '../../services/commitment-details-adaptor.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-commitment-chart',
  templateUrl: './commitment-chart.component.html',
  styleUrls: ['./commitment-chart.component.scss']
})
export class CommitmentChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input() contractProfileAmount = 0; // max profiles/bundles/services/phones/users permitted on contract
  @Input() currentProfileAmount = 0;  // current number of profiles/servics in use
  @Input() availableContractsAllowed = 0; // contract profile amount - current profile amount
  @Input() showChart: boolean;  // true, display the doughnut commitment chart;  false, display summary message
  @Input() countingCurrent = '';
  @Input() countingOnEqualAmounts = '';

  subscriptions: Subscription[] = [];
  chartDatasets: Array<any>;  // see https://www.chartjs.org/docs/latest/charts/doughnut.html

  public chartType = 'doughnut';

  public chartLabels: Array<any> = ['Current', 'Available'];

  public chartColors: Array<any> = [{
    backgroundColor: ['#3FAC49', 'rgba(255,255,255,0.3)']
  }];

  public chartOptions: any = {
    responsive: true,
    cutoutPercentage: 63
  };
  // Date
  serviceCommitmentDate = '';
  dateToday;
  isCommitmentDateInFuture: boolean = false;  // if true, commitment chart will be displayed

  param_contract_setup_complete = {
    max_permitted_on_contract: this.contractProfileAmount
  };
  param_contract_progress = {
    num_setup: this.currentProfileAmount,
    max_permitted_on_contract: this.contractProfileAmount
  };
  param_auto_activate_on = {
    activation_deadline_date: this.serviceCommitmentDate
  };

  constructor(private commitmentSvc: CommitmentDetailsAdaptorService) {
    this.dateToday = moment().format('MMM DD\, YYYY');
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.currentProfileAmount = changes.currentProfileAmount.currentValue;
      this.availableContractsAllowed = changes.availableContractsAllowed.currentValue;
      this.contractProfileAmount = changes.contractProfileAmount.currentValue;

      this.chartDatasets = [{data: [this.currentProfileAmount, this.availableContractsAllowed], borderWidth: 0}];
      this.param_contract_setup_complete.max_permitted_on_contract = this.contractProfileAmount;
      this.param_contract_progress.max_permitted_on_contract = this.contractProfileAmount;
      this.param_contract_progress.num_setup = this.currentProfileAmount;
      this.subscriptions.push(this.commitmentSvc.getCommitmentAmountDetails().subscribe((data) => {
        this.serviceCommitmentDate = moment(data.serviceCommitmentDate).format('MMM DD\, YYYY');
        this.param_auto_activate_on.activation_deadline_date = this.serviceCommitmentDate;
        this.isCommitmentDateInFuture = moment(this.dateToday).isBefore(this.serviceCommitmentDate);
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
