<div class="btn-group" #myDropdownWithHeading [attr.tabindex]="tabbable && !disabled ? 0 : -1" (blur)="onBlur()" (focus)="onFocus()"  (keydown)="keyEvent($event)" >
  <a class="button" class="btn btn-primary dropdown-toggle truncate" [ngClass]="{'disabled': disabled}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="onClick()">
      <div *ngIf="value && value.prefix">
      <span class="value-prefix" style="float: left;">
        {{ value.prefix }}
      </span>
    </div>
    <div *ngIf="value">
      <p [ngClass]="{'existing-value' : !value.prefix, 'existing-value-with-prefix' : value.prefix}">
        &nbsp; {{ value ? (value.displayName) : ('cl_dropdown.please_select' | translate) }}
      </p>
      <span>
        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ2ICg0NDQyMykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbi9BcnJvdy9CYXNpYzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9BcnJvdy9CYXNpYyIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjMTUzMjVGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTEwLDEzLjU4NTc4NjQgTDEuNzA3MTA2NzgsNS4yOTI4OTMyMiBDMS4zMTY1ODI0OSw0LjkwMjM2ODkzIDAuNjgzNDE3NTExLDQuOTAyMzY4OTMgMC4yOTI4OTMyMTksNS4yOTI4OTMyMiBDLTAuMDk3NjMxMDcyOSw1LjY4MzQxNzUxIC0wLjA5NzYzMTA3MjksNi4zMTY1ODI0OSAwLjI5Mjg5MzIxOSw2LjcwNzEwNjc4IEw5LjI5Mjg5MzIyLDE1LjcwNzEwNjggQzkuNjgzNDE3NTEsMTYuMDk3NjMxMSAxMC4zMTY1ODI1LDE2LjA5NzYzMTEgMTAuNzA3MTA2OCwxNS43MDcxMDY4IEwxOS43MDcxMDY4LDYuNzA3MTA2NzggQzIwLjA5NzYzMTEsNi4zMTY1ODI0OSAyMC4wOTc2MzExLDUuNjgzNDE3NTEgMTkuNzA3MTA2OCw1LjI5Mjg5MzIyIEMxOS4zMTY1ODI1LDQuOTAyMzY4OTMgMTguNjgzNDE3NSw0LjkwMjM2ODkzIDE4LjI5Mjg5MzIsNS4yOTI4OTMyMiBMMTAsMTMuNTg1Nzg2NCBaIiBpZD0iUGF0aC0yIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=">
      </span>
    </div>
    <div *ngIf="!value && !emptyHeadingOnNoValue">
      {{ 'cl_dropdown.please_select' | translate }}
      <span>
        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ2ICg0NDQyMykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbi9BcnJvdy9CYXNpYzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9BcnJvdy9CYXNpYyIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjMTUzMjVGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTEwLDEzLjU4NTc4NjQgTDEuNzA3MTA2NzgsNS4yOTI4OTMyMiBDMS4zMTY1ODI0OSw0LjkwMjM2ODkzIDAuNjgzNDE3NTExLDQuOTAyMzY4OTMgMC4yOTI4OTMyMTksNS4yOTI4OTMyMiBDLTAuMDk3NjMxMDcyOSw1LjY4MzQxNzUxIC0wLjA5NzYzMTA3MjksNi4zMTY1ODI0OSAwLjI5Mjg5MzIxOSw2LjcwNzEwNjc4IEw5LjI5Mjg5MzIyLDE1LjcwNzEwNjggQzkuNjgzNDE3NTEsMTYuMDk3NjMxMSAxMC4zMTY1ODI1LDE2LjA5NzYzMTEgMTAuNzA3MTA2OCwxNS43MDcxMDY4IEwxOS43MDcxMDY4LDYuNzA3MTA2NzggQzIwLjA5NzYzMTEsNi4zMTY1ODI0OSAyMC4wOTc2MzExLDUuNjgzNDE3NTEgMTkuNzA3MTA2OCw1LjI5Mjg5MzIyIEMxOS4zMTY1ODI1LDQuOTAyMzY4OTMgMTguNjgzNDE3NSw0LjkwMjM2ODkzIDE4LjI5Mjg5MzIsNS4yOTI4OTMyMiBMMTAsMTMuNTg1Nzg2NCBaIiBpZD0iUGF0aC0yIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=">
      </span>
    </div>
  </a>
  <div class="dropdown-menu" [ngClass]="{'show': showMenu}">
    <h6 class="dropdown-header" *ngFor="let heading of items; let i=index">
      <span class="heading">
        {{ heading.heading | translate }}
      </span>
      <a class="dropdown-item"
        *ngFor="let item of heading.items"
        [ngClass]="{'active': selectedItem === item}"
        [appFocusScroll]="selectedItem === item"
        (click)="onItemSelect(item)">
          <span class="prefix prefix-style">
            {{ item.prefix }}
          </span>
          <span *ngIf="item.prefix" class="suffix suffix-style">
            &nbsp; {{ item.displayName }}
          </span>
          <span *ngIf="!item.prefix&&!item.action" style="font-weight: normal;">
            {{ item.displayName | translate}}
          </span>
          <span *ngIf="item.action === true">
            <hr class="item-divider">
            <span style="color: #323A45; font-family: 'Open Sans'; font-size: 14px; font-weight: 600;">
              {{ item.displayName | translate}}
            </span>
          </span>
          <span class="checked"></span>
      </a>
    </h6>
  </div>
</div>
