import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class ParentCommsService {

  constructor() { }

  tellParentToCheckSession() {
    if (this.isInsideIframe()) {
      console.log('Tell the parent that it should do a session check');
      window.parent.postMessage('CHECK_SESSION', '*');
      return true;
    }
    return false;
  }

  tellParentToRedirectToLogin() {
    if (this.isInsideIframe()) {
      console.log('Tell the parent that it should redirect to login');
      window.parent.postMessage('REDIRECT_LOGIN', '*');
      return true;
    }
    return false;
  }

  isInsideIframe() {
    return window.location !== window.parent.location;
  }
}
