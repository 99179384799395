import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
   } from '@angular/common/http';
   import { Injectable } from '@angular/core';
   import { Observable, throwError } from 'rxjs';
   import { catchError } from 'rxjs/operators';

   import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
   import * as _ from 'lodash';

   @Injectable()
   export class HttpAuthErrorInterceptor implements HttpInterceptor {
     constructor( private clHeader: ClHeaderComponent) {}

     // This interceptor is looking for auth errors.
     // The error.authError is specific to the BossAuth interceptor.
     // Status code 401, unauthorized refers to a token being expired.
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          catchError((error) => {

            // If this is an auth type error then redirect to loginp.mo
            if ( error.authError ||

              (error.status && error.status == 401) ||
              (error.statusCode && error.statusCode == 401)
            ) {
                const urlArray = error.url.split('/'); // find prompt url
                if (!_.includes(urlArray, 'Prompt')) { // verify prompt url
                    this.clHeader.redirectToLogin(); // let session management figure it out
                } else {
                    console.log('Prompt', 'Prompt url found');
                }
              console.error('Error interceptor Caught auth error', error);
            }

            // pass the error on up to caller
            return throwError(error);
          })
        );
    }
   }
