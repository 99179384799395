import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';

import * as _ from 'lodash';
import { NavigationConfirmationComponent } from '../../navigation-confirmation/navigation-confirmation.component';
import { Days, rightPanelCloseReason } from '../../shared/constants';
import { SchedulesService } from '../../services/schedules.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { ScheduleAdaptorService } from '../../services/schedule-adaptor.service';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';

@Component({
    selector: 'app-holiday-schedule',
    templateUrl: './holiday-schedule.component.html',
    styleUrls: ['./holiday-schedule.component.css']
})
export class HolidayScheduleComponent implements OnInit, OnDestroy {

    @Input() popUp = false;
    @Input() dialogHeader = 'holiday.new_title';
    @Input() tabbable = true;
    @Input() toOpen: boolean;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() resetToOpen = new EventEmitter();
    modalRef: NgbModalRef;
    closeReason: string;
    deleteConfirmDisplayFlag = false;
    holidayForm: FormGroup;
    holidaySchedule: FormArray;
    holidaySchedules: any[] = [];
    showHolidaySchedule = false;
    timeZones: any[] = [];
    submitting = false;
    showFormError = false;
    errMsg = '';
    errMsgStyle: any = {'max-width': '650px'};
    displayLoadingSpinner = false;
    schedule: any = {};
    clickedScheduleSubscription: Subscription;
    updateSchedule = false;
    scheduleType = 3;
    otherErr: string[] = [];
    deletedSchedule = [];
    errorMsg: string[];
    scheduleNameError: '';

    constructor(public translateSvc: TranslateService,
                private fb: FormBuilder,
                private activeModal: NgbActiveModal,
                private modalSvc: NgbModal,
                public schedulesSvc: SchedulesService,
                private notificationService: NotificationService,
                private scheduleSvc: ScheduleAdaptorService) {
    }

    ngOnInit() {
        this.clickedScheduleSubscription = this.schedulesSvc.clickedScheduleChanged.subscribe(async schedule => {
            this.schedule = schedule;
            if (schedule !== null) {
                await this.setFormWithSchedule();
            }
        });
        this.schedule = this.schedulesSvc.getClickedSchedule();
        this.updateSchedule = !_.isEmpty(this.schedule);
        this.initForm();
        this.setFormWithSchedule();
    }

    async initForm() {
        this.holidayForm = this.fb.group({
            holidaySection: this.fb.group({
                name: ['New Holiday Schedule', Validators.required],
                timeZone: [null, Validators.required],
                holidaySchedule: this.fb.array([]),
            })
        });
    }

    async setFormWithSchedule() {
        this.displayLoadingSpinner = true;
        this.holidayForm.reset();
        if (!this.schedule) {

            await this.scheduleSvc.getNewSchedule(this.scheduleType).toPromise().then (
              holiday => {
                this.schedule = holiday;
                // this.schedule.name = this.schedule.name === null ? 'New Holiday Schedule' : this.schedule.name;
                this.schedule.name = 'New Holiday Schedule';
                this.displayLoadingSpinner = false;
              },
              error => {
                this.displayLoadingSpinner = false;
                console.error('failed to getNewSchedule', error);
                this.showServerError(
                    'Failed to get new holiday schedule data. ' + BossApiUtils.extractErrorMessage(error));
              }
            );
        } else {
            const scheduleId = this.schedule.id;
            await this.scheduleSvc.getSchedule(this.scheduleType, this.schedule.id).toPromise().then(
                holiday => {
                    this.schedule = holiday;
                  },
                  error => {
                    console.error('failed to fetch holiday schedule with id', error);
                    this.showServerError( 'Failed to fetch holiday schedule. ' + BossApiUtils.extractErrorMessage(error));
                  }
            );
        }

        if (this.schedule != null) {
            this.timeZones = this.schedule.timeZones;
            const holidaySchedules = this.loadAvailableSchedules(this.schedule);
            this.holidayForm.get('holidaySection').patchValue({
                name: this.schedule.name,
                holidaySchedule: holidaySchedules
            });
            if (this.schedule.timeZone) {
                this.holidayForm.get('holidaySection').patchValue({timeZone: {value: this.schedule.timeZone}});
            }
            this.displayLoadingSpinner = false;
        }
    }

    loadAvailableSchedules(schedule) {
        this.initForm();
        let schedules = _.cloneDeep(schedule.scheduleItems);
        schedules = _.map(schedules, returnDaySchedules);
        function returnDaySchedules(value) {
            if (value.scheduleDate && (value.scheduleDate.split('/').length - 1) === 1) {
                value.yearly = true;
                value.scheduleDate = new Date(value.scheduleDate + '/' + new Date().getFullYear());
            } else {
                value.yearly = false;
                value.scheduleDate = new Date(value.scheduleDate);
            }
            value.scheduleName = value.name;
            value._destroy = 0;
            return value;
        }
        /* istanbul ignore else*/
        if (schedules.length > 0) {
            for (let i = 0; i < schedules.length; i++) {
                this.reAddHolidaySchedule(i);
            }
        }
        return schedules;
    }

    reAddHolidaySchedule(index) {
        const scheduleObj = this.holidayForm.get('holidaySection').get('holidaySchedule') as FormArray;
        scheduleObj.insert(index + 1, this.createHolidayScheduleArray());
        this.showHolidaySchedule = true;
    }

    addHolidaySchedule(index) {
        const scheduleObj = this.holidayForm.get('holidaySection').get('holidaySchedule') as FormArray;
        scheduleObj.insert(index + 1, this.createHolidayScheduleArray());
        this.holidayForm.markAsDirty();
        this.showHolidaySchedule = true;
    }

    removeHolidaySchedule(index) {
        const scheduleObj = this.holidayForm.get('holidaySection').get('holidaySchedule') as FormArray;
        const selectedObject = scheduleObj.controls[index].value;
        if (selectedObject.id !== null) {
            selectedObject._destroy = 1;
            this.deletedSchedule.push(selectedObject);
        }
        scheduleObj.removeAt(index);
        this.holidayForm.markAsDirty();
        if (scheduleObj && scheduleObj.length === 0) {
            this.showHolidaySchedule = false;
        }
    }

    createHolidayScheduleArray(): FormGroup {
        return this.fb.group({
            id: null,
            scheduleDate: [new Date(), Validators.required],
            yearly: false,
            scheduleName: ['New Holiday', Validators.required],
            uniqueId: null,
            scheduleDateInvalid: false,
            scheduleDateReq: false,
            _destroy: 0
        });
    }

    async onSubmit() {
        this.submitting = true;
        this.clearServerErrorOnForm();
        if (this.holidayForm.valid) {
            this.displayLoadingSpinner = true;
            if (this.schedule && this.schedule.id != null) {
                console.log('Update Holiday schedule');
                const params = this.getHolidayScheduleParams(false);
                await this.scheduleSvc.updateHolidaySchedule(this.schedule.id, this.scheduleType, params)
                .toPromise().then(
                  response => {
                    this.schedule = response;
                    if (this.schedule.errors != null && this.schedule.errors !== '') {
                      console.error(this.schedule.errors);
                      this.displayLoadingSpinner = false;
                      this.showServerError('Failed to update holiday schedule');
                      this.parseServerError(this.schedule.errors);
                    } else {
                      this.displayLoadingSpinner = false;
                      this.callOnClose(true);
                      this.insertMessage('holiday.updated_holiday');
                    }
                  },
                  error => {
                    // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
                    console.error('failed to update holiday schedule', error);
                    this.displayLoadingSpinner = false;
                    this.translateSvc.get('error_messages').subscribe(err => {
                    this.showServerError( 'Unable to update holiday schedule: ' +  BossApiUtils.extractErrorMessage(error));
                    });
                  }
                );
                this.submitting = false;
            } else {
                console.log('Create Holiday schedule');
                const params = this.getHolidayScheduleParams(true);

                await this.scheduleSvc.createHolidaySchedule(this.scheduleType, params)
                .toPromise().then(
                  response => {
                    this.schedule = response;
                    if (this.schedule.errors != null && this.schedule.errors !== '') {
                      console.error(this.schedule.errors);
                      this.displayLoadingSpinner = false;
                      this.showServerError('Failed to create holiday schedule');
                      this.parseServerError(this.schedule.errors);
                    } else {
                        this.displayLoadingSpinner = false;
                        if (this.popUp) {
                            this.activeModal.close({id: this.schedule.id, name: this.schedule.name});
                            // this.insertMessage('holiday.created_holiday');
                          } else {
                            this.callOnClose(true);
                            this.insertMessage('holiday.created_holiday');
                          }
                        }
                    },
                  error => {
                    // if not caught here, will get an 'core.js:15714
                      // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                    console.error('failed to create holiday schedule', error);
                    this.displayLoadingSpinner = false;
                    this.translateSvc.get('error_messages').subscribe(err => {
                    this.showServerError( 'Unable to create holiday schedule: ' +  BossApiUtils.extractErrorMessage(error));
                    });
                  }
                );
                this.submitting = false;
            }
        } else {
            this.validateAllFormFields(this.holidayForm.get('holidaySection'));
        }
    }

    insertMessage(msg) {
        this.notificationService.updateMessage(msg);
    }

    validateAllFormFields(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    cancel() {
        if (this.popUp) {
            this.activeModal.close(false);
        } else {
            this.rightPanelcancel();
        }
    }

    getHolidayScheduleParams(isCreate) {
        const _this = this;
        const input = this.holidayForm.get('holidaySection').value;
        const items = input.holidaySchedule;
        let scheduleItems = [];
        this.schedule.name = input.name;
        this.schedule.timeZone = input.timeZone.value;
        _.forEach(items, function(value) {
          if (isCreate) {
            value._create = true;
          }
          if (value.yearly) {
            value.scheduleDate = _this.scheduleSvc.validateDateString(value.scheduleDate, 'MM/dd');
          } else {
            value.scheduleDate = _this.scheduleSvc.validateDateString(value.scheduleDate, 'MM/dd/yyyy');
          }
          scheduleItems.push(value);
        });
        scheduleItems = _.union(scheduleItems, this.deletedSchedule);
        this.schedule.holidayCreateItems = scheduleItems;
        this.schedule.errors = null;
        console.log(this.schedule);
        return this.schedule;
    }

    async onDelete() {
        console.log('Delete Schedule');
        if (!this.modalRef) {

            // makes it a modal window that cannot be dismissed by clicking outside.
            const options: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false
            };

            this.deleteConfirmDisplayFlag = true;
            this.modalRef = this.modalSvc.open(DeleteConfirmationComponent, options);
            this.modalRef.componentInstance.title = 'schedule_delete_confirmation.title';
            this.modalRef.componentInstance.content = 'schedule_delete_confirmation.content';
            this.modalRef.result.then(async (result) => {
                this.modalRef = null;
                console.log(result);
                if (result === true) {
                    await this.handleDelete();
                } else {
                    this.toOpen = true; // keep the Edit side window open
                }
                this.deleteConfirmDisplayFlag = false;
            }, (reason) => {
                // this.closeReason = this.getDismissReason(reason);
                this.modalRef = null;
                this.deleteConfirmDisplayFlag = false;
                console.log(reason);
            });
        } else {
            console.log('modalRef not se to null.');
            this.modalRef = null;
        }
    }

    async handleDelete() {
        this.clearServerErrorOnForm();
        this.displayLoadingSpinner = true;
        const scheduleName = this.schedule.name;
        await this.scheduleSvc.deleteSchedule(this.scheduleType, this.schedule.id)
        .toPromise().then(
          response => {
              response = JSON.parse(response);
            console.log('response from delete', response);
              if (!_.isEmpty(response)) {
                  this.displayLoadingSpinner = false;
                  console.error(response);
                  this.showServerError('Failed to delete holiday schedule');
                  this.parseDeleteError(response);
              } else {
                  this.displayLoadingSpinner = false;
                  this.callOnClose(true);
                  this.insertMessage('holiday.deleted_holiday');
            }
            this.deleteConfirmDisplayFlag = false;
          },
          error => {
            // if not caught here, will get an 'core.js:15714
              // ERROR Error: Uncaught (in promise)' error without a useful stack trace
            console.error('failed to delete holiday schedule ', error);
            this.displayLoadingSpinner = false;
            this.showServerError( 'Unable to delete holiday schedule: ' + error.message ? error.message : JSON.stringify(error));
          }
        );
    }

    parseServerError( message: string ) {
        const splitMsg =  message.split('Error:');

        this.errorMsg = splitMsg[1] ? splitMsg[1].split('\r\n') : [];
        if (this.errorMsg.length > 0) {
            this.errorMsg.forEach(err => {
                this.showErrorMessage(err);
            });
        }
    }

    parseDeleteError( message) {
        const msg = message.split('Error: delete - ');
        this.otherErr.push(msg[1]);
    }

    showErrorMessage( message ) {
        const msg = message.split('-');
        if (msg[0].trim() === 'ScheduleName') {
            this.scheduleNameError = msg[1].trim();
        }
        console.log(msg);
    }
    showServerError( message ) {
      this.errMsg = message;
      this.showFormError = true;
      this.scheduleNameError = '';
      this.otherErr = [];
    }

    clearServerErrorOnForm() {
        this.errMsg = '';
        this.showFormError = false;
        this.scheduleNameError = '';
    }

    openConfirmDialog() {
        if (!this.modalRef) {
            // makes it a modal window that cannot be dismissed by clicking outside.
            const options: NgbModalOptions = {
                backdrop: 'static',
                windowClass: 'confimation-box'
            };
            this.modalRef = this.modalSvc.open(NavigationConfirmationComponent, options);
            this.modalRef.result.then((result) => {
                this.modalRef = null;
                if (result === rightPanelCloseReason.LEAVE) {
                    this.callOnClose();
                }
            }, (reason) => {
                this.closeReason = this.getDismissReason(reason);
                this.modalRef = null;
            });
        } else {
            console.log('modalRef not set to null.');
            this.modalRef = null;
        }
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return rightPanelCloseReason.ESC;
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return rightPanelCloseReason.BACKDROP;
        } else {
            return reason;
        }
    }

    onClose() {
        // TODO reset
        if (this.holidayForm.dirty) {
            this.openConfirmDialog();
        } else {
            this.callOnClose();
        }
    }

    callOnClose(flowsUpdated = false) {
        this.activeModal.close(false);
        this.schedulesSvc.setClickedSchedule(null);
        this.close.emit(flowsUpdated);
    }

    rightPanelcancel() {
        this.onClose();
    }

    updateScheduleDate(event, index) {
        this.holidayForm.get('holidaySection').get('holidaySchedule')['controls'][index].controls['scheduleDate'].setValue(event);
        this.holidayForm.markAsDirty();
    }

    ngOnDestroy() {
        this.schedulesSvc.setClickedSchedule(null);
        this.clickedScheduleSubscription.unsubscribe();
    }

}
