import {
  Component, ElementRef, EventEmitter, HostListener,
  Input, OnDestroy,
  OnInit, Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {ModalTemplate} from './modal-template';
import {ModalContentDirective} from './modal-content.directive';
import {ClModalService} from '../services/cl-modal.service';
import {Subscription} from 'rxjs';
import {SuccessMessageService} from '../services/success-message.service';

@Component({
  selector: 'app-cl-modal',
  templateUrl: './cl-modal.component.html',
  styleUrls: ['./cl-modal.component.css']
})
export class ClModalComponent implements OnInit, OnDestroy {

  @Input() modalTemp: ModalTemplate;
  modalContent: ModalContentDirective;
  stillOpen: boolean;
  okBtnDisabledState = false;
  optionBtnDisabledState = false;
  okFunction: any = Function.prototype;
  rxjsSubscriptions: Subscription[] = [];
  displayLoadingSpinner = false;

  constructor(public translateSvc: TranslateService,
              public activeModal: NgbActiveModal,
              public clModalService: ClModalService,
              public successMsgService: SuccessMessageService) {
  }

  ngOnInit() {
    // init button state
    this.okBtnDisabledState = !!this.modalTemp.primaryBtn.disabled;
    this.optionBtnDisabledState = (this.modalTemp.optionalBtn && this.modalTemp.optionalBtn.disabled === true);
    this.clModalService.sendIsModalOpen(true);

    // Listen for Enable/Disable 'ok' and 'option' buttons
    this.rxjsSubscriptions.push(this.clModalService.getToggledStateForOKBtn().subscribe(state => {
      this.okBtnDisabledState = !state;
    }));
    this.rxjsSubscriptions.push(this.clModalService.getToggledStateForOptionsBtn().subscribe(state => {
      this.optionBtnDisabledState = !state;
    }));
  }

  /* istanbul ignore next */
  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.cancel();
    event.stopPropagation();
  }

  /**
   * For interactive modals (where actions such as server calls are made on click and data must be
   * returned up) the 'value' on the button set in the modal template is combined with the result
   * to be sent back. This is so we still know what button was pressed.
   */
  ok() {
    if (this.modalTemp.isInteractive) {
      this.clModalService.sendOkClick(true);
      this.displayLoadingSpinner = true;
      this.rxjsSubscriptions.push(this.clModalService.getOkAsyncActionResults().subscribe(result => {
        if (result.success === true) {
          result.value = this.modalTemp.primaryBtn.value;
          console.log('[ClModal] - met with success', result);
          this.rxjsSubscriptions.push(this.translateSvc.get('notification_messages').subscribe(message => {
            this.successMsgService.sendMessage(message.register_address, true);
          }));
          this.activeModal.close(result);
          this.sendModalOpenSignal();
        } else {
          console.log('[ClModal] - met with error', result);
        }
        this.displayLoadingSpinner = false;
        console.log('[ClModal] - display spinner set to false', this.displayLoadingSpinner);
      }));
    } else {
      this.activeModal.close(this.modalTemp.primaryBtn.value);
      this.sendModalOpenSignal();
    }
  }

  cancel() {
    const closeValue = (this.modalTemp.defaultBtn != null ? this.modalTemp.defaultBtn.value : null);
    this.activeModal.close(closeValue);
    this.sendModalOpenSignal();
  }

  optional() {
    const closeValue = (this.modalTemp.optionalBtn != null ? this.modalTemp.optionalBtn.value : null);
    this.activeModal.close(closeValue);
    this.sendModalOpenSignal();
  }

  ngOnDestroy(): void {
    this.rxjsSubscriptions.forEach( s => s.unsubscribe());
    console.log('[ClModal] - destroy modal');
  }

  private sendModalOpenSignal (): void {
    // Causes the updated to be sent after all micro tasks have been completed.
    // This allows other click handlers to read the state of the modal before it is updated.
    setTimeout(() => {
      this.clModalService.sendIsModalOpen(false);
    }, 0);
  }
}
