import {Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, OnChanges,
  OnDestroy, AfterViewChecked,  ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { FlowsService } from '../../services/flows.service';
import { trigger, style, animate, transition } from '@angular/animations';
import {Subject, Subscription} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FlowsModelComponent } from '../flows-model/flows-model.component';
import * as _ from 'lodash';
import {flowComponentCRUDActionValues, LOCATION_QUERY_FIELD, rightPanelCloseReason, TableSortingOrder} from 'src/app/shared/constants';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { CallflowAdaptorService } from '../../services/callflow-adaptor.service';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import {ClHeaderComponent} from '@mitel/cloudlink-console-components';
import { callFlowComponentTypeValues } from '../../shared/constants';
import {Location, LocationAdaptorService} from 'src/app/services/location-adaptor.service';
import {CuxTableComponent} from '../../shared/components/cux-table/cux-table.component';
import {CallFlowsService} from '../../modules/boss-api/generated/services/call-flows.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FlowVisualizerService} from '../../services/flow-visualizer.service';
import {ODataBuilderService} from '../../services/o-data-builder.service';

@Component({
  selector: 'app-flows-dashboard',
  templateUrl: './flows-dashboard.component.html',
  styleUrls: ['./flows-dashboard.component.scss'],
  animations: [
      trigger('slideInOut', [
          transition(':enter', [
              style({transform: 'translate3d(100%, 0, 0)'}),
              animate('400ms ease-in-out', style({transform: 'translate3d(0, 0, 0)'})),
          ]),
          transition(':leave', [
              animate('400ms ease-in-out', style({transform: 'translate3d(100%, 0, 0)'}))
          ])
      ])
  ]
})
export class FlowsDashboardComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {

  @Input() columns: any;
  @Input() claims: UserClaims;
  @Input() company: Account;
  @Output() rightPanelOpened: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('visualizerContainer') visualizerContainer: ElementRef;
  locations: Location[] = [];
  selectedFlows = [];
  rightPanelState = 'out';
  visualizerRightPanelState = 'out';
  toOpenRightPanel = false;
  flowSelected: string;
  accountId: string;
  clickedFlowSubscription: Subscription;
  subscriptions: Subscription[] = [];
  flows: any;

  modalRef: NgbModalRef;
  closeReason: string;

  displayLoadingSpinner = true;

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = {'max-width': '650px'};
  searchItem = '';
  storedFlows = [];
  loadedByIframe = false;

  locationDropdownLabel = 'flows_dashboard.all_locations';
  locationType = 'onboarding';
  locationValueType = 'uuid';
  selectedLocation: any = { id: 0 };
  locationFlows = [];
  searchValue = new Subject<string>();

  // p-table
  totalRecords: number;
  scrollable: boolean;
  virtualScroll: boolean;
  rows: number;
  scrollHeight: string;
  lazy: boolean;
  virtualRowHeight: number;
  loading: boolean;

  @ViewChild('cuxtable') cuxTable: CuxTableComponent;

  defaultSortField  = 'name';
  oDataSortingOrder = TableSortingOrder['asc'];
  oDataSortingColumn = 'name';
  oDataLocationFilter: string ;
  oDataSearchFilter: string;
  oDataTop: number;
  oDataSkip: number;

  constructor(public flowsSvc: FlowsService,
    public translateSvc: TranslateService,
    private modalService: NgbModal,
    public cd: ChangeDetectorRef,
    private huntgroupSvc: CallflowAdaptorService,
    private clHeader: ClHeaderComponent,
    private locSvc: LocationAdaptorService,
    private flowVizSvc: FlowVisualizerService,
    private oDataService: ODataBuilderService) {
    console.log('======Toolbar visibility==========');
    this.loadedByIframe = !clHeader.headerAuth.isToolbarVisible();
    console.log('==================================');
    this.initScrollProperties();
  }

  async ngOnInit() {
    this.subscriptions.push(this.flowsSvc.clickedFlowChanged.subscribe(flow => {
      if (flow) {
        this.rightPanelState = 'in';
        this.visualizerRightPanelState = 'in';
        this.toOpenRightPanel = true;
        this.rightPanelOpened.emit(this.toOpenRightPanel);
        if (flow.componentType === callFlowComponentTypeValues.hg) {
          this.flowSelected = 'hg';
        } else {
          this.flowSelected = 'aa';
        }
      } else {
        this.rightPanelState = 'out';
      }
    }));

    this.subscriptions.push(this.flowsSvc.componentNavigateIntent.subscribe(() => {
      if(this.visualizerRightPanelState === 'out') {
        this.visualizerRightPanelState = 'in';
        this.flowsSvc.componentNavigateAway.next();
      }
    }));

    if (this.company) {
      this.accountId = this.company.accountId;
      // await this.initLocationsList();
    }
    this.searchValue.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(searchKey => {
          /* istanbul ignore else */
          if (searchKey.length > 2 || searchKey.length === 0) {
            this.onSearch(searchKey);
          }
        });
  }

  initScrollProperties() {
    this.scrollable = true;
    this.rows = 15;
    this.scrollHeight = '450px';
    this.virtualScroll = true;
    this.lazy = true;
    this.virtualRowHeight = 36;
    this.initTopAndSkip();
  }

  initTopAndSkip() {
    this.oDataTop = this.rows * 2;
    this.oDataSkip = 0;
  }

    /**
   * Respond when Angular (re)sets data-bound input properties. The method receives a SimpleChanges object of
   * current and previous property values.  Called before ngOnInit() and whenever one or more data-bound
   * input properties change.    https://angular.io/guide/lifecycle-hooks
   *
   * @param changes
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.company && changes.company.currentValue && changes.company.currentValue.accountId !== this.accountId) {
      this.company = changes.company.currentValue;
      this.accountId = this.company.accountId;
      await this.initLocationsList();
      await this.initFlows();
    }
  }

  async initLocationsList() {
    try {
      await this.locSvc.refreshCache(this.accountId)
      .toPromise().then(
         data => {
           this.locations = _.cloneDeep(data);
         },
         error => {
           console.error('failed to retrieve locations list1', error);
           // tslint:disable-next-line: quotemark
           this.showServerError('Failed to retrieve locations.' + BossApiUtils.extractErrorMessage(error));
         }
       );
    } catch (reason) {
      console.error('failed to retrieve locations list2', reason);
      this.showServerError( 'Failed to retrieve locations.' + BossApiUtils.extractErrorMessage(reason));
    }
  }
  isTabbable() {
    // return this.rightPanelState === 'out';
    return  this.visualizerRightPanelState === 'out';
  }

  onAddFlow() {
    console.log('click add flow');
    if (this.modalRef) {
      console.log('modalRef not se to null.');
      this.modalRef = null;
      return;
    }
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        windowClass : 'add-flow'
      };
      this.modalRef = this.modalService.open(FlowsModelComponent, options);
      this.modalRef.result.then((result) => {
          console.log('--> result from popup', result);
          this.rightPanelState = 'in';
          this.visualizerRightPanelState = 'in';
          this.toOpenRightPanel = true;
          this.rightPanelOpened.emit(this.toOpenRightPanel);
          this.flowSelected = result;
        this.modalRef = null;

      }, (reason) => {
        this.closeReason = this.getDismissReason(reason);
        this.modalRef = null;
      });
  }

  async loadChunckData(event) {
    // Lets not fetch for already loaded data
    if (this.oDataTop === event.rows && this.oDataSkip === event.first &&
        this.oDataSortingColumn === event.sortField && this.oDataSortingOrder === event.sortOrder) {
      console.log('Discarding fetch for same query');
      return;
    }

    this.oDataTop = event.rows;
    this.oDataSkip = event.first;

    // If its a new sorting, resetScroll scroll to beginning
    /* istanbul ignore else */
    if (this.oDataSortingColumn !== event.sortField  || this.oDataSortingOrder !== event.sortOrder) {
      /* istanbul ignore else */
      if (this.cuxTable) {
        this.cuxTable.resetScrollTop();
      }
    }

    this.oDataSortingColumn = event.sortField;
    this.oDataSortingOrder = event.sortOrder;

    await this.initFlows();
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return rightPanelCloseReason.ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return rightPanelCloseReason.BACKDROP;
    } else {
      return  reason;
    }
  }

  onSelectedFlowsChanged(event) {
    const flow = this.flows.find(item => item.id === event.data.id);
    this.selectedFlows = flow;
    this.onClickFlow(flow);
  }

  onClickFlow(flow) {
    this.flowsSvc.setClickedFlow(flow);
    this.flowVizSvc.setSelectedFlow(flow, flowComponentCRUDActionValues.read);
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  async initFlows() {
    this.clearServerError();
    if (!!this.oDataTop) {
      try {
        const params = this.buildODataQuery();
        this.displayLoadingSpinner = true;
        await this.huntgroupSvc.getPagedFlows(params).toPromise()
            .then(pagedData => {
                  this.flows = pagedData.flows;
                  this.totalRecords = pagedData.count;
                },
                error => {
              console.error('failed to retrieve flows', error);
              this.displayLoadingSpinner = false;
              this.showServerError( 'Failed to retrieve flows. ' + BossApiUtils.extractErrorMessage(error));
            });
        } catch (error) {
        console.error('failed to retrieve flows', error);
        this.displayLoadingSpinner = false;
        this.showServerError( 'Failed to retrieve flows. ' + BossApiUtils.extractErrorMessage(error));
      } finally {
        this.displayLoadingSpinner = false;
      }
    } else {
      console.error('Verify the OData properties!');
    }
  }

  buildODataQuery() {
    const params: CallFlowsService.CallFlowsGetCallFlowsByAccountPagedParams = {
      Authorization: null,
      Top: this.oDataTop,
      Skip: this.oDataSkip,
      Filter: this.getFilter(),
      OrderBy: this.getOrderby(),
      Count: true
    };
    return params;
  }

  getFilter() {
    if (!!this.oDataLocationFilter && !!this.oDataSearchFilter) {
      return this.oDataLocationFilter + ' And ( ' + this.oDataSearchFilter + ' )';
    }
    if (!!this.oDataLocationFilter) {
      return this.oDataLocationFilter;
    } else if (!!this.oDataSearchFilter) {
      return this.oDataSearchFilter;
    }
    return null;
  }

  getOrderby() {
    if (!!this.oDataSortingColumn) {
      const column = this.columns.find(item => item.field === this.oDataSortingColumn);
      const sortOrder = TableSortingOrder[this.oDataSortingOrder];
      return column.sortBy + ' ' + sortOrder;
    } else {
      return null;
    }
  }

  async onCloseRightPanel(flowsUpdated: boolean) {
    this.rightPanelState = 'out';
    this.toOpenRightPanel = false;
    this.rightPanelOpened.emit(this.toOpenRightPanel);
    if (flowsUpdated) {
      await this.initFlows();
    }
  }

  onCollapseRightPanel() {
    this.visualizerRightPanelState = 'out';
  }

  onLocationChange(event) {
    const locId = event.name;
    if (locId !== 'all') {
      this.oDataLocationFilter = this.oDataService.buildEQFilter(LOCATION_QUERY_FIELD, locId);
    } else {
      this.oDataLocationFilter = '';
    }
    this.refreshPage();
  }

  refreshPage() {
    this.cuxTable.resetScrollTop();
    this.resetTopAndSkip();
    this.initFlows();
  }

  resetTopAndSkip() {
    this.oDataTop = this.rows * 2;
    this.oDataSkip = 0;
  }

  onSearch(searchKey) {
    const fields = this.columns.map(item => item.queryFields).flat();
    this.oDataSearchFilter = this.oDataService.buildSearchQuery(fields, searchKey);
    this.refreshPage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  showServerError( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerError() {
    this.errMsg = '';
    this.showFormError = false;
  }

  onBackToFlows($event) {
    if (this.visualizerRightPanelState === 'out') {
      this.onCloseRightPanel(true);
    } else {
      this.flowsSvc.rightPanelCloseInitiated.next();
    }
    $event.stopImmediatePropagation();
  }
}
