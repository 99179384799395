import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-emergency-registration-dialog',
  templateUrl: './reset-emergency-registration-dialog.component.html',
  styleUrls: ['./reset-emergency-registration-dialog.component.css']
})
export class ResetEmergencyRegistrationDialogComponent {

}
