<div class="container">
    <div class="row">
        <div class="col-md-12 title">
            {{ title | translate }}
            <button id="delete-confirm-close" type="button" class="close closeX" aria-label="Close" (click)="cancel()" aria-hidden="true" >
                &#10005;
            </button>
        </div>
    </div>
    <hr>
    <div class="content">
        <!--<span [innerHTML]="'delete_confirmation.content' | translate"></span>-->
        <span>{{ content | translate }}</span>
    </div>
    <!-- <hr> -->
    <div class="row">
        <div class="col-md-12 actions">
            <button id="delete-confirm-cancel" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: 83)}" type="button" class="btn btn-default" (click)="cancel()">{{ cancelText | translate }}</button>
            <button id="delete-confirm-delete" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: 76)}" type="button" class="btn btn-primary" (click)="delete()">{{ deleteText | translate }}</button>
        </div>
    </div>
</div>
