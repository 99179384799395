/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PromptCollection } from '../models/prompt-collection';
import { Prompt } from '../models/prompt';
import { PostPromptRequest } from '../models/post-prompt-request';

/**
 * Recorded prompts for playing back on a media stream
 */
@Injectable({
  providedIn: 'root',
})
class PromptsService extends __BaseService {
  static readonly getPromptsPath = '/prompts';
  static readonly createPromptPath = '/prompts';
  static readonly getPromptPath = '/prompts/{promptId}';
  static readonly updatePromptPath = '/prompts/{promptId}';
  static readonly deletePromptPath = '/prompts/{promptId}';
  static readonly getPromptMetaPath = '/prompts/{promptId}/meta';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves prompts from the system.  Prompts that you don't have permission to view will not be returned.
   * @param params The `PromptsService.GetPromptsParams` containing the following parameters:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$orderby`: Odata filter param
   *
   * - `$filter`: Odata filter param
   *
   * @return Ok
   */
  getPromptsResponse(params: PromptsService.GetPromptsParams): __Observable<__StrictHttpResponse<PromptCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Orderby != null) __params = __params.set('$orderby', params.Orderby.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/prompts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromptCollection>;
      })
    );
  }
  /**
   * Retrieves prompts from the system.  Prompts that you don't have permission to view will not be returned.
   * @param params The `PromptsService.GetPromptsParams` containing the following parameters:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$orderby`: Odata filter param
   *
   * - `$filter`: Odata filter param
   *
   * @return Ok
   */
  getPrompts(params: PromptsService.GetPromptsParams): __Observable<PromptCollection> {
    return this.getPromptsResponse(params).pipe(
      __map(_r => _r.body as PromptCollection)
    );
  }

  /**
   * Creates meta data for the prompt.  Prompt meta must be created before the media can be uploaded.
   * @param body undefined
   * @return Created
   */
  createPromptResponse(body: PostPromptRequest): __Observable<__StrictHttpResponse<Prompt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/prompts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Prompt>;
      })
    );
  }
  /**
   * Creates meta data for the prompt.  Prompt meta must be created before the media can be uploaded.
   * @param body undefined
   * @return Created
   */
  createPrompt(body: PostPromptRequest): __Observable<Prompt> {
    return this.createPromptResponse(body).pipe(
      __map(_r => _r.body as Prompt)
    );
  }

  /**
   * Retrieves prompt media.
   * @param promptId ID of the prompt
   */
  getPromptResponse(promptId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/prompts/${promptId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Retrieves prompt media.
   * @param promptId ID of the prompt
   */
  getPrompt(promptId: string): __Observable<null> {
    return this.getPromptResponse(promptId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update prompt media.  Returns an error if the prompt meta hasn't been created.
   * @param params The `PromptsService.UpdatePromptParams` containing the following parameters:
   *
   * - `promptId`: ID of the prompt
   *
   * - `body`: Binary data of the prompt
   */
  updatePromptResponse(params: PromptsService.UpdatePromptParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/prompts/${params.promptId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update prompt media.  Returns an error if the prompt meta hasn't been created.
   * @param params The `PromptsService.UpdatePromptParams` containing the following parameters:
   *
   * - `promptId`: ID of the prompt
   *
   * - `body`: Binary data of the prompt
   */
  updatePrompt(params: PromptsService.UpdatePromptParams): __Observable<null> {
    return this.updatePromptResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Removes the prompt from the system.
   * @param promptId ID of the prompt
   */
  deletePromptResponse(promptId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/prompts/${promptId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes the prompt from the system.
   * @param promptId ID of the prompt
   */
  deletePrompt(promptId: string): __Observable<null> {
    return this.deletePromptResponse(promptId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Retrieves meta data about the prompt.
   * @param promptId ID of the prompt
   * @return Prompt metadata
   */
  getPromptMetaResponse(promptId: string): __Observable<__StrictHttpResponse<Prompt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/prompts/${promptId}/meta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Prompt>;
      })
    );
  }
  /**
   * Retrieves meta data about the prompt.
   * @param promptId ID of the prompt
   * @return Prompt metadata
   */
  getPromptMeta(promptId: string): __Observable<Prompt> {
    return this.getPromptMetaResponse(promptId).pipe(
      __map(_r => _r.body as Prompt)
    );
  }
}

module PromptsService {

  /**
   * Parameters for getPrompts
   */
  export interface GetPromptsParams {

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Odata filter param
     */
    Orderby?: string;

    /**
     * Odata filter param
     */
    Filter?: string;
  }

  /**
   * Parameters for updatePrompt
   */
  export interface UpdatePromptParams {

    /**
     * ID of the prompt
     */
    promptId: string;

    /**
     * Binary data of the prompt
     */
    body: string;
  }
}

export { PromptsService }
