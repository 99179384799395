import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CallFlowsService as BossApiCallFlowsService} from '../modules/boss-api/generated/services';
import {
    HolidayScheduleItemDC,
    ScheduleDC,
    CustomScheduleItemDC,
    OnHoursScheduleItemDC, CallFlowDC,
} from '../modules/boss-api/generated/models';
import {mergeMap, map} from 'rxjs/operators';
import {formatDate} from '@angular/common';

export class Schedule {
    scheduleItems: Array<ScheduleItem>;
    onHoursCreateItems: Array<OnHoursScheduleItem>;
    holidayCreateItems: Array<HolidayScheduleItem>;
    customCreateItems: Array<CustomScheduleItem>;
    id: string;
    name: string;
    timeTypeId: number;
    timeZone: string;
    timeZones: Array<any>;
    errors: string;
}

export class ScheduleList {
    id: number;
    name: string;
    timeTypeId: number;
    timeType: string;
}

export class ScheduleItem {

    id: string;
    name: string;
    dayOfWeek: number;
    startTime: string;
    stopTime: string;
    scheduleDate: string;
    uniqueId: string;
    _destroy: number;
}

export class OnHoursScheduleItem {

    id: string;
    name: string;
    dayOfWeek: number;
    startTime: string;
    stopTime: string;
    scheduleDate: string;
    uniqueId: string;
    _create: boolean;
    _destroy: number;
}

export class HolidayScheduleItem {
    id: string;
    scheduleName: string;
    yearly: boolean;
    scheduleDate: string;
    uniqueId: string;
    _create: boolean;
    _destroy: number;
}

export class CustomScheduleItem {
    id: string;
    name: string;
    yearly: boolean;
    scheduleDate: string;
    startTime: string;
    stopTime: string;
    twoDigitHourFormat: boolean;
    showMeridian: boolean;
    uniqueId: string;
    _create: boolean;
    _destroy: number;
}

export class PagedSchedules {
    schedules: Schedule[];
    count: number;
    constructor(schedules, count) {
        this.schedules = schedules;
        this.count = count;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ScheduleAdaptorService {

    private readonly authToken = null;  // empty so that our  auth interceptor will handle this. Else should be 'Bearer <token>'

    static makeTimeZone(timezone: string): any {
        return {
            value: timezone,
            displayName: timezone,
        };
    }

    static makeTimeZoneDC(timeZone: any): string {
        return timeZone.displayName;
    }

    static makeHolidayScheduleItemDC(scheduleItem: HolidayScheduleItem): HolidayScheduleItemDC {
        return {
            id: scheduleItem.id,
            name: scheduleItem.scheduleName,
            yearly: scheduleItem.yearly,
            scheduleDate: scheduleItem.scheduleDate,
            uniqueId: scheduleItem.uniqueId,
            _create: scheduleItem._create,
            _destroy: scheduleItem._destroy,
        };
    }

    static makeCustomScheduleItemDC(scheduleItem: CustomScheduleItem): CustomScheduleItemDC {
        return {
            id: scheduleItem.id,
            name: scheduleItem.name,
            yearly: scheduleItem.yearly,
            startTime: scheduleItem.startTime,
            stopTime: scheduleItem.stopTime,
            scheduleDate: scheduleItem.scheduleDate,
            uniqueId: scheduleItem.uniqueId,
            _create: scheduleItem._create,
            _destroy: scheduleItem._destroy,
        };
    }

    static makeHoursScheduleItemDc(scheduleItem: OnHoursScheduleItem): OnHoursScheduleItemDC {
        return {
            id: scheduleItem.id,
            startTime: scheduleItem.startTime,
            stopTime: scheduleItem.stopTime,
            dayOfWeek: scheduleItem.dayOfWeek,
            uniqueId: scheduleItem.uniqueId,
            _create: scheduleItem._create,
            _destroy: scheduleItem._destroy,
        };
    }

    static makeIndividualSchedule(schedule: CallFlowDC): ScheduleList {
        return {
            id: schedule.id,
            name: schedule.name.toUpperCase(),
            timeTypeId: schedule.timeTypeId,
            timeType: schedule.componentType
        };
    }
    constructor(private bossCallFlowsService: BossApiCallFlowsService) {
    }

    getPagedSchedules(params: BossApiCallFlowsService.CallFlowsGetSchedulesByAccountPagedParams): Observable<PagedSchedules> {

        return this.bossCallFlowsService.CallFlowsGetSchedulesByAccountPaged(params)
            .pipe(
                mergeMap(async pageResults => {
                    const tempSchedules = await this.makeScheduleList(pageResults.items);
                    const pagedSchedules = new PagedSchedules(tempSchedules, pageResults.count);
                    return pagedSchedules;
                }));
    }

    getSchedules(): Observable<ScheduleList[]> {

        return this.bossCallFlowsService.CallFlowsGetSchedulesByAccount(this.authToken)
            .pipe(
                // mergeMap
                // wait for promise to resolve, then return resolved result. In this case, flows. See  https://stackoverflow.com/a/53650135
                mergeMap(schedules => {

                    return this.makeScheduleList(schedules); // returns a Promise
                }));
    }

    getNewSchedule(scheduleType: number): Observable<Schedule> {
        return this.bossCallFlowsService.CallFlowsGetDefaultScheduleSettings(
            {TimeTypeId: scheduleType, Authorization: this.authToken})
            .pipe(
                mergeMap(schedule => {
                    return this.makeSchedule(schedule);
                })
            );
    }

    getSchedule(scheduleType: number, scheduleId: number): Observable<Schedule> {
        return this.bossCallFlowsService.CallFlowsGetScheduleById(
            {
                TimeTypeId: scheduleType,
                ScheduleId: scheduleId,
                Authorization: this.authToken
            })
            .pipe(
                mergeMap(schedule => {
                    return this.makeSchedule(schedule);
                })
            );
    }

    public updateHolidaySchedule(id: number, timeTypeId: number, holidayData: Schedule): Observable<Schedule> {

        const timeZones: Array<string> = [];
        holidayData.timeZones.forEach(timeZone => {
            timeZones.push(ScheduleAdaptorService.makeTimeZoneDC(timeZone));
        });

        const holidaySchedules: Array<HolidayScheduleItemDC> = [];
        holidayData.holidayCreateItems.forEach(items => {
            holidaySchedules.push(ScheduleAdaptorService.makeHolidayScheduleItemDC(items));
        });

        const updateScheduleDC: ScheduleDC = {
            scheduleItems: [],
            onHoursCreateItems: null,
            holidayCreateItems: holidaySchedules,
            customCreateItems: null,
            id: holidayData.id,
            name: holidayData.name,
            timeTypeId: timeTypeId,
            timeZone: holidayData.timeZone,
            timeZones: timeZones,
            errors: holidayData.errors,
        };
        return this.bossCallFlowsService.CallFlowsUpdateSchedule(
            {
                scheduleDC: updateScheduleDC,
                TimeTypeId: timeTypeId,
                ScheduleId: id,
                Authorization: this.authToken
            })
            .pipe(
                // convert to holiday schedule object
                mergeMap(updateScheduleResponse => {
                    return this.makeSchedule(updateScheduleResponse)
                        .then(schedule => {
                            return Promise.resolve(schedule);
                        });
                })
            );
    }

    public createHolidaySchedule(timeTypeId: number, holidayData: Schedule): Observable<Schedule> {

        const timeZones: Array<string> = [];
        holidayData.timeZones.forEach(timeZone => {
            timeZones.push(ScheduleAdaptorService.makeTimeZoneDC(timeZone));
        });

        const holidaySchedules: Array<HolidayScheduleItemDC> = [];
        holidayData.holidayCreateItems.forEach(items => {
            holidaySchedules.push(ScheduleAdaptorService.makeHolidayScheduleItemDC(items));
        });

        const createScheduleDC: ScheduleDC = {
            scheduleItems: [],
            onHoursCreateItems: null,
            holidayCreateItems: holidaySchedules,
            customCreateItems: null,
            id: holidayData.id,
            name: holidayData.name,
            timeTypeId: timeTypeId,
            timeZone: holidayData.timeZone,
            timeZones: timeZones,
            errors: holidayData.errors,
        };
        return this.bossCallFlowsService.CallFlowsCreateSchedule(
            {scheduleDC: createScheduleDC, TimeTypeId: timeTypeId, Authorization: this.authToken})
            .pipe(
                // convert to holiday schedule object
                mergeMap(createScheduleResponse => {
                    return this.makeSchedule(createScheduleResponse)
                        .then(schedule => {
                            return Promise.resolve(schedule);
                        });
                })
            );
    }

    public updateCustomSchedule(id: number, timeTypeId: number, customData: Schedule): Observable<Schedule> {

        const timeZones: Array<string> = [];
        customData.timeZones.forEach(timeZone => {
            timeZones.push(ScheduleAdaptorService.makeTimeZoneDC(timeZone));
        });

        const customSchedules: Array<CustomScheduleItemDC> = [];
        customData.customCreateItems.forEach(items => {
            customSchedules.push(ScheduleAdaptorService.makeCustomScheduleItemDC(items));
        });

        const updateScheduleDC: ScheduleDC = {
            scheduleItems: [],
            onHoursCreateItems: null,
            holidayCreateItems: null,
            customCreateItems: customSchedules,
            id: customData.id,
            name: customData.name,
            timeTypeId: timeTypeId,
            timeZone: customData.timeZone,
            timeZones: timeZones,
            errors: customData.errors,
        };
        return this.bossCallFlowsService.CallFlowsUpdateSchedule(
            {
                scheduleDC: updateScheduleDC,
                TimeTypeId: timeTypeId,
                ScheduleId: id,
                Authorization: this.authToken
            })
            .pipe(
                // convert to holiday schedule object
                mergeMap(updateScheduleResponse => {
                    return this.makeSchedule(updateScheduleResponse)
                        .then(schedule => {
                            return Promise.resolve(schedule);
                        });
                })
            );
    }

    public createCustomSchedule(timeTypeId: number, customData: Schedule): Observable<Schedule> {

        const timeZones: Array<string> = [];
        customData.timeZones.forEach(timeZone => {
            timeZones.push(ScheduleAdaptorService.makeTimeZoneDC(timeZone));
        });

        const customSchedules: Array<CustomScheduleItemDC> = [];
        customData.customCreateItems.forEach(items => {
            customSchedules.push(ScheduleAdaptorService.makeCustomScheduleItemDC(items));
        });

        const createScheduleDC: ScheduleDC = {
            scheduleItems: [],
            onHoursCreateItems: null,
            holidayCreateItems: null,
            customCreateItems: customSchedules,
            id: customData.id,
            name: customData.name,
            timeTypeId: timeTypeId,
            timeZone: customData.timeZone,
            timeZones: timeZones,
            errors: customData.errors,
        };
        return this.bossCallFlowsService.CallFlowsCreateSchedule(
            {scheduleDC: createScheduleDC, TimeTypeId: timeTypeId, Authorization: this.authToken})
            .pipe(
                // convert to holiday schedule object
                mergeMap(createScheduleResponse => {
                    return this.makeSchedule(createScheduleResponse)
                        .then(schedule => {
                            return Promise.resolve(schedule);
                        });
                })
            );
    }

    public updateOnHoursSchedule(id: number, timeTypeId: number, hoursData: Schedule): Observable<Schedule> {

        const timeZones: Array<string> = [];
        hoursData.timeZones.forEach(timeZone => {
            timeZones.push(ScheduleAdaptorService.makeTimeZoneDC(timeZone));
        });

        const hoursSchedules: Array<OnHoursScheduleItemDC> = [];
        hoursData.onHoursCreateItems.forEach(items => {
            hoursSchedules.push(ScheduleAdaptorService.makeHoursScheduleItemDc(items));
        });

        const updateScheduleDC: ScheduleDC = {
            scheduleItems: [],
            onHoursCreateItems: hoursSchedules,
            holidayCreateItems: null,
            customCreateItems: null,
            id: hoursData.id,
            name: hoursData.name,
            timeTypeId: timeTypeId,
            timeZone: hoursData.timeZone,
            timeZones: timeZones,
            errors: hoursData.errors,
        };
        return this.bossCallFlowsService.CallFlowsUpdateSchedule(
            {
                scheduleDC: updateScheduleDC,
                TimeTypeId: timeTypeId,
                ScheduleId: id,
                Authorization: this.authToken
            })
            .pipe(
                // convert to holiday schedule object
                mergeMap(updateScheduleResponse => {
                    return this.makeSchedule(updateScheduleResponse)
                        .then(schedule => {
                            return Promise.resolve(schedule);
                        });
                })
            );
    }

    public createOnHourschedule(timeTypeId: number, hoursData: Schedule): Observable<Schedule> {

        const timeZones: Array<string> = [];
        hoursData.timeZones.forEach(timeZone => {
            timeZones.push(ScheduleAdaptorService.makeTimeZoneDC(timeZone));
        });

        const hoursSchedules: Array<OnHoursScheduleItemDC> = [];
        hoursData.onHoursCreateItems.forEach(items => {
            hoursSchedules.push(ScheduleAdaptorService.makeHoursScheduleItemDc(items));
        });

        const createScheduleDC: ScheduleDC = {
            scheduleItems: [],
            onHoursCreateItems: hoursSchedules,
            holidayCreateItems: null,
            customCreateItems: null,
            id: hoursData.id,
            name: hoursData.name,
            timeTypeId: timeTypeId,
            timeZone: hoursData.timeZone,
            timeZones: timeZones,
            errors: hoursData.errors,
        };
        return this.bossCallFlowsService.CallFlowsCreateSchedule(
            {scheduleDC: createScheduleDC, TimeTypeId: timeTypeId, Authorization: this.authToken})
            .pipe(
                // convert to holiday schedule object
                mergeMap(createScheduleResponse => {
                    return this.makeSchedule(createScheduleResponse)
                        .then(schedule => {
                            return Promise.resolve(schedule);
                        });
                })
            );
    }

    public deleteSchedule(scheduleType: number, scheduleId: number): Observable<string> {
        return this.bossCallFlowsService.CallFlowsDeleteSchedule(
            {TimeTypeId: scheduleType, ScheduleId: scheduleId, Authorization: this.authToken})
            .pipe(
                map(apiResponse => {
                    return apiResponse;
                })
            );
    }

    private makeSchedule(scheduleDC: ScheduleDC): Promise<Schedule> {

        const timezones: Array<string> = [];
        scheduleDC.timeZones.forEach(timezone => {
            timezones.push(ScheduleAdaptorService.makeTimeZone(timezone));
        });

        const schedule = {
            scheduleItems: _.cloneDeep(scheduleDC.scheduleItems),
            onHoursCreateItems: null,
            holidayCreateItems: null,
            customCreateItems: null,
            id: scheduleDC.id,
            name: scheduleDC.name,
            timeTypeId: scheduleDC.timeTypeId,
            timeZone: scheduleDC.timeZone,
            timeZones: timezones,
            errors: scheduleDC.errors
        };
        return Promise.resolve(schedule);
    }

    private makeScheduleList(scheduleLists: CallFlowDC[]): Promise<ScheduleList[]> {

        const schedules: ScheduleList[] = [];
        scheduleLists.forEach(schedule => {
            schedules.push(ScheduleAdaptorService.makeIndividualSchedule(schedule));
        });
        return Promise.resolve(schedules);
    }

    validateDateString(date, format) {
        if (_.isString(date)) {
            return date;
        } else {
            return formatDate(date, format, 'en-US');
        }
    }
}
