import { duration, flowComponentCRUDActionValues, callFlowComponentTypeValues, newComponentId } from '../../shared/constants';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import * as d3 from 'd3';
import * as d3Hierarchy from 'd3-hierarchy';
import { FlowVisualizerService } from 'src/app/services/flow-visualizer.service';
import { FlowsService } from 'src/app/services/flows.service';
import { HierarchyDatum, TreeDataModel } from '../../models/flow-model';
import { Subscription, of } from 'rxjs';
import * as _ from 'lodash';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'flow-visualizer',
  templateUrl: './flow-visualizer.component.html',
  styleUrls: ['./flow-visualizer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class FlowVisualizerComponent implements OnInit, OnDestroy {
  @ViewChild('svgElem') svgElem: ElementRef;
  @Input() fullScreenView: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter();

  public margin = { top: 20, right: 90, bottom: 30, left: 90 };
  public width = 1500 - this.margin.left - this.margin.right;
  public height;
  
  private i = 0;
  private root: d3Hierarchy.HierarchyNode<HierarchyDatum>;
  private rootTree: HierarchyDatum;
  private treemap;
  private svg;
  private svgGroup;
  private zoomListener;
  private nodeSpacing = {
    verticalGap: 100,
    horizontalGap: 350
  };
  private x;
  private y;
  private totalDepth = 0;
  private totalHeight = 0;
  private nodeState = {
    expand: '+',
    collapse: '-'
  };
  private subscriptions: Subscription[] = [];
  private currentSelectedNodePositions: {
    x: number,
    y: number;
  };
  private currentNode: any;
  private navigateToNode: any = {};
  
  constructor(private flowVizSvc: FlowVisualizerService,
    private flowsSvc: FlowsService) { }

  ngOnInit() {
    this.subscriptions.push(this.flowVizSvc.getFlowComponent
      .subscribe((treeData: TreeDataModel) => {
      /* istanbul ignore else */
      if (treeData) {
        if (treeData.action === flowComponentCRUDActionValues.read || treeData.action === flowComponentCRUDActionValues.create) {          
          this.initVisualization(treeData.tree);
          this.rootTree = _.cloneDeep(treeData.tree);
          this.updateVisualization(this.root);
          this.centerNode(this.root, 1);
        } else if (treeData.action === flowComponentCRUDActionValues.update) {
          this.updateNode(treeData.tree);
        }
        else if (treeData.action === flowComponentCRUDActionValues.delete) {
          this.deleteNode(treeData.tree.id);
        }
      }
    }, (err) => {
      console.error(`There was an error while fetching the visualization: ${err}`);
    }));
    this.subscriptions.push(this.flowsSvc.componentNavigateAway.subscribe(() => {
      let {d, i, n} = this.navigateToNode;      
      if(d){
        /* istanbul ignore if */
        if(n && i){
          d3.selectAll(n).classed('selected-node', false);
          d3.select(n[i]).classed('selected-node', true);
        }
        this.flowVizSvc.updateFlowForm({...d.data, canDelete: true});
        this.centerNode(d, 1);
        this.currentNode = d;
        this.updateVisualization(d);
      }
      this.navigateToNode = {};
    }));

  }

  ngOnDestroy(): void {
    d3.select(this.svgElem.nativeElement).remove();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.flowsSvc.setClickedFlow(null);
    this.flowVizSvc.setSelectedFlow(null, null);
  }

  //SECTION:  SETUP TREE WITH DATA 
  private initVisualization(rootTree: HierarchyDatum) {
    d3.selectAll("svg > *").remove();
    this.root = this.getD3Tree(rootTree);
    this.height = this.svgElem.nativeElement.parentElement.parentElement.offsetHeight - 20;
    this.treemap = d3.tree()
      .size([this.height, this.width])
      .nodeSize([50, 240])
      .separation(function separation(a, b) {
        return a.parent == b.parent ? 3 : 4;
      });

    this.root['x0'] = this.height / 2;
    this.root['y0'] = 10;

    this.svg = d3.select(this.svgElem.nativeElement)
      .attr('width', this.width + this.margin.right + this.margin.left)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('click', /* istanbul ignore next */() => {
        d3.event.stopPropagation();
      });

    this.svgGroup = this.svg
      .append('g');

    // define the this.zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
    // d3.zoom adds pan behavior in addition to zoom. See https://github.com/d3/d3-zoom for more details
    this.zoomListener = d3.zoom()
      .scaleExtent([1 / 2, 4])
      .on('start', this.zoomStart)
      .on('zoom', this.zoomInprogress)
      .on('end', this.zoomEnd);

    this.svg.call(this.zoomListener);
  }

  private updateNode(tree: HierarchyDatum) {
    /* istanbul ignore else */
    if (this.currentNode.data.duplicate && tree.duplicate && (!this.currentNode.children || this.currentNode.children && !this.currentNode.children.length)) {
      tree.children = [];
    }
    // D2 API returns "Incoming Call" for connection text when a node is edited.
    // This block ensures that we retain the original connection text
     /* istanbul ignore if */
    if(this.currentNode.parent.data.id !== null && tree.connection_text[0] === 'Incoming call'){ 
      tree.connection_text = this.currentNode.data.connection_text;
    }
    this.currentNode.data = tree;
    this.updateRoot(this.rootTree, tree);
    this.root = this.getD3Tree(this.rootTree);
    this.updateVisualization(this.currentNode);
    // this.centerNode(this.currentNode, 1);
  }

  private deleteNode(dnid: number) {
    let parentNode = this.currentNode.parent;
    if (parentNode.name !== 'Start' && parentNode.data.id !== null) {
      // parentNode.children = _.filter(parentNode.children, (child) => {
      //   return child.data.id !== this.currentNode.data.id;
      // });
      // parentNode.data.children = _.filter(parentNode.data.children, (child) => {
      //   return child.id !== this.currentNode.data.id;
      // });
      let filterChildren = /* istanbul ignore next */(child) => {
        return child.id !== this.currentNode.data.id;
      };

      let filterDataChildren = /* istanbul ignore next */ (child) => {        
          return child.data.id !== this.currentNode.data.id;            
      };

      parentNode.children = _.filter(parentNode.children, filterDataChildren);
      parentNode.data.children = _.filter(parentNode.data.children, filterChildren);

      parentNode.children = (parentNode.children.length === 0) && null;
      this.currentNode = parentNode;
      this.updateVisualization(this.currentNode);
      this.centerNode(this.currentNode, 1);      
    }
    else {
      this.close.emit(true);
    }
  }

  private updateVisualization(source) {
    // Assigns the x and y position for the nodes
    const treeData = this.treemap(this.root);
    const nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach((d, i, n) => {
      this.totalDepth = (d.depth > this.totalDepth) ? d.depth : this.totalDepth;
      d.y = d.depth * this.nodeSpacing.horizontalGap;
      if (source === this.root && i === 1) {
        this.currentNode = d;
      }
    });

    this.drawLinks(nodes, links, source);
    this.drawNodes(nodes, source);
  }

  //SECTION: ADD STRUCTURE TO THE TREE WITH NODES AND LINKS
  private drawNodes(nodes, source) {
    // ****************** Nodes section ***************************

    // Update the nodes...
    const node: any = this.svgGroup.selectAll('g.node')
      .data(nodes, d => d['id'] || (d['id'] = ++this.i));

    // Enter any new modes at the parent's previous position.
    const nodeEnter = node.enter().append('g')
      .attr('class', 'node')
      .style('cursor', function (d) {
        let cType = d.data.componentType;
        return ((d.children || d._children || d.data.childless == 'true') && (cType === callFlowComponentTypeValues.hg || cType === callFlowComponentTypeValues.aa)) ? 'pointer' : 'default';
      })
      .attr('transform', function (d) {
        return 'translate(' + source.y0 + ',' + source.x0 + ')';
      })
      .on('click', this.click);


    // Add labels for the nodes
    nodeEnter.append("foreignObject")
      .attr("class", "node-template")
      .attr("dy", ".35em")
      .attr("width", d => {
        let nameLength = d.data.name.length;
        let _width = ``;
        if (nameLength < 15) {
          _width = `10em`;
        }
        else {
          _width = `15em`;
        }
        // else if(nameLength >= 15 && nameLength < 30) {
        //   _width =`${nameLength / 2}em`;
        // }        
        // else if(nameLength >= 30 && nameLength < 45) {
        //   _width =`${nameLength / 2}em`;
        // } 
        // else {
        //   _width =`${nameLength / 4}em`;
        // }
        return _width;
      })
      // .attr("width", d => (d.data.name.length > 30) ? `${d.data.name.length / 3}em` : '15em'
      // )
      .attr("height", d => (d.data.name.length > 30) ? 200 : 150)
      .attr("x", function (d) {
        let cType = d.data.componentType;
        return cType === callFlowComponentTypeValues.hg || cType === callFlowComponentTypeValues.aa || cType === callFlowComponentTypeValues.start ? -65 : 0;
      })
      .attr('y', function (d) {
        let cType = d.data.componentType;
        return cType === callFlowComponentTypeValues.hg || cType === callFlowComponentTypeValues.aa || cType === callFlowComponentTypeValues.start ? -25 : -15;
      })
      .append('xhtml:body')
      .html((d) => {
        let className = '';
        let parentClassName = '';
        let duplicateNodeClass = '';
        let tnClassName = '';        
        if (d.data.duplicate && (!d.children || d.children && !d.children.length) && (d.data.componentType === callFlowComponentTypeValues.hg || d.data.componentType === callFlowComponentTypeValues.aa)) {
          duplicateNodeClass = 'duplicate-node';
        }
        if (d.data.componentType === callFlowComponentTypeValues.hg) {
          className = `hg-icon node-icon`;
          parentClassName += ' clickable';
        } else if (d.data.componentType === callFlowComponentTypeValues.aa) {
          className = `aa-icon node-icon`;
          parentClassName += ' clickable';
        } else /* istanbul ignore next */ if (d.data.componentType === callFlowComponentTypeValues.start) {
          className = 'start-icon node-icon';
        } else {
          className = 'no-icon';
        }
        /* istanbul ignore next */
        tnClassName = (d.data.tn && d.data.tn.length) ? 'node-subtext' : 'hidden';
        d.data.subText = (d.data.subText) ? `x${d.data.subText }` : '';
        return `<span class="${parentClassName}"><div class="node-icon-group"><div class="${className}"></div><div class="${duplicateNodeClass}"></div></div><div class="node-label"> ${this.flowVizSvc.toTitleCase(d.data.name)}</div><div class="${tnClassName}"> ${d.data.tn || ''}</div><div class="node-subtext"> ${d.data.subText}</div></span>`;
      });

    // UPDATE
    const nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate.transition()
      .duration(duration)
      .attr('transform', function (d) {
        return 'translate(' + d.y + ',' + d.x + ')';
      });

    // Remove any exiting nodes
    const nodeExit = node.exit().transition()
      .duration(duration)        
      .attr('transform', /* istanbul ignore next */ function (d) {
        return 'translate(' + source.y + ',' + source.x + ')';
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('circle')
      .attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text')
      .style('fill-opacity', 1e-6);
  }

  private drawLinks(nodes, links, source) {
    // ****************** links section ***************************
    // Update the links...
    const link: any = this.svgGroup.selectAll('g.link-group').data(links, function (d) { return d['id']; });


    // Enter any new links at the parent's previous position.

    const linkEnter = link.enter().append('g')
      .attr('class', 'link-group');

    linkEnter.insert('path', 'g')
      .attr('class', 'link')
      .attr('d', function (d) {
        const o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    linkEnter.append('foreignObject')
      .attr('dy', '.35em')
      .attr('width', 100)
      .attr('height', 50)
      .attr('y', function (d) {
        let offset = 0;
        /* istanbul ignore next */
        if (d.y === d.parent.y && d.parent.children.length === 1) {
          offset = 40;
        }
        return (d.x + d.parent.x) / 2 - offset;

      })
      .attr('x', function (d) {
        let offset = 20;
        if (d.x === d.parent.x && d.parent.children.length === 1) {
          offset = 50;
        }
        return (d.y + d.parent.y) / 2 - offset;
        // return (d.y + d.parent.y )/ 2;

      })
      .append('xhtml:body')
      .html((d) => `<div class="link-label"> ${this.flowVizSvc.toTitleCase(d.data.connection_text.join(', '))}</div>`);

    // UPDATE
    const linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate.select('path')
      .transition()
      .duration(duration)
      .attr('d', function (d) {
        return diagonal(d, d.parent);
      });


    linkUpdate.select('foreignObject')
      .attr('dy', '.35em')
      .attr('y', function (d) {
        let offset = 0;
        /* istanbul ignore next */
        if (d.y === d.parent.y && d.parent.children.length === 1) {
          offset = 40;
        }
        return (d.x + d.parent.x) / 2 - offset;

      })
      .attr('x', function (d) {
        let offset = 20;
        /* istanbul ignore if */
        if (d.x === d.parent.x && d.parent.children.length === 1) {
          offset = 50;
        }
        return (d.y + d.parent.y) / 2 - offset;
        // return (d.y + d.parent.y )/ 2;
      });


    // Remove any exiting links
    const linkExit = link.exit().transition()
      .duration(duration)
      .attr('d', /* istanbul ignore next */ function (d) {
        const o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d) {
      d['x0'] = d.x;
      d['y0'] = d.y;
    });


    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s, d) {

      // Actual calc that works
      const path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;

      return path;
    }

  }

  // SECTION:  ADD BEHAVIOR TO THE TREE
  private click = (d, i, n) => {
    d3 && d3.event && d3.event.stopPropagation();
    // const eventTarget = d3.event.target;

    // if the node is the new node before it has beed saved, don't do anything just exit the function    
    if (d.data.id === newComponentId) {
      return;
    }
    if (d.data.id && (d.data.componentType === callFlowComponentTypeValues.hg || d.data.componentType === callFlowComponentTypeValues.aa)) {
      this.navigateToNode = {d, i, n};
      this.flowsSvc.componentNavigateIntent.next();
    }
  }

  private zoomStart = () => {
    this.svgElem.nativeElement.classList.add('dragging-canvas');
  }

  private zoomEnd = () => {
    this.svgElem.nativeElement.classList.remove('dragging-canvas');
  }

  private zoomInprogress = () => {
    this.svgGroup.attr('transform', d3.event.transform);
  }

  private centerNode = (source, scale) => {
    this.x = (this.x) ? this.x + this.width / 2 : this.width / 2;
    this.y = (this.y) ? this.y + this.height : this.height;
    this.svg.transition()
      .duration(duration)
      .call(this.zoomListener.transform,
        d3.zoomIdentity
          .translate(100, this.height / 2)
          .scale(scale)
          .translate(-(+source['y0']), -(+source['x0'])));
  }

  //UTILITY METHODS
  private getD3Tree(tree: HierarchyDatum) {
    return d3.hierarchy(tree, d => d.children);
  }

  private updateRoot = (node, data) => {
    console.log(`node id ${node.id}`);
    console.log(`current node id ${this.currentNode.data.id}`);
    if (node.id === this.currentNode.data.id || node.id === newComponentId) {
      node.id = data.id;
      node.name = data.name;
      node.subText = data.subText;
      node.duplicate = data.duplicate;
      node.children = data.children;
      node.connection_text = data.connection_text;
      console.log(`Updated node with ${data.name}`);
    }
    else {
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          this.updateRoot(child, data);
        });
      }
    }
  }
}

