<div *ngIf="staticNotificationShow" class="notification notification-custom" [ngStyle]="externalStyles" id="notificationId" >
  <div class="row notification-row">
    <div class="col-auto align-icon">
<span class="notification-icon">
<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAYhJREFUOBGVUzFLQ0EMTq5aWp5ToZ1EN1EK4u6koOBgEbGig5sOCg66OvgDRDfp4uog1UURoYV28RcIRXFTnKqigw+xtheTs4/eq57UWy65L19yl3yH0LbGTqfT9FlfJoJJBOgTmADuEaGA3V0H5cxZxaZwzPfKVrLRx2t/j4NXmaKC8/COmgm55JC3mU/na4KZBEKu3vgXQDQeJjg8xFJq0JuSJKaSVO6YLDm5kOGwifJmXWtcua/tuAWgVtHIsKJ6Y6UTMjfxmFMVWulISbMVaZpoHf5uIeJRcrZngS/8akfIpFQwKhuwbSafD/T3Lj2d+Dt80/kQxmP+MS4E3Oek61xNA2IZEqm527uHLQ20YZMDW4lIAqe5j3oxdRgBNYMJL4PP1TUi2m6LMa5wFSos2iABjfgfVIzH6JJe3ha58q6N27ZRp3uM+M5vjtuEsN0co2hb5BkGxfuLbCScE65pomibG1b6mcRxwrGGw7BJIJoWbcsETPcdPMEkJvgHEmY+kx3/3+/8Befrm7LMWR9YAAAAAElFTkSuQmCC">
</span>
    </div>
    <div class="col notification-custom-message" id="theNotificationMessage">
      {{notificationMessage | translate}}
    </div>
    <button id="closeNotificationId" type="button" class="closeButton" (click)="onClose()" >
      &times;
    </button>
  </div>
</div>
