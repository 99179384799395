import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PhoneServicesService} from '../../services/phone-services.service';



@Component({
  selector: 'remove-phone',
  templateUrl: './remove-phone.component.html',
  styleUrls: ['./remove-phone.component.css']

})
export class RemovePhoneComponent implements OnInit {

  constructor(public translateSvc: TranslateService, public phSvc: PhoneServicesService) { }
  public type = '';

  ngOnInit() {
    this.type = this.phSvc.changePhoneNumberType;
  }
}
