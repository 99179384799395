
export const messageTypes = { // Must match choose_new_number.message_box in i18n
    outsideServiceArea: 'outside_service_area',
    outsideCanadaArea: 'outside_canada_area',
    noMatchFound: 'no_match_found',
    lessThanResults: 'less_than_results',
    noMatchingRanges: 'no_matching_ranges'
  };

  export const numberTypes = {
      default: 'default',
      localNumber: 'localNumber',
      tollFree: 'tollFree'
  };
