/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PagedResultDCEntitlementDC } from '../models/paged-result-dcentitlement-dc';
@Injectable({
  providedIn: 'root',
})
class EntitlementsService extends __BaseService {
  static readonly EntitlementsGetEntitlementsPath = '/api/Account/{accountUuid}/Entitlements';
  static readonly EntitlementsGetEntitlements_1Path = '/api/Entitlements';
  static readonly EntitlementsGetEntitlementStatusesPath = '/api/EntitlementStatuses';
  static readonly EntitlementsGetEntitlementsForUserPath = '/api/Users/{userUuid}/Entitlements';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `EntitlementsService.EntitlementsGetEntitlementsParams` containing the following parameters:
   *
   * - `modifiedSince`:
   *
   * - `accountUuid`:
   *
   * - `Authorization`: access token
   *
   * - `globalProductId`:
   *
   * - `entitlementName`:
   *
   * - `_per_page`:
   *
   * - `_page`:
   *
   * @return OK
   */
  EntitlementsGetEntitlementsResponse(params: EntitlementsService.EntitlementsGetEntitlementsParams): __Observable<__StrictHttpResponse<PagedResultDCEntitlementDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.modifiedSince != null) __params = __params.set('modifiedSince', params.modifiedSince.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.globalProductId || []).forEach(val => {if (val != null) __params = __params.append('globalProductId', val.toString())});
    (params.entitlementName || []).forEach(val => {if (val != null) __params = __params.append('entitlementName', val.toString())});
    if (params.PerPage != null) __params = __params.set('_per_page', params.PerPage.toString());
    if (params.Page != null) __params = __params.set('_page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/${params.accountUuid}/Entitlements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultDCEntitlementDC>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `EntitlementsService.EntitlementsGetEntitlementsParams` containing the following parameters:
   *
   * - `modifiedSince`:
   *
   * - `accountUuid`:
   *
   * - `Authorization`: access token
   *
   * - `globalProductId`:
   *
   * - `entitlementName`:
   *
   * - `_per_page`:
   *
   * - `_page`:
   *
   * @return OK
   */
  EntitlementsGetEntitlements(params: EntitlementsService.EntitlementsGetEntitlementsParams): __Observable<PagedResultDCEntitlementDC> {
    return this.EntitlementsGetEntitlementsResponse(params).pipe(
      __map(_r => _r.body as PagedResultDCEntitlementDC)
    );
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `EntitlementsService.EntitlementsGetEntitlements_1Params` containing the following parameters:
   *
   * - `modifiedSince`:
   *
   * - `Authorization`: access token
   *
   * - `globalProductId`:
   *
   * - `entitlementName`:
   *
   * - `_per_page`:
   *
   * - `_page`:
   *
   * @return OK
   */
  EntitlementsGetEntitlements_1Response(params: EntitlementsService.EntitlementsGetEntitlements_1Params): __Observable<__StrictHttpResponse<PagedResultDCEntitlementDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.modifiedSince != null) __params = __params.set('modifiedSince', params.modifiedSince.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.globalProductId || []).forEach(val => {if (val != null) __params = __params.append('globalProductId', val.toString())});
    (params.entitlementName || []).forEach(val => {if (val != null) __params = __params.append('entitlementName', val.toString())});
    if (params.PerPage != null) __params = __params.set('_per_page', params.PerPage.toString());
    if (params.Page != null) __params = __params.set('_page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entitlements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultDCEntitlementDC>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `EntitlementsService.EntitlementsGetEntitlements_1Params` containing the following parameters:
   *
   * - `modifiedSince`:
   *
   * - `Authorization`: access token
   *
   * - `globalProductId`:
   *
   * - `entitlementName`:
   *
   * - `_per_page`:
   *
   * - `_page`:
   *
   * @return OK
   */
  EntitlementsGetEntitlements_1(params: EntitlementsService.EntitlementsGetEntitlements_1Params): __Observable<PagedResultDCEntitlementDC> {
    return this.EntitlementsGetEntitlements_1Response(params).pipe(
      __map(_r => _r.body as PagedResultDCEntitlementDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  EntitlementsGetEntitlementStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EntitlementStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  EntitlementsGetEntitlementStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.EntitlementsGetEntitlementStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `EntitlementsService.EntitlementsGetEntitlementsForUserParams` containing the following parameters:
   *
   * - `userUuid`:
   *
   * - `Authorization`: access token
   *
   * - `globalProductId`:
   *
   * - `entitlementName`:
   *
   * - `_per_page`:
   *
   * - `_page`:
   *
   * @return OK
   */
  EntitlementsGetEntitlementsForUserResponse(params: EntitlementsService.EntitlementsGetEntitlementsForUserParams): __Observable<__StrictHttpResponse<PagedResultDCEntitlementDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.globalProductId || []).forEach(val => {if (val != null) __params = __params.append('globalProductId', val.toString())});
    (params.entitlementName || []).forEach(val => {if (val != null) __params = __params.append('entitlementName', val.toString())});
    if (params.PerPage != null) __params = __params.set('_per_page', params.PerPage.toString());
    if (params.Page != null) __params = __params.set('_page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/${params.userUuid}/Entitlements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultDCEntitlementDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `EntitlementsService.EntitlementsGetEntitlementsForUserParams` containing the following parameters:
   *
   * - `userUuid`:
   *
   * - `Authorization`: access token
   *
   * - `globalProductId`:
   *
   * - `entitlementName`:
   *
   * - `_per_page`:
   *
   * - `_page`:
   *
   * @return OK
   */
  EntitlementsGetEntitlementsForUser(params: EntitlementsService.EntitlementsGetEntitlementsForUserParams): __Observable<PagedResultDCEntitlementDC> {
    return this.EntitlementsGetEntitlementsForUserResponse(params).pipe(
      __map(_r => _r.body as PagedResultDCEntitlementDC)
    );
  }
}

module EntitlementsService {

  /**
   * Parameters for EntitlementsGetEntitlements
   */
  export interface EntitlementsGetEntitlementsParams {
    modifiedSince: string;
    accountUuid: string;

    /**
     * access token
     */
    Authorization: string;
    globalProductId?: Array<string>;
    entitlementName?: Array<string>;
    PerPage?: number;
    Page?: number;
  }

  /**
   * Parameters for EntitlementsGetEntitlements_1
   */
  export interface EntitlementsGetEntitlements_1Params {
    modifiedSince: string;

    /**
     * access token
     */
    Authorization: string;
    globalProductId?: Array<string>;
    entitlementName?: Array<string>;
    PerPage?: number;
    Page?: number;
  }

  /**
   * Parameters for EntitlementsGetEntitlementsForUser
   */
  export interface EntitlementsGetEntitlementsForUserParams {
    userUuid: string;

    /**
     * access token
     */
    Authorization: string;
    globalProductId?: Array<string>;
    entitlementName?: Array<string>;
    PerPage?: number;
    Page?: number;
  }
}

export { EntitlementsService }
