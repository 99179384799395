import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
   } from '@angular/common/http';
   import { Injectable } from '@angular/core';
   import { Observable, throwError } from 'rxjs';
   import { catchError } from 'rxjs/operators';

import { AppUserClaimsService, MajorAlert } from '../services/app-user-claims.service';
import { NavigationExtras, Router } from '@angular/router';
import * as _ from 'lodash';

   @Injectable()
   export class Http500Interceptor implements HttpInterceptor {
     constructor(private appSvc: AppUserClaimsService, private router: Router) {}

     // This interceptor is looking for 500 errors.
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          catchError((error) => {
              const urlArray = error.url.split('/'); // find prompt url
              if (error.status && error.status == 500 ||  (error.statusCode && error.statusCode == 500)) {
                  if (!_.includes(urlArray, 'Prompt') && !_.includes(urlArray, 'media-transcode')) { // verify prompt url
                      this.show500Error();
                  } else {
                      console.log('Prompt', 'Prompt url or media-transcode url found');
                  }
                }

            // pass the error on up to caller
            return throwError(error);
          })
        );
    }

    public show500Error() {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            'nav_error': '500'
          }
        };
        this.router.navigate(['navigationError'], navigationExtras);
      }
   }
