import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFocusScroll]'
})
export class FocusScrollDirective {
  @Input() set appFocusScroll(value : boolean){
    if(value){
      this.elementRef.nativeElement.scrollIntoView({block: "nearest", inline: "nearest"});
    }
  }
  constructor(private elementRef : ElementRef) { }

}
