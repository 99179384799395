import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation-error',
  templateUrl: './navigation-error.component.html',
  styleUrls: ['./navigation-error.component.scss']
})
export class NavigationErrorComponent implements OnInit {

  @Input() errorNum: string = "";

  subscriptions: Subscription[] = [];

  constructor( private route: ActivatedRoute, public translateSvc: TranslateService) { }

  ngOnInit() {    
    this.subscriptions.push(this.route.queryParams.subscribe((params)=>{      
      this.errorNum = params.nav_error || this.errorNum;
     }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
