import {Component, OnInit, Input, OnDestroy, OnChanges, HostListener, ElementRef, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { InvoiceSmallModel } from 'src/app/flows/invoice-small/invoice-small.model';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { Subscription } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  count = 0;
  msg = '';
  invoice = new InvoiceSmallModel();
  // Set true to display the content of the shopping cart on the screen
  showCartContent = false;
  whenEqualAmounts;
  /**
   * A string that is added to Commitment chart message.
   *
   * @memberof ShoppingCartComponent
   */
  @Input() countingCurrent:string = "";

  /**
   * pass false to not show the commitment chart
   */
  @Input() shouldShowCommitment = false;

  /**
   * Will emit true if shopping button state changes to displayed.
   * Will emit false if shopping button state changes to NOT displayed.
   */
  @Output() shoppingCartIsDisplayed = new EventEmitter<boolean>();

  // commitment chart
  showChart = false;
  currentProfileAmount = 0;
  contractProfileAmount = 0;
  showCommitmentChart = false;

  currentProfileAmountOnProductSelect = 0;



  constructor(private shoppingCartService: ShoppingCartService,
              private translateSvc: TranslateService,
              private elRef: ElementRef) { }

  ngOnInit() {
    // this.invoice = {
    //   header: {
    //     title: 'Add Order Details'
    //   },
    //     sections: [{
    //       header: {
    //         title: 'Changes to Monthly Charges',
    //       },
    //     items: [{
    //       label: 'Add 1 TN Turn Up',
    //       prefix: '$',
    //       price: '40',
    //       suffix: 'mo.'
    //     }],
    //     summary: {
    //       label: 'Total',
    //       arrowup: false,  // if true, place a up arrow in front of prefix
    //       arrowdown: false, // if true, place a down arrow in front of prefix
    //       prefix: '$',  // $
    //       price: '60.24',    // 5.99   or   5,99 €
    //       suffix: 'mo.'
    //     }
    //   },
    //       {
    //         header: {
    //           title: 'September 2019 Invoice',
    //         },
    //         items: [],
    //         summary: {
    //           label: 'Total',
    //           arrowup: false,  // if true, place a up arrow in front of prefix
    //           arrowdown: false, // if true, place a down arrow in front of prefix
    //           prefix: '$',  // $
    //           price: '80.53',    // 5.99   or   5,99 €
    //         }
    //       }],
    //   footers: ['Transfer to Austin', 'Totals do not include shipping, taxes, and other surcharges.']
    // };
    this.subscriptions.push(this.shoppingCartService.cartUpdatedSubject.asObservable().subscribe(
      shoppingCart => {
        if (this.count === 0  && shoppingCart.itemCount > 0) { this.shoppingCartIsDisplayed.emit(true)};
        if (this.count !== 0  && shoppingCart.itemCount === 0) { this.shoppingCartIsDisplayed.emit(false)};
        this.count = shoppingCart.itemCount;
        this.msg = shoppingCart.summaryMessage;
        this.invoice = shoppingCart.invoice;
        this.currentProfileAmountOnProductSelect = shoppingCart.currentProfileAmountOnProductSelect;
        this.contractProfileAmount = shoppingCart.contractProfileAmount;
        this.currentProfileAmount = shoppingCart.currentProfileAmount;
        // Note, the commitment chart/doughnut will display when contractProfileAmount >  currentProfileAmountOnProductSelect
        this.getCurrentProfileAmount();
      }
    ));
    this.updateShowCartContentState(this.showCartContent);
  }

  ngOnDestroy() {
    this.subscriptions.forEach( s => s.unsubscribe() );
  }

  onSubmit() {
    // this.updateShowCartContentState( ! this.showCartContent );
    if (this.count > 0) {
      this.updateShowCartContentState( ! this.showCartContent );
    } else {
      this.updateShowCartContentState(false);
    }
  }

  getCurrentProfileAmount() {
      this.currentProfileAmountOnProductSelect >= this.contractProfileAmount ?
          this.showCommitmentChart = false : this.showCommitmentChart = true;
      this.currentProfileAmountOnProductSelect === this.contractProfileAmount ?
      this.subscriptions.push(this.translateSvc.get('commitment_chart').subscribe(str => {
          this.whenEqualAmounts = str.counting_this_one;
      })) : this.whenEqualAmounts = '';
      return this.currentProfileAmountOnProductSelect;
  }



  getAvailableContractsAllowed() {
    const available =  this.contractProfileAmount - this.getCurrentProfileAmount();
    if (available < 0) {
      return  0;
    }
    return available;
  }


  // close invoice on esc key
  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.updateShowCartContentState(false);
  }

  // close on any click outside of the invoice
  @HostListener('document:touchend', ['$event'])
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    // did user click outside of this element?
    if (!this.elRef.nativeElement.contains(event.target)) {
      // yes, clicked outside
      this.updateShowCartContentState(false);
    }
  }


  /**
   * Call to indicate change in cart content display state,
   * will notify shopping cart service
   * @param isDisplayed  true==Displayed,  false==NOT-Displayed
   */
  updateShowCartContentState(isDisplayed:boolean) {
    this.showCartContent = isDisplayed;
    this.shoppingCartService.cartDisplayStateChangesSubject.next(this.showCartContent);
  }

  isCartButtonDisplayed()
  {
    if (this.count > 0 )
      return true;
    return false;
  }
}
