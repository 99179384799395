<span class="filter-by-city-container" (document:click)="onOutsideClick($event)">
    <span class="search-box-group-container">
        <span [ngClass]="{'search-box-group': true, 'red-border': redBorder}">
<input class="search-box form-control"
       type="text" placeholder="{{placeholder | translate}}"
       [(ngModel)]="value"
       (change)="onChange($event)"
       (keyup)="onChange($event)"
       [attr.tabindex]="0"
       tabindex="0">
<span class="clear-icon"
      *ngIf="showClearIcon"
      tabindex=0
      role="button" (click)="clearSearch($event)"></span>
    </span>
    </span>
    <span class="search-results-box row" *ngIf="showSuggestion">
<ul class="col-md-12 matched-locations-container" *ngIf="items && items.length > 0">
<li class="row matched-locations" *ngFor="let item of items"
    (click)="onSelectItem(item)"
    (keyup.enter)="onSelectItem(item)" tabindex="0">
<small class="matched-locations-numbers"
       [innerHTML]="item.extensionFormatted"></small>
  : <small class="col-md-12 matched-locations-numbers"
           [innerHTML]="item.description"></small>
      </li>
      </ul>
    </span>
    </span>
