import {Component, OnInit, OnDestroy, HostListener, NgZone} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { AppUserClaimsService } from './services/app-user-claims.service';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { AppInfoService } from './services/app-info.service';
import { ParentCommsService } from './services/parent-comms.service';
import { first, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BootstrapperService } from './services/bootstrapper.service';
import { ApiConfiguration as BossApiConfiguration } from './modules/boss-api/generated/api-configuration';
import { Subscription, BehaviorSubject, zip, Subject } from 'rxjs';
import { AdminService } from './services/admin.service';
import { UserAdaptorService, UserRole } from './services/user-adaptor.service';
import { AccountDC } from './modules/boss-api/generated/models';
import { PartnersType } from 'src/app/shared/constants';
import { AddressService } from './modules/boss-api/generated/services';

const salesForceURL = environment.salesForceURL;

// needed for salesforce live agent
declare var liveagent: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Flows';
  claims: UserClaims;
  company: Account;
  cloudType = 'dev';
  languages: any[];
  message: string;
  messageVisible = false;

  bossAccountID: number;
  isOnboardingFlag: boolean;
  isMCSSEnabledFlag: boolean;
  bossPersonID: number;
  userFullName: string;
  userEmail: string;
  userPhoneNumber: string;
  bossAccountName: string;
  devEnv = false;

  private userDCSource = new Subject<any>();
  public userDC$ =  this.userDCSource.asObservable();

  translated: any;
  subscriptions: Subscription[] = [];

  private invokedAccountIdQueryParam = 'accountId';
  private invokedRoleQueryParam = 'role';
  private invokedTargetIdQueryParam = 'targetId';
  invokedAccountId: string;
  invokedRole: string;
  invokedTargetId: string;
  claimsReceivedAndProcessed: BehaviorSubject<boolean> = new BehaviorSubject(false);

  pendoVisitor: {[key: string]: any};
  pendoAccount: {[key: string]: any};

constructor(
  private translateSvc: TranslateService,
  private clHeader: ClHeaderComponent,
  private router: Router,
  private addressService: AddressService,
  private appUserClaimsSvc: AppUserClaimsService,
  private appService: AppService,
  private appInfoService: AppInfoService,
  private bootstrapperService: BootstrapperService,
  private parentCommsService: ParentCommsService,
  private bossApiConfiguration: BossApiConfiguration,
  private adminService: AdminService,
  private userServices: UserAdaptorService,
  private _ngZone: NgZone
) {


  console.log('Flows AppComponent constructor start');
  this.cloudType = environment.cloud;

  this.loadScript();
  this.languages = this.getLanguages();
  this.setupTranslationService();

  this.subscriptions.push(this.bootstrapperService.bootstrapUpdated.subscribe(
    bootstrapper => {
      // setup the BOSS SDApi URI
      this.bossApiConfiguration.rootUrl = bootstrapper.bossApiBaseUrl;
      this.appUserClaimsSvc.setBossUserType(true);
      if ( bootstrapper && bootstrapper.commonName === 'Empty Bootstrap Configuration' ) {
        console.log('THIS IS NOT A BOSS USER');
        this.appUserClaimsSvc.setBossUserType(false);
      } else {
        this.appUserClaimsSvc.setBossUserType(true);
      }

    }
  ));
  this.subscriptions.push(this.appUserClaimsSvc.applnIdentityIsLoaded.subscribe(processed => {
    if (processed === true) {
      this.appUserClaimsSvc.setAccountPartner();
    }
  }));

  console.log('Flows AppComponent constructor end');
 }

 /**
  * Called when user is authenticated.  Will redirect to the presented URL.
  * @param url url that user invoked
  */
  // handleAuthConfirmedInvokingUrl(url) {
  //   //  console.log('invoking url', url);
  //   // Remove the https://host and /#/ from the URl that user invoked to launch this app and navigate to that page.
  //   this.router.navigateByUrl(url.replace(location.origin, '').replace('#/', ''));
  // }
    /**
   * Called when user is authenticated
   */
  async handleAuthConfirmedInvokingUrl(url: string) {
    console.log('>>>>Received the URL event', url);



    // here we will check if the app was invoked with an accountId and role
    // if so, this is the only indication that this app was invoked from the boss portal (ATM: Jul 23/19)
    // we are to use
    if ( url && url.length > 0 ) {
      if ( url.indexOf('?') > 0 ) {
        // there are query parameters
        const theParams = url.split('?')[1];
          const theAccountId = theParams.indexOf(this.invokedAccountIdQueryParam + '=') >= 0 ?
            theParams.split(this.invokedAccountIdQueryParam + '=')[1].split('&')[0] : '';

          if ( theAccountId ) {
            console.log('Here is the extracted account id', theAccountId);
          }
          const theRole = theParams.indexOf(this.invokedRoleQueryParam + '=') >= 0 ?
            theParams.split(this.invokedRoleQueryParam + '=')[1].split('&')[0] : '';

          if ( theRole ) {
            console.log('Here is the extracted role', theRole);
          }

          const theTargetId = theParams.indexOf(this.invokedTargetIdQueryParam + '=') >= 0 ?
          theParams.split(this.invokedTargetIdQueryParam + '=')[1].split('&')[0] : null;

          if ( theTargetId ) {
            console.log('Here is the extracted target id', theTargetId);
          }

          if ( theAccountId.length > 0 && theRole.length > 0 ) {
            this.invokedAccountId = theAccountId;
            this.invokedRole = theRole;
            this.invokedTargetId = theTargetId;
            this.subscriptions.push(this.claimsReceivedAndProcessed.subscribe( processed => {
            this.appUserClaimsSvc.setTargetAccount(this.invokedTargetId);
              if ( processed === true ) {
                this.appUserClaimsSvc.setInvokedParams( this.invokedAccountId,  this.invokedRole );
                console.log('The claims have been processed, now assume role');
                this.adminService.getAccountById(theAccountId).then (async account => {
                  console.log('handle url will set the company to ', account);
                  await this.onCompanyUpdated(account);
                  this.goToDeepLink(url);
                });
              }
            }));
          } else {
            this.goToDeepLink(url);
          }
      } else {
        this.goToDeepLink(url);
      }
    }
  }

 ngOnInit() {
  this.clHeader.setClientId(environment.clientId);

  this.checkEnvironmentURL();

  this.translateSvc.get(['applauncher', 'base64_images']).pipe(first()).subscribe(strings => {
    this.translated = strings;
    // console.log('Translated Strings', this.translated);
  });
  zip(this.appUserClaimsSvc.accountDC$, this.userDC$).pipe(
    tap(([accountDC, userDC]) => {
      this.bossAccountID = accountDC.id;
      this.isOnboardingFlag = accountDC.isOnboarding;
      this.isMCSSEnabledFlag = accountDC.ismcssEnabled;
      this.bossPersonID = userDC.bossId;
      this.userFullName = `${userDC.firstName} ${userDC.lastName}`;
      this.userEmail = userDC.email;
      this.userPhoneNumber = userDC.phoneNumber;
      this.bossAccountName = userDC.loginId;
    })
  ).subscribe(val => {
    console.log('Values passed into Pendo', val);
    this._ngZone.runOutsideAngular(() => {
       this.initAnalytics(this.claims, val[0]);
    });
  });

  // this.appUserClaimsSvc.claimsChanged.subscribe((claims)=>{
  //   this.claims = claims;
  // })
 }

checkEnvironmentURL(): void {
  this.devEnv = location.href.indexOf('dev.mitel.io') >= 0 || location.href.indexOf('stage.dev') >= 0;
}

initAnalytics(claims: UserClaims, accountDC: AccountDC) {
  const pendo = window['pendo'] || { initialize: function() {} };
  /**
  * Add common details
  */
  this.pendoVisitor = {
    id: this.claims.principalId,
    role: this.claims.role
  };
  const accountObject = {
    id: this.claims.accountId,
    bossID: this.bossPersonID,
    bossAccountID: this.bossAccountID,
    fullName: this.userFullName,
    email: this.userEmail,
    phoneNumber: this.userPhoneNumber,
    accountName: this.bossAccountName,
    onboarding: this.isOnboardingFlag,
    isMcssEnabled: this.isMCSSEnabledFlag,
    devEnv: this.devEnv
  };

  /**
  * Add support details
  */
  const isSupportEnabled = this.appUserClaimsSvc.getTargetAccount();
  let supportObject = {
    isSupportEnabled: false,
    supportPartnerId: null,
    supportPartnerName: null,
    targetAccountId: null,
    targetAccountName: null
  };
  if (isSupportEnabled) {
    supportObject = {
      isSupportEnabled: true,
      supportPartnerId: this.company.accountId,
      supportPartnerName: this.company.name,
      targetAccountId: accountDC.id,
      targetAccountName: accountDC.name
    };
  }
  /**
  * Add partner account details
  */
  const accountPartner = this.appUserClaimsSvc.getAccountPartner();
  let partnerObject = {
    partnerRelationshipType: null,
    partnerName: null,
    partnerAddressLine1: null,
    partnerAddressLine2: null,
    partnerMainPhoneNumber: null,
    partnerTollFreePhoneNumber: null,
    partnerSupportEmailAddress: null,
    partnerSupportWebsiteUrl: null
  };
  if (accountPartner) {
    partnerObject = {
      partnerRelationshipType: accountPartner.relationshipTypeId,
      partnerName: accountPartner.partnerAccountName,
      partnerAddressLine1: '',
      partnerAddressLine2: '',
      partnerMainPhoneNumber: accountPartner.mainTelephoneNumber,
      partnerTollFreePhoneNumber: accountPartner.tollfreeTelephoneNumber,
      partnerSupportEmailAddress: accountPartner.supportEmailAddress,
      partnerSupportWebsiteUrl: accountPartner.supportWebSite
    };
    if (accountPartner.address) {
      partnerObject.partnerAddressLine1 = accountPartner.address.address1 + ' ' +  accountPartner.address.address2;
      partnerObject.partnerAddressLine2 = accountPartner.address.city + ' ' + accountPartner.address.stateCodeAlpha
        + ' ' + accountPartner.address.zipCode + ' ' + accountPartner.address.countryName;
    }
  } else {
    partnerObject.partnerRelationshipType = PartnersType.support;
  }
  this.pendoAccount = Object.assign(accountObject, supportObject, partnerObject);
  console.log('Values passed into Pendo', this.pendoVisitor, this.pendoAccount);
  pendo.initialize({
    'visitor': this.pendoVisitor,
    'account': this.pendoAccount
  });
}

public loadScript() {
  console.log('preparing to load...');
  const node = document.createElement('script');
  node.src = salesForceURL;
  node.type = 'text/javascript';
  node.async = true;
  node.charset = 'utf-8';
  document.getElementsByTagName('head')[0].appendChild(node);
}


 @HostListener('window:beforeunload')
 beforeunload() {
   // An unload event is fired when browser window closes or a new page load is requested.
   // https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event

     if (!this.parentCommsService.isInsideIframe()) {
         return;
     }
     console.log('parent is unloading');
     this.clHeader.logoutOnly();
 }

 // If we are inside an iframe, listening for parent=>child message
 @HostListener('window:message', ['$event'])
 onMessage(event) {
   // console.log('orders windows:message', event);
     if (!(this.parentCommsService.isInsideIframe() && event && event.data === 'UNLOAD')) {
         return;
     }
     console.log('page hosting iframe is unloading');
     this.clHeader.logoutOnly();
 }

  async handleUserClaims(claims: UserClaims) {
    // console.log('in handle user claims ', claims);
    // 1st inform Bootstrapper Service. This will trigger BOSS SDApi URI setup
    if (claims) {
      this.bootstrapperService.setPartnerId(claims['partnerId']);

      /**
      * Start of hack code
      *
      * Give anyone with CloudLink User role ACCOUNT_ADMIN role
      * This is different from account in the fact that only ACCOUNT_ADMIN users have access
      */
      if (claims.role === 'USER') {
        claims.role = 'ACCOUNT_ADMIN';
      }
      /**
      * End of hack code
      */

      this.claims = claims;
      this.appUserClaimsSvc.setClaims(claims);
      await this.determineAndAssignBossRole();
      this.adminService.getAccountById(claims.accountId).then (async account => {
        // console.log('handle user claims will set the company to ', account);
        const claimAcctId = (claims['providerAccountId']) ? claims['providerAccountId'] : account.accountId;
        await this.onCompanyUpdated(account);
        if (claims['userId']) {
          this.userServices.getUser(claims['userId'], account.accountId).then(user => {
            this.appUserClaimsSvc.loggedInUser = user;
            this.userDCSource.next(user);
            this.handleInternalChangesToUserClaims();
          });
        }
      });
      this.claimsReceivedAndProcessed.next(true);
    }
  }

  // to handle the fact that assumed role changes the claims
  handleInternalChangesToUserClaims() {
    this.subscriptions.push(this.appUserClaimsSvc.claimsChanged.subscribe(claims => {
      // console.log('internally user claims have changed', claims);
      this.claims = claims;

      // this._ngZone.runOutsideAngular( () => {
      //   // helps e2e tests run, especially users.e2e-spec.ts  describe('001 ADMIN Account Side Bar Dashboard (Home)
      //   this.initAnalytics(claims);
      // });
    }));
  }

  // a kludge to get by the BOSS API issue
  determineAndAssignBossRole(): Promise<void> {
    if (this.appUserClaimsSvc.isBossUser() &&
      (this.claims.role === 'ACCOUNT_ADMIN' || this.claims.role === 'PARTNER_ADMIN')) {
      this.appUserClaimsSvc.setBossRoles(this.claims.role === 'PARTNER_ADMIN' ? [UserRole.STAFF, UserRole.ADMIN] : [UserRole.ADMIN]);
      return Promise.resolve();
    }
    return this.userServices.getUserRole(this.claims['userId']).toPromise().then(roles => {
      return this.appUserClaimsSvc.setBossRoles(roles);
    });
  }

  /**
   * Will navigate to the supplied link
   * @param url this can be a full url with http prefix
   */
  goToDeepLink(url) {
    this.loadSeedData();
     this.subscriptions.push(this.userServices.getUserPermissions().subscribe(permissions => {
      this.appUserClaimsSvc.setPermissions(permissions);
      this.appUserClaimsSvc.applnIdentityIsLoaded.next(true);
      const deepLink = url.split('#')[1].split('?')[0].split('&')[0];
      this.router.navigateByUrl(deepLink);
    }));
 }

  goHomePage() {
    this.router.navigateByUrl('/flows');
  }


  loadSeedData() {
    this.subscriptions.push(this.addressService.AddressGetCountries(null).subscribe(addresses => {
      const countryCode = addresses[0].id;
      /*istanbul ignore else*/
      if (countryCode) { 
        this.appUserClaimsSvc.setCountryCode(countryCode);
        this.appService.setCountryCode(countryCode);
      }
    }));
  }

  setupTranslationService() {
    const langRegex = new RegExp(this.appInfoService.getLangCodes().join('|'), 'gi');
    this.translateSvc.addLangs(this.appInfoService.getLangCodes());
    this.translateSvc.setDefaultLang('en');
    const browserLang =  this.translateSvc.getBrowserLang();
    console.log(' TS browser lang: ', browserLang);
    // TODO: we are forcefully setting the default lang to 'en', below method is valid?
    // this.translateSvc.use(browserLang.match(langRegex) ? browserLang : 'en');
    this.translateSvc.use('en');
  }

@HostListener('window:focus', ['$event'])
onFocus(event: any): void {
  this.clHeader.checkValidSessionStatus();
  if (this.parentCommsService.tellParentToCheckSession() === false) {
    this.clHeader.checkValidSessionStatus();
  }
}

  getLanguages(): any[] {
    return (this.appInfoService.getSupportedLanguages());
  }

  async onCompanyUpdated(company: Account) {
    // console.log('in handle company change', company);
    this.company = company;
    this.appUserClaimsSvc.setCompany(company);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
