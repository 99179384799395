import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppUserClaimsService } from 'src/app/services/app-user-claims.service';
import { BootstrapperService } from 'src/app/services/bootstrapper.service';

/**
* Add a custom header X-Mitel-SDAPI-TargetAccount that indicates
* that the requests are for the AccountGUID in the header.
*
* // https://angular.io/guide/http
*
*/
@Injectable()
export class TargetAccountInterceptor implements HttpInterceptor {
  constructor(
    private appUserClaimsService: AppUserClaimsService,
    private bootstrapperService: BootstrapperService
  ) { }

  public static Header = 'X-Mitel-SDAPI-TargetAccount';
  /**
  * Intercept requests and add X-Mitel-SDAPI-TargetAccount header if TargetAccount is set.
  *
  * Intercept responses and parse X-Mitel-SDAPI-TargetAccount header and update TargetAccount.
  * If the header doesn't exist return the current TargetAccount.
  * If the header exists and is not a valid GUID (or empty) return null.
  * If the header exists and is a valid GUID return the new value.
  *
  * @param request   incoming request
  * @param next      the next HttpHandler
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let returnRequest = request;
    let targetAccount = this.appUserClaimsService.getTargetAccount();
    if (this.bootstrapperService.getPartnerId()) {
      if (request.url.search(this.bootstrapperService.getBootstrap().bossApiBaseUrl) === -1) {
        targetAccount = null;
      }
    }
    // add the header if necessary
    if (targetAccount !== undefined && targetAccount != null && !this.hasTargetAccountHeader(request)) {
      returnRequest = request.clone({
        headers: request.headers.append(TargetAccountInterceptor.Header, targetAccount)
      });
    }
    // use the response to update the TargetAccount
    return next.handle(returnRequest);
  }

  /**
  * Check to see if the X-Mitel-SDAPI-TargetAccount header exist and contains
  * an Account GUID formated as 00112233-4455-6677-8899-aabbccddeeff.
  *
  * @param request HttpRequest to be processed
  * @returns True if the request contains the correctly formatted header
  */
  hasTargetAccountHeader(request: HttpRequest<any>): boolean {
    const targetAccount = request.headers.get(TargetAccountInterceptor.Header);
    const regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$');
    return targetAccount != null && regex.test(targetAccount);
  }

}
