import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewNumberService {
public selectNewNumber = new Subject<any>();
public defaultAreaCode = new Subject<any>();
public searchExecuted = new Subject<any>();
public tollFreeSearchCompleted = new Subject<any>();
public pageReloaded = new Subject<any>();
public resetSelections = new Subject<void>()

constructor() { }
}
