/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CountryDC } from '../models/country-dc';
import { AddressSchemaDC } from '../models/address-schema-dc';
import { StateDC } from '../models/state-dc';
import { ValidateAddressResultDC } from '../models/validate-address-result-dc';
import { ValidateAddressRequestDC } from '../models/validate-address-request-dc';
@Injectable({
  providedIn: 'root',
})
class AddressService extends __BaseService {
  static readonly AddressGetCosmoTimeZonesPath = '/api/Address/CosmoTimeZones';
  static readonly AddressGetCountriesPath = '/api/Address/Countries';
  static readonly AddressGetAddressSchemaPath = '/api/Address/Schema/{countryId}';
  static readonly AddressGetStatesPath = '/api/Address/States/{countryId}';
  static readonly AddressValidateAddressPath = '/api/Address/Validate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  AddressGetCosmoTimeZonesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Address/CosmoTimeZones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  AddressGetCosmoTimeZones(Authorization: string): __Observable<Array<string>> {
    return this.AddressGetCosmoTimeZonesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  AddressGetCountriesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CountryDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Address/Countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CountryDC>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  AddressGetCountries(Authorization: string): __Observable<Array<CountryDC>> {
    return this.AddressGetCountriesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CountryDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `AddressService.AddressGetAddressSchemaParams` containing the following parameters:
   *
   * - `countryId`: ISO 3166-1 country id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  AddressGetAddressSchemaResponse(params: AddressService.AddressGetAddressSchemaParams): __Observable<__StrictHttpResponse<AddressSchemaDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Address/Schema/${params.countryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddressSchemaDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `AddressService.AddressGetAddressSchemaParams` containing the following parameters:
   *
   * - `countryId`: ISO 3166-1 country id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  AddressGetAddressSchema(params: AddressService.AddressGetAddressSchemaParams): __Observable<AddressSchemaDC> {
    return this.AddressGetAddressSchemaResponse(params).pipe(
      __map(_r => _r.body as AddressSchemaDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `AddressService.AddressGetStatesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  AddressGetStatesResponse(params: AddressService.AddressGetStatesParams): __Observable<__StrictHttpResponse<Array<StateDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Address/States/${params.countryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<StateDC>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `AddressService.AddressGetStatesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  AddressGetStates(params: AddressService.AddressGetStatesParams): __Observable<Array<StateDC>> {
    return this.AddressGetStatesResponse(params).pipe(
      __map(_r => _r.body as Array<StateDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `AddressService.AddressValidateAddressParams` containing the following parameters:
   *
   * - `address`: Address to validate
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  AddressValidateAddressResponse(params: AddressService.AddressValidateAddressParams): __Observable<__StrictHttpResponse<ValidateAddressResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.address;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Address/Validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValidateAddressResultDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `AddressService.AddressValidateAddressParams` containing the following parameters:
   *
   * - `address`: Address to validate
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  AddressValidateAddress(params: AddressService.AddressValidateAddressParams): __Observable<ValidateAddressResultDC> {
    return this.AddressValidateAddressResponse(params).pipe(
      __map(_r => _r.body as ValidateAddressResultDC)
    );
  }
}

module AddressService {

  /**
   * Parameters for AddressGetAddressSchema
   */
  export interface AddressGetAddressSchemaParams {

    /**
     * ISO 3166-1 country id
     */
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for AddressGetStates
   */
  export interface AddressGetStatesParams {
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for AddressValidateAddress
   */
  export interface AddressValidateAddressParams {

    /**
     * Address to validate
     */
    address: ValidateAddressRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { AddressService }
