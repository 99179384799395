<p-dropdown 
  styleClass="cux-dropdown"
  [options]="items" 
  [(ngModel)]="selectedItem"
  (onClick)="click($event)"
  (onChange)="change($event)"
  (onFocus)="focus($event)"
  (onBlur)="blur($event)"
  (onShow)="show($event)"
  (onHide)="hide($event)">

  <ng-template *ngIf="selectedItemIcon" let-item pTemplate="selectedItem">
    <img src="{{ selectedItemIcon }}" class="selectedItemIcon" />
    <span>{{item.label}}</span>
  </ng-template>

</p-dropdown>