<div class="container">
  <div class="row">
    <div class="col-md-10 title">
      {{ 'schedules_model.title' | translate }}
    </div>
    <div class="col-md-2 close-button">
          <span
                  class="pull-right"
                  style="cursor: pointer;"
                  (click)="onClose()"
                  [attr.tabindex]="tabbable ? 0 : -1"
                  (keydown.enter)="$event.preventDefault(); onClose()"
                  (keydown.space)="$event.preventDefault(); onClose()">
              <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ2LjIgKDQ0NDk2KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29uL0Nsb3NlL0dyZXk8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikljb24vQ2xvc2UvR3JleSIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjQkZCRkMwIj4KICAgICAgICAgICAgPHBhdGggZD0iTTE3LjA3MTA2NzgsNC4zMzk4ODI0OSBDMTcuNDYwNjkxLDMuOTUwMjU5MzIgMTcuNDYwNjkxLDMuMzE4NTU1MzYgMTcuMDcxMDY3OCwyLjkyODkzMjE5IEMxNi42ODE0NDQ2LDIuNTM5MzA5MDIgMTYuMDQ5NzQwNywyLjUzOTMwOTAyIDE1LjY2MDExNzUsMi45Mjg5MzIxOSBMMTAsOC41ODkwNDk3IEw0LjMzOTg4MjQ5LDIuOTI4OTMyMTkgQzMuOTUwMjU5MzIsMi41MzkzMDkwMiAzLjMxODU1NTM2LDIuNTM5MzA5MDIgMi45Mjg5MzIxOSwyLjkyODkzMjE5IEMyLjUzOTMwOTAyLDMuMzE4NTU1MzYgMi41MzkzMDkwMiwzLjk1MDI1OTMyIDIuOTI4OTMyMTksNC4zMzk4ODI0OSBMOC41ODkwNDk3LDEwIEwyLjkyODkzMjE5LDE1LjY2MDExNzUgQzIuNTM5MzA5MDIsMTYuMDQ5NzQwNyAyLjUzOTMwOTAyLDE2LjY4MTQ0NDYgMi45Mjg5MzIxOSwxNy4wNzEwNjc4IEMzLjMxODU1NTM2LDE3LjQ2MDY5MSAzLjk1MDI1OTMyLDE3LjQ2MDY5MSA0LjMzOTg4MjQ5LDE3LjA3MTA2NzggTDEwLDExLjQxMDk1MDMgTDE1LjY2MDExNzUsMTcuMDcxMDY3OCBDMTYuMDQ5NzQwNywxNy40NjA2OTEgMTYuNjgxNDQ0NiwxNy40NjA2OTEgMTcuMDcxMDY3OCwxNy4wNzEwNjc4IEMxNy40NjA2OTEsMTYuNjgxNDQ0NiAxNy40NjA2OTEsMTYuMDQ5NzQwNyAxNy4wNzEwNjc4LDE1LjY2MDExNzUgTDExLjQxMDk1MDMsMTAgTDE3LjA3MTA2NzgsNC4zMzk4ODI0OSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="/></span>
    </div>
  </div>
  <hr>

  <ng-container>
  <div class="data-container">
    <div class="column-value-wrapper" (click)="onClickOption('hours')">
      <div class="column-value">{{ 'schedules_model.hours_schedule' | translate }}</div>
      <div class="column-desc">{{ 'schedules_model.hours_schedule_desc' | translate }}</div>
    </div>
  </div>
  <div class="data-container">
    <div class="column-value-wrapper" (click)="onClickOption('holiday')">
      <div class="column-value">{{ 'schedules_model.holiday_schedule' | translate }}</div>
      <div class="column-desc">{{ 'schedules_model.holiday_schedule_desc' | translate }}</div>
    </div>
  </div>
  <div class="data-container">
    <div class="column-value-wrapper" (click)="onClickOption('custom')">
      <div class="column-value">{{ 'schedules_model.custom_schedule' | translate }}</div>
      <div class="column-desc">{{ 'schedules_model.custom_schedule_desc' | translate }}</div>
    </div>
  </div>
  </ng-container>
</div>
