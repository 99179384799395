import { Subscription } from 'rxjs';
import { PhoneServicesService } from '../../../../services/phone-services.service';
import { TnAdapterService, RateCenter } from '../../../../services/tn-adapter.service';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { numberTypes } from '../constants';

import * as _ from 'lodash';
import { NewNumberService } from 'src/app/services/newNumber.service';
import { filter } from 'rxjs/operators';
import { LocationDC } from 'src/app/modules/boss-api/generated/models/location-dc';

interface CLDropdownItems {
  value: string,
  displayName: string
}

export interface FilterCriteria {
  rateCenter?: RateCenter,
  filterByAreaCode?: string,
  searchedTerm?: string,
  quantity?: number,
  continuousRange?: boolean,
  areaCode?: string,
  prefix?: string,
  nextDigit?: string,
}

@Component({
  selector: 'filter-numbers',
  templateUrl: './filter-numbers.component.html',
  styleUrls: ['./filter-numbers.component.scss']
})

export class FilterNumbersComponent implements OnInit, OnDestroy {

  @Input() numberType?: string;
  @Input() currentUserLocation?: LocationDC;
  @Input() tollFreeAreaCodes: CLDropdownItems[] = [];
  @Input() areaCodesAnyValue: string = '';
  @Input() searchCriteriaDigits?: string = '';
  @Input() searchCriteriaRateCenter?: string = '';
  @Output() onSearchByRateCenter = new EventEmitter<any>();
  @Output() onsearchByNumber = new EventEmitter<any>();
  @Output() searchTollFree = new EventEmitter<any>();

  @ViewChild('rcSearch') rcSearch: ElementRef;
  @ViewChild('prefixElement') prefixElement: ElementRef;
  @ViewChild('nextDigitElement') nextDigitElement: ElementRef;

  public typeAheadSearch: any = Function.prototype;
  public numbersForm: FormGroup;
  public citySearchForm: FormGroup;
  public tollFreeSearchForm: FormGroup;
  public numberRangeForm: FormGroup;
  public rateCenters: RateCenter[] = [];
  public showResults: boolean = false;
  public containingErr: boolean = false;
  public numberSearch: {
    areaCode: string,
    prefix?: string,
    nextDigit?: string
  } = {
      areaCode: '',
      prefix: '',
      nextDigit: ''
    };
  public isLocalNumber: boolean = false;
  public numberTypes: any = numberTypes;

  private rateCenterAreaCode: string = '';
  public filterCriteria: FilterCriteria;
  private continuousRange: boolean = true;
  private subscriptions: Subscription[] = [];
  public isUS: boolean = false;
  quantityModel: string = '1';
  emptyInputRC: boolean = true;
  emptyInputTN: boolean = true;

  constructor(public translateSvc: TranslateService,
    public phSvc: PhoneServicesService,
    public tnAdapterSvc: TnAdapterService,
    private newNumberSvc: NewNumberService) { }

  ngOnInit() {
    this.currentUserLocation = (this.currentUserLocation) ? this.currentUserLocation : this.phSvc.tnIntegration.currentUserLocation;
    this.currentUserLocation && this.currentUserLocation.countryName === 'United States' ? this.isUS = true : this.isUS = false;
    this.typeAheadSearch = _.debounce(this.tas, 400);
    this.prepView();

    this.newNumberSvc.pageReloaded.subscribe(val => {
      if (val) {
        if(!this.emptyInputRC) this.clearSearchRC();
        this.numberRangeForm.patchValue({quantity: '1'});
        this.tollFreeSearchForm.patchValue({containing: ''});
        this.numbersForm.patchValue({areaCode: '', prefix: '', nextDigit: ''});
        this.emptyInputTN = true;
      }
    })

    this.subscriptions.push(this.newNumberSvc.defaultAreaCode.subscribe(defaultAreaCode => {
      this.tollFreeSearchForm.patchValue({areaCodeTollFree: defaultAreaCode});
      this.onTollFreeSearch();
    }));

    this.subscriptions.push(this.phSvc.tnIntegration.tabChanged.subscribe((filterByCity: boolean) => {
      if (filterByCity) {
        if (this.emptyInputRC) {
          this.showResults = false;
        } else {
          const areaCodeRCValue = this.filterCriteria.rateCenter.areaCodesString.replace('<strong>', '').replace('</strong>', '');
          const rcSearchValue = `${this.filterCriteria.rateCenter.address} (${areaCodeRCValue})`;
          this.citySearchForm.patchValue({rcSearch: rcSearchValue});
        }
      } else {
        const areaCodeValue = this.numbersForm.value.areaCode;
        const prefixValue = this.numbersForm.value.prefix;
        const nextDigitValue = this.numbersForm.value.nextDigit;

        if (this.emptyInputTN) {
          this.numbersForm.patchValue({areaCode: '', prefix: '', nextDigit: ''});
        } else {
          this.numbersForm.patchValue({ areaCode: areaCodeValue });
          prefixValue === undefined ? this.numbersForm.patchValue({ prefix: '' }) : this.numbersForm.patchValue({ prefix: prefixValue });
          nextDigitValue === undefined ? this.numbersForm.patchValue({ nextDigit: '' }) : this.numbersForm.patchValue({ nextDigit: nextDigitValue });
        }
      }
    }));
  }

  prepView() {
    if (this.numberType === numberTypes.localNumber) {
      this.isLocalNumber = true;
    }
    this.initForm();
    this.newPhoneModalFlags();
    // this.citySearchForm.patchValue({ rcSearch: this.searchCriteriaRateCenter });
    // this.numbersForm.patchValue({ areaCode: this.searchCriteriaDigits.substr(0, 3) });
    // this.numbersForm.patchValue({ prefix: this.searchCriteriaDigits.substr(3, 3) });
    // this.numbersForm.patchValue({ nextDigit: this.searchCriteriaDigits.substr(6) });
  }

  initForm() {
    let numbersFormData = {
      areaCode: {
        value: this.searchCriteriaDigits.substr(0, 3),
        disabled: false
      },
      prefix: {
        value: this.searchCriteriaDigits.substr(3, 3),
        disabled: !this.searchCriteriaDigits.length && true
      },
      nextDigit: {
        value: this.searchCriteriaDigits.substr(6),
        disabled: !this.searchCriteriaDigits.length && true
      }
    }
    this.numbersForm = new FormGroup({
      'areaCode': new FormControl(numbersFormData.areaCode),
      'prefix': new FormControl(numbersFormData.prefix),
      'nextDigit': new FormControl(numbersFormData.nextDigit)
    });

    this.citySearchForm = new FormGroup({
      'rcSearch': new FormControl({value: this.searchCriteriaRateCenter, disabled: false})
    });

    this.tollFreeSearchForm = new FormGroup({
      'areaCodeTollFree': new FormControl(),
      'containing': new FormControl(null, {
        validators: [
          Validators.minLength(4),
          Validators.maxLength(7)
        ],
        updateOn: 'blur'
      }),
    });

    this.numberRangeForm = new FormGroup({
      'quantity': new FormControl(null, {
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(110),
          Validators.minLength(1),
          Validators.maxLength(3)
        ],
        updateOn: 'blur'
      }),
      'continuousRange': new FormControl()
    });
  }

  newPhoneModalFlags() {
    const areaCodeValue = `${this.searchCriteriaDigits.substr(0, 3)}`;
    const prefixValue = `${this.searchCriteriaDigits.substr(3, 3)}`;
    const nextDigitValue = `${this.searchCriteriaDigits.substr(6)}`;
    const prefixElem = this.numbersForm.get('prefix');
    const nextDigitElem = this.numbersForm.get('nextDigit');

    if (this.emptyInputTN && !this.searchCriteriaDigits) {
      this.numbersForm.patchValue({areaCode: '', prefix: '', nextDigit: ''});
      prefixElem.disable();
      nextDigitElem.disable();
    } else {
      this.numbersForm.patchValue({ areaCode: areaCodeValue });
      this.emptyInputTN = false;
      areaCodeValue.length === 3 ? prefixElem.enable() : prefixElem.disable();
      nextDigitElem.disable();
      if (prefixValue) {
        this.numbersForm.patchValue({ prefix: prefixValue });
        if (prefixValue.length === 3) {
          nextDigitElem.enable();
          this.emptyInputTN = false;
        } else {
          nextDigitElem.disable();
          this.emptyInputTN = true;
        }
      } else {
        this.numbersForm.patchValue({ prefix: '' });
      }
      if (nextDigitValue) {
        this.numbersForm.patchValue({ nextDigit: nextDigitValue });
      } else {
        this.numbersForm.patchValue({ nextDigit: '' });
      }
    }
  }

  private tasHighlighter(key: string, rateCenters: RateCenter[]) {
    let tasRex = new RegExp(key, 'gi');
    let didMatch: boolean = false;
    return rateCenters.map((rateCenter: RateCenter) => {
      let modRc = {
        ...rateCenter
      };
      didMatch = tasRex.test(rateCenter.address) || tasRex.test(rateCenter.areaCodesString);
      if (didMatch) {
        modRc.address = modRc.address.replace(tasRex, (match, index) => {
          let matchedString = modRc.address.substr(index, key.length);
          return `<strong>${matchedString}</strong>`
        });
        modRc.areaCodesString = modRc.areaCodesString.replace(tasRex, `<strong>${key}</strong>`);
      }
      return modRc;
    });
  }

  private tas(event: any) {
    let value = event.target.value;
    if (value.length) {
      this.currentUserLocation = (this.currentUserLocation) ? this.currentUserLocation : this.phSvc.tnIntegration.currentUserLocation;
      if (this.currentUserLocation) {
        this.subscriptions.push(this.tnAdapterSvc.getRateCenters(this.currentUserLocation, value)
          .subscribe((rateCenters: RateCenter[]) => {
            if (rateCenters.length) {
              this.rateCenters = this.tasHighlighter(value, rateCenters);
              this.showResults = true;
              if (this.isInputNumber(value)) {
                this.rateCenterAreaCode = value;
              }
              else {
                this.rateCenterAreaCode = '';
              }
            }
            else {
              this.rateCenters = [];
              this.showResults = false;
            }
          }));
      }
    }
    else {
      this.clearSearchRC();
    }
  }

  public onTabClicked(tabState) {
    this.phSvc.tnIntegration.filterByCity = tabState.filterByCity;
    this.showResults = false;
  }

  public onQuantityInput(event) {
    let value = parseInt(event.target.value, 10);
    let quantityElem = this.numberRangeForm.get('quantity');
    value = (value/110 > 1) ? 110 : (value/1 < 1) ? 1 : value;
    quantityElem.setValue(value.toString());

    // if (!this.isInputNumber(value)) {
    //   quantityElem.setValue(value.slice(0, value.length - 1));
    // } else if (parseInt(value, 10) / 110 > 1) {
    //   quantityElem.setValue('110');
    // } else if (parseInt(value, 10) / 1 < 1) {
    //   quantityElem.setValue('1');
    // }
  }

  public onContainingInput(event) {
    let value = event.target.value;
    let containingElem = this.tollFreeSearchForm.get('containing');

    this.containingErr = value.length > 0 && (value.length < 4 || value.length > 7);
    if (!this.isInputNumber(value)) {
      this.containingErr = false;
      containingElem.setValue(value.slice(0, value.length - 1));
    }
  }

  public onUserInputNumber(event) {
    let value = event.target.value;
    let formControlName = event.target.attributes.formcontrolname.value;
    if (!this.isInputNumber(value)) {
      this.numbersForm.get(formControlName).setValue(value.slice(0, value.length - 1));
    }
    let prefixElem = this.numbersForm.get('prefix');
    let nextDigitElem = this.numbersForm.get('nextDigit');
    switch (formControlName) {
      // Enable the Prefix input if 3 digit area code is entered
      case 'areaCode':
        if (value.length === 3) {
          prefixElem.enable();
          this.emptyInputTN = false;
          this.prefixElement.nativeElement.focus();
          // this.onNumberFilterSelected();
        } else {
          prefixElem.disable();
          prefixElem.setValue('');
          nextDigitElem.disable();
          nextDigitElem.setValue('');
          this.emptyInputTN = true;
        }
        break;
      // Enable the Next Digit input if 3 digit Prefix is entered
      case 'prefix':
        if (value.length === 3) {
          nextDigitElem.enable();
          this.emptyInputTN = false;
          this.nextDigitElement.nativeElement.focus();
        } else if (!value.length) {
          nextDigitElem.disable();
          nextDigitElem.setValue('');
          this.emptyInputTN = false;
        } else {
          nextDigitElem.disable();
          nextDigitElem.setValue('');
          this.emptyInputTN = true;
        }
        break;
    }
  }

  public clearSearchRC() {
    this.rcSearch.nativeElement.value = '';
    this.citySearchForm.setValue({rcSearch: ''});
    this.rateCenters = [];
    this.showResults = false;
    this.emptyInputRC = true;
  }

  public onCityFilterSelected(rateCenter) {
    this.showResults = false;
    rateCenter.address = `${rateCenter.cityName}, ${rateCenter.stateCode}`;
    let areaCodesString = rateCenter.areaCodesString.replace('<strong>', '').replace('</strong>', '');
    this.rcSearch.nativeElement.value = `${rateCenter.address} (${areaCodesString})`;
    let searchedTerm = ` for "${rateCenter.address} (${areaCodesString})"`;
    let filterCriteria: FilterCriteria = {
      rateCenter,
      filterByAreaCode: this.rateCenterAreaCode,
      searchedTerm
    };
    this.emptyInputRC = false;
    this.filterCriteria = filterCriteria;
    if (this.numberType === numberTypes.default) {
      this.onSearchByRateCenter.emit(this.filterCriteria);
    }
  }

  public searchLocalNumber() {
    let quantity = this.numberRangeForm.get('quantity').value;
    let continuousRange = this.continuousRange;
    let filterCriteria: FilterCriteria = {
      ...this.filterCriteria,
      quantity,
      continuousRange
    }
    this.onSearchByRateCenter.emit(filterCriteria);
    this.newNumberSvc.searchExecuted.next('');
  }

  public onNumberFilterSelected() {
    let areaCode = this.numbersForm.get('areaCode').value || '';
    let prefix = this.numbersForm.get('prefix').value || '';
    let nextDigit = this.numbersForm.get('nextDigit').value || '';

    const msgStarting = this.translateSvc.instant('choose_new_number.available_num_starting');
    let searchedTerm = msgStarting + ` "(${areaCode})`;
    if (!prefix && !nextDigit) {
      searchedTerm += '\"';
    } else if (prefix && !nextDigit) {
      searchedTerm += ` ${prefix}"`;
    } else if (prefix && nextDigit) {
      searchedTerm += ` ${prefix}-${nextDigit}"`;
    }

    this.filterCriteria = {
      areaCode,
      prefix,
      nextDigit,
      searchedTerm
    };
    if (this.isLocalNumber) {
      let quantity = this.numberRangeForm.get('quantity').value;
      let continuousRange = this.continuousRange;
      let filterCriteria: FilterCriteria = {
        ...this.filterCriteria,
        quantity,
        continuousRange
      }
      this.filterCriteria = filterCriteria;
      this.newNumberSvc.searchExecuted.next('');
    }
    this.onsearchByNumber.emit(this.filterCriteria);
  }

  public onClickContinousRange(event) {
    this.continuousRange = event.target.checked;
  }

  increaseValue() {
    let value = parseInt(this.quantityModel, 10);
    value++;
    value = (value > 110) ? 110 : isNaN(value/1) ? 1 : value;
    this.quantityModel = value.toString();
  }

  decreaseValue() {
    let value = parseInt(this.quantityModel, 10);
    value--;
    value = (value < 1) || isNaN(value/1) ? 1 : value;
    this.quantityModel = value.toString();
  }

  quantityBlur() {
    let value = parseInt(this.quantityModel, 10);
    value = (value < 1) || isNaN(value/1) ? 1 : (value > 110) ? 110 : value;
    this.quantityModel = value.toString();
  }

  private isInputNumber(value) {
    return !isNaN(value / 1);
  }

  public onTollFreeSearch() {
    let areaCode = this.tollFreeSearchForm.get('areaCodeTollFree').value && this.tollFreeSearchForm.get('areaCodeTollFree').value.value;
    let prefix = this.tollFreeSearchForm.get('containing').value || '';
    let nextDigit = '';

    const msgAreaCode = this.translateSvc.instant('choose_new_number.available_toll_free_area_code');
    const msgContaining = this.translateSvc.instant('choose_new_number.available_toll_free_containing');

    let searchedTerm = msgAreaCode.replace('<area_code>', areaCode);
    if (prefix) {
      searchedTerm += msgContaining.replace('<contained_digits>', prefix);
    }

    //any has value of 'any' to prevent not allowing selecting of item in list
    //so setting the value to the first area code here if it is 'any'
    if(areaCode==='any'){
      areaCode = this.tollFreeAreaCodes[1] ? this.tollFreeAreaCodes[1].value : '';
    }

    let filterCriteria: FilterCriteria = {
      areaCode,
      prefix,
      nextDigit,
      searchedTerm
    };
    this.filterCriteria = filterCriteria;
    this.searchTollFree.emit(this.filterCriteria);
  }

  get containing() { return this.tollFreeSearchForm.get('containing'); }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

  }
}