/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MediaTranscode } from '../models/media-transcode';
import { MediaTranscodeRequest } from '../models/media-transcode-request';
import { PutHoldMusicResponse } from '../models/put-hold-music-response';
import { PutHoldMusicRequest } from '../models/put-hold-music-request';
import { DeleteHoldMusicResponse } from '../models/delete-hold-music-response';
import { HoldMusicListResponse } from '../models/hold-music-list-response';
import { PutHoldMusicListRequest } from '../models/put-hold-music-list-request';
@Injectable({
  providedIn: 'root',
})
class MediaService extends __BaseService {
  static readonly transcodeMediaPath = '/media-transcode';
  static readonly putHoldMusicPath = '/accounts/{accountId}/musics/{musicId}';
  static readonly deleteHoldMusicPath = '/accounts/{accountId}/musics/{musicId}';
  static readonly putHoldMusicListPath = '/settings/accounts/{accountId}/sites/{siteId}/musics';
  static readonly getHoldMusicListPath = '/settings/accounts/{accountId}/sites/{siteId}/musics';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Convert an mp3 file to mulaw or alaw
   * @param body undefined
   * @return 200 response
   */
  transcodeMediaResponse(body: MediaTranscodeRequest): __Observable<__StrictHttpResponse<MediaTranscode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/media-transcode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MediaTranscode>;
      })
    );
  }
  /**
   * Convert an mp3 file to mulaw or alaw
   * @param body undefined
   * @return 200 response
   */
  transcodeMedia(body: MediaTranscodeRequest): __Observable<MediaTranscode> {
    return this.transcodeMediaResponse(body).pipe(
      __map(_r => _r.body as MediaTranscode)
    );
  }

  /**
   * Upload hold music for account. POST new hold music files if not exist or UPDATE files if exist
   * @param params The `MediaService.PutHoldMusicParams` containing the following parameters:
   *
   * - `musicId`: Music Identifier. This ID should be the file name, e.g music.wav
   *
   * - `body`:
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  putHoldMusicResponse(params: MediaService.PutHoldMusicParams): __Observable<__StrictHttpResponse<PutHoldMusicResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/accounts/${params.accountId}/musics/${params.musicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PutHoldMusicResponse>;
      })
    );
  }
  /**
   * Upload hold music for account. POST new hold music files if not exist or UPDATE files if exist
   * @param params The `MediaService.PutHoldMusicParams` containing the following parameters:
   *
   * - `musicId`: Music Identifier. This ID should be the file name, e.g music.wav
   *
   * - `body`:
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  putHoldMusic(params: MediaService.PutHoldMusicParams): __Observable<PutHoldMusicResponse> {
    return this.putHoldMusicResponse(params).pipe(
      __map(_r => _r.body as PutHoldMusicResponse)
    );
  }

  /**
   * Remove hold music for account
   * @param params The `MediaService.DeleteHoldMusicParams` containing the following parameters:
   *
   * - `musicId`: Music Identifier. This ID should be the file name, e.g music.wav
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  deleteHoldMusicResponse(params: MediaService.DeleteHoldMusicParams): __Observable<__StrictHttpResponse<DeleteHoldMusicResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/accounts/${params.accountId}/musics/${params.musicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteHoldMusicResponse>;
      })
    );
  }
  /**
   * Remove hold music for account
   * @param params The `MediaService.DeleteHoldMusicParams` containing the following parameters:
   *
   * - `musicId`: Music Identifier. This ID should be the file name, e.g music.wav
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  deleteHoldMusic(params: MediaService.DeleteHoldMusicParams): __Observable<DeleteHoldMusicResponse> {
    return this.deleteHoldMusicResponse(params).pipe(
      __map(_r => _r.body as DeleteHoldMusicResponse)
    );
  }

  /**
   * Choose the hold music to play during the call. This will update the hold music list for account
   * @param params The `MediaService.PutHoldMusicListParams` containing the following parameters:
   *
   * - `siteId`: Site Identifier
   *
   * - `body`:
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  putHoldMusicListResponse(params: MediaService.PutHoldMusicListParams): __Observable<__StrictHttpResponse<HoldMusicListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/settings/accounts/${params.accountId}/sites/${params.siteId}/musics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HoldMusicListResponse>;
      })
    );
  }
  /**
   * Choose the hold music to play during the call. This will update the hold music list for account
   * @param params The `MediaService.PutHoldMusicListParams` containing the following parameters:
   *
   * - `siteId`: Site Identifier
   *
   * - `body`:
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  putHoldMusicList(params: MediaService.PutHoldMusicListParams): __Observable<HoldMusicListResponse> {
    return this.putHoldMusicListResponse(params).pipe(
      __map(_r => _r.body as HoldMusicListResponse)
    );
  }

  /**
   * Get a list of hold music for the account and the current chosen music to play when call is on hold
   * @param params The `MediaService.GetHoldMusicListParams` containing the following parameters:
   *
   * - `siteId`: Site Identifier
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  getHoldMusicListResponse(params: MediaService.GetHoldMusicListParams): __Observable<__StrictHttpResponse<HoldMusicListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/settings/accounts/${params.accountId}/sites/${params.siteId}/musics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HoldMusicListResponse>;
      })
    );
  }
  /**
   * Get a list of hold music for the account and the current chosen music to play when call is on hold
   * @param params The `MediaService.GetHoldMusicListParams` containing the following parameters:
   *
   * - `siteId`: Site Identifier
   *
   * - `accountId`: Account Identifier
   *
   * @return Success
   */
  getHoldMusicList(params: MediaService.GetHoldMusicListParams): __Observable<HoldMusicListResponse> {
    return this.getHoldMusicListResponse(params).pipe(
      __map(_r => _r.body as HoldMusicListResponse)
    );
  }
}

module MediaService {

  /**
   * Parameters for putHoldMusic
   */
  export interface PutHoldMusicParams {

    /**
     * Music Identifier. This ID should be the file name, e.g music.wav
     */
    musicId: string;
    body: PutHoldMusicRequest;

    /**
     * Account Identifier
     */
    accountId: string;
  }

  /**
   * Parameters for deleteHoldMusic
   */
  export interface DeleteHoldMusicParams {

    /**
     * Music Identifier. This ID should be the file name, e.g music.wav
     */
    musicId: string;

    /**
     * Account Identifier
     */
    accountId: string;
  }

  /**
   * Parameters for putHoldMusicList
   */
  export interface PutHoldMusicListParams {

    /**
     * Site Identifier
     */
    siteId: string;
    body: PutHoldMusicListRequest;

    /**
     * Account Identifier
     */
    accountId: string;
  }

  /**
   * Parameters for getHoldMusicList
   */
  export interface GetHoldMusicListParams {

    /**
     * Site Identifier
     */
    siteId: string;

    /**
     * Account Identifier
     */
    accountId: string;
  }
}

export { MediaService }
