import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  private clickedSchedule: any;
  clickedScheduleChanged = new Subject<any>();

  constructor() { }

  setClickedSchedule(Schedule: any) {
    this.clickedSchedule = Schedule;
    this.clickedScheduleChanged.next(this.clickedSchedule);
  }

  getClickedSchedule() {
    return this.clickedSchedule;
  }
}
