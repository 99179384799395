<div class="container">
	<div class="row header-block">
		<div class="col-md-12 title">
      {{ modalTemp.title | translate }}
      <button id="confirm-close" type="button" class="close" aria-label="Close" (click)="cancel()"><span aria-hidden="true"> &times;</span>
      </button>
    </div>
  </div>
	<div *ngIf="(!modalTemp.isContentHTML)" class="content text-content content-block1">
		<span [innerHTML]="modalTemp.content | translate"></span>
	</div>
    <div *ngIf="(modalTemp.isContentHTML)" class="content content-block2">
        <ng-template appModalContent></ng-template>
    </div>
  <div class="row footer-block">
  	<div class="col-md-12 actions">
        <button id="modal-optional" *ngIf="(modalTemp.optionalBtn != null)" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: modalTemp.optionalBtn.width)}" type="button" class="btn btn-default" (click)="optional()" [disabled]="optionBtnDisabledState">{{ modalTemp.optionalBtn.label | translate }}</button>
        <button id="modal-cancel" *ngIf="(modalTemp.defaultBtn != null)" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: modalTemp.defaultBtn.width)}" type="button" class="btn btn-default" (click)="cancel()">{{ modalTemp.defaultBtn.label | translate }}</button>
        <button id="modal-ok" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: modalTemp.primaryBtn.width)}" type="button" class="btn btn-primary" (click)="ok()" [disabled]="okBtnDisabledState">{{ modalTemp.primaryBtn.label | translate }}</button>
    </div>
  </div>
    <app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" message="{{ 'app.loading' | translate}}"></app-spinner>
</div>
