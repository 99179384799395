import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormatService } from 'src/app/services/format.service';
import { DateFormats, HoursFormats } from '../../constants';
import { FeatureService, FeatureType } from '../../features/features.service';
import { GeographicFeatures } from '../../features/geographic-feature.const';

@Component({
  selector: 'cux-datepicker',
  templateUrl: './cux-datepicker.component.html',
  styleUrls: ['./cux-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CuxDatepickerComponent),
      multi: true
    }]
})
export class CuxDatepickerComponent implements OnInit, OnChanges {
  en: any;
  @ViewChild('cuxDatePickerWrapper', {}) cuxDatePickerWrapper: ElementRef;
  @Input() disabledDates: Array<Date>;
  @Input() disabledDays: Array<number>;
  @Input() value: Date =  null;
  @Input() minDate: Date;
  @Input() maxDate: Date = null;
  @Input() disabled: boolean = null;
  @Input() isTimeOnly = false;
  @Input() appendTo = '';
  @Input() containsError = false;
  @Output() onSelect = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onClickOutside = new EventEmitter();
  @Output() onInput = new EventEmitter();

  firstDayOfWeek: number;
  dateFormat: string;
  hourFormat: string;
  showIcon = true;

  public onTouched: any = Function.prototype; // Trascent the onTouch event
  public placeHolder: string;
  constructor(
    private renderer: Renderer2,
    private translateSvc: TranslateService,
    private formatService: FormatService,
    private featureService: FeatureService) { }

  ngOnInit() {
    this.firstDayOfWeek = this.formatService.getCalenderFirstDay();
    this.translateSvc
    .get([
      'calendar.day_names',
      'calendar.day_short_names',
      'calendar.day_names_min',
      'calendar.month_names',
      'calendar.month_short_names',
      'date_picker.place_holder_US',
      'date_picker.place_holder_UK'
    ]).subscribe(translated => {
      this.en = {
        firstDayOfWeek: this.firstDayOfWeek,
        dayNames: translated['calendar.day_names'],
        dayNamesShort: translated['calendar.day_short_names'],
        dayNamesMin: translated['calendar.day_names_min'],
        monthNames: translated['calendar.month_names'],
        monthNamesShort: translated['calendar.month_short_names'],
       };
      if (this.isTimeOnly) {
        if (this.formatService.is12HoursTimeFormat()) {
          this.hourFormat =  HoursFormats.Hour12;
          this.placeHolder = '--:--:--';
        } else {
          this.hourFormat = HoursFormats.Hour24;
          this.placeHolder = '--:--';
        }
        this.showIcon = false;
      } else {
        this.dateFormat = this.featureService.isEnabled(FeatureType.GEOGRAPHIC, GeographicFeatures.FEATURE.CALENDAR_FORMAT)
          ? DateFormats.DDMMYY : DateFormats.MMDDYY;
        this.placeHolder = this.firstDayOfWeek ? translated['date_picker.place_holder_US'] : translated['date_picker.place_holder_UK'];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    /* istanbul ignore else */
    if (changes.containsError && !changes.containsError.firstChange) {
      if (changes.containsError.currentValue) {
        this.renderer.addClass(this.cuxDatePickerWrapper.nativeElement.getElementsByClassName('ui-inputtext')[0], 'error-border');
      } else {
        this.renderer.removeClass(this.cuxDatePickerWrapper.nativeElement.getElementsByClassName('ui-inputtext')[0], 'error-border');
      }
    }
  }

  writeValue(val: Date) {
    this.value = val;
  }

    // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }


  // not used, used for touch input
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  // the method set in registerOnChange to emit changes back to the form
  public propagateChange = (_: any) => { };

  select(event) {
    this.onSelect.emit(event);
  }

  blur(event) {
    this.onBlur.emit(this.value);
  }

  focus(event) {
    this.onFocus.emit(event);
  }

  close(event) {
    this.onClose.emit(event);
  }

  show(event) {
    this.onShow.emit(event);
  }

  clickOutSide(event) {
    this.renderer.removeClass(this.cuxDatePickerWrapper.nativeElement.getElementsByClassName('ui-calendar')[0], 'add-border-background');
    this.onClickOutside.emit(event);
  }

  input(event) {
    this.onInput.emit(event);
  }
}
