<div class="btn-group" [style.width]="manualWidth ? manualWidthValue :'100%'"  #mydropdown [attr.tabindex]="tabbable && !disabled ? 0 : -1" (blur)="onBlur()" (focus)="onFocus()"  (keydown)="keyEvent($event)">
  <button type="button"
          [ngClass]="{'btn btn-primary': true, 'dropdown-toggle': true, 'truncate': true, 'red-border': redBorder}"
          redBorderclass="btn btn-primary dropdown-toggle truncate" [attr.tabindex]="tabbable && !disabled ? -1 : 0" [disabled]="disabled" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (document:click)="onOutsideClick($event)" (click)="onClick($event)">
      <!-- <a class="button" class="btn btn-primary dropdown-toggle truncate" [ngClass]="{'disabled': disabled}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="onClick($event)"> -->

      {{ value ? (value.displayName | translate) : (selectUser ? ('cl_dropdown.select_user' | translate) : ('cl_dropdown.please_select' | translate)) }}
    <span>
      <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ2ICg0NDQyMykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbi9BcnJvdy9CYXNpYzwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9BcnJvdy9CYXNpYyIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjMTUzMjVGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTEwLDEzLjU4NTc4NjQgTDEuNzA3MTA2NzgsNS4yOTI4OTMyMiBDMS4zMTY1ODI0OSw0LjkwMjM2ODkzIDAuNjgzNDE3NTExLDQuOTAyMzY4OTMgMC4yOTI4OTMyMTksNS4yOTI4OTMyMiBDLTAuMDk3NjMxMDcyOSw1LjY4MzQxNzUxIC0wLjA5NzYzMTA3MjksNi4zMTY1ODI0OSAwLjI5Mjg5MzIxOSw2LjcwNzEwNjc4IEw5LjI5Mjg5MzIyLDE1LjcwNzEwNjggQzkuNjgzNDE3NTEsMTYuMDk3NjMxMSAxMC4zMTY1ODI1LDE2LjA5NzYzMTEgMTAuNzA3MTA2OCwxNS43MDcxMDY4IEwxOS43MDcxMDY4LDYuNzA3MTA2NzggQzIwLjA5NzYzMTEsNi4zMTY1ODI0OSAyMC4wOTc2MzExLDUuNjgzNDE3NTEgMTkuNzA3MTA2OCw1LjI5Mjg5MzIyIEMxOS4zMTY1ODI1LDQuOTAyMzY4OTMgMTguNjgzNDE3NSw0LjkwMjM2ODkzIDE4LjI5Mjg5MzIsNS4yOTI4OTMyMiBMMTAsMTMuNTg1Nzg2NCBaIiBpZD0iUGF0aC0yIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=">
    </span>
<!-- </a> -->
  </button>
  <div class="dropdown-menu" [ngClass]="{'show': showMenu}">
  	<a
        *ngFor="let item of items let i=index"
        class="dropdown-item"
        [ngClass]="{'active': selectedItem === item && this._value}"
        [appFocusScroll]="selectedItem === item"
  	 	(click)="onItemSelect(item)">
       {{ item.displayName | translate}}
       <span class="checked" *ngIf="this._value"></span>
    </a>
  </div>
</div>
