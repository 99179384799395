import { Injectable } from '@angular/core';
import { Days } from '../shared/constants';
import { FeatureService, FeatureType } from '../shared/features/features.service';
import { GeographicFeatures } from '../shared/features/geographic-feature.const';
import { AppUserClaimsService } from './app-user-claims.service';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  countriesFollow12HoursTimeFormat = ['US', 'CANADA', 'UK'];

  constructor(
    private featureService: FeatureService,
    private appService: AppService
    ) {}

  getCalenderFirstDay() {
    return this.featureService.isEnabled(FeatureType.GEOGRAPHIC, GeographicFeatures.FEATURE.CALENDAR_FORMAT) ? Days.Monday : Days.Sunday;
  }

  is12HoursTimeFormat() {
    return this.countriesFollow12HoursTimeFormat.includes(GeographicFeatures.COUNTRY_MAPPING[this.appService.getCountryCode()]);
  }
}
