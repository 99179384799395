import { HttpErrorResponse } from '@angular/common/http';

export class BossApiUtils {

    static extractErrorMessage(error: HttpErrorResponse) {
        // https://stackoverflow.com/questions/48311852/angular-httpclient-error-handling-difficult

        if ( error.error && error.error instanceof ProgressEvent) {
            return 'Network error. Server not reachable/responding or failed CORS pre-fetch.';
        }

        if ( error.error ) {
            // contains JSON body from server
            return error.status + ' ' + error.statusText + ' ' + JSON.stringify(error.error);
        }

        return error.status + ' ' + error.statusText;

    }

}
