/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NotificationEventDC } from '../models/notification-event-dc';
@Injectable({
  providedIn: 'root',
})
class NotificationsService extends __BaseService {
  static readonly NotificationsPublishPath = '/api/Notification/Publish';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `NotificationsService.NotificationsPublishParams` containing the following parameters:
   *
   * - `notificationEvent`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  NotificationsPublishResponse(params: NotificationsService.NotificationsPublishParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.notificationEvent;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Notification/Publish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `NotificationsService.NotificationsPublishParams` containing the following parameters:
   *
   * - `notificationEvent`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  NotificationsPublish(params: NotificationsService.NotificationsPublishParams): __Observable<{}> {
    return this.NotificationsPublishResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module NotificationsService {

  /**
   * Parameters for NotificationsPublish
   */
  export interface NotificationsPublishParams {
    notificationEvent: NotificationEventDC;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { NotificationsService }
