import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MODAL_DATA } from '../../../services/modal.service';
import { ReviewOrderMode } from '../../../models/review-order-mode.enum';
import { OrderPreview } from '../../../flows/invoice-small/invoice-small.model';
import { ShippingSpeed } from '../../../services/shopping-cart.service';
import { fromEvent, Subject } from 'rxjs';
import { filter, pluck, takeUntil } from 'rxjs/operators';

export interface ReviewOrderModalData {
  data: {
    mode: ReviewOrderMode;
    orderDetails: OrderPreview;
  };
}

@Component({
  selector: 'app-review-order',
  templateUrl: './review-order.component.html',
  styleUrls: ['./review-order.component.css']
})
export class ReviewOrderComponent implements OnInit, OnDestroy {
  mode: ReviewOrderMode;
  reviewOrderMode = ReviewOrderMode;
  orderDetails: OrderPreview;
  ShippingSpeed = ShippingSpeed;
  destroy$: Subject<void> = new Subject();

  constructor(
    @Optional() @Inject(MODAL_DATA) private modalData: ReviewOrderModalData,
    private activeModal: NgbActiveModal,
    public translateSvc: TranslateService
  ) {
    if (this.modalData && this.modalData.data) {
      // console.log(
      //   'Function: constructor, this.modalData.data.orderDetails: ',
      //   this.modalData.data.orderDetails
      // );
      this.mode = this.modalData.data.mode;
      this.orderDetails = this.modalData.data.orderDetails;
    }
  }

  ngOnInit() {
    fromEvent(document, 'keyup')
      .pipe(
        takeUntil(this.destroy$),
        pluck('code'),
        filter(key => key === 'Escape')
      )
      .subscribe(() => this.backToEdit());
  }

  backToEdit() {
    this.activeModal.dismiss();
  }

  placeOrder() {
    this.activeModal.close(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
