import { Injectable, OnDestroy } from '@angular/core';
import {InvoiceSmallModel, OrderPreview} from '../flows/invoice-small/invoice-small.model';
import { Subject, Subscription } from 'rxjs';
import { CommonUtils } from '../shared/common-utils';
import { CommitmentDetailsAdaptorService, ContractDetails } from './commitment-details-adaptor.service';
import { BillingImpactDC } from '../modules/boss-api/generated/models';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';


export class ShoppingCart {
  invoice = new InvoiceSmallModel();
  itemCount = 0;        // if 0, shopping cart button will be hidden. If >0, will display in circle to left of scroll ikon on shopping cart button.
  summaryMessage = '';  // will appear to right of scroll ikon on shopping cart button
  contractProfileAmount = 0; // commitment-chart: max profiles/bundles/services/phones/users permitted on contract
  currentProfileAmount = 0;  // commitment-chart: current number of profiles/servics in use
  currentProfileAmountOnProductSelect = 0;
  buildProfileAmount = 0;
}

export interface ShippingInfo {
  shipTo: string;
  shippingSpeed: string;
}

export enum ShippingSpeed {
  STANDARD = 1,
  EXPEDITED = 2
}


/**
 * Generate Invoice for Shopping Cart component, <app-shopping-cart>
 * https://ottjira01.mitel.com/browse/SBOSS-8779
 */
@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService implements OnDestroy {

  private subscriptions: Subscription[] = [];
  cart = new ShoppingCart();

  /**
   * Fires when shopping cart is updated.
   *
   * @type {Subject<ShoppingCart>}
   * @memberof UserInvoiceService
   */
  cartUpdatedSubject: Subject<ShoppingCart> = new Subject();
  /**
   * Fires when shopping cart/invoice/content changes display state.
   * to true -> cart content is displayed
   * to false -> cart content is NOT displayed
   *
   * @type {Subject<boolean>}
   * @memberof ShoppingCartService
   */
  cartDisplayStateChangesSubject: Subject<boolean> = new Subject();

  cartButtonVisibilityInChangeNumberComponent: Subject<boolean> = new Subject();
  cartButtonVisibilityInMainComponent: Subject<boolean> = new Subject();

  placeOrderDetails = new Subject();

  /**
   * Indicate if shopping cart content is currently being displayed
   *
   * @type {boolean}
   * @memberof ShoppingCartService
   */
  isCartOpen = false;

  // data for commitment chart
  translatedStrings = {};
  translatedMonthNames = {};

  private contractDetails: ContractDetails = null;

  constructor(private commitmentSvc: CommitmentDetailsAdaptorService, private translateSvc: TranslateService) {

    this.init();

    this.subscriptions.push(this.translateSvc.get('cart_invoice_messages').subscribe(translated => this.translatedStrings = translated ));

    this.subscriptions.push(this.translateSvc.get('month_names').subscribe(months => this.translatedMonthNames = months));

    this.subscriptions.push(this.cartDisplayStateChangesSubject.subscribe(isOpen => this.isCartOpen = isOpen));
  }

  getCartOpenStatus(): boolean {
    return this.isCartOpen;
  }

  setCartButtonVisibleStatusInChangeNumberComponent(status) {
    this.cartButtonVisibilityInChangeNumberComponent.next(status);
  }

  setCartButtonVisibleStatusInMainComponent(status) {
    this.cartButtonVisibilityInMainComponent.next(status);
  }

  /* istanbul ignore next */
  processContractDetails( contractDetails: ContractDetails ) {
    this.contractDetails = contractDetails;
    // this.contractDetails.contractProfileAmount = contractDetails.contractProfileAmount + 100;  //TODO tmp debugging don
  }

  /**
   * Init the shopping cart invoice and load the customer's commitment contract.
   */
  init() {
    this.cart = new ShoppingCart();
    this.subscriptions.push(this.commitmentSvc.getCommitmentAmountDetails().subscribe(data => this.processContractDetails(data)));
  }

  /**
   * Empty the shopping cart invoice.
   */
  reset() {
    this.cart = new ShoppingCart();
    this.cartUpdatedSubject.next(this.cart);
  }

  ngOnDestroy() {
    this.subscriptions.forEach( s => s.unsubscribe() );
  }


  /**
   * Get last calendar day of month
   * @param year
   * @param month 0-11, if > 11, year will be incremented
   */
  _lastDay(year, month: number) {
    const res = new Date(year, month + 1, 0);  // setting day-of-month to zero is effectively a -1, so getting last day of previous month
    return res.getDate();
  }

  generateOrderPreview(
      billingImpact: BillingImpactDC,
      shipment?: ShippingInfo
  ): OrderPreview {
    console.log(
        'Function: generateOrderPreview, billingImpact: ',
        billingImpact
    );
    if (!billingImpact.recurringImpact) {
      billingImpact.recurringImpact = { items: [] };
    }
    if (!billingImpact.nextImpact) {
      billingImpact.nextImpact = { items: [], invoiceDate: '' };
    }

    let mrcTotal = 0; // Monthly Recurring Cost
    let nrcTotal = 0; // Non Recurring Cost    (aka one-time charge)

    const order = new OrderPreview();
    order.currencyCode = billingImpact.currency
        ? billingImpact.currency.toUpperCase()
        : 'USD';
    order.chargeTo = billingImpact.location;
    if (shipment) {
      order.shipmentDetails.shipTo = shipment.shipTo;
      order.shipmentDetails.shippingSpeed =
          shipment.shippingSpeed === 'standard'
              ? ShippingSpeed.STANDARD
              : ShippingSpeed.EXPEDITED;
    }

    const sectionNextMonth = {
      header: {
        title: this.translateSvc.instant('cart_invoice_messages.next_invoice', {
          date: moment(billingImpact.nextImpact.invoiceDate).format('MMMM YYYY')
        })
      },
      items: [],
      summary: {
        label: '',
        price: '',
        arrowup: false,
        arrowdown: false,
        prefix: ''
      }
    };

    let setupChargesTotal = 0;
    let oneTimeChargesOtherTotal = 0;
    let proratedTotal = 0;
    let proratedStartDate = '';
    let proratedEndDate = '';
    let creditTotal = 0;

    billingImpact.nextImpact.items.forEach(item => {
      switch (item.changeType.toLowerCase()) {
        case 'onetimeadd':
          proratedTotal += item.mrc; // 1st bill covers next to end of month after next. This is prorated monthly charge over that period.
          // Then if there are items with both MRR and NRR total the NRR of these products with the label "One-Time/Setup Charges"
          if (item.mrc !== 0 && item.nrc !== 0) {
            setupChargesTotal += item.nrc; // typically, setup charges on items monthly recurring charges
          } else {
            oneTimeChargesOtherTotal += item.nrc; // one time change but no recurring monthly charge
          }
          // I hope all of the items have same date range
          proratedStartDate = item.startDate;
          proratedEndDate = item.endDate;
          break;

        case 'credit':
          creditTotal += item.mrc;
          break;

          /* istanbul ignore next */
        default:
          console.error(
              'Unknown nextImpact changeType: ' + item.changeType,
              billingImpact
          );
          break;
      }

      nrcTotal += item.totalNRC;

      if (item.nrcWaived === true && item.nrc === 0) {
        sectionNextMonth.items.push({
          label: this.translatedStrings['one_time'],
          price: this.translatedStrings['waived']
        });
      }
      if (item.mrc === 0 && item.nrc !== 0) {
        sectionNextMonth.items.push({
          label: item.productName,
          price: item.nrc.toFixed(2)
        });
      }
    });

    // Credit
    /* istanbul ignore else */
    if (creditTotal !== 0) {
      sectionNextMonth.items.push({
        label: this.translatedStrings['credit'],
        price: creditTotal.toFixed(2)
      });
    }

    // Then if there are items with both MRR and NRR total the NRR of these products with the label "One-Time/Setup Charges"
    /* istanbul ignore else */
    if (setupChargesTotal > 0) {
      sectionNextMonth.items.push({
        label: this.translatedStrings['one_time'],
        price: setupChargesTotal.toFixed(2)
      });
    }

    // Then if there are items with MRR charges, the total prorated MRR (for the current month and the following 2 months) with
    // the label "Initial Monthly Charges (<Date Bill From>-<Date Bill To>)"
    /* istanbul ignore else */
    if (proratedTotal !== 0) {
      sectionNextMonth.items.push({
        label:
            this.translatedStrings['initial_monthly_charges'] +
            ' (' +
            this._buildProratedDateString(proratedStartDate, proratedEndDate) +
            ')',
        price: proratedTotal.toFixed(2)
      });
    }

    // Then the Total charges on the initial invoice labeled "Total"
    // Total should have a currency symbol and an up arrow if positive, down arrow if negative
    const summaryPrice =
        creditTotal +
        setupChargesTotal +
        oneTimeChargesOtherTotal +
        proratedTotal;
    sectionNextMonth.summary.label = this.translatedStrings['total'];
    sectionNextMonth.summary.arrowup = summaryPrice > 0;
    sectionNextMonth.summary.arrowdown = summaryPrice < 0;
    sectionNextMonth.summary.prefix = order.currencyCode;
    sectionNextMonth.summary.price = Math.abs(summaryPrice).toFixed(2);
    order.nextInvoice = sectionNextMonth;
    const sectionRecurring = {
      header: { title: this.translatedStrings['changes_to_monthly_charges'] },
      items: [],
      summary: {
        label: '',
        price: '',
        arrowup: false,
        arrowdown: false,
        prefix: '',
        suffix: ''
      }
    };

    billingImpact.recurringImpact.items.forEach(item => {
      let changeType = '';
      if (!item.changeType) {
        item.changeType = '';
      }
      switch (item.changeType.toLowerCase()) {
        case 'add':
          changeType = this.translatedStrings['add'];
          mrcTotal += item.totalMRC; // totalMRC is MRC* quantity
          break;

        case 'close':
          changeType = this.translatedStrings['remove'];
          mrcTotal += item.totalMRC; // totalMRC is MRC* quantity
          break;

          /* istanbul ignore next */
        default:
          console.error(
              'Unknown recurringImpact changeType: ' + item.changeType,
              billingImpact
          );
          break;
      }

      sectionRecurring.items.push({
        label: changeType + ' ' + item.quantity + ' ' + item.productName,
        price: item.mrc.toFixed(2),
        suffix: '/' + this.translatedStrings['month_short']
      });
    });

    //  if there are items with MRR charges show a "Changes to Monthly Charges" section
    if (sectionRecurring.items.length) {
      sectionRecurring.summary = {
        label: this.translatedStrings['total'],
        arrowup: mrcTotal > 0,
        arrowdown: mrcTotal < 0,
        prefix: order.currencyCode,
        price: mrcTotal.toFixed(2),
        suffix: '/' + this.translatedStrings['month_short']
      };
      order.changes = sectionRecurring;
    }
    console.log('Function: generateOrderPreview, order: ', order);
    return order;
  }

  /**
   * Generate invoice
   * @param billingImpact of type BillingImpactDC as received from BOSS SDApi
   * @param invoiceTitle eg 'Add Order Details'
   */
  generateOrderInvoice(billingImpact: BillingImpactDC, invoiceTitle: string ) {

    if (!billingImpact) { return; } // SBOSS-9551
    if (!billingImpact.recurringImpact) {
      billingImpact.recurringImpact = {items: []};
    }
    if (!billingImpact.nextImpact) {
      billingImpact.nextImpact = {items: [], invoiceDate: ''};
    }

    const currency = CommonUtils.convertCurrency(billingImpact.currency ? billingImpact.currency.toUpperCase() : 'USD');

    let mrcTotal = 0;     // Monthly Recurring Cost
    let nrcTotal = 0;     // Non Recurring Cost    (aka one-time charge)

    this.cart = new ShoppingCart();
    this.cart.invoice.header = { title: invoiceTitle };
    this.cart.invoice.footers = [
      this.translatedStrings['footer_not_included'],
      this.translatedStrings['charged_to'] + ' ' + billingImpact.location
    ];


    /**
     * First if there are items with MRR charges show a "Changes to Monthly Charges" section
        - In the section list the product name of the items that have MRR charges and what the price is, followed by the total
        - Total should have a currency symbol and an up arrow if positive, down arrow if negative
     */

    const sectionRecurring = {
      header: { title: this.translatedStrings['changes_to_monthly_charges'] },
      items: [],
      summary: { label: '', price: '', arrowup: false, arrowdown: false, prefix: '', suffix: '' }
    };

    billingImpact.recurringImpact.items.forEach(item => {
      /**
       * In this code block, MRR, Monthly-Recurring-Cost refers to the pro-rated cost for the
       * duration indicated between item.startDate and item.endDate
      */
      // In the section list the product name of the items that have MRR charges and what the price is, followed by the total
      // if I test for mrc!=0 then will exclude all of the $0 items.
      this.cart.itemCount++;
      let changeType = '';
      if (!item.changeType) { item.changeType = ''; }
      switch (item.changeType.toLowerCase()) {
        case 'add':
          changeType = this.translatedStrings['add'];
          mrcTotal += item.totalMRC;   // totalMRC is MRC* quantity
          break;

        case 'close':
          changeType = this.translatedStrings['remove'];
          mrcTotal += item.totalMRC;   // totalMRC is MRC* quantity
          break;

        /* istanbul ignore next */
        default:
          console.error('Unknown recurringImpact changeType: ' + item.changeType, billingImpact);
          break;
      }

      sectionRecurring.items.push(
        {
          label: changeType + ' ' + item.quantity + ' ' + item.productName,
          price: item.mrc.toFixed(2),
          suffix: '/' + this.translatedStrings['month_short']
        }
      );
    });

    //  if there are items with MRR charges show a "Changes to Monthly Charges" section
    if (billingImpact.recurringImpact.items.length > 0) {
      sectionRecurring.summary = {
        label: this.translatedStrings['total'],
        arrowup: mrcTotal > 0,
        arrowdown: mrcTotal < 0,
        prefix: currency,
        price: mrcTotal.toFixed(2),
        suffix: '/' + this.translatedStrings['month_short']
      };
      this.cart.invoice.sections.push(sectionRecurring);
    }



    /**
     * Next Month invoice
     *
     * Then show a section for the changes on the next invoice, labeled "<Invoicename> Invoice"
          -  Then if there are items with NRR charges only, list the product name of the items that have NRR charges and the price
          -  Then if there are items with both MRR and NRR total the NRR of these products with the label "One-Time/Setup Charges"
                -  If all the "One-Time/Setup Charges" have been waived show "Waived" instead of the price
                    Note: The first x phone services will have waived NRR, where x is the contract profile amount
          -  Then if there are items with MRR charges, the total prorated MRR (for the current month and the following 2 months) with the label "Initial Monthly Charges (<Date Bill From>-<Date Bill To>)"
          -  Then the Total charges on the initial invoice labeled "Total"
          -  Total should have a currency symbol and an up arrow if positive, down arrow if negative
    */

    const sectionNextMonth = {
      header: { title: this._parseTranslatedInvoiceDate(billingImpact.nextImpact.invoiceDate) + ' ' + this.translatedStrings['charges'] },
      items: [],
      summary: { label: '', price: '', arrowup: false, arrowdown: false, prefix: '' }
    };

    let setupChargesTotal = 0;
    let oneTimeChargesOtherTotal = 0;
    let proratedTotal = 0;
    let proratedStartDate = '';
    let proratedEndDate = '';
    let creditTotal = 0;

    billingImpact.nextImpact.items.forEach(item => {

      switch (item.changeType.toLowerCase()) {
        case 'onetimeadd':
          proratedTotal += item.mrc;       // 1st bill covers next to end of month after next. This is prorated monthly charge over that period.
          // Then if there are items with both MRR and NRR total the NRR of these products with the label "One-Time/Setup Charges"
          if (item.mrc !== 0 && item.nrc !== 0 ) {
              setupChargesTotal += item.nrc;   // typically, setup charges on items monthly recurring charges
          } else {
            oneTimeChargesOtherTotal += item.nrc;     // one time change but no recurring monthly charge
          }
          // I hope all of the items have same date range
          proratedStartDate = item.startDate;
          proratedEndDate = item.endDate;
          break;

        case 'credit':
          creditTotal += item.mrc;
          break;

        /* istanbul ignore next */
        default:
          console.error('Unknown nextImpact changeType: ' + item.changeType, billingImpact);
          break;
      }

      nrcTotal += item.totalNRC;

      // Then if there are items with NRR charges only, list the product name of the items that have NRR charges and the price
      if (item.mrc === 0 && item.nrc !== 0) {
        sectionNextMonth.items.push({
          label: item.productName,
          price: item.nrc.toFixed(2)
        });
      }
    });

    // Credit
    /* istanbul ignore else */
    if ( creditTotal !== 0) {
      sectionNextMonth.items.push( {
        label: this.translatedStrings['credit'],
        price: creditTotal.toFixed(2)
      });
    }

    // Then if there are items with both MRR and NRR total the NRR of these products with the label "One-Time/Setup Charges"
    /* istanbul ignore else */
    if ( setupChargesTotal > 0) {
      sectionNextMonth.items.push({
        label: this.translatedStrings['one_time'],
        price: setupChargesTotal.toFixed(2)
        // price: nrcTotal > 0 ? nrcTotal.toFixed(2) : this.translatedStrings['waived'] // If all the "One-Time/Setup Charges" have been waived show "Waived" instead of the price
      });
    }

    // Then if there are items with MRR charges, the total prorated MRR (for the current month and the following 2 months) with
    // the label "Initial Monthly Charges (<Date Bill From>-<Date Bill To>)"
    /* istanbul ignore else */
    if ( proratedTotal !== 0) {
      sectionNextMonth.items.push({
        label: this.translatedStrings['initial_monthly_charges'] + ' (' + this._buildProratedDateString(proratedStartDate, proratedEndDate) + ')',
        price: proratedTotal.toFixed(2)
      });
    }

    // Then the Total charges on the initial invoice labeled "Total"
    // Total should have a currency symbol and an up arrow if positive, down arrow if negative
    const summaryPrice = creditTotal + setupChargesTotal + oneTimeChargesOtherTotal + proratedTotal;
    sectionNextMonth.summary.label = this.translatedStrings['total'];
    sectionNextMonth.summary.arrowup = summaryPrice > 0;
    sectionNextMonth.summary.arrowdown = summaryPrice < 0;
    sectionNextMonth.summary.prefix = currency;
    sectionNextMonth.summary.price = summaryPrice.toFixed(2);
    /* istanbul ignore else */
    if (summaryPrice !== 0) {
    this.cart.invoice.sections.push(sectionNextMonth);
    }

    // this.cart.itemCount = billingImpact.recurringImpact.items.length;
    this.cart.summaryMessage = this._buildCartSummaryMessage(nrcTotal, mrcTotal, currency);

    // setup the contract commitment amounts
    if (this.contractDetails) {
      this.cart.contractProfileAmount = this.contractDetails.contractProfileAmount;
      this.cart.currentProfileAmount = this.contractDetails.builtProfileAmount;
      // increment the count of services 'currently allocated' to include this new service.
      this.cart.currentProfileAmountOnProductSelect = this.contractDetails.builtProfileAmount + 1;
    }

    this.cartUpdatedSubject.next(this.cart);
  }


  /**
   * show the billing impact total in this format:
        If there is only MRR: "<currency symbol><total MRR>/mo."
        If there is only NRR: "<currency symbol><total NRR>"
        If there is both MRR and NRR: "<currency symbol><total NRR> + <currency symbol><total MRR>/mo."
   * @param nrc
   * @param mrc
   * @param currency  $, £, etc
   */
  _buildCartSummaryMessage(nrc: number, mrc: number, currency: string) {
    let msg = '';
    msg += (nrc !== 0) ? currency + nrc.toFixed(2) : '';
    msg += nrc !== 0  ? ' + ' : '';
    msg += currency + mrc.toFixed(2) + '/' + this.translatedStrings['month_short'];

    return msg;
  }


  /**
   * Converts a string like "2019-06-04T00:00:00-07:00"
   * to "6/04"
   * @param dateStr
   */
  _createDisplayDate(dateStr: string) {
    if ( dateStr === null || dateStr === '') {
      return '';
    }
    const d = moment(dateStr, 'YYYY-MM-DD');
    return d.format('M') + '/' + d.format('DD');
  }

  /**
   * Create a billing invoice prorated date string, suitable for insertion into brackets () of this string:
   *  "Initial Monthly Charges (<Date Bill From>-<Date Bill To>)"
   * @param billFrom
   * @param billTo
   */
  _buildProratedDateString( billFrom: string, billTo: string) {
    return this._createDisplayDate(billFrom) + '-' + this._createDisplayDate(billTo);
  }

  /**
   * Get translated month YYYY string from a date string like "2019-12-04T00:00:00-07:00"
   * Will return 'December 2019'
   * @param invoiceDate
   */
  _parseTranslatedInvoiceDate( invoiceDate: string) {
    const d = moment(invoiceDate, 'YYYY-MM-DD');
    if (d.isValid()) {
      return this.translatedMonthNames[ d.month() + 1 ] + ' ' + d.year();
    } else {
      return '';
    }
  }

}
