<div class="container">
  <div class="row">
      <div class="col-md-12 title">
          {{ title | translate }}
          <button id="flows-create-close" type="button" class="close closeX" aria-label="Close" (click)="cancel()" aria-hidden="true" >
              &#10005;
          </button>
      </div>
  </div>
  <hr>
  <div class="content">
    <div *ngIf="showFormError" class="errorPlacement">
        <cl-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></cl-error-alert>
    </div>
            
    <!-- Uncomment if other errors need to be displayed
        <div *ngFor="let errmsg of otherErr" class="text-danger error-align"> {{errmsg}} </div> -->
    <form [formGroup]="flowsForm" (ngSubmit)="save()" id="flowsPanel">
      <div formGroupName="flowsSection">
        <div class="container">
            <div class="row">
                <div class="form-group col-7 name">
                  <label for="name">{{ 'hunt_group.name' | translate }}</label>
                    <input
                      type="text"
                      formControlName="name"
                      id = "name"
                      #name
                      [attr.tabindex]="tabbable ? 0 : -1"
                      maxlength="50"
                      class="form-control">
                    <div class="text-danger"
                         *ngIf="flowsForm.get('flowsSection.name').touched && flowsForm.get('flowsSection.name').hasError('required')">
                        {{ 'hunt_group.name_required' | translate }}
                    </div>
                </div>
                <div class="form-group col-3 extn">
                  <label for="extension">{{ 'hunt_group.extension' | translate }}</label>
                    <input
                      type="text"
                      formControlName="extension"
                      maxlength="50"
                      [attr.tabindex]="tabbable ? 0 : -1"
                      (blur)="validateExtension(flowsForm.get('flowsSection.extension').value)"
                      class="form-control">
                    <div class="text-danger"
                         *ngIf="flowsForm.get('flowsSection.extension').touched && flowsForm.get('flowsSection.extension').hasError('required')">
                        {{ 'hunt_group.phone_number_required' | translate }}
                    </div>
                    <div class="text-danger" *ngIf="extnErrMessage">
                        {{ extnErrMessage}}
                    </div>
                    <div class="text-danger" *ngIf="dnErr != ''">
                        {{ dnErr}}
                    </div>
            </div>
            </div>
        </div>
      </div>
    </form>

  </div>
  <hr> 
  <div class="row">
      <div class="col-md-12 actions">
          <button id="flows-create-cancel" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: 83)}" type="button" class="btn btn-default" (click)="cancel()">{{ 'flows_create_model.cancel' | translate }}</button>
          <button id="flows-create-delete" [ngStyle]="{'width.px': (translateSvc.currentLang !== 'en' ? 150: 180)}" type="button" class="btn btn-primary" (click)="save()">{{ addText | translate }}</button>
      </div>
  </div>
</div>

<app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" message="{{ 'app.loading' | translate}}"></app-spinner>
