import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FlowsService} from 'src/app/services/flows.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {NavigationConfirmationComponent} from 'src/app/navigation-confirmation/navigation-confirmation.component';
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {rightPanelCloseReason, Countries, CountryLanguageMapping} from '../../shared/constants';
import {CallflowAdaptorService, SubMenu, SubMenuItem} from 'src/app/services/callflow-adaptor.service';
import {DeleteConfirmationComponent} from 'src/app/delete-confirmation/delete-confirmation.component';
import {FlowsCreateModelComponent} from 'src/app/flows/flows-create-model/flows-create-model.component';
import { RemovePhoneComponent } from 'src/app/flows/remove-phone/remove-phone.component';
import { debounceTime, switchMap, filter } from 'rxjs/operators';
import {LocationAdaptorService, Location, LocationTypes} from 'src/app/services/location-adaptor.service';
import * as _ from 'lodash';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import {CommonUtils} from '../../shared/common-utils';
import {PHONE_NUMBER_ASSIGNMENTS, PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING, UserPhoneService} from '../../services/phone-service';
import {PhoneServicesService} from '../../services/phone-services.service';
import {PhoneNumberFormatService} from '../../services/phone-number-format.service';
import {BillingImpactDC, LocationDC, QuotePriceProductDC, QuotePriceRequestDC} from 'src/app/modules/boss-api/generated/models';
import { TnAdapterService } from '../../services/tn-adapter.service';
import {PhoneNumber} from '../../services/tn-adaptor';
import {ModalTemplate} from '../../cl-modal/modal-template';
import {ClModalComponent} from '../../cl-modal/cl-modal.component';
import { NewNumberComponent } from '../../shared/components/new-number/new-number.component';
import {ItemsWithHeading} from '../../shared/common.interface';

import { ExtensionListRightPanelComponent } from '../../extension-list/extension-list-right-panel/extension-list-right-panel.component';
import { OnHoursScheduleComponent } from 'src/app/schedules/on-hours-schedule/on-hours-schedule.component';
import { HolidayScheduleComponent } from 'src/app/schedules/holiday-schedule/holiday-schedule.component';
import { CustomScheduleComponent } from 'src/app/schedules/custom-schedule/custom-schedule.component';
import { NotificationService } from '../../services/notification.service';
import { FlowVisualizerService } from 'src/app/services/flow-visualizer.service';
import { flowComponentCRUDActionValues, callFlowComponentTypeValues, newComponentId } from '../../shared/constants';
import {  HierarchyDatum } from '../../models/flow-model';
import { PromptUploaderComponent } from '../prompt-uploader/prompt-uploader.component';
import {ClHeaderComponent} from '@mitel/cloudlink-console-components';
import {ChangePhoneNumberComponent, ChangeNumberType, OldNumberAction,
  ChangePhoneNumberData } from 'src/app/flows/change-phone-number/change-phone-number.component';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {ProductsService} from '../../modules/boss-api/generated/services/products.service';
import {TemporaryNumber} from '../temporary-number/temporary-number.component';
import {ReviewOrderComponent} from '../../shared/components/review-order/review-order.component';
import {ReviewOrderMode} from '../../models/review-order-mode.enum';
import {ModalService} from '../../services/modal.service';
import {ClModalService} from '../../services/cl-modal.service';
import {OnHoursBlockComponent} from './on-hours-block/on-hours-block.component';
import {OffHoursBlockComponent} from './off-hours-block/off-hours-block.component';
import {HolidaysBlockComponent} from './holidays-block/holidays-block.component';
import {CustomsBlockComponent} from './customs-block/customs-block.component';

import { FeatureService, FeatureType } from 'src/app/shared/features/features.service';
import { GeographicFeatures } from 'src/app/shared/features/geographic-feature.const';
import { AppService } from 'src/app/services/app.service';
export interface Op {
  value: string;
  displayName: string;
  // constructor(value: string, displayName: string) {
  //   this.value = value;
  //   this.displayName = displayName;
  // }
}

export class NewOption {
  key: number;
  opcode: number;
  extension: string;
  extensionFormatted: string;
  textDest: string;
  ddDest: string;
  displayString: string;
  listId: number;

  constructor(key: number, opcode: number,
              extension: string, extensionFormatted: string,
              displayString: string, listId: number,
              textDest: string, ddDest: string) {
    this.key = key;
    this.opcode = opcode;
    this.extension = extension;
    this.extensionFormatted = extensionFormatted;
    this.displayString = displayString;
    this.textDest = textDest;
    this.ddDest = ddDest;
    this.listId = listId;
  }
}

@Component({
  selector: 'app-auto-attendant',
  templateUrl: './auto-attendant.component.html',
  styleUrls: ['./auto-attendant.component.scss']
})
export class AutoAttendantComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accountId: string;
  @Input() tabbable = true;
  @Input() toOpen: boolean;
  @Input() visualizerElRef: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() collapse: EventEmitter<any> = new EventEmitter();

  @Output() resetToOpen = new EventEmitter();
  @Output() newPhoneNumberSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() clearServerError: EventEmitter<void> = new EventEmitter<void>();
  @Output() serverError: EventEmitter<string> = new EventEmitter<string>();
  @Output() tempNumberUpdated: EventEmitter<TemporaryNumber> = new EventEmitter<TemporaryNumber>();
  @ViewChild('name') nameElement: ElementRef;
  @ViewChild('newPhoneNumberDigits') newPhoneNumberDigits: ElementRef;

  // define constants
  OP_TO_EXT = '15';
  OP_TO_EXT_W_PROMPT = '7';
  OP_TO_AA = '5';
  OP_TO_HG = '150';
  OP_DIAL_BY_FIRST_NAME = '14';
  OP_DIAL_BY_LAST_NAME = '1';
  OP_LEAVE_MSG = '9';
  OP_LEAVE_MSG_BY_FIRST_NAME = '13';
  OP_LEAVE_MSG_BY_LAST_NAME = '12';
  OP_LOGIN_VM = '16';
  OP_REPEAT = '2';
  OP_DISCONNECT = '11';
  OP_EXTENSION_TO_EXT = '151';
  OP_EXTENSION_TO_EXT_PROMPT = '71';
  OP_EXTENSION_TO_MSG = '91';

  // add int version to prevent parseInt again and again
  IOP_TO_EXT = 15;
  IOP_TO_EXT_W_PROMPT = 7;
  IOP_TO_AA = 5;
  IOP_TO_HG = 150;
  IOP_DIAL_BY_FIRST_NAME = 14;
  IOP_DIAL_BY_LAST_NAME = 1;
  IOP_LEAVE_MSG = 9;
  IOP_LEAVE_MSG_BY_FIRST_NAME = 13;
  IOP_LEAVE_MSG_BY_LAST_NAME = 12;
  IOP_LOGIN_VM = 16;
  IOP_REPEAT = 2;
  IOP_DISCONNECT = 11;
  IOP_EXTENSION_TO_EXT = 151;
  IOP_EXTENSION_TO_EXT_PROMPT = 71;
  IOP_EXTENSION_TO_MSG = 91;

  TIME_TYPE_ON_HOURS = 1;
  TIME_TYPE_OFF_HOURS = 2;
  TIME_TYPE_HOLIDAY = 3;
  TIME_TYPE_CUSTOM = 4;

  options: Op[] = [
    {value: '0', displayName: 'Press 0'},
    {value: '1', displayName: 'Press 1'},
    {value: '2', displayName: 'Press 2'},
    {value: '3', displayName: 'Press 3'},
    {value: '4', displayName: 'Press 4'},
    {value: '5', displayName: 'Press 5'},
    {value: '6', displayName: 'Press 6'},
    {value: '7', displayName: 'Press 7'},
    {value: '8', displayName: 'Press 8'},
    {value: '9', displayName: 'Press 9'},
    {value: '10', displayName: 'Press *'},
    {value: '11', displayName: 'Press #'},
    {value: '15', displayName: 'Extension'}
  ];
  optionsDefault: Op[] = [
    {value: '0', displayName: 'Press 0'},
    {value: '1', displayName: 'Press 1'},
    {value: '2', displayName: 'Press 2'},
    {value: '3', displayName: 'Press 3'},
    {value: '4', displayName: 'Press 4'},
    {value: '5', displayName: 'Press 5'},
    {value: '6', displayName: 'Press 6'},
    {value: '7', displayName: 'Press 7'},
    {value: '8', displayName: 'Press 8'},
    {value: '9', displayName: 'Press 9'},
    {value: '10', displayName: 'Press *'},
    {value: '11', displayName: 'Press #'},
    {value: '15', displayName: 'Extension'}
  ];

  actionLong: Op[] = [
    {value: '15', displayName: 'Transfer to extension'},
    {value: '7', displayName: 'Transfer to extension, with prompt'},
    {value: '5', displayName: 'Transfer to auto-attendant'},
    {value: '150', displayName: 'Transfer to hunt group'},
    {value: '14', displayName: 'Dial by first name'},
    {value: '1', displayName: 'Dial by last name'},
    {value: '9', displayName: 'Leave a message'},
    {value: '13', displayName: 'Leave a message by first name'},
    {value: '12', displayName: 'Leave a message by last name'},
    {value: '16', displayName: 'Log in to voice mail'},
    {value: '2', displayName: 'Repeat'},
    {value: '11', displayName: 'Disconnect'}
  ];

  actionTooManyErrors: Op[] = [
    {value: '15', displayName: 'Transfer to extension'},
    {value: '7', displayName: 'Transfer to extension, with prompt'},
    {value: '5', displayName: 'Transfer to auto-attendant'},
    {value: '150', displayName: 'Transfer to hunt group'},
    {value: '9', displayName: 'Leave a message'},
    {value: '16', displayName: 'Log in to voice mail'},
    {value: '11', displayName: 'Disconnect'}
  ];

  actionExtension: Op[] = [
    {value: '151', displayName: 'Transfer to entry'},
    {value: '71', displayName: 'Transfer to entry, with prompt'},
    {value: '91', displayName: 'Leave a message for entry'},
    {value: '16', displayName: 'Log in to voice mail'}
  ];

  aaForm: FormGroup;

  aa: any;
  origDN = '';
  extensionFromD2: string;
  subMenus: any[];
  showAllTheTimes = true;
  showOnHours = false;
  showOffHours = false;
  showHoliday = false;
  showCustom = false;

  // for on-hours tab, timetypeid = 1
  hideCustomStep1 = true;
  hideOpList1 = false;
  showAllOptions1 = false;

  // for timetypeid = 2, 3, 4
  hideCustomStep2 = true;
  hideCustomStep3 = true;
  hideCustomStep4 = true;
  hideOpList2 = false;
  hideOpList3 = false;
  hideOpList4 = false;
  showAllOptions2 = false;
  showAllOptions3 = false;
  showAllOptions4 = false;
  opcodeDD1 = this.actionLong;

  submitting = false;
  locations: Location[] = [];
  languages: any[] = [];
  phoneTypes: any[] = [];
  extensionList: any[] = [];
  huntGroups: any[] = [];
  autoAttendants: any[] = [];

  hoursSchedules: any[] = [];
  holidaySchedules: any[] = [];
  customSchedules: any[] = [];

  // option list for keys 0-11, 15.  mainOptions1 means for time type 1.
  mainOptions1: Array<NewOption> = [];
  mainOptions2: Array<NewOption> = [];
  mainOptions3: Array<NewOption> = [];
  mainOptions4: Array<NewOption> = [];

  clickedFlowSubscription: Subscription;
  subscriptions: Subscription[] = [];

  modalRef: NgbModalRef;
  closeReason: string;
  deleteConfirmDisplayFlag = false;

  tenantPrefix = '';
  isCreate = false;
  displayLoadingSpinner = false;
  canDeleteAA = false;

  key12Op1DestList: any;
  key12Op2DestList: any;
  newOpCodeDestList: any;
  key14OpDestList: any;
  key13OpDestList: any;

  offHoursKey12Op1DestList: any;
  offHoursKey12Op2DestList: any;
  offHoursNewOpCodeDestList: any;
  offHoursKey14OpDestList: any;
  offHoursKey13OpDestList: any;

  holidayKey12Op1DestList: any;
  holidayKey12Op2DestList: any;
  holidayNewOpCodeDestList: any;
  holidayKey14OpDestList: any;
  holidayKey13OpDestList: any;

  customKey12Op1DestList: any;
  customKey12Op2DestList: any;
  customNewOpCodeDestList: any;
  customKey14OpDestList: any;
  customKey13OpDestList: any;

  phoneNumberAssignment;
  rxjsSubscriptions: Subscription[] = [];
  addingNewPhone = false;
  currentUserPhoneService: UserPhoneService;
  loadingNewNumber = false;
  defaultPhoneNumber: PhoneNumber;
  defaultPhoneNumberValue: string;
  availablePhoneNumbers: ItemsWithHeading[];
  translated;
  imgname: string;

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = {'max-width': '650px'};

  extnErrMessage = '';
  errorMsg: string[];
  dnErr = '';

  // existingExtnErr: string;
  otherErr: string[] = [];
  user: any;
  timeTypeId: number;
  TIME_TYPE_MAPPING = { '1': 0, '2': 1, '3': 2, '4': 3 };
  @ViewChild('onHoursPrompt') onHoursFileElement: ElementRef;
  @ViewChild('onHoursPromptPreview') onHoursFileElementPreview: ElementRef;
  @ViewChild('holidayPrompt') holidayFileElement: ElementRef;
  @ViewChild('holidayPromptPreview') holidayFileElementPreview: ElementRef;
  @ViewChild('offHoursPrompt') offHoursFileElement: ElementRef;
  @ViewChild('offHoursPromptPreview') offHoursFileElementPreview: ElementRef;
  @ViewChild('customPrompt') customFileElement: ElementRef;
  @ViewChild('customPromptPreview') customFileElementPreview: ElementRef;
  @ViewChild('onHoursAudio') onHoursAudioElement: ElementRef;
  @ViewChild('offHoursAudio') offHoursAudioElement: ElementRef;
  @ViewChild('holidayAudio') holidayAudioElement: ElementRef;
  @ViewChild('customAudio') customAudioElement: ElementRef;
  openOnHoursAudio = false;
  openOffHoursAudio = false;
  openHolidayAudio = false;
  openCustomAudio = false;
  loadedByIframe = false;
  temporaryTn: TemporaryNumber = { rcfTarget: null, mgmtGUID: null };
  usingExistingTn = false;
  defaultTnPortedOrTransferred = false;
  defaultUserCountryId = 840;
  countingCurrent = '';
  newPhoneNumber: any;
  isCartOpen = false;
  isVisError = false;
  wasIgnoreClicked = false;
  loadingLocations = true;
  aaLocation: LocationDC;
  disableLocation = false;
  updateAa = false;
  containsMouseUp = true;
  containsMouseDown = true;
  placeOrderDetails: BillingImpactDC;
  hoursTimeoutErr = '';
  hoursAfterThreeErr = '';
  hoursInvalidDestErr = '';
  offHoursTimeoutErr = '';
  offHoursAfterThreeErr = '';
  offHoursInvalidDestErr = '';
  holidayTimeoutErr = '';
  holidayAfterThreeErr = '';
  holidayInvalidDestErr = '';
  customTimeoutErr = '';
  customAfterThreeErr = '';
  customInvalidDestErr = '';
  @ViewChild(OnHoursBlockComponent ) onHoursComp: OnHoursBlockComponent ;
  @ViewChild(OffHoursBlockComponent ) offHoursComp: OffHoursBlockComponent ;
  @ViewChild(HolidaysBlockComponent ) holidayComp: HolidaysBlockComponent ;
  @ViewChild(CustomsBlockComponent ) customComp: CustomsBlockComponent ;
  @ViewChild('aaBlock') aaBlock: ElementRef;

  constructor(public flowsSvc: FlowsService,
    private aaSvc: CallflowAdaptorService,
    public translateSvc: TranslateService,
    public tnAdapterSvc: TnAdapterService,
    private modalSvc: NgbModal,
    private modalService: ModalService,
    private clModalService: ClModalService,
    private elRef: ElementRef,
    private notificationService: NotificationService,
    private locSvc2: LocationAdaptorService,
    public flowVizSvc: FlowVisualizerService,
    private clHeader: ClHeaderComponent,
    public phSvc: PhoneServicesService,
    private phoneNumberFormatService: PhoneNumberFormatService,
    private shoppingCartService: ShoppingCartService,
    private productsServices: ProductsService,
    private featureService: FeatureService,
    private appService: AppService) {

    console.log('======Toolbar visibility==========');
    this.loadedByIframe = !clHeader.headerAuth.isToolbarVisible();
    console.log('==================================');
}



ngOnInit() {

    this.subscriptions.push(this.flowsSvc.componentNavigateIntent.subscribe(() => {
      this.resetToOpen.emit();
      this.toOpen = false;
      this.closeReason = null;
      this.checkDirtyAndCall(this.allowNavigateAway, {navigateAway: true});
    }));
    this.subscriptions.push(this.flowsSvc.rightPanelCloseInitiated.subscribe(() => {
      this.closeReason = null;
      this.onClose();
    }));

    this.subscriptions.push(this.flowsSvc.rightPanelCloseInitiated.subscribe(this.onClose));
    this.subscriptions.push(this.flowsSvc.clickedFlowChanged.subscribe(async aa => {
      this.aa = aa;
      if (aa != null && aa.componentType === callFlowComponentTypeValues.aa) {
        this.canDeleteAA = this.aa ? this.aa.canDelete : false;
        this.setFormWithAA();
      }
    }));
    this.aa = this.flowsSvc.getClickedFlow();
    this.canDeleteAA = this.aa ? this.aa.canDelete : false ;
    this.initForm();
    this.phoneNumberAssignment = PHONE_NUMBER_ASSIGNMENTS;
    this.setFormWithAA();
    // this.aaForm.get('aaSection').patchValue({location: {value: 'f8ed505b-3346-41e1-83cb-7f7631cf6b70'}});
    // this.aaForm.get('aaSection').patchValue({language: {value: '1'}});
    // this.aaForm.get('aaSection.onHoursSection').patchValue({key_12_opcode1: {value: this.OP_REPEAT}});
    // this.aaForm.get('aaSection.onHoursSection').patchValue({key_12_opcode2: {value: this.OP_REPEAT}});
    // this.aaForm.get('aaSection.onHoursSection').patchValue({key_14_opcode: {value: this.OP_REPEAT}});
    // this.aaForm.get('aaSection.onHoursSection').patchValue({key_13_opcode: {value: this.OP_DISCONNECT}});

  this.rxjsSubscriptions.push(this.translateSvc.get(['cl_dropdown_headers',
    'notification_messages', 'error_messages', 'hunt_group', 'base64_images', 'cl_dropdown']).subscribe(strings => {
    this.translated = strings;
    // console.log('Translated Strings', this.translated);
  }));

  this.rxjsSubscriptions.push(this.phSvc.getChangeLocationSubject().subscribe(strings => {
    if (this.addingNewPhone) {
      this.onChangedPhoneNumberAssignment({value: 'New'});
    }
  }));
  this.imgname = this.translated.base64_images.loading_360;
  let locationId;
  if (this.aa) {
    locationId = this.aa.locationUUID;
  } else {
    this.locations && this.locations.length > 0 ? locationId = this.locations[0].value : locationId = null;
  }
  this.phSvc.setSelectedLocation( locationId );
  // This method will actually get called twice as it is awaiting separate calls even though it emits one final result
  // this.rxjsSubscriptions.push(this.phSvc.getAvailPhoneNumbers(this.aa, locationId).subscribe(availablePhoneNumbers => {
  //   this.availablePhoneNumbers = availablePhoneNumbers;
  //   this.defaultPhoneNumber = this.availablePhoneNumbers.length > 0 ? this.availablePhoneNumbers[0].items[0] : undefined;
  //   this.defaultPhoneNumberValue = _.head(this.phSvc.getAggregatedPhoneNumbers());
  // }));

  this.rxjsSubscriptions.push(
      this.shoppingCartService.cartButtonVisibilityInMainComponent.subscribe(
          cartButtonVisibile => this.isCartOpen = cartButtonVisibile));

  this.rxjsSubscriptions.push(this.flowVizSvc.visualizationError.subscribe((isVisErr) => {
    if (isVisErr) {
      this.isVisError = true;
    } else {
      this.isVisError = false;
    }
  }));

  this.rxjsSubscriptions.push(this.flowVizSvc.ignoreClicked.subscribe(() => {
    this.wasIgnoreClicked = true;
  }));

  this.rxjsSubscriptions.push(this.shoppingCartService.placeOrderDetails.subscribe((placeOrderDetails) => {
    this.placeOrderDetails = placeOrderDetails;
  }));

  }

  async getAvailNumbers() {
    await this.phSvc.getAvailPhoneNumbers(this.aa, this.phSvc.getSelectedLocation()).toPromise().then(availablePhoneNumbers => {
          this.availablePhoneNumbers = availablePhoneNumbers;
          if (this.availablePhoneNumbers.length > 0) {
            this.availablePhoneNumbers[0].items.unshift({
              value: null,
              displayName: this.translated.hunt_group.select_phone_number
            });
            // if (
            //     !_.isEmpty(this.availablePhoneNumbers) && this.availablePhoneNumbers[0].heading ===
            //     'cl_dropdown_headers.ported'
            // ) {
            //   this.defaultTnPortedOrTransferred = true;
            // }
            this.defaultPhoneNumber = this.availablePhoneNumbers[0].items[1];
            this.phoneNumberAssignment = PHONE_NUMBER_ASSIGNMENTS;
          } else {
            this.defaultPhoneNumber = undefined;
            this.phoneNumberAssignment = PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING;
          }
          /** To remove the New option for UK */
          const isDisabled = this.featureService.isDisabled(FeatureType.GEOGRAPHIC, GeographicFeatures.FEATURE.CALL_FLOW_ADD_NEW_PHONE_NO);
          if (isDisabled) {
           this.phoneNumberAssignment = this.phoneNumberAssignment.filter((item) => item.value!== 'New');
          }
          this.defaultPhoneNumberValue = _.head(this.phSvc.getAggregatedPhoneNumbers());
        },
        error => {
          // this.showServerErrorOnForm(this.translated.error_messages.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
          console.error(BossApiUtils.extractErrorMessage(error));
        });
  }

  async initLocationsList() {
    let locationId = null;
    try {
      // this.locations = await this.locSvc.getLocationsMinimalList(this.accountId);
      await this.locSvc2.getLocations(this.accountId)
        .toPromise().then(
          data => {
            this.locations = _.cloneDeep(data);
            this.loadingLocations = false;
            this.locations && this.locations.length > 0 ? locationId = this.locations[0].value : locationId = null;
            this.phSvc.setSelectedLocation( locationId );
          },
          error => {
            console.error(error);
          });
    } catch (reason) {
      this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
        this.showServerErrorOnForm( err.location_list + ': ' + BossApiUtils.extractErrorMessage(reason));
      }));
      console.error('failed to retrieve locations list', reason);
    }
  }


  // close panel on esc key
  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.deleteConfirmDisplayFlag) {
      this.onClose();
    }
  }

  @HostListener('document:touchend', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:mousedown', ['$event'])
  clickedOutside(event) {
    // check if it is the badge or pendo related menus and don't close if it is
    if (event.target && event.target.className &&
      (event.target.className === 'fade-in' ||
        (event.target.id && event.target.id.includes('pendo') && event.target.className.includes('pendo')))) {
      return;
    }

    // checking if the 'mouseup' or 'mousedown' events don't occur within the panel
    if (event.type === 'mouseup' && !this.elRef.nativeElement.contains(event.target)) {
      this.containsMouseUp = false;
    }

    if (event.type === 'mousedown' && !this.elRef.nativeElement.contains(event.target)) {
      this.containsMouseDown = false;
    }

    // did user click outside of this element?
    if (event.type === 'mouseup' && (!this.containsMouseUp && !this.containsMouseDown)) {
      // yes, clicked outside
      this.containsMouseUp = true;
      this.containsMouseDown = true;

      if (this.tabbable && this.aaForm.dirty) {
        if (this.closeReason === rightPanelCloseReason.LEAVE) {
          this.callOnClose(true);
        } else if (this.closeReason === rightPanelCloseReason.COLLAPSE) {
          this.callOnCancel();
        } else if (this.closeReason === rightPanelCloseReason.NAVIGATEAWAY) {
          this.allowNavigateAway();
        } else if (this.closeReason === rightPanelCloseReason.STAY ||
          this.closeReason === rightPanelCloseReason.BACKDROP) {
          // Just close the dialog
        } else {
          console.log(this.closeReason);
        }
      } else if (this.tabbable && !this.toOpen && !this.deleteConfirmDisplayFlag && !this.isVisError) {
        this.callOnClose();
      } else if (this.isVisError) {
        // we want to not close the right panel if the visualization error modal is open
        // however if ignore was clicked (meaning they want to stay on the page)
        // we need to reset the flags so that outside clicks will close the right panel again
        if (this.wasIgnoreClicked) {
          this.resetVisErrorFlags();
        }
      }

    } else if (event.type === 'mouseup' && !(!this.containsMouseUp && !this.containsMouseDown)) {
      this.containsMouseUp = true;
      this.containsMouseDown = true;
    }

    if (!this.deleteConfirmDisplayFlag) {
      this.resetToOpen.emit();
      this.toOpen = false;
      this.closeReason = null;
    }
  }

  setFormKey(timeType0: number, str0: string, smi0: SubMenuItem, key0: number) {
    switch (smi0.opcodeId) {
      case this.IOP_TO_EXT:
      case this.IOP_TO_EXT_W_PROMPT:
      case this.IOP_LEAVE_MSG:
        if (key0 === 12) {
          this.aaForm.get(str0).patchValue({
            key_12_opcode1_dest: smi0.extensionFormatted,
            key_12_opcode2_dest: smi0.extensionFormatted,
          });
        } else if (key0 === 13) {
          this.aaForm.get(str0).patchValue({
            key_13_opcode_dest: smi0.extensionFormatted
          });
        } else if (key0 === 14) {
          this.aaForm.get(str0).patchValue({
            key_14_opcode_dest: smi0.extensionFormatted
          });
        } else {
          console.log('No Key Matched');
        }
        break;
      case this.IOP_TO_AA:
        const ary0 = smi0.extensionFormatted.split(' : ');
        const dn0 = ary0.length > 1 ? ary0[0] : smi0.extensionFormatted;
        if (key0 === 12) {
          this.aaForm.get(str0).patchValue({
            key_12_opcode1_dest_dd: {value: dn0},
            key_12_opcode2_dest_dd: {value: dn0},
          });
        } else if (key0 === 13) {
          this.aaForm.get(str0).patchValue({
            key_13_opcode_dest_dd: {value: dn0},
          });
        } else if (key0 === 14) {
          this.aaForm.get(str0).patchValue({
            key_14_opcode_dest_dd: {value: dn0},
          });
        } else {
          console.log('No Key Matched');
        }
        break;
      case this.IOP_DIAL_BY_FIRST_NAME:
      case this.IOP_DIAL_BY_LAST_NAME:
      case this.IOP_LEAVE_MSG_BY_FIRST_NAME:
      case this.IOP_LEAVE_MSG_BY_LAST_NAME:
        this.setFormKeyWithExtList(timeType0, str0, smi0, key0);
        break;
    }
  }

  setFormKeyWithExtList(timeType0: number, str0: string, smi0: SubMenuItem, key0: number) {

    if (smi0.extensionListId) {

      switch (timeType0) {
        case this.TIME_TYPE_ON_HOURS:
            if (key0 === 12) {
              this.aaForm.get(str0).patchValue({
                key_12_opcode1_dest_dd: {value: +smi0.extensionListId},
                key_12_opcode2_dest_dd: {value: +smi0.extensionListId},
              });
            } else if (key0 === 14) {
              this.aaForm.get(str0).patchValue({
                key_14_opcode_dest_dd: {value: +smi0.extensionListId},
              });
            } else {
              console.log('No Key Matched');
            }
            break;
        case this.TIME_TYPE_OFF_HOURS:
            if (key0 === 12) {
              this.aaForm.get(str0).patchValue({
                key_12_opcode1_dest_dd: {value: +smi0.extensionListId},
                key_12_opcode2_dest_dd: {value: +smi0.extensionListId},
              });
            } else if (key0 === 14) {
              this.aaForm.get(str0).patchValue({
                key_14_opcode_dest_dd: {value: +smi0.extensionListId},
              });
            } else {
              console.log('No Key Matched');
            }
            break;
        case this.TIME_TYPE_HOLIDAY:
            if (key0 === 12) {
              this.aaForm.get(str0).patchValue({
                key_12_opcode1_dest_dd: {value: +smi0.extensionListId},
                key_12_opcode2_dest_dd: {value: +smi0.extensionListId},
              });
            } else if (key0 === 14) {
              this.aaForm.get(str0).patchValue({
                key_14_opcode_dest_dd: {value: +smi0.extensionListId},
              });
            } else {
              console.log('No Key Matched');
            }
            break;
        case this.TIME_TYPE_CUSTOM:
            if (key0 === 12) {
              this.aaForm.get(str0).patchValue({
                key_12_opcode1_dest_dd: {value: +smi0.extensionListId},
                key_12_opcode2_dest_dd: {value: +smi0.extensionListId},
              });
            } else if (key0 === 14) {
              this.aaForm.get(str0).patchValue({
                key_14_opcode_dest_dd: {value: +smi0.extensionListId},
              });
            } else {
              console.log('No Key Matched');
            }
            break;
      }

    } else { // set to 'Everyone'
      console.log('No Extension Selected');
      if (key0 === 12) {
        this.aaForm.get(str0).patchValue({
          key_12_opcode1_dest_dd: {value: '0'},
          key_12_opcode2_dest_dd: {value: '0'},
        });

      } else if (key0 === 14) {
        this.aaForm.get(str0).patchValue({
          key_14_opcode_dest_dd: {value: '0'},
        });
      } else {
        console.log('No Key Matched');
      }
    }
  }

  async setFormWithAA() {
    this.displayLoadingSpinner = true;
    this.isCreate = false;
    this.resetAAFormOptions();
    if (!this.aa) {
      this.isCreate = true;
      if (this.accountId) {
        await this.initLocationsList();
        await this.getAvailNumbers();
      }
      await this.aaSvc.getNewAA().toPromise().then (
        aa => {
          this.aa = aa;
          this.phSvc.clusterBasedExtensionLength.next(aa.extensionFormatted.length);
          this.extensionFromD2 = this.aa.extensionFormatted;
          this.displayLoadingSpinner = false;
          const vizAAObj: HierarchyDatum = {
            name: 'New Auto-Attendant',
            subText: aa.extensionFormatted,
            componentType: callFlowComponentTypeValues.aa,
            id: newComponentId
          };
          this.flowVizSvc.setSelectedFlow(vizAAObj, flowComponentCRUDActionValues.create);
        },
        error => {
          this.displayLoadingSpinner = false;
          console.error('failed to getNewAA', error);
          this.showServerError('Failed to get new auto-attendant data. ' + BossApiUtils.extractErrorMessage(error));
        }
      );
    } else {
      await this.aaSvc.getAA(this.aa.id).toPromise().then (
        aa => {
          this.aa = aa;
          this.phSvc.clusterBasedExtensionLength.next(aa.extensionFormatted.length);
          this.origDN = aa.extension;
          this.displayLoadingSpinner = false;
        },
        error => {
          console.error('failed to fetch auto-attendant', error);
          this.showServerError('Failed to fetch auto-attendant. ' + BossApiUtils.extractErrorMessage(error));
          this.displayLoadingSpinner = false;
        }
      );
      await this.getAvailNumbers();
    }

    if (this.aa != null) {
      this.tenantPrefix = this.aa.extension ? this.aa.extension.split('-')[0] + '-' : '';
      // setting the display value for phonenumber
      if (this.aa.tnId != null) {
        this.aa.displayPhoneNumber = await this.phoneNumberFormatService
            .formatPhoneNumberBasedOnCountry(this.aa.tnId);
      }
      this.aaForm.get('aaSection').patchValue({
        name: this.aa.name,
        // location: this.aa.locationName,
        ptype: 'New', // To-DO : display based on what is saved in the database
        pnumber: this.aa.phoneNumber,
        extension: this.aa.extensionFormatted,
        language: {value: this.aa.languageId},
        schedule: '1',
        makeExtPrivate: this.aa.makeNumberPrivate
      });

      // set schedules
      this.setFormWithSchedules();

      // set subMenus and subMenuItems
      if (this.aa.subMenus && this.aa.subMenus.length === 4) {
        const m0 = this.aa.subMenus[0];
        const m1 = this.aa.subMenus[1];
        const m2 = this.aa.subMenus[2];
        const m3 = this.aa.subMenus[3];

        this.setFormWithSubMenu('aaSection.onHoursSection', m0, this.mainOptions1, 1);
        this.setFormWithSubMenu('aaSection.offHoursSection', m1, this.mainOptions2, 2);
        this.setFormWithSubMenu('aaSection.holidaySection', m2, this.mainOptions3, 3);
        this.setFormWithSubMenu('aaSection.customSection', m3, this.mainOptions4, 4);

        if (!this.isCreate &&
          (m0.scheduleId > 0 || m1.scheduleId > 0 || m2.scheduleId > 0 || m3.scheduleId > 0)) {
            this.aaForm.get('aaSection').patchValue({schedule: '2'});
            this.scheduleOnClick(2);
        }

        this.setFormWithSelectedSchedules('onHoursSection.onHours', m0.scheduleId);
        this.setFormWithSelectedSchedules('holidaySection.holiday', m2.scheduleId);
        this.setFormWithSelectedSchedules('customSection.custom', m3.scheduleId);

        this.hideOpList1 = this.hasOptions0to11(this.mainOptions1);
        this.hideOpList2 = this.hasOptions0to11(this.mainOptions2);
        this.hideOpList3 = this.hasOptions0to11(this.mainOptions3);
        this.hideOpList4 = this.hasOptions0to11(this.mainOptions4);

      }

      // if (this.aa.locationUUID) {
      //   this.aaForm.get('aaSection').patchValue({location: {value: this.aa.locationUUID}});
      // }

      this.languages = this.aa.languages;
      this.extensionList = this.aa.extensionList;
      this.huntGroups = this.aa.huntGroups;
      this.autoAttendants = this.aa.autoAttendants;
      this.subMenus = this.aa.subMenus;

      // setting System Prompt Language to account country default language
      // we can add missing countries to CountryLanguageMapping in constants as required
      const countryId = this.appService.getCountryCode();
      const countryLanguage = CountryLanguageMapping.filter((item) =>{ return item.countryCode === countryId});
      
      // setting default language to English(US)
      let defaultValue = 1;

      if (countryLanguage.length) {
        defaultValue = parseInt(countryLanguage[0].id);
      } 
      this.aaForm.get('aaSection').patchValue({
        language: {value: defaultValue}
      });
     
      
     



    if (this.isCreate) {
      /* istanbul ignore else */
      if (this.locations && this.locations.length > 0) {
        this.aaForm.get('aaSection').patchValue({location: {value: this.locations[0].value}});
        this.disableLocation = this.locations.length <= 1;
      }
      // set default name correctly
      this.aa.name = 'New Auto-Attendant';
      this.aaForm.get('aaSection').patchValue({ name: this.aa.name });
    } else {
      if (this.aa.locationUUID != null) {
        await this.locSvc2.getFullLocationObjectForSelectedLocation(this.accountId, this.aa.locationUUID).toPromise()
            .then(
                result => {
                  this.aaLocation = result;
                });
        if (this.aaLocation.locationTypeId === LocationTypes.GLOBAL_LOCATION) {
          this.locations = [this.locSvc2._makeLocation(this.aaLocation)];
          this.loadingLocations = false;
          /* istanbul ignore else */
          if (this.locations && this.locations.length > 0) {
            this.aaForm.get('aaSection').patchValue({location: {value: this.aa.locationUUID}});
            this.disableLocation = this.locations.length <= 1;
          }
        } else {
          if (this.accountId) {
            await this.initLocationsList();
          }
          this.aaForm.get('aaSection').patchValue({location: {value: this.aa.locationUUID}});
          this.disableLocation = this.locations.length <= 1;
        }
      }
    }

    if (this.aa.rcfTarget) {
      this.temporaryTn.rcfTarget = this.aa.rcfTarget;
      this.usingExistingTn = true;
      this.defaultTnPortedOrTransferred = true;
    }
  }

    // TODO the available phone numbers taking long time to load so always new is updating so the
    // we are replacing this block
    // if (this.isCreate) {
    //   this.addingNewPhone = true;
    //   this.aaForm.get('aaSection').patchValue(
    //       {
    //         phoneNumber: PHONE_NUMBER_ASSIGNMENTS[2]
    //       });
    // } else {
    //   this.aaForm.get('aaSection').patchValue(
    //       {
    //         phoneNumber: PHONE_NUMBER_ASSIGNMENTS[1]
    //       });
    // }
    if (this.availablePhoneNumbers && this.availablePhoneNumbers.length ) {
      this.aaForm.get('aaSection').patchValue(
          {
            phoneNumber: this.isCreate ? PHONE_NUMBER_ASSIGNMENTS[1] : PHONE_NUMBER_ASSIGNMENTS[2]
          });
    } else {
      this.aaForm.get('aaSection').patchValue(
          {
            phoneNumber: PHONE_NUMBER_ASSIGNMENTS[2]
          });
    }

    this.nameElement.nativeElement.focus();
    this.updateAa = !this.isCreate;
    this.aaForm.markAsPristine();
  }

  resetAAFormOptions() {
    this.aaForm.reset();
    this.mainOptions1 = [];
    this.mainOptions2 = [];
    this.mainOptions3 = [];
    this.mainOptions4 = [];
  }

  // ************* call this function once per tab ******************
  setFormWithSubMenu(str0: string, m0: any,
                      mainOptions0: Array<NewOption>,
                      timeType0: number) {
    this.aaForm.get(str0).patchValue({
      timeout1: m0.timeout / 1000,
      timeout2: m0.timeout / 1000,
      key_12_opcode1: {value: m0.subMenuItems[12].opcodeId},
      key_12_opcode2: {value: m0.subMenuItems[12].opcodeId},
      key_13_opcode: {value: m0.subMenuItems[13].opcodeId},
      key_14_opcode: {value: m0.subMenuItems[14].opcodeId},
    });

    let interaction0 = '2'; // default Take an immediate action
    for (const item of m0.subMenuItems) {
      if ((item.opcodeId > 0 && (item.keyPadId < 12 || item.keyPadId > 14)) ||
          (item.keyPadId === 14 && item.opcodeId !== this.IOP_REPEAT) ||
          (item.keyPadId === 13 && item.opcodeId !== this.IOP_DISCONNECT)) {
        interaction0 = '1'; // set to Present a menu of options
        break;
      }
    }

    let nextStep0 = '2'; // custom for keys 12, 13, 14
    if (interaction0 === '1' &&
        m0.subMenuItems[13].opcodeId === this.OP_DISCONNECT &&
        m0.subMenuItems[14].opcodeId === this.OP_REPEAT) {
          nextStep0 = '1'; // Repeat three times if there are errors then hang up
    } else {
      this.setFormKey(timeType0, str0, m0.subMenuItems[12], 12);
      this.setFormKey(timeType0, str0, m0.subMenuItems[13], 13);
      this.setFormKey(timeType0, str0, m0.subMenuItems[14], 14);
    }

    this.aaForm.get(str0).patchValue({
      interaction: interaction0,
      nextStep: nextStep0
    });

    this.interactionOnClick(interaction0, timeType0);
    this.nextStepOnClick(nextStep0, timeType0);

    // set key 0 - 11 and 15
    for (const item of m0.subMenuItems) {
      // constructor(key: number, opcode: number,
      //   extension: string, extensionFormatted: string,
      //   displayString: string, listId: number,
      //   textDest: string, ddDest: string)

      let sOpcodeId = item.opcodeId.toString();
      if (item.keyPadId === 15 &&
        (sOpcodeId === this.OP_TO_EXT ||
          sOpcodeId === this.OP_TO_EXT_W_PROMPT ||
          sOpcodeId === this.OP_LEAVE_MSG)) {
            sOpcodeId = sOpcodeId + '1';
      }

      const displayString0 = this.getNewOpDisplayString(
        sOpcodeId,
        item.extensionFormatted,
        item.extensionFormatted ? item.extensionFormatted : item.extensionListId
      );
      if (item.keyPadId < 12 || item.keyPadId > 14) {
        mainOptions0.push(
          new NewOption(item.keyPadId,
                        item.opcodeId,
                        item.extension,
                        item.extensionFormatted,
                        displayString0,
                        item.extensionListId,
                        item.extension,
                        item.extension));
      }
    }
  }

  setFormWithSelectedSchedules(section: string, scheduleID: number) {
    if (scheduleID > 0) {
      this.setFlowSeelected(section, scheduleID.toString());
    } else {
      this.setFlowSeelected(section, 'none');
    }
  }
  setFormWithSchedules() {

    this.hoursSchedules.push({ value: 'none', displayName: 'None applied' });
    this.hoursSchedules.push({ value: 'new', displayName: 'New On-Hours Schedule...' });
    this.holidaySchedules.push({ value: 'none', displayName: 'None applied' });
    this.holidaySchedules.push({ value: 'new', displayName: 'New Holiday Schedule...' });
    this.customSchedules.push({ value: 'none', displayName: 'None applied'});
    this.customSchedules.push({ value: 'new', displayName: 'New Custom Schedule...' });

    if (this.aa.schedules) {
      this.aa.schedules.forEach(schedule => {
        if (schedule.timeTypeId === this.TIME_TYPE_ON_HOURS) {
          this.hoursSchedules.push({ value: schedule.id, displayName: schedule.description });
        } else if (schedule.timeTypeId === this.TIME_TYPE_HOLIDAY) {
          this.holidaySchedules.push({ value: schedule.id, displayName: schedule.description });
        } else if (schedule.timeTypeId === this.TIME_TYPE_CUSTOM) {
          this.customSchedules.push({ value: schedule.id, displayName: schedule.description });
        } else {
          console.log('TimeType not exists');
        }
      });
    }
  }

  scheduleOnChange(field: string) {
    const schedule = this.aaForm.get('aaSection.' + field).value;
    if (schedule && schedule.value === 'new') {
      switch (field) {
        case 'onHoursSection.onHours':
          this.createSchedule(field, 'onhours');
          break;
        case 'holidaySection.holiday':
          this.createSchedule(field, 'holiday');
          break;
        case 'customSection.custom':
            this.createSchedule(field, 'custom');
            break;
      }
    } else {
      console.log('schedule not exists');
    }
  }


  createSchedule(field: string, scheduleType: string) {
    console.log('create schedule ', scheduleType);
    let component, title;
    /* istanbul ignore else */
    if (scheduleType === 'onhours') {
      component = OnHoursScheduleComponent;
      title = 'on_hours.add_title';
    } else if (scheduleType === 'holiday') {
      component = HolidayScheduleComponent;
      title = 'holiday.add_title';
    } else if (scheduleType === 'custom') {
      component = CustomScheduleComponent;
      title = 'custom.add_title';
    }
    this.deleteConfirmDisplayFlag = true;
    if (!this.modalRef) {

      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        windowClass : 'hours-schedule-custom'
      };

      this.modalRef = this.modalSvc.open(component, options);
      this.modalRef.componentInstance.popUp = true;
      this.modalRef.componentInstance.dialogHeader = title;
      this.modalRef.result.then(async (result) => {
      this.modalRef = null;
      /* istanbul ignore else */
      if (scheduleType === 'onhours') {
        this.hoursSchedules.push({ value: result.id, displayName: result.name });
      } else if (scheduleType === 'holiday') {
        this.holidaySchedules.push({ value: result.id, displayName: result.name });
      } else if (scheduleType === 'custom') {
        this.customSchedules.push({ value: result.id, displayName: result.name });
      }
      this.setFlowSeelected(field, result.id);
      }, (reason) => {
        this.modalRef = null;
        console.log(reason);
      });
    } else {
      console.log('modalRef not set to null.');
      this.modalRef = null;
    }
  }

  getAA(id: number): Promise<any>  {
    const promise = new Promise<any>(async (resolve, reject) => {
      try {
        const aa = await this.aaSvc.getAA(id);
        resolve(aa);
      } catch (reason) {
        reject(reason);
      }
    });
    return promise;
  }



  async initForm() {
    this.aaForm = new FormGroup({
      aaSection: new FormGroup({
      'name': new FormControl('New Auto-Attendant', Validators.required),
      'location': new FormControl('', Validators.required),
      'phoneNumber': new FormControl(),
      'phoneNumberDigits': new FormControl(),
      'newPhoneNumberDigits': new FormControl(),
      'extension': new FormControl('', Validators.required),
      'makeExtPrivate': new FormControl(),
      'language': new FormControl('', Validators.required),
      'schedule': new FormControl('1'),
      'onHoursSection': new FormGroup({
        'nextStep': new FormControl('1'),
        'interaction': new FormControl('2'),
        'key_12_opcode1': new FormControl(this.OP_REPEAT),
        'key_12_opcode2': new FormControl(this.OP_REPEAT),
        'key_13_opcode': new FormControl(this.OP_DISCONNECT),
        'key_14_opcode': new FormControl(this.OP_REPEAT),
        'key_12_opcode1_dest': new FormControl(''),
        'key_12_opcode2_dest': new FormControl(''),
        'key_13_opcode_dest': new FormControl(''),
        'key_14_opcode_dest': new FormControl(''),
        'key_12_opcode1_dest_dd': new FormControl(''),
        'key_12_opcode2_dest_dd': new FormControl(''),
        'key_13_opcode_dest_dd': new FormControl(''),
        'key_14_opcode_dest_dd': new FormControl(''),
        'timeout1': new FormControl('8'),
        'timeout2': new FormControl('8'),
        'options_dd1': new FormControl(''),
        'new_opcode1': new FormControl(''),
        'new_opcode1_dest': new FormControl(''),
        'new_opcode1_dest_dd': new FormControl(''),
        'limit_to_checkbox11': new FormControl(),
        'limit_to_checkbox12': new FormControl(),
        'limit_to_checkbox13': new FormControl(),
        'limit_to_checkbox14': new FormControl(),
        'onHours': new FormControl('none'),
      }),
      'offHoursSection': new FormGroup({
        'nextStep': new FormControl('1'),
        'interaction': new FormControl('2'),
        'key_12_opcode1': new FormControl(this.OP_REPEAT),
        'key_12_opcode2': new FormControl(this.OP_REPEAT),
        'key_13_opcode': new FormControl(this.OP_DISCONNECT),
        'key_14_opcode': new FormControl(this.OP_REPEAT),
        'key_12_opcode1_dest': new FormControl(''),
        'key_12_opcode2_dest': new FormControl(''),
        'key_13_opcode_dest': new FormControl(''),
        'key_14_opcode_dest': new FormControl(''),
        'key_12_opcode1_dest_dd': new FormControl(''),
        'key_12_opcode2_dest_dd': new FormControl(''),
        'key_13_opcode_dest_dd': new FormControl(''),
        'key_14_opcode_dest_dd': new FormControl(''),
        'timeout1': new FormControl('8'),
        'timeout2': new FormControl('8'),
        'options_dd1': new FormControl(''),
        'new_opcode1': new FormControl(''),
        'new_opcode1_dest': new FormControl(''),
        'new_opcode1_dest_dd': new FormControl(''),
        'limit_to_checkbox21': new FormControl(),
        'limit_to_checkbox22': new FormControl(),
        'limit_to_checkbox23': new FormControl(),
        'limit_to_checkbox24': new FormControl(),
      }),
      'holidaySection': new FormGroup({
        'nextStep': new FormControl('1'),
        'interaction': new FormControl('2'),
        'key_12_opcode1': new FormControl(this.OP_REPEAT),
        'key_12_opcode2': new FormControl(this.OP_REPEAT),
        'key_13_opcode': new FormControl(this.OP_DISCONNECT),
        'key_14_opcode': new FormControl(this.OP_REPEAT),
        'key_12_opcode1_dest': new FormControl(''),
        'key_12_opcode2_dest': new FormControl(''),
        'key_13_opcode_dest': new FormControl(''),
        'key_14_opcode_dest': new FormControl(''),
        'key_12_opcode1_dest_dd': new FormControl(''),
        'key_12_opcode2_dest_dd': new FormControl(''),
        'key_13_opcode_dest_dd': new FormControl(''),
        'key_14_opcode_dest_dd': new FormControl(''),
        'timeout1': new FormControl('8'),
        'timeout2': new FormControl('8'),
        'options_dd1': new FormControl(''),
        'new_opcode1': new FormControl(''),
        'new_opcode1_dest': new FormControl(''),
        'new_opcode1_dest_dd': new FormControl(''),
        'limit_to_checkbox31': new FormControl(),
        'limit_to_checkbox32': new FormControl(),
        'limit_to_checkbox33': new FormControl(),
        'limit_to_checkbox34': new FormControl(),
        'holiday': new FormControl('none')
      }),
      'customSection': new FormGroup({
        'nextStep': new FormControl('1'),
        'interaction': new FormControl('2'),
        'key_12_opcode1': new FormControl(this.OP_REPEAT),
        'key_12_opcode2': new FormControl(this.OP_REPEAT),
        'key_13_opcode': new FormControl(this.OP_DISCONNECT),
        'key_14_opcode': new FormControl(this.OP_REPEAT),
        'key_12_opcode1_dest': new FormControl(''),
        'key_12_opcode2_dest': new FormControl(''),
        'key_13_opcode_dest': new FormControl(''),
        'key_14_opcode_dest': new FormControl(''),
        'key_12_opcode1_dest_dd': new FormControl(''),
        'key_12_opcode2_dest_dd': new FormControl(''),
        'key_13_opcode_dest_dd': new FormControl(''),
        'key_14_opcode_dest_dd': new FormControl(''),
        'timeout1': new FormControl('8'),
        'timeout2': new FormControl('8'),
        'options_dd1': new FormControl(''),
        'new_opcode1': new FormControl(''),
        'new_opcode1_dest': new FormControl(''),
        'new_opcode1_dest_dd': new FormControl(''),
        'limit_to_checkbox41': new FormControl(),
        'limit_to_checkbox42': new FormControl(),
        'limit_to_checkbox43': new FormControl(),
        'limit_to_checkbox44': new FormControl(),
        'custom': new FormControl('none')
      })
      })
    });
    // console.log(this.aaForm.value.aaSection);
    this.getCFDestinations();
    // await this.initLocationsList();

  }

  checkDirtyAndCall = (cb?, action: { collapse?: boolean, navigateAway?: boolean} = {}) => {
    // TODO reset
    if (this.tabbable && this.aaForm.dirty) {
      if (this.closeReason === rightPanelCloseReason.LEAVE || this.closeReason === rightPanelCloseReason.COLLAPSE ) {
        if (cb) {
          cb();
        }
      } else if (this.closeReason === rightPanelCloseReason.STAY ||
        this.closeReason === rightPanelCloseReason.BACKDROP) {
        // Just close the dialog
      } else {
        this.openConfirmDialog(action);
      }
    } else if (this.tabbable && !this.toOpen) {
      if (cb) {
        cb();
      }
  }
  }

  onClose = () => {
    this.resetToOpen.emit();
    this.toOpen = false;
    this.checkDirtyAndCall(this.callOnClose);
  }

  onCancel($event) {
    this.resetToOpen.emit();
    this.toOpen = false;
    if (!this.isCreate) {
      this.checkDirtyAndCall(this.callOnCancel, {collapse: true});
    } else {
      this.onClose();
    }
    $event.stopImmediatePropagation();

  }

  callOnClose = (flowsUpdated = false) => {
    this.flowsSvc.setClickedFlow(null);
    this.close.emit(flowsUpdated);
    this.resetVisErrorFlags();
  }

  callOnCancel = () => {
    this.collapse.emit();
  }

  allowNavigateAway = () => {
    this.flowsSvc.componentNavigateAway.next();
  }

  resetVisErrorFlags() {
    this.isVisError = false;
    this.wasIgnoreClicked = false;
  }


  /**
   * User changed the selected location in the AA create/edit dialog.
   */
  onLocationChange() {
    const locationControl = this.aaForm.get('aaSection.location');
    if (locationControl && locationControl.value && locationControl.value.value) {
      const locationId = locationControl.value.value;
      this.phSvc.setSelectedLocation( locationId );
    } else {
      console.log('data :', 'location not found');
    }
  }

  phoneNumberReadOnlyValue() {
    const none = this.translated.hunt_group.phone_number_none;
    return this.aa && this.aa.displayPhoneNumber ?
        this.aa.displayPhoneNumber : none;
  }

  onChangePhoneNumber() {
    this.deleteConfirmDisplayFlag = true;
    if (!this.modalRef) {
      // Create & display ChangePhoneNumberComponent as a modal.

      // Reference
      // https://ng-bootstrap.github.io/#/components/modal/api
      // https://ng-bootstrap.github.io/#/components/modal/examples

      const options: NgbModalOptions = {
        windowClass: 'md-modal',
        backdrop: 'static', /*  `'static'` for a backdrop which doesn't close the modal on click  */
        keyboard: false, /* false == don't close modal if Esc key is hit. Here, ChangePhoneNumberComponent has its own Esc handler */
        centered: true
      };

      // Pop open the modal
      this.modalRef = this.modalSvc.open(ChangePhoneNumberComponent, options);
      // load the modal component's Input()'s
      this.modalRef.componentInstance.hg = this.aa;
      // this.modalRef.componentInstance.currentUserPhoneService = this.currentUserPhoneService;
      // this.modalRef.componentInstance.userPendingClose = this.userPendingClose; ???
      // listen for Output() event
      this.rxjsSubscriptions.push(this.modalRef.componentInstance.outputChangePhoneNumberData.subscribe(
          (outputChangePhoneNumberData: ChangePhoneNumberData) => {
            // user successfully submitted the form; here is the data that was entered
            // console.log('devices-n-services outputChangePhoneNumberData=', outputChangePhoneNumberData);

            if ([ChangeNumberType.replace_with_existing, ChangeNumberType.replace_with_new, ChangeNumberType.unassign]
                .indexOf(outputChangePhoneNumberData.changeType) > -1) {

              if (outputChangePhoneNumberData.oldNumberAction === OldNumberAction.KEEP_ON_ACCOUNT) {
                this.aa.releaseTn = false;
              } else if (outputChangePhoneNumberData.oldNumberAction === OldNumberAction.RELEASE) {
                this.aa.releaseTn = true;
              } else {
                console.log('action not applicable', 'selected');
              }
              // phone number changed
              if (outputChangePhoneNumberData.phoneNumber) {
                this.aa.phoneNumber = outputChangePhoneNumberData.phoneNumber.value;
                this.aa.tnId = outputChangePhoneNumberData.phoneNumber.value;
                this.aa.tnCountryId = outputChangePhoneNumberData.phoneNumber.countryId;
                this.aa.displayPhoneNumber = outputChangePhoneNumberData.phoneNumber.displayName;
              } else {
                this.aa.phoneNumber = '';
                this.aa.tnId = null;
                this.aa.tnCountryId = 0;
                this.aa.displayPhoneNumber = '';
              }
              this.aa.rcfTarget = outputChangePhoneNumberData.rcfTarget;
              this.aa.managementGUID = outputChangePhoneNumberData.managementGUID;
              if (outputChangePhoneNumberData.temporaryTn) {
                this.onTemporaryNumberUpdate(outputChangePhoneNumberData.temporaryTn);
                this.usingExistingTn = (outputChangePhoneNumberData.temporaryTn.rcfTarget) ? true : false;
                this.defaultTnPortedOrTransferred = true;
              }
            } else {
              console.log('ChangeNumberType :', 'not valid');
            }

            if (outputChangePhoneNumberData.changeType === ChangeNumberType.replace_with_new) {
              this.newPhoneNumber = outputChangePhoneNumberData.phoneNumber;
              this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
            } else {
              this.newPhoneNumber = {};
              this.countingCurrent = '';
              this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
            }

            if (outputChangePhoneNumberData.updateExtension === true) {
              // extension changed
              this.aa.extension = outputChangePhoneNumberData.extension;
              this.aaForm.get('aaSection').patchValue(
                  {
                    extension: this.aa.extension
                  });
            } else {
              console.log('extension', 'not updated');
            }
            this.aaForm.markAsDirty();
            this.phSvc.setUserUpdated(true);  // indicate that we made a user change
            // close the ChangePhoneNumberComponent modal
            this.closeModal();
          },
          error => {
            console.error('onChangePhoneNumber', error);
            // close the ChangePhoneNumberComponent modal
            this.closeModal();
          },
          complete => {
            // complete, user canceled
            // close the ChangePhoneNumberComponent modal
            this.closeModal();
          }
      ));
    } else {
      console.log('no change', 'detected');
      this.modalRef = null;
    }
  }

  closeModal() {
    if (!_.isNull(this.modalRef)) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  /**
   * Called when user chooses a new source for DID phone numbers: Existing, New
   */
  onChangedPhoneNumberAssignment(event) {
    if (event.value === 'None') {
      this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
      this.usingExistingTn = false;
      this.tempNumberUpdated.emit(null);
      this.addingNewPhone = false;
      this.countingCurrent = '';
      this.newPhoneNumber = {};
      this.aaForm.get('aaSection').patchValue({phoneNumberDigits: {}});
      this.aaForm.get('aaSection').patchValue({newPhoneNumberDigits: null});
      this.aaForm.get('aaSection').get('phoneNumberDigits').disable();
      this.setValidExtension(this.extensionFromD2);
      this.newPhoneNumberSelected.emit(null);
      if (!this.modalRef && CommonUtils.flowHasPhoneNumber(this.aa)) {
        this.openReleaseNumberModal();
      }
    } else if (event.value === 'New') {
      if (this.tnAdapterSvc.wasNewPhoneNumberPreviouslyFetched === false) {
        this.addingNewPhone = true;
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
        this.aaForm.get('aaSection').patchValue({phoneNumberDigits: {}});
        this.aaForm.get('aaSection').patchValue({newPhoneNumberDigits: ''});
        /* istanbul ignore else */
        if (this.phSvc.getSelectedLocation()) {
          this.loadingNewNumber = true;
          this.rxjsSubscriptions.push(this.phSvc.getLocationDC(this.phSvc.getSelectedLocation()).subscribe((location: LocationDC) => {
                this.rxjsSubscriptions.push(this.tnAdapterSvc.getAvailableNumbersFromSearchCriteria(location, 1)
                    .subscribe((availNumber) => {
                          this.loadingNewNumber = false;
                          this.clearServerError.emit();
                          let openModal = false;
                          if (availNumber && availNumber.length) {
                            if (availNumber.length === 1 && Object.keys(availNumber[0]).length === 0) {
                              openModal = true;
                            }
                          } else {
                            openModal = false;
                            this.aaForm.get('aaSection').patchValue({phoneNumberDigits: {}});
                            this.aaForm.get('aaSection').patchValue({phoneNumber: PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING[0]});
                          }
                          if (openModal) {
                            this.openNewNumberModal();
                          } else {
                            if (availNumber && availNumber.length) {
                              this.addPhoneNumber(availNumber[0]);
                              // this.newPhoneNumber = availNumber[0];
                              this.tnAdapterSvc.wasNewPhoneNumberPreviouslyFetched = true;
                              this.setValidExtension(
                                  this.phSvc.convertPhoneNumberToExtension(
                                      availNumber[0].value
                                  )
                              );
                              this.displayShoppingCart(availNumber[0].turnupProductId);
                              this.newPhoneNumberSelected.emit(availNumber[0].turnupProductId);
                            }
                          }
                          this.aaForm.markAsDirty();
                        },
                        (error) => {
                          this.rxjsSubscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                            this.showServerErrorOnForm(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                            this.serverError.emit(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                            this.loadingNewNumber = false;
                          }));
                        }));
              },
              (error) => {
                this.rxjsSubscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                  this.showServerErrorOnForm(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  this.serverError.emit(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  this.loadingNewNumber = false;
                }));
              }));
        }
      } else {
        this.addingNewPhone = true;
        this.setValidExtension(
            this.phSvc.convertPhoneNumberToExtension(this.tnAdapterSvc.availNumberValue[0].value)
        );
        this.newPhoneNumber = this.tnAdapterSvc.availNumberValue[0];
        this.newPhoneNumberSelected.emit(this.newPhoneNumber.turnupProductId); // add number to shopping cart using number already fetched
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
        this.displayShoppingCart(this.tnAdapterSvc.availNumberValue[0].turnupProductId);
        this.aaForm.get('aaSection').patchValue({phoneNumberDigits: {}});
        this.aaForm.get('aaSection').patchValue({newPhoneNumberDigits: ''});
        this.addPhoneNumber(this.tnAdapterSvc.availNumberValue[0]);
      }
    } else { // 'Existing'
      this.usingExistingTn = true;
      this.addingNewPhone = false;
      this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
      this.countingCurrent = '';
      this.newPhoneNumber = {};
      this.aaForm.get('aaSection').get('phoneNumberDigits').enable();
      this.aaForm.get('aaSection').patchValue({newPhoneNumberDigits: ''});
      const phNumber = CommonUtils.flowHasPhoneNumber(this.aa)
          ? this.aa.displayPhoneNumber : this.defaultPhoneNumber;
      this.aaForm.get('aaSection').patchValue({
        phoneNumberDigits: phNumber
      });
      // if (phNumber && phNumber.displayName) {
      //   this.setValidExtension(
      //       this.phSvc.convertPhoneNumberToExtension(
      //           phNumber.displayName
      //       )
      //   );
      // }
      this.newPhoneNumberSelected.emit(null);
    }
  }

  requiresTempTn(): boolean {
    return this.usingExistingTn && this.defaultTnPortedOrTransferred;
  }

  onTemporaryNumberUpdate(tempTn: TemporaryNumber) {
    this.temporaryTn.rcfTarget = tempTn.rcfTarget;
    this.temporaryTn.mgmtGUID = tempTn.mgmtGUID;
    this.tempNumberUpdated.emit(this.temporaryTn);
  }


  onChangedPhoneNumberDigits(event: { value: PhoneNumber, reason: string }) {

    // // If set programmatically or if the new value is the same as the old value, pristinify and return
    // if (event.reason === 'set' || CommonUtils.userHasPhoneNumber(this.currentUserPhoneService) &&
    // event.value === this.currentUserPhoneService.phoneNumber) {
    //   // this.devicesAndServices.markAsPristine();
    //   return;
    // }

    if (!this.modalRef && CommonUtils.flowHasPhoneNumber(this.aa) &&
        event.value !== this.currentUserPhoneService.phoneNumber) {
      this.openReleaseNumberModal();
    }

    this.defaultTnPortedOrTransferred = this.phoneNumberIsPortedOrTransferred(
        event.value
    );

    // update the extension with the based on phone number change from the available numbers
    if (!_.isEmpty(event.value) && !_.isEmpty(event.value.value)) {
      this.setValidExtension(this.phSvc.convertPhoneNumberToExtension(event.value.value));
    }

    const phoneNumber = this.aaForm.get('aaSection').get('phoneNumber').value;
    if (phoneNumber.value === PHONE_NUMBER_ASSIGNMENTS[1].value) {
      if (_.isEmpty(event.value.value)) {
        this.aaForm.get('aaSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
      } else {
        if (this.availablePhoneNumbers[0].items[0].value == null) {
          this.availablePhoneNumbers[0].items.shift();
        }
        this.resetErrorsForPhoneNumber();
      }
    } else {
      this.resetErrorsForPhoneNumber();
    }

    this.aaForm.get('aaSection').get('phoneNumberDigits').markAsTouched({onlySelf: true});
    this.aaForm.get('aaSection').get('phoneNumberDigits').markAsDirty({onlySelf: true});
  }

  resetErrorsForPhoneNumber() {
    this.aaForm.get('aaSection').get('phoneNumberDigits').setErrors({invalidPhone: null});
    this.aaForm.get('aaSection').get('phoneNumberDigits').updateValueAndValidity();
  }

  phoneNumberIsPortedOrTransferred(number: PhoneNumber): boolean {
    let isPortedOrTransferred = false;
    if (
        number &&
        number.displayName &&
        number.displayName.length > 0 &&
        number.value != null &&
        this.availablePhoneNumbers
    ) {
      for (const numberList of this.availablePhoneNumbers) {
        if (numberList.heading === 'cl_dropdown_headers.ported') {
          if (numberList.items) {
            const foundNum = numberList.items.find(
                portedNum => portedNum.displayName === number.displayName
            );
            if (foundNum !== undefined) {
              isPortedOrTransferred = true;
            }
            break;
          }
        }
      }
    }
    return isPortedOrTransferred;
  }

  openReleaseNumberModal() {
    this.phSvc.changePhoneNumberType = 'release_number';
    const modTemp: ModalTemplate = {
      title: 'change_phone_number.release_number.title',
      content: RemovePhoneComponent,
      isContentHTML: true,
      primaryBtn: {
        value: true,
        label: 'change_phone_number.release_number.return_number',
        width: 150
      },
      defaultBtn: {
        value: false,
        label: 'change_phone_number.release_number.keep_number',
        width: 150
      }
    };

    const options: NgbModalOptions = {
      windowClass: 'sm-modal',
      centered: true,
      backdrop: 'static'
    };

    this.modalRef = this.modalSvc.open(ClModalComponent, options);
    this.modalRef.componentInstance.modalTemp = modTemp;

    this.modalRef.result.then((result) => {
      if (result === true) {
        this.onPhoneNumberChangeReturnNumber(); // place holder method for future use
      } else {
        this.onPhoneNumberChangeKeepNumber(); // place holder method for future use
      }
      // this.toOpen.emit(true);
      this.modalRef = null;
    }, (reason) => {
      this.modalRef = null;
    });
  }

  onNewPhoneInputClicked(event) {
    if (!this.loadingNewNumber) {
      this.openNewNumberModal();
    }
  }

  openNewNumberModal() {
    const modTemp: ModalTemplate = {
      title: 'choose_new_number.title',
      content: NewNumberComponent,
      isContentHTML: true,
      primaryBtn: {
        value: true,
        label: 'choose_new_number.assign',
        width: 95,
        disabled: true
      },
      defaultBtn: {
        value: false,
        label: 'choose_new_number.cancel',
        width: 95
      }
    };

    const options: NgbModalOptions = {
      windowClass: 'lg-modal',
      centered: true
    };

    this.modalRef = this.modalSvc.open(ClModalComponent, options);
    this.modalRef.componentInstance.modalTemp = modTemp;

    this.modalRef.result.then((result) => {
      if (result === true) {
        this.addPhoneNumber(this.phSvc.tnIntegration.currentSelectedPhoneNumber);
        this.newPhoneNumberSelected.emit(this.phSvc.tnIntegration.currentSelectedPhoneNumber.turnupProductId);
        if (this.phSvc.tnIntegration.saveSearchCriteria) {
          this.tnAdapterSvc.updateTNSearchCriteria();
        }
      }
      // this.toOpen.emit(true);
      this.modalRef = null;
    }, (reason) => {
      this.modalRef = null;
    });
  }

  addPhoneNumber(phoneNumberObj: any) {
    if (phoneNumberObj) {
      const availNumber: PhoneNumber = {
        value: phoneNumberObj.value,
        displayName: phoneNumberObj.displayName,
        locationUuid: phoneNumberObj.locationUuid,
        countryId: phoneNumberObj.countryId
      };
      // Workaround in order to trigger change detection in l-dropdown-wtih-headings
      const phoneNumbers = this.availablePhoneNumbers.length ?
          [...this.availablePhoneNumbers] : [{ heading: 'cl_dropdown_headers.ported', items: []}];
      this.tnAdapterSvc.availNumberValue = [phoneNumberObj];
      this.availablePhoneNumbers = phoneNumbers;
      this.newPhoneNumber = phoneNumberObj;
      setTimeout(() => { // Circumvent the timing issue of change detection in cl-dropdown-wtih-headings and
        //  calling patchValue on the dropdown
        if (this.newPhoneNumberDigits) {
          this.newPhoneNumberDigits.nativeElement.value = availNumber.displayName;
        }
        this.aaForm.get('aaSection').patchValue({phoneNumberDigits: availNumber});
        console.log('Phone Number Added');
      });
    }
  }

  onPhoneNumberChangeKeepNumber() {
    console.log('Phone Number Changed');
    this.phSvc.currentReleaseNumberFlag = false;
  }

  onPhoneNumberChangeReturnNumber() {
    console.log('Phone Number Changed');
    this.phSvc.currentReleaseNumberFlag = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.accountId && changes.accountId.currentValue &&
        changes.accountId.previousValue && changes.accountId.previousValue !== changes.accountId.currentValue) {
      this.accountId = changes.accountId.currentValue;
      await this.initLocationsList();
    }
  }

  async onDelete() {
    console.log('Delete AA');
    if (!this.modalRef) {

      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };

      this.deleteConfirmDisplayFlag = true;
      this.modalRef = this.modalSvc.open(DeleteConfirmationComponent, options);
      this.modalRef.componentInstance.title = 'delete_aa_confirmation.title';
      this.modalRef.componentInstance.content = 'delete_aa_confirmation.content';
      this.modalRef.result.then(async (result) => {
        this.modalRef = null;
        console.log(result);
        if (result === true) {
          await this.handleDelete();
        } else {
          this.toOpen = true; // keep the Edit side window open
        }
        this.deleteConfirmDisplayFlag = false;
      }, (reason) => {
        // this.closeReason = this.getDismissReason(reason);
        this.modalRef = null;
        this.deleteConfirmDisplayFlag = false;
        console.log(reason);
      });
    } else {
      console.log('modalRef is not null.');
      this.modalRef = null;
    }
  }

  async handleDelete() {
    this.clearServerErrorOnForm();
    this.displayLoadingSpinner = true;
    await this.aaSvc.deleteAA(this.aa.id)
    .toPromise().then(
      response => {
        console.log('response from delete', response);
        if (response != null && response.length > 0) {
          // this.parseDeleteError(response[0]);
          console.error(response);
          const msg = this.translateDelError(response[0]);
          this.showServerErrorOnForm(msg);
        } else {
          this.flowVizSvc.setSelectedFlow(this.aa, flowComponentCRUDActionValues.delete);
          this.collapse.emit();
          this.insertMessage('aa.deleted_aa');
        }
        this.deleteConfirmDisplayFlag = false;
      },
      error => {
        // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
        console.error('failed to delete auto-attendant ', error);
        this.displayLoadingSpinner = false;
        this.showServerErrorOnForm( 'Unable to delete auto-attendant: ' + error.message ? error.message : JSON.stringify(error));

      }
    );
    this.displayLoadingSpinner = false;
  }

  translateDelError(msg0) {
    const start0 = 'Cannot delete: used ';
    const end0 = '. Remove any references, then try again.';
    if (msg0) {
      // console.log(msg0);
      let msg1 = msg0.split('busy extension for the hunt group');
      if (msg1.length > 1) {
        return start0 + 'as busy extension for hunt group ' + msg1[1] + end0;
      }

      msg1 = msg0.split('No answer extension for the hunt group');
      if (msg1.length > 1) {
        return start0 + 'as no answer extension for hunt group ' + msg1[1] + end0;
      }

      msg1 = msg0.split('off-hours extension for the hunt group');
      if (msg1.length > 1) {
        return start0 + 'as holiday/off-hours extension for hunt group ' + msg1[1] + end0;
      }

      msg1 = msg0.split('backup extension for the hunt group');
      if (msg1.length > 1) {
        return start0 + 'as backup extension for hunt group ' + msg1[1] + end0;
      }

      msg1 = msg0.split('backup extension for the BCA');
      if (msg1.length > 1) {
        return start0 + 'as backup extension for bridged call appearance ' + msg1[1] + end0;
      }

      msg1 = msg0.split('No answer extension for the BCA');
      if (msg1.length > 1) {
        return start0 + 'no answer extension for bridged call appearance ' + msg1[1] + end0;
      }

      msg1 = msg0.split('Busy extension for the BCA');
      if (msg1.length > 1) {
        return start0 + 'busy extension for bridged call appearance ' + msg1[1] + end0;
      }

      msg1 = msg0.split('operator extension for the site');
      if (msg1.length > 1) {
        return start0 + 'as operator extension for ' + msg1[1] + end0;
      }

      if (msg0.indexOf('is the default auto-attendant') !== -1) {
        return start0 + 'as default auto attendant. Select a different default, then try again.';
      }

      if (msg0.indexOf('Availability state Available') !== -1) {
        return start0 + 'by \"Available\" default availability state' + end0;
      }

      if (msg0.indexOf('Availability state In a Meeting') !== -1) {
        return start0 + 'by \"In a Meeting\" default availability state' + end0;
      }

      if (msg0.indexOf('Availability state Out of Office') !== -1) {
        return start0 + 'by \"Out of Office\" default availability state' + end0;
      }

      if (msg0.indexOf('Availability state Vacation') !== -1) {
        return start0 + 'by \"Vacation\" default availability state' + end0;
      }

      if (msg0.indexOf('Availability state Custom') !== -1) {
        return start0 + 'by \"Custom\" default availability state' + end0;
      }

      msg1 = msg0.split('used as by the auto-attendant menu');
      if (msg1.length > 1) {
        // console.log(msg1[1]); // returns a dn with xxx-yyy format
        const idx0 = msg1[1].indexOf('-');
        const str0 = idx0 > 0 ? 'x' + msg1[1].substring(idx0 + 1) : msg1[1];
        return start0 + 'by ' + str0 + ' auto-attendant' + end0;
      }
    }

    return 'Failed to delete auto-attendant';
  }

  hasOptions0to11(mainOptions0: Array<NewOption>) {
    if (mainOptions0 && mainOptions0.length > 0) {
      for (const item0 of mainOptions0) {
        if (item0.opcode > 0) {
          return false;
        }
      }
    }
    return true;
  }

  addOneOption(str0: string, mainOptions0: Array<NewOption>) {
    const smi0 = this.aaForm.get(str0).value;
    if (smi0 && smi0.options_dd1 && smi0.new_opcode1) {  // 'press key#' is entered
      // console.log(smi0.options_dd1.value); // the key
    // console.log(smi0.new_opcode1.value); // opcode
    // console.log(smi0.new_opcode1_dest);
    // console.log(smi0.new_opcode1_dest_dd.value);

      for (const item0 of mainOptions0) {
        // console.log(item0.key);
        // console.log(smi0.options_dd1.value);
        // console.log(item0.key.toString() === smi0.options_dd1.value);
        if (item0.key.toString() === smi0.options_dd1.value) {

          item0.opcode = +smi0.new_opcode1.value;
          item0.listId = null;
          item0.extension = null;
          item0.extensionFormatted = null;
          item0.displayString = this.getNewOpDisplayString(
            smi0.new_opcode1 ? smi0.new_opcode1.value : null,
            smi0.new_opcode1_dest,
            smi0.new_opcode1_dest_dd ? smi0.new_opcode1_dest_dd.value : null
          );

          if (smi0.new_opcode1_dest_dd && smi0.new_opcode1_dest_dd.value) {
            // item0.extension = this.tenantPrefix + '-' + smi0.new_opcode1_dest_dd.value;
            // item0.extension = smi0.new_opcode1_dest_dd.value;
            // item0.extensionFormatted = smi0.new_opcode1_dest_dd.value;
            if (smi0.new_opcode1.value === this.OP_LEAVE_MSG_BY_FIRST_NAME ||
              smi0.new_opcode1.value === this.OP_LEAVE_MSG_BY_LAST_NAME ||
              smi0.new_opcode1.value === this.OP_EXTENSION_TO_EXT ||
              smi0.new_opcode1.value === this.OP_EXTENSION_TO_EXT_PROMPT ||
              smi0.new_opcode1.value === this.OP_EXTENSION_TO_MSG ||
              smi0.new_opcode1.value === this.OP_DIAL_BY_FIRST_NAME ||
              smi0.new_opcode1.value === this.OP_DIAL_BY_LAST_NAME) {
                if (smi0.new_opcode1_dest_dd.value !== '0') {
                  item0.listId = smi0.new_opcode1_dest_dd.value;
                }
                item0.extension = null;
                item0.extensionFormatted = item0.extension;

            } else { // aa or hg
              item0.extension = this.tenantPrefix + smi0.new_opcode1_dest_dd.value;
              item0.extensionFormatted = smi0.new_opcode1_dest_dd.value;
            }

          } else if (smi0.new_opcode1_dest) {
            // 2 possible type of dn
            // const dn0 = this.getDNFromFormated(smi0.new_opcode1_dest);
            item0.extension = this.getDNFromFormated(smi0.new_opcode1_dest);
            item0.extensionFormatted = this.getFormatedString(smi0.new_opcode1_dest);
          }
          // everyone is the same
          item0.textDest = item0.extension;
          item0.ddDest = item0.extension;
        break;
        }
      }
    }

    // clean up new option fields
    this.aaForm.get(str0).patchValue({
      options_dd1: {value: ''},
      new_opcode1: {value: ''},
      new_opcode1_dest_dd: {value: ''},
      new_opcode1_dest: ''
    });
  }

  onAddOption(timeType: number) {
    switch (timeType) {
      case this.TIME_TYPE_ON_HOURS: // on-hours
        this.addOneOption('aaSection.onHoursSection', this.mainOptions1);
        this.hideOpList1 = false;
        break;
      case this.TIME_TYPE_OFF_HOURS: // off hours
        this.addOneOption('aaSection.offHoursSection', this.mainOptions2);
        this.hideOpList2 = false;
        break;
      case this.TIME_TYPE_HOLIDAY:
        this.addOneOption('aaSection.holidaySection', this.mainOptions3);
        this.hideOpList3 = false;
        break;
      case this.TIME_TYPE_CUSTOM:
        this.addOneOption('aaSection.customSection', this.mainOptions4);
        this.hideOpList4 = false;
        break;
    }
  }

  onRemoveOption(mainOptions0: Array<NewOption>, key0: number, $event) {
    $event.stopImmediatePropagation();
    for (const item0 of mainOptions0) {
      if (item0.key === key0) {
        item0.opcode = 0;
        this.aaForm.markAsDirty();
        break;
      }
    }
  }

  getNewOpDisplayString(opcode: string, textDest: any, ddDest: string) {
    // console.log(opcode);
    // console.log(textDest);
    // console.log(ddDest);

    let displayString0 = 'some string';
    const dest0 = (textDest && typeof textDest === 'object') ?
                          textDest.extensionFormatted : textDest;

    switch (opcode) {
      case this.OP_TO_EXT:
          // displayString0 = 'Transfer to x1234 Mark Ford';
          displayString0 = 'Transfer to x' + dest0;
          break;
      case this.OP_TO_EXT_W_PROMPT:
          // displayString0 = 'Transfer to x1234 Mark Ford, with prompt';
          displayString0 = 'Transfer to x' + dest0 + ', with prompt';
          break;
      case this.OP_TO_AA:
          // displayString0 = 'Transfer to auto-attendant x1235 Main AA';
          displayString0 = 'Transfer to auto-attendant x' + ddDest;
          break;
      case this.OP_TO_HG:
          // displayString0 = 'Transfer to x1236 Main HG';
          displayString0 = 'Transfer to x' + ddDest;
          break;
      case this.OP_DIAL_BY_FIRST_NAME:
          // displayString0 = 'Dial by first name from NY Extension list';
          // displayString0 = 'Dial by first name, including everyone';
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Dial by first name, including everyone';
          } else {
            displayString0 = 'Dial by first name from ' + this.getDNListName(ddDest);
          }
          break;
      case this.OP_DIAL_BY_LAST_NAME:
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Dial by last name, including everyone';
          } else {
            displayString0 = 'Dial by last name from ' + this.getDNListName(ddDest);
          }
          break;
      case this.OP_LEAVE_MSG_BY_FIRST_NAME:
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Leave a message by first name, including everyone';
          } else {
            displayString0 = 'Leave a message by first name from ' + this.getDNListName(ddDest);
          }
          break;
      case this.OP_LEAVE_MSG_BY_LAST_NAME:
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Leave a message by last name, including everyone';
          } else {
            displayString0 = 'Leave a message by last name from ' + this.getDNListName(ddDest);
          }
          break;
      case this.OP_LEAVE_MSG:
          displayString0 = 'Leave a message for x' + dest0;
          break;
      case this.OP_LOGIN_VM:
          displayString0 = 'Log in to voice mail';
          break;
      case this.OP_REPEAT:
          displayString0 = 'Repeat';
          break;
      case this.OP_DISCONNECT:
          displayString0 = 'Disconnect';
          break;
      case this.OP_EXTENSION_TO_EXT:
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Transfer to entry, including everyone';
          } else {
            displayString0 = 'Transfer to entry from ' + this.getDNListName(ddDest);
          }
          break;
      case this.OP_EXTENSION_TO_EXT_PROMPT:
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Transfer to entry, with prompt, including everyone';
          } else {
            displayString0 = 'Transfer to entry, with prompt, from ' + this.getDNListName(ddDest);
          }
          break;
      case this.OP_EXTENSION_TO_MSG:
          if (ddDest === '0' || ddDest === null) {
            displayString0 = 'Leave a message for entry, including everyone';
          } else {
            displayString0 = 'Leave a message for entry from ' + this.getDNListName(ddDest);
          }
          break;
    }
    return displayString0;
  }

  getDNListName(ddDest: string) {
    for (const item of this.aa.extensionList) {
      if (item !== null) {
        // !!! won't work with '===', has to be '=='. !!!
        // if you compare a number with a string with numeric literal, == allows that,
        // but === doesn't allow that, because it not only checks the value but also type of two variable,
        // if two variables are not of the same type "===" return false, while "==" return true.
        // typeof item['value'] and ddDest are both string, but "===" failed.  I don't get it.
        if (item['value'] === ddDest) {
          return item['displayName'];
        }
      }
    }
    return ddDest;
  }

  getDNFromFormated(dest0: any) {
    let dn0 = '';
    if (dest0 && typeof dest0 === 'string') {
      // not clear what string to check ' : '
      const ary0 = dest0.split(' ');
      dn0 = ary0.length > 1 ? this.tenantPrefix + ary0[0] : this.tenantPrefix + dest0;
    } else if (dest0 && typeof dest0 === 'object') {
      dn0 = dest0.extension;
    }
    return dn0;
  }

  getFormatedString(dest0: any) {
    let str0 = dest0;
    if (dest0 && typeof dest0 === 'object') {
      str0 = dest0.extensionFormatted;
    }
    return str0;
  }

  nextStepOnClick(value: string, timeType: number) {
    switch (timeType) {
      case this.TIME_TYPE_ON_HOURS:
          this.hideCustomStep1 = value === '1';
          break;
      case this.TIME_TYPE_OFF_HOURS:
          this.hideCustomStep2 = value === '1';
          break;
      case this.TIME_TYPE_HOLIDAY:
          this.hideCustomStep3 = value === '1';
          break;
      case this.TIME_TYPE_CUSTOM:
          this.hideCustomStep4 = value === '1';
          break;
    }
  }

  interactionOnClick(value: string, timetypeid: number) {
    switch (timetypeid) {
      case this.TIME_TYPE_ON_HOURS:
        this.showAllOptions1 = value === '1';
        break;
      case this.TIME_TYPE_OFF_HOURS:
        this.showAllOptions2 = value === '1';
        break;
      case this.TIME_TYPE_HOLIDAY:
          this.showAllOptions3 = value === '1';
          break;
      case this.TIME_TYPE_CUSTOM:
          this.showAllOptions4 = value === '1';
          break;
      default:
        break;
    }
  }

  keyDestOnChange(str0: string) {

    if (this.aaForm.get('aaSection.' + str0 + '_dest_dd') && this.aaForm.get('aaSection.' + str0 + '_dest_dd').value) {
      const dest_dd_value = this.aaForm.get('aaSection.' + str0 + '_dest_dd').value.value;
      if ( dest_dd_value === 'new_ext_list') {
        this.createNewExtensionList(str0);
      } else if ( dest_dd_value === 'new_hg') {
        this.createNewHuntGroup(str0);
      } else if ( dest_dd_value === 'new_aa') {
        this.createNewAA(str0);
      } else {
        console.log('no value matched');
      }
    } else {
      console.log('value is null');
    }
  }

  async onSubmit() {
    this.submitting = true;

    const isPhoneNumber = this.aaForm.get('aaSection').value.phoneNumber;
    if (!_.isEmpty(this.newPhoneNumber)) {
      this.aaForm.get('aaSection').get('phoneNumberDigits').patchValue(this.newPhoneNumber);
    }

    if (isPhoneNumber.value === PHONE_NUMBER_ASSIGNMENTS[1].value) {
      if (!this.aaForm.get('aaSection').get('phoneNumberDigits').value.value) {
        this.aaForm.get('aaSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
      }
    } else {
      this.resetErrorsForPhoneNumber();
    }
    // if (!this.aaForm.get('aaSection').get('phoneNumberDigits').value) {
    //   this.aaForm.get('aaSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
    // } else {
    //   if (!this.aaForm.get('aaSection').get('phoneNumberDigits').value.value) {
    //     this.aaForm.get('aaSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
    //   }
    // }


    const extension = this.aaForm.get('aaSection').value.extension;
    if (extension.length === this.phSvc.clusterBasedExtensionLength.value && this.extnErrMessage === '') {
      this.clearServerErrorOnForm();
      /* istanbul ignore else */
      if (this.aaForm.valid) {
        // const isPhoneNumber = this.aaForm.get('aaSection').value.phoneNumber;
        if (this.aa && this.aa.id != null) {
          console.log('Update AA');
          const params = this.getAAParams();
          if (_.isEmpty(this.newPhoneNumber)) {
            await this.updateAutoAttentant(params);
          } else {
            await this.placeOrder(params, 'update', this.translated.error_messages.update_hunt_group);
          }
        } else {
          console.log('Create AA');
          const params = this.getAAParams();
          if (isPhoneNumber.value === PHONE_NUMBER_ASSIGNMENTS[2].value) {
            await this.placeOrder(params, 'create', this.translated.error_messages.create_hunt_group);
          } else {
            await this.createAutoAttentant(params);
          }
        }
      } else if (!this.aaForm.get('aaSection').valid) {
        this.validateAllFormFields(this.aaForm.get('aaSection'));
      }
    }
  }

  async updateAutoAttentant(params) {
    this.displayLoadingSpinner = true;
    let formattedPhoneNumber = null;
    if (params.tnId != null) {
      formattedPhoneNumber = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.tnId, params.tnCountryId);
    }
    if (params.rcfTarget != null) {
      params.rcfTarget = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.rcfTarget, params.tnCountryId);
    }
    await this.aaSvc.updateAA(this.aa.id, params, formattedPhoneNumber)
        .toPromise().then(
            response => {
              this.aa = response;
              if (this.aa.errors != null && this.aa.errors !== '') {
                console.error(this.aa.errors);
                this.displayLoadingSpinner = false;
                this.showServerErrorOnForm('Failed to update auto-attendant');
                this.parseServerError(this.aa.errors);
                this.shoppingCartService.placeOrderDetails.next('');
              } else {
                this.insertMessage('aa.updated_aa');
                this.flowVizSvc.setSelectedFlow(this.aa, flowComponentCRUDActionValues.update);
                this.collapse.emit();
                this.canDeleteAA = true;
                this.isCreate = false;
                this.displayLoadingSpinner = false;
              }
            },
            error => {
              // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
              console.error('failed to update auto-attendant', error);
              this.displayLoadingSpinner = false;
              this.clModalService.sendIsModalOpen(false);
              this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                this.showServerErrorOnForm( 'Unable to update auto-attendant: ' + BossApiUtils.extractErrorMessage(error));
              }));
            }
        );
    this.submitting = false;
  }
  async createAutoAttentant(params) {
    this.displayLoadingSpinner = true;
    let formattedPhoneNumber = null;
    if (params.tnId != null) {
      formattedPhoneNumber = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.tnId, params.tnCountryId);
    }
    if (params.rcfTarget != null) {
      params.rcfTarget = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.rcfTarget, params.tnCountryId);
    }
    await this.aaSvc.createAA(params, formattedPhoneNumber)
        .toPromise().then(
            response => {
              this.aa = response;
              if (this.aa.errors != null && this.aa.errors !== '') {
                console.error(this.aa.errors);
                this.displayLoadingSpinner = false;
                this.showServerErrorOnForm('Failed to create auto-attendant');
                this.parseServerError(this.aa.errors);
                this.shoppingCartService.placeOrderDetails.next('');
              } else {
                this.insertMessage('aa.created_aa');
                this.flowVizSvc.setSelectedFlow(this.aa, flowComponentCRUDActionValues.update);
                this.collapse.emit();
                this.canDeleteAA = true;
                this.isCreate = false;
                this.displayLoadingSpinner = false;
              }
            },
            error => {
              // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
              console.error('failed to create auto-attendant', error);
              this.displayLoadingSpinner = false;
              this.clModalService.sendIsModalOpen(false);
              this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                this.showServerErrorOnForm( 'Unable to create auto-attendant: ' + BossApiUtils.extractErrorMessage(error));
              }));
            }
        );
    this.submitting = false;
  }

  async placeOrder(params, action, errorMsg) {
    const reviewModalRef = this.modalService.open(ReviewOrderComponent, {
      mode: ReviewOrderMode.LOCAL_NUMBERS,
      orderDetails: this.shoppingCartService.generateOrderPreview(
          this.placeOrderDetails
      )
    }, {
      windowClass : 'order-preview'
    });
    this.clModalService.sendIsModalOpen(true);
    reviewModalRef.result.then(async (result) => {
          console.log('Order reviewed successfully');
          await this.placeOrderAfterReview(result, params, action, errorMsg);
        },
        () => {
          console.log('Review modal Closed');
        });
  }

  async placeOrderAfterReview(reviewResult, params, action, errorMsg) {
    if (reviewResult) {
      this.displayLoadingSpinner = true;
      const returnObs = await this.tnAdapterSvc.placeOrder(
          [this.newPhoneNumber], this.newPhoneNumber.locationUuid).toPromise()
          .catch(error1 => {
            console.log('The telephone number is unvailable for ordering', error1);
            this.displayLoadingSpinner = false;
            this.showServerErrorOnForm(errorMsg + ': ' + BossApiUtils.extractErrorMessage(error1));
          });
      if (returnObs && returnObs.status === 'COMPLETE') {
        console.log(`The order with order id ${returnObs.order_id} was placed`);
        if (action === 'create') {
          await this.createAutoAttentant(params);
        } else {
          await this.updateAutoAttentant(params);
        }
      } else if (returnObs && returnObs.errors) {
        console.log('The telephone number is unvailable for ordering');
        this.displayLoadingSpinner = false;
        this.showServerErrorOnForm(errorMsg + ': ' + returnObs.errors);
      }
    }
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }

  setDelOpParams(sm0: SubMenu, key0: number) {
    sm0.subMenuItems[key0]._create = true;
    sm0.subMenuItems[key0].opcodeId = 0;
    sm0.subMenuItems[key0].extension = null;
    sm0.subMenuItems[key0].extensionFormatted = null;
    sm0.subMenuItems[key0].extensionListId = null;
  }

  setDnListParams(sm0: SubMenu, key0: number, opcode0: number, value0: number) {
    // set value to null when 'Everyone' is selected
    const value1 = (value0 === 0) ? null : value0;
    /* istanbul ignore else */
    if (sm0.subMenuItems[key0].opcodeId !== opcode0 ||
      sm0.subMenuItems[key0].extensionListId !== value1) {
      sm0.subMenuItems[key0]._create = true;
      sm0.subMenuItems[key0].opcodeId = opcode0;
      sm0.subMenuItems[key0].extension = null;
      sm0.subMenuItems[key0].extensionFormatted = null;
      sm0.subMenuItems[key0].extensionListId = value1;
    }
    // console.log(sm0.subMenuItems[key0]);
  }

  setDnParams(sm0: SubMenu, key0: number, opcode0: number, value0: any) {
    /* istanbul ignore else */
    if (sm0.subMenuItems[key0].opcodeId !== opcode0 ||
      sm0.subMenuItems[key0].extensionFormatted !== value0) {
      sm0.subMenuItems[key0]._create = true;
      sm0.subMenuItems[key0].opcodeId = opcode0;
      if (typeof value0 === 'string') {
        sm0.subMenuItems[key0].extension = value0 === '' ? null : this.tenantPrefix + value0;
        sm0.subMenuItems[key0].extensionFormatted = value0 === '' ? null : value0;
      } else {
        sm0.subMenuItems[key0].extension = value0 ? value0.extension : null;
        sm0.subMenuItems[key0].extensionFormatted = value0 ? value0.extensionFormatted : null;

      }
      sm0.subMenuItems[key0].extensionListId = null;
    }
    // console.log(sm0.subMenuItems[key0]);
  }

  // keys without destinations
  setCleanKey(sm0: SubMenu, key0: number, opcode0: number) {
    if (this.isCreate || sm0.subMenuItems[key0].opcodeId !== opcode0) {
      sm0.subMenuItems[key0]._create = true;
      sm0.subMenuItems[key0].opcodeId = opcode0;
      sm0.subMenuItems[key0].extension = null;
      sm0.subMenuItems[key0].extensionFormatted = null;
      sm0.subMenuItems[key0].extensionListId = null;
    } else {
      console.log('opcode matched and create false');
    }
  }

  setOpFromInput(sm0: SubMenu, key0: number, opcode0: string,
                dest0: any, dest_dd0: string) {
    switch (opcode0) {
      case this.OP_REPEAT:
      case this.OP_DISCONNECT:
      case this.OP_LOGIN_VM:
        this.setCleanKey(sm0, key0, parseInt(opcode0, 10));
        break;
      case this.OP_TO_EXT:
      case this.OP_TO_EXT_W_PROMPT:
      case this.OP_LEAVE_MSG:
        let dn0 = '';
        if (dest0) {
          if (typeof dest0 === 'string') {
            const ary0 = dest0.split(' : ');
            dn0 = ary0.length > 1 ? ary0[0] : dest0;
          } else {
            dn0 = dest0;
          }
        }
        this.setDnParams(sm0, key0, parseInt(opcode0, 10), dn0);
        break;
      case this.OP_TO_AA:
        this.setDnParams(sm0, key0, parseInt(opcode0, 10), dest_dd0);
        break;
      case this.OP_TO_HG:
        this.setDnParams(sm0, key0, this.IOP_TO_EXT, dest_dd0);
        break;
      case this.OP_EXTENSION_TO_EXT:
        this.setDnListParams(sm0, key0, this.IOP_TO_EXT, parseInt(dest_dd0, 10));
        break;
      case this.OP_EXTENSION_TO_EXT_PROMPT:
        this.setDnListParams(sm0, key0, this.IOP_TO_EXT_W_PROMPT, parseInt(dest_dd0, 10));
        break;
      case this.OP_EXTENSION_TO_MSG:
        this.setDnListParams(sm0, key0, this.IOP_LEAVE_MSG, parseInt(dest_dd0, 10));
        break;
      case this.OP_DIAL_BY_FIRST_NAME:
      case this.OP_DIAL_BY_LAST_NAME:
      case this.OP_LEAVE_MSG_BY_FIRST_NAME:
      case this.OP_LEAVE_MSG_BY_LAST_NAME:
        this.setDnListParams(sm0, key0, parseInt(opcode0, 10), parseInt(dest_dd0, 10));
        break;
    }
  }

  setSMItemBeforeSave(sm0: SubMenu, key0: number, opcode0: number,
    dest0: any, dest_hg_aa: string, dest_dn_list: number) {
      if (key0 === 15 &&
        (opcode0 === this.IOP_TO_EXT ||
          opcode0 === this.IOP_TO_EXT_W_PROMPT ||
          opcode0 === this.IOP_LEAVE_MSG)) {
            opcode0 = opcode0 * 10 + 1;
      }

      switch (opcode0) {
        case this.IOP_REPEAT:
        case this.IOP_DISCONNECT:
        case this.IOP_LOGIN_VM:
          this.setCleanKey(sm0, key0, opcode0);
          break;
        case this.IOP_TO_EXT:
        case this.IOP_TO_EXT_W_PROMPT:
        case this.IOP_LEAVE_MSG:
          let dn0 = '';
          /* istanbul ignore else */
          if (dest0) {
            if (typeof dest0 === 'string') {
              const ary0 = dest0.split(' : ');
              dn0 = ary0.length > 1 ? ary0[0] : dest0;
            } else {
              dn0 = dest0;
            }
          }
          this.setDnParams(sm0, key0, opcode0, dn0);
          break;
        case this.IOP_TO_AA:
          this.setDnParams(sm0, key0, opcode0, dest_hg_aa);
          break;
        case this.IOP_TO_HG:
          this.setDnParams(sm0, key0, this.IOP_TO_EXT, dest_hg_aa);
          break;
        case this.IOP_EXTENSION_TO_EXT:
            this.setDnListParams(sm0, key0, this.IOP_TO_EXT, dest_dn_list);
            break;
        case this.IOP_EXTENSION_TO_EXT_PROMPT:
            this.setDnListParams(sm0, key0, this.IOP_TO_EXT_W_PROMPT, dest_dn_list);
            break;
        case this.IOP_EXTENSION_TO_MSG:
            this.setDnListParams(sm0, key0, this.IOP_LEAVE_MSG, dest_dn_list);
            break;
        case this.IOP_DIAL_BY_FIRST_NAME:
        case this.IOP_DIAL_BY_LAST_NAME:
        case this.IOP_LEAVE_MSG_BY_FIRST_NAME:
        case this.IOP_LEAVE_MSG_BY_LAST_NAME:
          this.setDnListParams(sm0, key0, opcode0, dest_dn_list);
          break;
        case 0:
            this.setDelOpParams(sm0, key0);
            break;
      }
  }

  getAATabParams(mainOptions0: Array<NewOption>, m0: SubMenu, input0: any) {

      // handles key 12-14
      /* istanbul ignore else */
      if (input0.interaction === '2') {
        m0.timeout = input0.timeout1 * 1000;
        this.setOpFromInput(m0, 12, input0.key_12_opcode1.value,
          input0.key_12_opcode1_dest,
          input0.key_12_opcode1_dest_dd ? input0.key_12_opcode1_dest_dd.value : null);

        // clear all keys when 'take immediate action' is selected
        for (const item0 of mainOptions0) {
          item0.opcode = 0;
        }
        this.setCleanKey(m0, 13, this.IOP_DISCONNECT);
        this.setCleanKey(m0, 14, this.IOP_REPEAT);

      } else if (input0.nextStep === '1') {
        m0.timeout = 8000;
        this.setCleanKey(m0, 12, this.IOP_REPEAT);
        this.setCleanKey(m0, 13, this.IOP_DISCONNECT);
        this.setCleanKey(m0, 14, this.IOP_REPEAT);

      } else if (input0.nextStep === '2') { // custom keys 12 - 14
        m0.timeout = input0.timeout2 * 1000;
        this.setOpFromInput(m0, 12, input0.key_12_opcode2.value,
                            input0.key_12_opcode2_dest,
                            input0.key_12_opcode2_dest_dd ? input0.key_12_opcode2_dest_dd.value : null);

        this.setOpFromInput(m0, 13, input0.key_13_opcode.value,
                            input0.key_13_opcode_dest,
                            input0.key_13_opcode_dest_dd ? input0.key_13_opcode_dest_dd.value : null);

        this.setOpFromInput(m0, 14, input0.key_14_opcode.value,
                            input0.key_14_opcode_dest,
                            input0.key_14_opcode_dest_dd ? input0.key_14_opcode_dest_dd.value : null);
      }

      // set keys 0-11 & 15
      // console.log('getAATabParams------');
      // console.log(mainOptions0);
      for (const item of mainOptions0) {
        this.setSMItemBeforeSave(m0, item.key, item.opcode,
          item.extension, item.extensionFormatted, item.listId);
      }
      // console.log(m0);

  }

  getAAParams() {
    // const tenantPrefix = this.aa.extension ? this.aa.extension.split('-')[0] + '-' : '';
    /* istanbul ignore else */
    if (this.subMenus && this.subMenus.length === 4) {
      // set on-hours tab
      // set subMenuItem's id and _create here by comparing the values between this.subMenus & aaForm
      const m0 = this.subMenus[0];
      const m1 = this.subMenus[1];
      const m2 = this.subMenus[2];
      const m3 = this.subMenus[3];
      const input0 = this.aaForm.get('aaSection.onHoursSection').value;
      const input1 = this.aaForm.get('aaSection.offHoursSection').value;
      const input2 = this.aaForm.get('aaSection.holidaySection').value;
      const input3 = this.aaForm.get('aaSection.customSection').value;
      this.getAATabParams(this.mainOptions1, m0, input0);
      this.getAATabParams(this.mainOptions2, m1, input1);
      this.getAATabParams(this.mainOptions3, m2, input2);
      this.getAATabParams(this.mainOptions4, m3, input3);

      if (this.showAllTheTimes === true) {
        m0.scheduleId = null;
        m2.scheduleId = null;
        m3.scheduleId = null;
      } else {
        m0.scheduleId = input0.onHours && input0.onHours.value !== 'none' ? input0.onHours.value : null;
        m2.scheduleId = input2.holiday && input2.holiday.value !== 'none' ? input2.holiday.value : null;
        m3.scheduleId = input3.custom  && input3.custom.value !== 'none' ? input3.custom.value : null;
      }
      m0.timeTypeId = 1;
      m1.timeTypeId = 2;
      m2.timeTypeId = 3;
      m3.timeTypeId = 4;

    }

    const input = this.aaForm.get('aaSection').value;
    this.aa.errors = null;
    this.aa.name = input.name;
    this.aa.locationName = input.location.displayName;
    this.aa.locationUUID = input.location.value;
    this.aa.phoneNumber = input.pnumber;
    this.aa.extensionFormatted = input.extension;
    if (!this.isCreate && this.origDN !== '') {
      this.aa.extension = this.origDN;
    } else {
      this.aa.extension = this.tenantPrefix + input.extension;
    }
    this.aa.makeNumberPrivate = input.makeExtPrivate;
    this.aa.languageId = input.language.value;
    this.aa.languages = this.languages;
    this.aa.extensionList = this.extensionList;
    this.aa.huntGroups = this.huntGroups;
    this.aa.autoAttendants = this.autoAttendants;
    this.aa.subMenus = this.subMenus;

    if (input.phoneNumberDigits && input.phoneNumberDigits.value !== '') {
      this.aa.tnId = input.phoneNumberDigits.value;
      this.aa.tnCountryId = input.phoneNumberDigits.countryId;
    }

    if ( (this.temporaryTn != null) && (this.temporaryTn.rcfTarget) && (this.temporaryTn.rcfTarget.length > 0) ) {
      this.aa.rcfTarget = this.temporaryTn.rcfTarget;
      this.aa.managementGUID = this.temporaryTn.mgmtGUID;
    }

    if (!_.isEmpty(this.newPhoneNumber)) {
      this.aa.tnId = this.newPhoneNumber.value;
      this.aa.tnCountryId = this.newPhoneNumber.countryId;
    }

    // ToDo: set UI radio selection here
    return this.aa;
  }

  scheduleOnClick(value0: number) {
    if (value0 === 1) {
      // at all times
      this.showAllTheTimes = true;
      this.showOnHours = false;
      this.showOffHours = false;
      this.showHoliday = false;
      this.showCustom = false;
    } else if (value0 === 2) {
      this.showAllTheTimes = false;
      this.showOnHours = true;
      this.showOffHours = false;
      this.showHoliday = false;
      this.showCustom = false;
    } else {
      console.log('not the expected number');
    }
  }

  timeTypeOnClick(type0: number, event) {
    const allElements = event.target.parentNode.getElementsByTagName('a');
    _.forEach(allElements, (element) => {
      element['classList'].remove('active');
    });
    event.target.classList.add('active');
    switch (type0) {
      case this.TIME_TYPE_ON_HOURS:
          this.showOnHours = true;
          this.showOffHours = false;
          this.showHoliday = false;
          this.showCustom = false;
          break;
      case this.TIME_TYPE_OFF_HOURS:
          this.showOnHours = false;
          this.showOffHours = true;
          this.showHoliday = false;
          this.showCustom = false;
          break;
      case this.TIME_TYPE_HOLIDAY:
          this.showOnHours = false;
          this.showOffHours = false;
          this.showHoliday = true;
          this.showCustom = false;
          break;
      case this.TIME_TYPE_CUSTOM:
          this.showOnHours = false;
          this.showOffHours = false;
          this.showHoliday = false;
          this.showCustom = true;
          break;
    }
  }


  async validateExtension(extn: string) {
    console.log('Validate Extension');
    this.extnErrMessage  = '';

    if ((extn !== '' && this.aa.id == null) ||
        (extn !== '' && this.aa.id != null && this.aa.extensionFormatted !== extn)) {

      await this.aaSvc.validateExtension(extn).toPromise().then (
        response => {

          if (!response.isValid && response.errorMessage) {
            this.extnErrMessage = response.errorMessage;
          }
          if (this.extnErrMessage !== '') {
            this.aaForm.controls['aaSection'].get('extension').setErrors({'invalidExtension': true});
            this.aaForm.updateValueAndValidity();
          }
        },
        error => {
          console.error('failed to validate extension', error);
          this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
            this.showServerError( 'validate extension. ' + BossApiUtils.extractErrorMessage(error));
          }));
        }
      );

    }
  }

  getCFDestinations() {
    this.aaForm.get('aaSection.onHoursSection').get('key_12_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.onHoursSection', 'key_12_opcode1');
      })).subscribe(val => {
           this.key12Op1DestList = val.candidates;
        });

    this.aaForm.get('aaSection.onHoursSection').get('key_12_opcode2_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.onHoursSection', 'key_12_opcode2');
      })).subscribe( val => {
           this.key12Op2DestList = val.candidates;
        });

    this.aaForm.get('aaSection.onHoursSection').get('new_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.onHoursSection', 'new_opcode1');
      })).subscribe(val => {
      this.newOpCodeDestList = val.candidates;
    });

    this.aaForm.get('aaSection.onHoursSection').get('key_14_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.onHoursSection', 'key_14_opcode');
      })).subscribe(val => {
      this.key14OpDestList = val.candidates;
    });

    this.aaForm.get('aaSection.onHoursSection').get('key_13_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.onHoursSection', 'key_13_opcode');
      })).subscribe(val => {
      this.key13OpDestList = val.candidates;
    });

    // Offhours tab
    this.aaForm.get('aaSection.offHoursSection').get('key_12_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.offHoursSection', 'key_12_opcode1');
      })).subscribe(val => {
      this.offHoursKey12Op1DestList = val.candidates;
    });

    this.aaForm.get('aaSection.offHoursSection').get('key_12_opcode2_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.offHoursSection', 'key_12_opcode2');
      })).subscribe(val => {
      this.offHoursKey12Op2DestList = val.candidates;
    });

    this.aaForm.get('aaSection.offHoursSection').get('new_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.offHoursSection', 'new_opcode1');
      })).subscribe(val => {
      this.offHoursNewOpCodeDestList = val.candidates;
    });

    this.aaForm.get('aaSection.offHoursSection').get('key_14_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.offHoursSection', 'key_14_opcode');
      })).subscribe(val => {
      this.offHoursKey14OpDestList = val.candidates;
    });

    this.aaForm.get('aaSection.offHoursSection').get('key_13_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.offHoursSection', 'key_13_opcode');
      })).subscribe(val => {
      this.offHoursKey13OpDestList = val.candidates;
    });

    // holiday tab
    this.aaForm.get('aaSection.holidaySection').get('key_12_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem =>  {
        return this.getDestinations('aaSection.holidaySection', 'key_12_opcode1')
      })).subscribe(val => {
      this.holidayKey12Op1DestList = val.candidates;
    });

    this.aaForm.get('aaSection.holidaySection').get('key_12_opcode2_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.holidaySection', 'key_12_opcode2');
      })).subscribe(val => {
      this.holidayKey12Op2DestList = val.candidates;
    });

    this.aaForm.get('aaSection.holidaySection').get('new_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.holidaySection', 'new_opcode1');
      })).subscribe(val => {
      this.holidayNewOpCodeDestList = val.candidates;
    });

    this.aaForm.get('aaSection.holidaySection').get('key_14_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.holidaySection', 'key_14_opcode');
      })).subscribe(val => {
      this.holidayKey14OpDestList = val.candidates;
    });

    this.aaForm.get('aaSection.holidaySection').get('key_13_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.holidaySection', 'key_13_opcode');
      })).subscribe(val => {
      this.holidayKey13OpDestList = val.candidates;
    });

    // Custom tab
    this.aaForm.get('aaSection.customSection').get('key_12_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.customSection', 'key_12_opcode1');
      })).subscribe(val => {
      this.customKey12Op1DestList = val.candidates;
    });

    this.aaForm.get('aaSection.customSection').get('key_12_opcode2_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.customSection', 'key_12_opcode2');
      })).subscribe(val => {
      this.customKey12Op2DestList = val.candidates;
    });
    this.aaForm.get('aaSection.customSection').get('new_opcode1_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.customSection', 'new_opcode1');
      })).subscribe(val => {
      this.customNewOpCodeDestList = val.candidates;
    });

    this.aaForm.get('aaSection.customSection').get('key_14_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.customSection', 'key_14_opcode');
      })).subscribe(val => {
      this.customKey14OpDestList = val.candidates;
    });

    this.aaForm.get('aaSection.customSection').get('key_13_opcode_dest').valueChanges
    .pipe(
      debounceTime(300),
      switchMap(mem => {
        return this.getDestinations('aaSection.customSection', 'key_13_opcode');
      })).subscribe(val => {
      this.customKey13OpDestList = val.candidates;
    });
  }

  getDestinations(section: string, keyOpCode: string) {
    // CFType: 1 for Hunt Group Call Forward, 2 for Auto Attendant Take a Message, 3  for Auto Attendant Call Forward
    let cfType_key_12_opcode = 0;
    const destList = [];
    const key_12_opcode = this.aaForm.get(section).get(keyOpCode).value ? this.aaForm.get(section).get(keyOpCode).value.value : '';

    if ( key_12_opcode === this.OP_TO_EXT || key_12_opcode === this.OP_TO_EXT_W_PROMPT) {
      cfType_key_12_opcode = 3;
    } else if (key_12_opcode === this.OP_LEAVE_MSG) {
      cfType_key_12_opcode = 2;
    }
    if (key_12_opcode !== '' && this.aaForm.get(section).get(keyOpCode + '_dest').dirty && cfType_key_12_opcode !== 0) {
      try {
        const fieldVal = this.aaForm.get(section).get(keyOpCode + '_dest').value;
        /* istanbul ignore else */
        if (fieldVal && !fieldVal.trim().match(/ : /)) {
          return this.aaSvc.getCallForwardDestinations(
              this.aaForm.get(section).get(keyOpCode + '_dest').value,
              cfType_key_12_opcode);
        }
      } catch (reason) {
        console.error('failed to fetch call forward destination', JSON.stringify(reason));
      }
    }
    return destList;
  }

  createNewExtensionList(str0: string) {
    console.log('Add New Extension Group');
    this.deleteConfirmDisplayFlag = true;
    if (!this.modalRef) {

        // makes it a modal window that cannot be dismissed by clicking outside.
        const options: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass : 'hours-schedule-custom'
        };

        this.modalRef = this.modalSvc.open(ExtensionListRightPanelComponent, options);
        this.modalRef.componentInstance.popUp = true;
        this.modalRef.result.then(async (result) => {
          this.modalRef = null;
          console.log(result);
          this.extensionList.push({ value: result.id, displayName: result.name });
          this.setFlowSeelected(str0, result.id);
        }, (reason) => {
            this.modalRef = null;
            console.log(reason);
        });
    } else {
        console.log('modalRef not set to null.');
        this.modalRef = null;
    }
}

createNewHuntGroup(str0: string) {
  console.log('Add New Huntgroup');

  if (!this.modalRef) {
      const options: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false
      };

      this.deleteConfirmDisplayFlag = true;
      this.modalRef = this.modalSvc.open(FlowsCreateModelComponent, options);
      this.modalRef.componentInstance.title = 'hunt_group.new_hg';
      this.modalRef.componentInstance.addText = 'hunt_group.add_hg';
      this.modalRef.componentInstance.flow = 'hg';
      this.modalRef.componentInstance.locationUUID = this.aaForm.value.aaSection.location.value;
      this.modalRef.componentInstance.locationName = this.aaForm.value.aaSection.location.value;
      this.modalRef.result.then(async (result) => {
      this.modalRef = null;
      // this.huntGroups.push({ value: result.dn, displayName: result.name });
        // should not fill in blank entry to hg dropdown
        if (result.dn) {
          this.huntGroups.push({ value: result.dn, displayName: result.dn + ' : ' + result.name });
          this.setFlowSeelected(str0, result.dn);
        }

      }, (reason) => {
          // this.closeReason = this.getDismissReason(reason);
          this.modalRef = null;
          this.deleteConfirmDisplayFlag = false;
          console.log(reason);
      });
  } else {
      console.log('modalRef not set to null.');
      this.modalRef = null;
  }
}

createNewAA(str0: string) {
  console.log('Add New AA');
  const section = str0.split('.')[0];
  const keyOption = str0.split('.')[1] + '_dest_dd';
  if (!this.modalRef) {
      const options: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false
      };

      this.deleteConfirmDisplayFlag = true;
      this.modalRef = this.modalSvc.open(FlowsCreateModelComponent, options);
      this.modalRef.componentInstance.title = 'aa.new_aa';
      this.modalRef.componentInstance.addText = 'aa.add_aa';
      this.modalRef.componentInstance.flow = 'aa';
      this.modalRef.componentInstance.locationUUID = this.aaForm.value.aaSection.location.value;
      this.modalRef.componentInstance.locationName = this.aaForm.value.aaSection.location.value;

      this.modalRef.result.then(async (result) => {
          this.modalRef = null;
          console.log(result);
          // should not fill in blank entry to aa dropdown
          if (result.dn) {
            this.autoAttendants.push({ value: result.dn, displayName: result.dn + ' : ' + result.name });
            this.setFlowSeelected(str0, result.dn);
          }

      }, (reason) => {
          // this.closeReason = this.getDismissReason(reason);
          this.modalRef = null;
          this.deleteConfirmDisplayFlag = false;
          console.log(reason);
      });
  } else {
      console.log('modalRef not set to null.');
      this.modalRef = null;
  }
}

setFlowSeelected(str0: string, dn: string ) {
  const section = 'aaSection.' + str0.split('.')[0];
  switch (str0) {
    case 'onHoursSection.onHours':
      this.aaForm.get(section).patchValue({
        onHours: {value: dn}
      });
      break;
    case 'holidaySection.holiday':
      this.aaForm.get(section).patchValue({
        holiday: {value: dn}
      });
      break;
    case 'customSection.custom':
      this.aaForm.get(section).patchValue({
        custom: {value: dn}
      });
      break;
    default:
      if (this.aaForm.get('aaSection.' + str0 + '_dest_dd')) {
        this.aaForm.get('aaSection.' + str0 + '_dest_dd').patchValue( {value: dn} );
      } else {
        console.log('no form object');
      }
      break;
  }

}

  displayCFDestination(destination:  any) {
    if (typeof destination === 'string') {
      return destination;
    }
    if (destination != null) {
      console.log('Destination object:', true);
      return destination.extension ? destination.extension.split('-')[1] + ' : ' + destination.description :
          destination.extension + ' : ' + destination.description ;
    }
  }

  openConfirmDialog(action: { collapse?: boolean, navigateAway?: boolean} = {}) {
    if (!this.modalRef) {
      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        windowClass: 'confimation-box'
      };
      this.modalRef = this.modalSvc.open(NavigationConfirmationComponent, options);
      this.modalRef.result.then((result) => {
        this.modalRef = null;
        this.closeReason = this.getDismissReason(result, action);
        this.checkDirtyAndCall(this.callOnClose);
      }, (reason) => {
        this.closeReason = this.getDismissReason(reason, action);
        this.modalRef = null;
        this.checkDirtyAndCall(this.callOnClose);
      });
    }
  }

  getDismissReason(reason: any, action: { collapse?: boolean, navigateAway?: boolean} = {}): string {
    if (reason === ModalDismissReasons.ESC) {
      return rightPanelCloseReason.ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return rightPanelCloseReason.BACKDROP;
    } else if (reason === 'leave') {
      if (action.collapse) {
        return rightPanelCloseReason.COLLAPSE;
      } else if (action.navigateAway) {
        return rightPanelCloseReason.NAVIGATEAWAY;
      } else {
        return reason;
      }
    } else {
      return  reason;
    }
  }

  insertMessage(msg) {
    this.notificationService.updateMessage(msg);
  }

  ngOnDestroy() {
    this.tnAdapterSvc.wasNewPhoneNumberPreviouslyFetched = false;
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.rxjsSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  showServerError( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  showServerErrorOnForm( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerErrorOnForm() {
    this.errMsg = '';
    this.showFormError = false;
    this.extnErrMessage = '';
    this.dnErr = '';
    this.otherErr = [];
    this.hoursTimeoutErr = '';
    this.hoursInvalidDestErr = '';
    this.hoursAfterThreeErr = '';
    this.offHoursTimeoutErr = '';
    this.offHoursAfterThreeErr = '';
    this.offHoursInvalidDestErr = '';
    this.holidayTimeoutErr = '';
    this.holidayAfterThreeErr = '';
    this.holidayInvalidDestErr = '';
    this.customTimeoutErr = '';
    this.customAfterThreeErr = '';
    this.customInvalidDestErr = '';
  }

  parseServerError( message: string ) {
    if (message) {
      // console.log(message);
      // message might be a pure string (from m5) or json string (from d2)
      // error returned from d2api
      if (message.indexOf('sub_menus.sub_menu_items.DN') !== -1) {
        // this.otherErr.push('Cannot assign invalid extension to sub menu(s)');

        const err0 = JSON.parse(message);
        // if (err0 !== null) {
          // console.log(err0);
          // console.log(err0['sub_menus_attributes']);
          let i = 1;
          for (const mode of err0['sub_menus_attributes']) {
            // console.log(mode);
            if (mode !== null) {
              let j = 0;
              let msg0 = '';
              for (const item of mode['sub_menu_items_attributes']) {
                if (item !== null) {
                  // console.log(item);
                  if (item['DN']) {
                    msg0 = item['DN'];
                    // i = time type, j = key#
                    this.showErrorMessage(i, j, msg0, 'dn');
                  } else if (item['ExtensionListID']) {
                    msg0 = item['ExtensionListID'];
                    this.showErrorMessage(i, j, msg0, 'list');
                  }
                }
                j = j + 1;
                msg0 = '';
              }
            }

            i = i + 1;
          }

        // }
      } else if (message.indexOf('dn.DN') !== -1) {
        const err1 = JSON.parse(message);
        if (err1 && err1['dn.DN'] && err1['dn.DN'][0]) {
          if (this.extnErrMessage && this.extnErrMessage.indexOf('Recommended') !== -1) {
            this.extnErrMessage = this.extnErrMessage.replace(/The selected extension is not valid or is in use/g, err1['dn.DN'][0]);
          } else {
            this.dnErr = err1['dn.DN'][0];
          }
        } else {
          console.log('no dn error');
        }
      } else {
        console.log('no dn error');
      }
        // otherwise error message is from m5 which is already handled
        // this.otherErr.push(message);

    } {
      console.log('no message');
    }
  }

  showErrorMessage(timeType0: number, key0: number, msg0: string, type0: string) {
    if (msg0 !== '') {
      let keyDisplay0 = '';
      let timeDisplay0 = '';
      switch (key0) {
        case 10:
            keyDisplay0 = '*';
            break;
        case 11:
            keyDisplay0 = '#';
            break;
        case 12:
            keyDisplay0 = 'Timeout';
            break;
        case 13:
            keyDisplay0 = 'After 3 errors';
            break;
        case 14:
            keyDisplay0 = 'Invalid';
            break;
        case 15:
            keyDisplay0 = 'Extension';
            break;
        default:
            keyDisplay0 = key0.toString();
            break;
      }

      switch (timeType0) {
        case 1:
            timeDisplay0 = 'On-Hours ';
            break;
        case 2:
            timeDisplay0 = 'Off-Hours ';
            break;
        case 3:
            timeDisplay0 = 'Holiday ';
            break;
        case 4:
            timeDisplay0 = 'Custom ';
            break;
      }

      let dest0 = ' destination extension was not found or is not permitted';
      if (type0 === 'list') {
        dest0 = ' destination extension group was not found or is not permitted';
      }
      // i.e. On-Hours menu option 1’s destination is invalid
      if (_.includes([12, 13, 14], key0)) {
        this.showErrorMessageInForm(key0, type0, timeType0);
      } else {
        this.otherErr.push(timeDisplay0 + 'menu option ' + keyDisplay0 + dest0);
      }
    } else {
      console.log('Message is Empty');
    }
  }

  showErrorMessageInForm(key, type, timeType) {
    let dest0 = ' destination extension was not found or is not permitted';
    if (type === 'list') {
      dest0 = ' destination extension group was not found or is not permitted';
    }
    switch (key) {
      case 12:
        if (timeType === 1) {
          this.hoursTimeoutErr = dest0;
        } else if (timeType === 2) {
          this.offHoursTimeoutErr = dest0;
        } else if (timeType === 3) {
          this.holidayTimeoutErr = dest0;
        } else if (timeType === 4) {
          this.customTimeoutErr = dest0;
        } else {
          console.log('timetype not matched');
        }
        break;
      case 13:
        if (timeType === 1) {
          this.hoursAfterThreeErr = dest0;
        } else if (timeType === 2) {
          this.offHoursAfterThreeErr = dest0;
        } else if (timeType === 3) {
          this.holidayAfterThreeErr = dest0;
        } else if (timeType === 4) {
          this.customAfterThreeErr = dest0;
        } else {
          console.log('timetype not matched');
        }
        break;
      case 14:
        if (timeType === 1) {
          this.hoursInvalidDestErr = dest0;
        } else if (timeType === 2) {
          this.offHoursInvalidDestErr = dest0;
        } else if (timeType === 3) {
          this.holidayInvalidDestErr = dest0;
        } else if (timeType === 4) {
          this.customInvalidDestErr = dest0;
        } else {
          console.log('timetype not matched');
        }
        break;
      default:
        console.log('no key matched');
        break;
    }
  }

  openUploadPrompt(timeTypeId) {
    console.log('Upload Prompt Opened');
    console.log(`Schedule Type ${timeTypeId}`);
    this.deleteConfirmDisplayFlag = true;
      if (!this.modalRef) {

        // makes it a modal window that cannot be dismissed by clicking outside.
        const options: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          windowClass : 'prompt-block'
        };

        this.modalRef = this.modalSvc.open(PromptUploaderComponent, options);
        this.modalRef.componentInstance.autoAttentantId = _.isEmpty(this.aa.id) ? 0 : this.aa.id;
        this.modalRef.componentInstance.timeType = timeTypeId;
        this.modalRef.componentInstance.triggerOnLoad();
        this.subscriptions.push(this.modalRef.componentInstance.promptData.subscribe((receivedData) => {
          const key = this.TIME_TYPE_MAPPING[String(timeTypeId)];
          this.subMenus[key]['prompts']['fileName'] = receivedData;
          this.subMenus[key]['prompts']['_create'] = true;
          this.aaBlock.nativeElement.click();
          this.aaForm.markAsDirty();
        }));
        this.modalRef.result.then(async (result) => {
          this.modalRef = null;
          console.log(result);
        }, (reason) => {
          this.modalRef = null;
          console.log(reason);
        });
      } else {
        console.log('modalRef not se to null.');
        this.modalRef = null;
      }
    }

  async downloadAudio(anchorElement, anchorElementPreview, audioElement, timeTypeId, fileName, showAudioPlayer = false) {
    const _this = this;
    if (anchorElement.nativeElement.href === 'javascript:') {
      const aaId = _.isEmpty(this.aa.id) ? 0 : this.aa.id;
      _this.displayLoadingSpinner = true;
      await this.aaSvc.getRecordings(aaId, timeTypeId, fileName)
          .toPromise().then(response => {
                const audioUrl = 'data:audio/wav;base64,' + JSON.parse(response);
                if (showAudioPlayer) {
                  _this.showAudioPlayer(timeTypeId, true, audioUrl);
                  _this.updateAnchorHref(anchorElement, anchorElementPreview, audioElement, audioUrl);
                } else {
                  _this.updateAnchorHref(anchorElement, anchorElementPreview, audioElement, audioUrl);
                  anchorElement.nativeElement.click();
                }
              },
              error => {
                _this.displayLoadingSpinner = false;
                console.error('Failed to load Audio', error);
              });
   } else {
      /* istanbul ignore else */
     if (showAudioPlayer) {
       _this.showAudioPlayer(timeTypeId, true, anchorElement.nativeElement.href);
    }
   }
  }

  updateAnchorHref(anchorElement, anchorElementPreview, audioElement, audioUrl) {
    this.displayLoadingSpinner = false;
    anchorElement.nativeElement.href = audioUrl;
    // anchorElementPreview.nativeElement.href = audioUrl;
    // audioElement.nativeElement.src = audioUrl;
  }

  removePrompt(key, timeTypeId) {
    this.subMenus[key]['promptId'] = null;
    this.subMenus[key]['prompts'] = { 'id': null, 'fileName': null, 'fileDuration': 0, '_create': false };
    this.aaForm.markAsDirty();
    this.showAudioPlayer(timeTypeId, false);
  }

  showAudioPlayer(timeTypeId, value, dataUrl = '') {
    switch (timeTypeId) {
      case 1: {
        this.openOnHoursAudio = value;
        if (value) {
          setTimeout(() => {
            this.onHoursComp.onHoursAudioElement.nativeElement.src = dataUrl;
          }, 1000);
        }
        break;
      }
      case 2: {
        this.openOffHoursAudio = value;
        if (value) {
          setTimeout(() => {
            this.offHoursComp.offHoursAudioElement.nativeElement.src = dataUrl;
          }, 1000);
        }
        break;
      }
      case 3: {
        this.openHolidayAudio = value;
        if (value) {
          setTimeout(() => {
            this.holidayComp.holidayAudioElement.nativeElement.src = dataUrl;
          }, 1000);
        }
        break;
      }
      default: {
        this.openCustomAudio = value;
        if (value) {
          setTimeout(() => {
            this.customComp.customAudioElement.nativeElement.src = dataUrl;
          }, 1000);
        }
        break;
      }
    }
  }

  displayShoppingCart(turnupProductId: number) {
    if (!turnupProductId) {return; }

    // put up the shopping cart

    const products: Array<QuotePriceProductDC> = [];
    products.push({
      quantity: 1,
      productId: turnupProductId
      /**
       * Parent product ID
       * To quote profile service addon price, this field must be set to phone bundle product ID
       */
      // dependentOnProductId?: number;
    });

    const quotePriceRequestDC: QuotePriceRequestDC = {
      /**
       * Purchase effective date
       * Optional
       * If omitted, defaults to today
       */
      // effectiveDate?: string;

      /**
       * Products to quote
       * Array<QuotePriceProductDC>
       */
      products: products
    };

    this.subscriptions.push(
        this.productsServices.ProductsQuotePurchasePrice(
            { request: quotePriceRequestDC, locationUuid: this.phSvc.getSelectedLocation(),
              Authorization: null }).subscribe(
            (billingImpactDC: BillingImpactDC) => {
              // console.log('displayShoppingCart  billingImpactDC=', billingImpactDC);
              this.shoppingCartService.placeOrderDetails.next(billingImpactDC);
              this.shoppingCartService.generateOrderInvoice((<BillingImpactDC>billingImpactDC),
                  this.translateSvc.instant('cart_invoice_messages.add_order_details'));
            },
            error => {
              console.error('ProductsQuotePurchasePrice() failed1: ', error);
              this.showServerErrorOnForm(BossApiUtils.extractErrorMessage(error));
            }
        ));
  }

  setValidExtension(ext) {
    let newExt = ext;
    this.phSvc.isExtensionValid(newExt).then(validExt => {
      this.extnErrMessage = '';
      if (!validExt.isValid) {
        if (!_.isEmpty(validExt.suggestedExtension)) {
          newExt = validExt.suggestedExtension;
        } else {
          this.extnErrMessage = validExt.errorMessage;
        }
      }
      if (this.extnErrMessage === '') {
        this.aaForm.controls['aaSection'].get('extension').setErrors({'invalidExtension': null});
        this.aaForm.updateValueAndValidity();
      }
      this.aaForm.get('aaSection').patchValue({
        extension: newExt
      });
    });
  }
}
