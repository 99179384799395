import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() notificationMessage: string;
  @Input() externalStyles: any = {};
  @Input() id = 'theNotification';
  staticNotificationShow = false;

  constructor(public translateSvc: TranslateService,
              private notificationService: NotificationService) { }

  ngOnInit() {
    this.notificationService.notification.subscribe( data => {
      this.notificationMessage = data;
      this.staticNotificationShow = true;
      setTimeout(() => {
        this.staticNotificationShow = false;
        console.log('timed out on displaying notification window');
      }, 7000);
    });
  }

  onClose() {
    console.log('closing notification window');
    this.staticNotificationShow = false;
  }

  ngOnDestroy() {
    // Cancel the subscription
  }
}
