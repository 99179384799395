/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserDC } from '../models/user-dc';
import { PageResultUserDC } from '../models/page-result-user-dc';
import { PersonDC } from '../models/person-dc';
import { ProfileDC } from '../models/profile-dc';
import { ValidateExtensionDC } from '../models/validate-extension-dc';
import { CreatePersonDC } from '../models/create-person-dc';
import { EditPersonDC } from '../models/edit-person-dc';
import { CreateUserResultDC } from '../models/create-user-result-dc';
import { CreateProfileDC } from '../models/create-profile-dc';
import { CreateUserDC } from '../models/create-user-dc';
import { CanDeleteRoleDC } from '../models/can-delete-role-dc';
import { MoveUserResultDC } from '../models/move-user-result-dc';
import { MoveUserRequestDC } from '../models/move-user-request-dc';
import { BillingImpactDC } from '../models/billing-impact-dc';
import { EditProfileDC } from '../models/edit-profile-dc';
import { OrderDC } from '../models/order-dc';
import { SwapUserProductDC } from '../models/swap-user-product-dc';
import { SwapOrderPreviewDC } from '../models/swap-order-preview-dc';
import { SOXDetailsDC } from '../models/soxdetails-dc';
import { CloseOrderImpactDC } from '../models/close-order-impact-dc';
import { UserReferenceDC } from '../models/user-reference-dc';
import { UsernameValidationResultDC } from '../models/username-validation-result-dc';
import { UsernameValidationRequestDC } from '../models/username-validation-request-dc';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly UsersGetUsersByAddonFeaturePath = '/api/Account/AddonFeature/Users';
  static readonly UsersGetUsersByAddonFeaturePagedPath = '/api/Account/AddonFeature/UsersPaged';
  static readonly UsersGetPersonsByAccountPath = '/api/Account/Persons';
  static readonly UsersGetPersonRolesPath = '/api/Account/Persons/{userUuid}/Roles';
  static readonly UsersGetPersonsByCanRequestOrderPermissionPath = '/api/Account/Persons/CanRequestOrder';
  static readonly UsersGetProfilesByAccountPath = '/api/Account/Profiles';
  static readonly UsersGetUsersByAccountPath = '/api/Account/Users';
  static readonly UsersGetUsersByAccountPagedPath = '/api/Account/UsersPaged';
  static readonly UsersExtensionValidationPath = '/api/Account/ValidateExtension';
  static readonly UsersCreateRoleMembersPath = '/api/Accounts/Persons/{userUuid}/Roles';
  static readonly UsersDeleteRoleMembersPath = '/api/Accounts/Persons/{userUuid}/Roles';
  static readonly UsersGetPermissionsPath = '/api/Permissions';
  static readonly UsersCreatePersonPath = '/api/Persons';
  static readonly UsersEditPersonPath = '/api/Persons/{uuid}';
  static readonly UsersDeletePersonPath = '/api/Persons/{uuid}';
  static readonly UsersCreateProfilePath = '/api/Profiles';
  static readonly UsersGetProfilePath = '/api/Profiles/{profileId}';
  static readonly UsersAssignExistingPersonPath = '/api/Profiles/{profileId}/AssignExistingPerson';
  static readonly UsersAssignNewPersonPath = '/api/Profiles/{profileId}/AssignNewPerson';
  static readonly UsersGetProfileTypesPath = '/api/ProfileTypes';
  static readonly UsersGetRolesPath = '/api/Roles';
  static readonly UsersCreateUserPath = '/api/Users';
  static readonly UsersCanDeleteRolePath = '/api/Users/{userUuid}/CanDeleteRole/{roleId}';
  static readonly UsersMoveUserPath = '/api/Users/{userUuid}/Move';
  static readonly UsersMoveUserPreviewPath = '/api/Users/{userUuid}/Move/Preview';
  static readonly UsersEditProfilePath = '/api/Users/{userUuid}/Profile/{profileId}';
  static readonly UsersSwapProductPath = '/api/Users/{userUuid}/Profile/{profileId}/SwapProduct';
  static readonly UsersSwapProductPreviewPath = '/api/Users/{userUuid}/Profile/{profileId}/SwapProduct/Preview';
  static readonly UsersReassignUserServicePath = '/api/Users/{userUuid}/Reassign';
  static readonly UsersGetUserPath = '/api/Users/{uuid}';
  static readonly UsersCloseUserPath = '/api/Users/{uuid}/Close';
  static readonly UsersCloseUserPreviewPath = '/api/Users/{uuid}/Close/Preview';
  static readonly UsersGetUserReferencesPath = '/api/Users/{uuid}/References';
  static readonly UsersGetUsersForReassignPath = '/api/Users/ForReassign';
  static readonly UsersGetImportUserCSVTemplatePath = '/api/Users/GetImportUserCSVTemplate';
  static readonly UsersGetMyPermissionsPath = '/api/Users/MyPermissions';
  static readonly UsersValidateUsernamePath = '/api/Users/ValidateUsername';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAddonFeatureParams` containing the following parameters:
   *
   * - `featureIds`: Feature Id or Id's of the addon feature
   *
   * - `Authorization`: access token
   *
   * - `subscribedUsers`: True = subscribed users, False = available users to subscribe
   *
   * - `includeAddons`: Includes all the subscribed addons for the users
   *
   * @return OK
   */
  UsersGetUsersByAddonFeatureResponse(params: UsersService.UsersGetUsersByAddonFeatureParams): __Observable<__StrictHttpResponse<Array<UserDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.featureIds || []).forEach(val => {if (val != null) __params = __params.append('featureIds', val.toString())});
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.subscribedUsers != null) __params = __params.set('subscribedUsers', params.subscribedUsers.toString());
    if (params.includeAddons != null) __params = __params.set('includeAddons', params.includeAddons.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/AddonFeature/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAddonFeatureParams` containing the following parameters:
   *
   * - `featureIds`: Feature Id or Id's of the addon feature
   *
   * - `Authorization`: access token
   *
   * - `subscribedUsers`: True = subscribed users, False = available users to subscribe
   *
   * - `includeAddons`: Includes all the subscribed addons for the users
   *
   * @return OK
   */
  UsersGetUsersByAddonFeature(params: UsersService.UsersGetUsersByAddonFeatureParams): __Observable<Array<UserDC>> {
    return this.UsersGetUsersByAddonFeatureResponse(params).pipe(
      __map(_r => _r.body as Array<UserDC>)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAddonFeaturePagedParams` containing the following parameters:
   *
   * - `featureIds`: Feature Id or Id's of the addon feature
   *
   * - `Authorization`: access token
   *
   * - `subscribedUsers`: True = subscribed users, False = available users to subscribe
   *
   * - `includeAddons`: Includes all the subscribed addons for the users
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  UsersGetUsersByAddonFeaturePagedResponse(params: UsersService.UsersGetUsersByAddonFeaturePagedParams): __Observable<__StrictHttpResponse<PageResultUserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.featureIds || []).forEach(val => {if (val != null) __params = __params.append('featureIds', val.toString())});
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.subscribedUsers != null) __params = __params.set('subscribedUsers', params.subscribedUsers.toString());
    if (params.includeAddons != null) __params = __params.set('includeAddons', params.includeAddons.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/AddonFeature/UsersPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultUserDC>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAddonFeaturePagedParams` containing the following parameters:
   *
   * - `featureIds`: Feature Id or Id's of the addon feature
   *
   * - `Authorization`: access token
   *
   * - `subscribedUsers`: True = subscribed users, False = available users to subscribe
   *
   * - `includeAddons`: Includes all the subscribed addons for the users
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  UsersGetUsersByAddonFeaturePaged(params: UsersService.UsersGetUsersByAddonFeaturePagedParams): __Observable<PageResultUserDC> {
    return this.UsersGetUsersByAddonFeaturePagedResponse(params).pipe(
      __map(_r => _r.body as PageResultUserDC)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param Authorization access token
   * @return OK
   */
  UsersGetPersonsByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<PersonDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Persons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param Authorization access token
   * @return OK
   */
  UsersGetPersonsByAccount(Authorization: string): __Observable<Array<PersonDC>> {
    return this.UsersGetPersonsByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<PersonDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `UsersService.UsersGetPersonRolesParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `Authorization`: access token
   *
   * - `X-Mitel-SDAPI-TargetAccount`: API request target account guid
   *
   * @return OK
   */
  UsersGetPersonRolesResponse(params: UsersService.UsersGetPersonRolesParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.XMitelSDAPITargetAccount != null) __headers = __headers.set('X-Mitel-SDAPI-TargetAccount', params.XMitelSDAPITargetAccount.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Persons/${params.userUuid}/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `UsersService.UsersGetPersonRolesParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `Authorization`: access token
   *
   * - `X-Mitel-SDAPI-TargetAccount`: API request target account guid
   *
   * @return OK
   */
  UsersGetPersonRoles(params: UsersService.UsersGetPersonRolesParams): __Observable<Array<number>> {
    return this.UsersGetPersonRolesResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param Authorization access token
   * @return OK
   */
  UsersGetPersonsByCanRequestOrderPermissionResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<PersonDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Persons/CanRequestOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param Authorization access token
   * @return OK
   */
  UsersGetPersonsByCanRequestOrderPermission(Authorization: string): __Observable<Array<PersonDC>> {
    return this.UsersGetPersonsByCanRequestOrderPermissionResponse(Authorization).pipe(
      __map(_r => _r.body as Array<PersonDC>)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetProfilesByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `includeOrders`:
   *
   * @return OK
   */
  UsersGetProfilesByAccountResponse(params: UsersService.UsersGetProfilesByAccountParams): __Observable<__StrictHttpResponse<Array<ProfileDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeOrders != null) __params = __params.set('includeOrders', params.includeOrders.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Profiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProfileDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetProfilesByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `includeOrders`:
   *
   * @return OK
   */
  UsersGetProfilesByAccount(params: UsersService.UsersGetProfilesByAccountParams): __Observable<Array<ProfileDC>> {
    return this.UsersGetProfilesByAccountResponse(params).pipe(
      __map(_r => _r.body as Array<ProfileDC>)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `includeOrders`:
   *
   * @return OK
   */
  UsersGetUsersByAccountResponse(params: UsersService.UsersGetUsersByAccountParams): __Observable<__StrictHttpResponse<Array<UserDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeOrders != null) __params = __params.set('includeOrders', params.includeOrders.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `includeOrders`:
   *
   * @return OK
   */
  UsersGetUsersByAccount(params: UsersService.UsersGetUsersByAccountParams): __Observable<Array<UserDC>> {
    return this.UsersGetUsersByAccountResponse(params).pipe(
      __map(_r => _r.body as Array<UserDC>)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `includeOrders`:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  UsersGetUsersByAccountPagedResponse(params: UsersService.UsersGetUsersByAccountPagedParams): __Observable<__StrictHttpResponse<PageResultUserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.includeOrders != null) __params = __params.set('includeOrders', params.includeOrders.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/UsersPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultUserDC>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetUsersByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `includeOrders`:
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  UsersGetUsersByAccountPaged(params: UsersService.UsersGetUsersByAccountPagedParams): __Observable<PageResultUserDC> {
    return this.UsersGetUsersByAccountPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultUserDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersExtensionValidationParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `extension`:
   *
   * @return OK
   */
  UsersExtensionValidationResponse(params: UsersService.UsersExtensionValidationParams): __Observable<__StrictHttpResponse<ValidateExtensionDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.extension != null) __params = __params.set('extension', params.extension.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/ValidateExtension`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ValidateExtensionDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersExtensionValidationParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `extension`:
   *
   * @return OK
   */
  UsersExtensionValidation(params: UsersService.UsersExtensionValidationParams): __Observable<ValidateExtensionDC> {
    return this.UsersExtensionValidationResponse(params).pipe(
      __map(_r => _r.body as ValidateExtensionDC)
    );
  }

  /**
   * Required Permission: EditProfileRoles (212)
   * @param params The `UsersService.UsersCreateRoleMembersParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `roleIds`: Array of role IDs
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreateRoleMembersResponse(params: UsersService.UsersCreateRoleMembersParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.roleIds;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Accounts/Persons/${params.userUuid}/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Required Permission: EditProfileRoles (212)
   * @param params The `UsersService.UsersCreateRoleMembersParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `roleIds`: Array of role IDs
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreateRoleMembers(params: UsersService.UsersCreateRoleMembersParams): __Observable<Array<number>> {
    return this.UsersCreateRoleMembersResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Required Permission: EditProfileRoles (212)
   * @param params The `UsersService.UsersDeleteRoleMembersParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `roleIds`: Array of role IDs
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersDeleteRoleMembersResponse(params: UsersService.UsersDeleteRoleMembersParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.roleIds;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Accounts/Persons/${params.userUuid}/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditProfileRoles (212)
   * @param params The `UsersService.UsersDeleteRoleMembersParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `roleIds`: Array of role IDs
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersDeleteRoleMembers(params: UsersService.UsersDeleteRoleMembersParams): __Observable<{}> {
    return this.UsersDeleteRoleMembersResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetPermissionsResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Permissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetPermissions(Authorization: string): __Observable<{[key: string]: string}> {
    return this.UsersGetPermissionsResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `UsersService.UsersCreatePersonParams` containing the following parameters:
   *
   * - `createPersonDC`: User data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreatePersonResponse(params: UsersService.UsersCreatePersonParams): __Observable<__StrictHttpResponse<PersonDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createPersonDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Persons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `UsersService.UsersCreatePersonParams` containing the following parameters:
   *
   * - `createPersonDC`: User data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreatePerson(params: UsersService.UsersCreatePersonParams): __Observable<PersonDC> {
    return this.UsersCreatePersonResponse(params).pipe(
      __map(_r => _r.body as PersonDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersEditPersonParams` containing the following parameters:
   *
   * - `uuid`: User UUID
   *
   * - `editPersonDC`: User data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersEditPersonResponse(params: UsersService.UsersEditPersonParams): __Observable<__StrictHttpResponse<PersonDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.editPersonDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Persons/${params.uuid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersEditPersonParams` containing the following parameters:
   *
   * - `uuid`: User UUID
   *
   * - `editPersonDC`: User data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersEditPerson(params: UsersService.UsersEditPersonParams): __Observable<PersonDC> {
    return this.UsersEditPersonResponse(params).pipe(
      __map(_r => _r.body as PersonDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersDeletePersonParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersDeletePersonResponse(params: UsersService.UsersDeletePersonParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Persons/${params.uuid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersDeletePersonParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersDeletePerson(params: UsersService.UsersDeletePersonParams): __Observable<{}> {
    return this.UsersDeletePersonResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `UsersService.UsersCreateProfileParams` containing the following parameters:
   *
   * - `personUuid`: Person UUID
   *
   * - `createProfileDC`: Profile data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreateProfileResponse(params: UsersService.UsersCreateProfileParams): __Observable<__StrictHttpResponse<CreateUserResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.personUuid != null) __params = __params.set('personUuid', params.personUuid.toString());
    __body = params.createProfileDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Profiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateUserResultDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `UsersService.UsersCreateProfileParams` containing the following parameters:
   *
   * - `personUuid`: Person UUID
   *
   * - `createProfileDC`: Profile data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreateProfile(params: UsersService.UsersCreateProfileParams): __Observable<CreateUserResultDC> {
    return this.UsersCreateProfileResponse(params).pipe(
      __map(_r => _r.body as CreateUserResultDC)
    );
  }

  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetProfileParams` containing the following parameters:
   *
   * - `profileId`: BOSS Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersGetProfileResponse(params: UsersService.UsersGetProfileParams): __Observable<__StrictHttpResponse<ProfileDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Profiles/${params.profileId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProfileDC>;
      })
    );
  }
  /**
   * Required Permission: ViewUsers (216)
   * @param params The `UsersService.UsersGetProfileParams` containing the following parameters:
   *
   * - `profileId`: BOSS Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersGetProfile(params: UsersService.UsersGetProfileParams): __Observable<ProfileDC> {
    return this.UsersGetProfileResponse(params).pipe(
      __map(_r => _r.body as ProfileDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersAssignExistingPersonParams` containing the following parameters:
   *
   * - `userUuid`: User UUID
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersAssignExistingPersonResponse(params: UsersService.UsersAssignExistingPersonParams): __Observable<__StrictHttpResponse<UserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userUuid != null) __params = __params.set('userUuid', params.userUuid.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Profiles/${params.profileId}/AssignExistingPerson`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersAssignExistingPersonParams` containing the following parameters:
   *
   * - `userUuid`: User UUID
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersAssignExistingPerson(params: UsersService.UsersAssignExistingPersonParams): __Observable<UserDC> {
    return this.UsersAssignExistingPersonResponse(params).pipe(
      __map(_r => _r.body as UserDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersAssignNewPersonParams` containing the following parameters:
   *
   * - `profileId`: Profile ID
   *
   * - `createPersonDC`: New person data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersAssignNewPersonResponse(params: UsersService.UsersAssignNewPersonParams): __Observable<__StrictHttpResponse<UserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.createPersonDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Profiles/${params.profileId}/AssignNewPerson`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersAssignNewPersonParams` containing the following parameters:
   *
   * - `profileId`: Profile ID
   *
   * - `createPersonDC`: New person data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersAssignNewPerson(params: UsersService.UsersAssignNewPersonParams): __Observable<UserDC> {
    return this.UsersAssignNewPersonResponse(params).pipe(
      __map(_r => _r.body as UserDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetProfileTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ProfileTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetProfileTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.UsersGetProfileTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetRolesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetRoles(Authorization: string): __Observable<{[key: string]: string}> {
    return this.UsersGetRolesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `UsersService.UsersCreateUserParams` containing the following parameters:
   *
   * - `createUserDC`: User data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreateUserResponse(params: UsersService.UsersCreateUserParams): __Observable<__StrictHttpResponse<CreateUserResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createUserDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateUserResultDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `UsersService.UsersCreateUserParams` containing the following parameters:
   *
   * - `createUserDC`: User data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCreateUser(params: UsersService.UsersCreateUserParams): __Observable<CreateUserResultDC> {
    return this.UsersCreateUserResponse(params).pipe(
      __map(_r => _r.body as CreateUserResultDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `UsersService.UsersCanDeleteRoleParams` containing the following parameters:
   *
   * - `userUuid`: User UUID
   *
   * - `roleId`: Role Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCanDeleteRoleResponse(params: UsersService.UsersCanDeleteRoleParams): __Observable<__StrictHttpResponse<CanDeleteRoleDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/${params.userUuid}/CanDeleteRole/${params.roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CanDeleteRoleDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `UsersService.UsersCanDeleteRoleParams` containing the following parameters:
   *
   * - `userUuid`: User UUID
   *
   * - `roleId`: Role Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCanDeleteRole(params: UsersService.UsersCanDeleteRoleParams): __Observable<CanDeleteRoleDC> {
    return this.UsersCanDeleteRoleResponse(params).pipe(
      __map(_r => _r.body as CanDeleteRoleDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersMoveUserParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `request`: Request body
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersMoveUserResponse(params: UsersService.UsersMoveUserParams): __Observable<__StrictHttpResponse<MoveUserResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.userUuid}/Move`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MoveUserResultDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersMoveUserParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `request`: Request body
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersMoveUser(params: UsersService.UsersMoveUserParams): __Observable<MoveUserResultDC> {
    return this.UsersMoveUserResponse(params).pipe(
      __map(_r => _r.body as MoveUserResultDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersMoveUserPreviewParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `request`: Request body
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersMoveUserPreviewResponse(params: UsersService.UsersMoveUserPreviewParams): __Observable<__StrictHttpResponse<BillingImpactDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.userUuid}/Move/Preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingImpactDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersMoveUserPreviewParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `request`: Request body
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersMoveUserPreview(params: UsersService.UsersMoveUserPreviewParams): __Observable<BillingImpactDC> {
    return this.UsersMoveUserPreviewResponse(params).pipe(
      __map(_r => _r.body as BillingImpactDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersEditProfileParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `profileId`: Profile ID
   *
   * - `profileData`: New profile settings
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersEditProfileResponse(params: UsersService.UsersEditProfileParams): __Observable<__StrictHttpResponse<ProfileDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.profileData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Users/${params.userUuid}/Profile/${params.profileId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProfileDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersEditProfileParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `profileId`: Profile ID
   *
   * - `profileData`: New profile settings
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersEditProfile(params: UsersService.UsersEditProfileParams): __Observable<ProfileDC> {
    return this.UsersEditProfileResponse(params).pipe(
      __map(_r => _r.body as ProfileDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersSwapProductParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `swapUserProductDC`: Swap data
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersSwapProductResponse(params: UsersService.UsersSwapProductParams): __Observable<__StrictHttpResponse<OrderDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.swapUserProductDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.userUuid}/Profile/${params.profileId}/SwapProduct`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersSwapProductParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `swapUserProductDC`: Swap data
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersSwapProduct(params: UsersService.UsersSwapProductParams): __Observable<OrderDC> {
    return this.UsersSwapProductResponse(params).pipe(
      __map(_r => _r.body as OrderDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersSwapProductPreviewParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `swapUserProductDC`: Swap data
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersSwapProductPreviewResponse(params: UsersService.UsersSwapProductPreviewParams): __Observable<__StrictHttpResponse<SwapOrderPreviewDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.swapUserProductDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.userUuid}/Profile/${params.profileId}/SwapProduct/Preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SwapOrderPreviewDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersSwapProductPreviewParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `swapUserProductDC`: Swap data
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersSwapProductPreview(params: UsersService.UsersSwapProductPreviewParams): __Observable<SwapOrderPreviewDC> {
    return this.UsersSwapProductPreviewResponse(params).pipe(
      __map(_r => _r.body as SwapOrderPreviewDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersReassignUserServiceParams` containing the following parameters:
   *
   * - `userUuid`: Current user UUID
   *
   * - `newUserUuid`: Reassigned user UUID
   *
   * - `Authorization`: access token
   *
   * - `keepUser`: Flag for keeping user open
   *
   * @return OK
   */
  UsersReassignUserServiceResponse(params: UsersService.UsersReassignUserServiceParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.newUserUuid != null) __params = __params.set('newUserUuid', params.newUserUuid.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.keepUser != null) __params = __params.set('keepUser', params.keepUser.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.userUuid}/Reassign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersReassignUserServiceParams` containing the following parameters:
   *
   * - `userUuid`: Current user UUID
   *
   * - `newUserUuid`: Reassigned user UUID
   *
   * - `Authorization`: access token
   *
   * - `keepUser`: Flag for keeping user open
   *
   * @return OK
   */
  UsersReassignUserService(params: UsersService.UsersReassignUserServiceParams): __Observable<{}> {
    return this.UsersReassignUserServiceResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `UsersService.UsersGetUserParams` containing the following parameters:
   *
   * - `uuid`: User UUID
   *
   * - `Authorization`: access token
   *
   * - `X-Mitel-SDAPI-TargetAccount`: API request target account guid
   *
   * @return OK
   */
  UsersGetUserResponse(params: UsersService.UsersGetUserParams): __Observable<__StrictHttpResponse<UserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.XMitelSDAPITargetAccount != null) __headers = __headers.set('X-Mitel-SDAPI-TargetAccount', params.XMitelSDAPITargetAccount.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/${params.uuid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `UsersService.UsersGetUserParams` containing the following parameters:
   *
   * - `uuid`: User UUID
   *
   * - `Authorization`: access token
   *
   * - `X-Mitel-SDAPI-TargetAccount`: API request target account guid
   *
   * @return OK
   */
  UsersGetUser(params: UsersService.UsersGetUserParams): __Observable<UserDC> {
    return this.UsersGetUserResponse(params).pipe(
      __map(_r => _r.body as UserDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersCloseUserParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `soxDetailsDC`:
   *
   * - `closeDate`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCloseUserResponse(params: UsersService.UsersCloseUserParams): __Observable<__StrictHttpResponse<OrderDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.soxDetailsDC;
    if (params.closeDate != null) __params = __params.set('closeDate', params.closeDate.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.uuid}/Close`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersCloseUserParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `soxDetailsDC`:
   *
   * - `closeDate`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCloseUser(params: UsersService.UsersCloseUserParams): __Observable<OrderDC> {
    return this.UsersCloseUserResponse(params).pipe(
      __map(_r => _r.body as OrderDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersCloseUserPreviewParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `soxDetailsDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCloseUserPreviewResponse(params: UsersService.UsersCloseUserPreviewParams): __Observable<__StrictHttpResponse<CloseOrderImpactDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.soxDetailsDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/${params.uuid}/Close/Preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CloseOrderImpactDC>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersCloseUserPreviewParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `soxDetailsDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersCloseUserPreview(params: UsersService.UsersCloseUserPreviewParams): __Observable<CloseOrderImpactDC> {
    return this.UsersCloseUserPreviewResponse(params).pipe(
      __map(_r => _r.body as CloseOrderImpactDC)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersGetUserReferencesParams` containing the following parameters:
   *
   * - `uuid`: User UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersGetUserReferencesResponse(params: UsersService.UsersGetUserReferencesParams): __Observable<__StrictHttpResponse<Array<UserReferenceDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/${params.uuid}/References`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserReferenceDC>>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersGetUserReferencesParams` containing the following parameters:
   *
   * - `uuid`: User UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersGetUserReferences(params: UsersService.UsersGetUserReferencesParams): __Observable<Array<UserReferenceDC>> {
    return this.UsersGetUserReferencesResponse(params).pipe(
      __map(_r => _r.body as Array<UserReferenceDC>)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param Authorization access token
   * @return OK
   */
  UsersGetUsersForReassignResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<PersonDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/ForReassign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonDC>>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param Authorization access token
   * @return OK
   */
  UsersGetUsersForReassign(Authorization: string): __Observable<Array<PersonDC>> {
    return this.UsersGetUsersForReassignResponse(Authorization).pipe(
      __map(_r => _r.body as Array<PersonDC>)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param Authorization access token
   * @return OK
   */
  UsersGetImportUserCSVTemplateResponse(Authorization: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/GetImportUserCSVTemplate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param Authorization access token
   * @return OK
   */
  UsersGetImportUserCSVTemplate(Authorization: string): __Observable<{}> {
    return this.UsersGetImportUserCSVTemplateResponse(Authorization).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetMyPermissionsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Users/MyPermissions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  UsersGetMyPermissions(Authorization: string): __Observable<Array<number>> {
    return this.UsersGetMyPermissionsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersValidateUsernameParams` containing the following parameters:
   *
   * - `requests`: A list of Username data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersValidateUsernameResponse(params: UsersService.UsersValidateUsernameParams): __Observable<__StrictHttpResponse<Array<UsernameValidationResultDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.requests;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Users/ValidateUsername`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsernameValidationResultDC>>;
      })
    );
  }
  /**
   * Required Permission: EditUsers (217)
   * @param params The `UsersService.UsersValidateUsernameParams` containing the following parameters:
   *
   * - `requests`: A list of Username data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  UsersValidateUsername(params: UsersService.UsersValidateUsernameParams): __Observable<Array<UsernameValidationResultDC>> {
    return this.UsersValidateUsernameResponse(params).pipe(
      __map(_r => _r.body as Array<UsernameValidationResultDC>)
    );
  }
}

module UsersService {

  /**
   * Parameters for UsersGetUsersByAddonFeature
   */
  export interface UsersGetUsersByAddonFeatureParams {

    /**
     * Feature Id or Id's of the addon feature
     */
    featureIds: Array<number>;

    /**
     * access token
     */
    Authorization: string;

    /**
     * True = subscribed users, False = available users to subscribe
     */
    subscribedUsers?: boolean;

    /**
     * Includes all the subscribed addons for the users
     */
    includeAddons?: boolean;
  }

  /**
   * Parameters for UsersGetUsersByAddonFeaturePaged
   */
  export interface UsersGetUsersByAddonFeaturePagedParams {

    /**
     * Feature Id or Id's of the addon feature
     */
    featureIds: Array<number>;

    /**
     * access token
     */
    Authorization: string;

    /**
     * True = subscribed users, False = available users to subscribe
     */
    subscribedUsers?: boolean;

    /**
     * Includes all the subscribed addons for the users
     */
    includeAddons?: boolean;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for UsersGetPersonRoles
   */
  export interface UsersGetPersonRolesParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * access token
     */
    Authorization: string;

    /**
     * API request target account guid
     */
    XMitelSDAPITargetAccount?: string;
  }

  /**
   * Parameters for UsersGetProfilesByAccount
   */
  export interface UsersGetProfilesByAccountParams {

    /**
     * access token
     */
    Authorization: string;
    includeOrders?: boolean;
  }

  /**
   * Parameters for UsersGetUsersByAccount
   */
  export interface UsersGetUsersByAccountParams {

    /**
     * access token
     */
    Authorization: string;
    includeOrders?: boolean;
  }

  /**
   * Parameters for UsersGetUsersByAccountPaged
   */
  export interface UsersGetUsersByAccountPagedParams {

    /**
     * access token
     */
    Authorization: string;
    includeOrders?: boolean;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for UsersExtensionValidation
   */
  export interface UsersExtensionValidationParams {

    /**
     * access token
     */
    Authorization: string;
    extension?: string;
  }

  /**
   * Parameters for UsersCreateRoleMembers
   */
  export interface UsersCreateRoleMembersParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Array of role IDs
     */
    roleIds: Array<number>;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersDeleteRoleMembers
   */
  export interface UsersDeleteRoleMembersParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Array of role IDs
     */
    roleIds: Array<number>;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersCreatePerson
   */
  export interface UsersCreatePersonParams {

    /**
     * User data
     */
    createPersonDC: CreatePersonDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersEditPerson
   */
  export interface UsersEditPersonParams {

    /**
     * User UUID
     */
    uuid: string;

    /**
     * User data
     */
    editPersonDC: EditPersonDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersDeletePerson
   */
  export interface UsersDeletePersonParams {
    uuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersCreateProfile
   */
  export interface UsersCreateProfileParams {

    /**
     * Person UUID
     */
    personUuid: string;

    /**
     * Profile data
     */
    createProfileDC: CreateProfileDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersGetProfile
   */
  export interface UsersGetProfileParams {

    /**
     * BOSS Profile ID
     */
    profileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersAssignExistingPerson
   */
  export interface UsersAssignExistingPersonParams {

    /**
     * User UUID
     */
    userUuid: string;

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersAssignNewPerson
   */
  export interface UsersAssignNewPersonParams {

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * New person data
     */
    createPersonDC: CreatePersonDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersCreateUser
   */
  export interface UsersCreateUserParams {

    /**
     * User data
     */
    createUserDC: CreateUserDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersCanDeleteRole
   */
  export interface UsersCanDeleteRoleParams {

    /**
     * User UUID
     */
    userUuid: string;

    /**
     * Role Id
     */
    roleId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersMoveUser
   */
  export interface UsersMoveUserParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Request body
     */
    request: MoveUserRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersMoveUserPreview
   */
  export interface UsersMoveUserPreviewParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Request body
     */
    request: MoveUserRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersEditProfile
   */
  export interface UsersEditProfileParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * New profile settings
     */
    profileData: EditProfileDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersSwapProduct
   */
  export interface UsersSwapProductParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Swap data
     */
    swapUserProductDC: SwapUserProductDC;

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersSwapProductPreview
   */
  export interface UsersSwapProductPreviewParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Swap data
     */
    swapUserProductDC: SwapUserProductDC;

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersReassignUserService
   */
  export interface UsersReassignUserServiceParams {

    /**
     * Current user UUID
     */
    userUuid: string;

    /**
     * Reassigned user UUID
     */
    newUserUuid: string;

    /**
     * access token
     */
    Authorization: string;

    /**
     * Flag for keeping user open
     */
    keepUser?: boolean;
  }

  /**
   * Parameters for UsersGetUser
   */
  export interface UsersGetUserParams {

    /**
     * User UUID
     */
    uuid: string;

    /**
     * access token
     */
    Authorization: string;

    /**
     * API request target account guid
     */
    XMitelSDAPITargetAccount?: string;
  }

  /**
   * Parameters for UsersCloseUser
   */
  export interface UsersCloseUserParams {
    uuid: string;
    soxDetailsDC: SOXDetailsDC;
    closeDate: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersCloseUserPreview
   */
  export interface UsersCloseUserPreviewParams {
    uuid: string;
    soxDetailsDC: SOXDetailsDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersGetUserReferences
   */
  export interface UsersGetUserReferencesParams {

    /**
     * User UUID
     */
    uuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for UsersValidateUsername
   */
  export interface UsersValidateUsernameParams {

    /**
     * A list of Username data
     */
    requests: Array<UsernameValidationRequestDC>;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { UsersService }
