<div class="alert alert-custom" [ngStyle]="externalStyles" id="theAlert" >
	<div class="row"> 
			<!-- <div class="col-auto">
				<span class="error-icon">
					<img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ2ICg0NDQyMykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbi9TZWFyY2gvR3JleTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi9TZWFyY2gvR3JleSIgZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPSIjQkZCRkMwIj4KICAgICAgICAgICAgPHBhdGggZD0iTTgsMiBDNC42ODYyOTE1LDIgMiw0LjY4NjI5MTUgMiw4IEMyLDExLjMxMzcwODUgNC42ODYyOTE1LDE0IDgsMTQgQzExLjMxMzcwODUsMTQgMTQsMTEuMzEzNzA4NSAxNCw4IEMxNCw0LjY4NjI5MTUgMTEuMzEzNzA4NSwyIDgsMiBaIE04LDAgQzEyLjQxODI3OCwtOC4xMTYyNDUwMWUtMTYgMTYsMy41ODE3MjIgMTYsOCBDMTYsMTIuNDE4Mjc4IDEyLjQxODI3OCwxNiA4LDE2IEMzLjU4MTcyMiwxNiA1LjQxMDgzMDAxZS0xNiwxMi40MTgyNzggMCw4IEMtNS40MTA4MzAwMWUtMTYsMy41ODE3MjIgMy41ODE3MjIsOC4xMTYyNDUwMWUtMTYgOCwwIFoiIGlkPSJSZWN0YW5nbGUtMiI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMTIuMjkyODkzMiwxMy43MDcxMDY4IEwxOC4yOTI4OTMyLDE5LjcwNzEwNjggQzE4LjY4MzQxNzUsMjAuMDk3NjMxMSAxOS4zMTY1ODI1LDIwLjA5NzYzMTEgMTkuNzA3MTA2OCwxOS43MDcxMDY4IEMyMC4wOTc2MzExLDE5LjMxNjU4MjUgMjAuMDk3NjMxMSwxOC42ODM0MTc1IDE5LjcwNzEwNjgsMTguMjkyODkzMiBMMTMuNzA3MTA2OCwxMi4yOTI4OTMyIEMxMy4zMTY1ODI1LDExLjkwMjM2ODkgMTIuNjgzNDE3NSwxMS45MDIzNjg5IDEyLjI5Mjg5MzIsMTIuMjkyODkzMiBDMTEuOTAyMzY4OSwxMi42ODM0MTc1IDExLjkwMjM2ODksMTMuMzE2NTgyNSAxMi4yOTI4OTMyLDEzLjcwNzEwNjggWiIgaWQ9IlBhdGgtMyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+" />
				</span>
			</div> -->
			<div class="col alert-custom-message" id="theAlertMessage">
				{{errorMessage}}
			</div>
	</div>
</div>
