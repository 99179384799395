import { Component, OnInit, Input } from '@angular/core';
import { NgStyle } from '@angular/common';



@Component({
  selector: 'cl-error-alert',
  templateUrl: './cl-error-alert.component.html',
  styleUrls: ['./cl-error-alert.component.scss']
})
export class ClErrorAlertComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() externalStyles: any = {};
  @Input() id = 'theAlert';

  constructor() { }

  ngOnInit() {

  }


}
