import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface CuxDropdownItem {
  label: string;
  value: CuxDropDownItemValue;
}

export interface CuxDropDownItemValue {
  id: string | number;
  name: string;
}

@Component({
  selector: 'cux-dropdown',
  templateUrl: './cux-dropdown.component.html',
  styleUrls: ['./cux-dropdown.component.scss']
})
export class CuxDropdownComponent implements OnInit {

  @Input() selectedItem: CuxDropDownItemValue;
  @Input() items: CuxDropdownItem[];
  @Input() selectedItemIcon: string;
  @Input() resetValue: EventEmitter<CuxDropDownItemValue>;

  @Output() onClick = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.resetValue) {
      this.resetValue.subscribe(data => {
        this.selectedItem = data;
      });
    }
  }

  click($event) {
    this.onClick.emit($event);
  }

  change($event) {
    this.onChange.emit($event);
  }

  focus($event) {
    this.onFocus.emit($event);
  }

  blur($event) {
    this.onBlur.emit($event);
  }

  show($event) {
    this.onShow.emit($event);
  }

  hide ($event) {
    this.onHide.emit($event);
  }
}
