import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CuxDropdownComponent, CuxDropdownItem, CuxDropDownItemValue } from 'src/app/shared/components/cux-dropdown/cux-dropdown.component';
import { Location, LocationAdaptorService } from 'src/app/services/location-adaptor.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss']
})
export class LocationDropdownComponent implements OnInit {

  @Input() locationType: string;
  @Input() valueType: string;
  @Input() accountId: string;
  @Input() dropdownLabel: string;
  @Input() resetValue: EventEmitter<CuxDropDownItemValue>;

  @Output() onClick = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();

  selectedItem: CuxDropDownItemValue;
  selectedItemValue: string;
  selectedItemIcon = '/assets/images/pin.svg';
  locations: Location[] = [];
  items: CuxDropdownItem[];
  locationsLabel: string;

  constructor(
    private locationService: LocationAdaptorService,
    public translateSvc: TranslateService
  ) { }


  ngOnInit() {
    /**
    * Location type load (all/onboarding)
    */
    if (!this.locationType) {
      this.locationType = 'all';
    }
    /**
    * Value type load (name/uuid)
    */
    if (!this.valueType) {
      this.valueType = 'name';
    }
    this.translateSvc.get(this.dropdownLabel).subscribe(results => {
      this.locationsLabel = results;
      this.loadLocations();
    });
    this.selectedItem = {
      id: 0,
      name: 'all'
    };
    if (this.resetValue) {
      this.resetValue.subscribe(data => {
        this.selectedItem = data;
      });
    }
  }

  loadLocations() {
    if (this.locationType === 'onboarding') {
      this.locationService.getOnboardingSimpleLocations(this.accountId).subscribe(locations => {
        this.convertLocationsToDropdownItems(locations);
      });
    } else {
      this.locationService.getSimpleLocations(this.accountId).subscribe(locations => {
        this.convertLocationsToDropdownItems(locations);
      });
    }
  }

  convertLocationsToDropdownItems(locations) {
    let count = 1;
    this.items = [{
      label: this.locationsLabel,
      value: {
        id: 0,
        name: 'all'
      }
    }];

    locations.map(location => {
      let name = location.displayName;
      if (this.valueType === 'uuid') {
        name = location.value;
      }
      this.items.push({
        label: location.displayName,
        value: {
          id: count,
          name: name
        }
      });
      if (name === this.selectedItemValue) {
        this.selectedItem = {
          id: count,
          name: name
        };
      }
      count++;
    });
  }

  onLocationClick($event) {
    this.onClick.emit($event);
  }

  onLocationChange($event) {
    this.onChange.emit($event);
  }

  onLocationFocus($event) {
    this.onFocus.emit($event);
  }

  onLocationBlur($event) {
    this.onBlur.emit($event);
  }

  onLocationShow($event) {
    this.onShow.emit($event);
  }

  onLocationHide($event) {
    this.onHide.emit($event);
  }

}
