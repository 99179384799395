import {
  ComponentFactoryResolver,
  Directive,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {ClModalComponent} from './cl-modal.component';
import {ClModalContentData} from './cl-modal-content-data';

@Directive({
  selector: '[appModalContent]'
})
export class ModalContentDirective implements OnInit {

  data: any;

  constructor(public viewContainer: ViewContainerRef,
              public componentFactoryResolver: ComponentFactoryResolver,
              public modalComponent: ClModalComponent)
  {}

  ngOnInit(): void {
    if (typeof this.modalComponent.modalTemp.content === 'string') {

      // report the problem
      console.error('Cl-Modal Error: Invalid content supplied to render Modal content.');
    } else {
      console.log('++++++++++++++++++++++++++++++++');
      let contentFactory = this.componentFactoryResolver.resolveComponentFactory(this.modalComponent.modalTemp.content);
      let contentRef = this.viewContainer;
      contentRef.clear();
      let content = contentRef.createComponent(contentFactory);

      if (this.modalComponent.modalTemp.data) {
        (<ClModalContentData>content.instance).data = this.modalComponent.modalTemp.data;
        this.data = (<ClModalContentData>content.instance).data;
      }
    }
  }
}
