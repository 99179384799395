import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {
  @Input() title = 'delete_confirmation.title';
  @Input() content = 'delete_confirmation.content';
  @Input() cancelText = 'delete_confirmation.cancel';
  @Input() deleteText = 'delete_confirmation.delete';
  constructor(public translateSvc: TranslateService,
              private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  cancel() {
      this.activeModal.close(false);
  }

  delete() {
      this.activeModal.close(true);
  }
}
