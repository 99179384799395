import { NewNumberService } from './../../../../services/newNumber.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { AvailableNumbersModel } from '../models';
import { numberTypes } from '../constants';
import * as _ from 'lodash';
import { ClModalService } from 'src/app/services/cl-modal.service';


interface MassagedNumbersModel {
  left: AvailableNumbersModel;
  right: AvailableNumbersModel;

}
interface ActiveIndexes {
  left: number[]
  right: number[]
}

@Component({
  selector: 'numbers-grid',
  templateUrl: './numbers-grid.component.html',
  styleUrls: ['./numbers-grid.component.scss']
})


export class NumbersGridComponent implements OnInit, OnChanges {
  @Input() availableNumbers: AvailableNumbersModel[];
  @Input() numberType?: string;

  @Output() newPhoneNumberSelected = new EventEmitter<any>();

  public activeIndex: number;
  public activeIndexes: any = {};
  public activeColumn: string;
  public massagedNumbers: MassagedNumbersModel[];
  public isLocalNumber: boolean = false;
  public totalPages: number = 0;
  public currentPage: number = 1;
  public pagedResult = [];
  public partialResultsSelected: boolean = false;
  public allResultsSelected: boolean = false;
  public noResultsSelected: boolean = true;
  public gridFilterOpen: boolean = false;
  public numberOfItemsSelected: number = 0;
  public totalResults: number = 0;
  private gridChunkSize = 30; // TODO: REVERT TO THIS ORINIGAL CODE
  // private gridChunkSize = 8; // TODO: REMOVE THIS TEMPORARY UI HACK SINCE BACKEND DOES NOT RETURN MORE THAN 20 NUMBERS


  constructor(private newNumberSvc: NewNumberService, private clModalSvc: ClModalService) { }

  ngOnInit() {
    this.totalResults = this.availableNumbers.length ;
    this.totalPages = Math.ceil(this.totalResults / this.gridChunkSize);
    this.currentPage = 1;
    this.pagedResult = this.splitAvailableNumbersInColumn(this.availableNumbers);
    this.massagedNumbers = this.pagedResult[0];
    if (this.numberType === numberTypes.localNumber) {
      this.isLocalNumber = true;
    }

    this.newNumberSvc.resetSelections.subscribe(()=>{
      this.activeIndex = null;
      this.activeColumn = null;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availableNumbers && !changes.availableNumbers.firstChange && changes.availableNumbers.currentValue) {
      this.totalResults = changes.availableNumbers.currentValue.length;
      this.totalPages = Math.ceil(this.totalResults / this.gridChunkSize);
      this.currentPage = 1;
      this.pagedResult = this.splitAvailableNumbersInColumn(changes.availableNumbers.currentValue);
      this.massagedNumbers = this.pagedResult[0];
      this.onSelectNone();
    }
  }

  onSelectAll(){
    this.newNumberSvc.selectNewNumber.next({selectAll: this.availableNumbers});
    this.partialResultsSelected = false;
    this.allResultsSelected = true;
    this.numberOfItemsSelected = this.totalResults;
    this.gridFilterOpen = false;
    let maxIndexes = (this.gridChunkSize > this.totalResults) ? this.totalResults : this.gridChunkSize;
    for (let key in this.activeIndexes){
      let obj = this.activeIndexes[key];
      for(let i = 0; i < maxIndexes / 2 ; i++){
        obj.left.push(i);
        obj.right.push(i);
      }
    }
    // this.activeIndexes.forEach((obj) => {
    //   for(let i = 0; i < this.gridChunkSize / 2 ; i++){
    //     obj.left.push(i);
    //     obj.right.push(i);
    //   }
    // });
  }

  onSelectNone(){
    this.newNumberSvc.selectNewNumber.next({deselectAll: true});
    this.partialResultsSelected = false;
    this.allResultsSelected = false;
    this.numberOfItemsSelected = 0;
    this.gridFilterOpen = false;
    for (let key in this.activeIndexes){
      this.activeIndexes[key].left = [];
      this.activeIndexes[key].right = [];
    }
    // this.activeIndexes.forEach((obj) => {
    //   obj.left = [];
    //   obj.right = [];
    // });
  }

  // selectItems($event, allOrNone){
  //   allOrNone === 'ALL' ? this.onSelectAll() : this.onSelectNone();
  // }

  onNumberSelected(selectedNumber: any, column: string, selectedIndex: number, $event) {
    if (this.isLocalNumber) {
      let itemIndex = this.activeIndexes[this.currentPage][column].indexOf(selectedIndex);
      if (itemIndex >= 0) {
        this.numberOfItemsSelected --;

        if(this.numberOfItemsSelected === 0) {
          this.onSelectNone();
        }
        else {
          this.activeIndexes[this.currentPage][column].splice(itemIndex, 1);
        }
        this.newNumberSvc.selectNewNumber.next({deselectedNumber: selectedNumber});
        this.allResultsSelected = false;
        this.partialResultsSelected = (this.numberOfItemsSelected > 0 && this.numberOfItemsSelected < this.totalResults) ? true : false;
      }
      else {
        this.activeIndexes[this.currentPage][column].push(selectedIndex);
        this.newNumberSvc.selectNewNumber.next({selectedNumbers: [selectedNumber]});
        this.numberOfItemsSelected++;
        this.allResultsSelected = (this.numberOfItemsSelected === this.totalResults) ? true : false;
        this.partialResultsSelected = (this.numberOfItemsSelected > 0 && this.numberOfItemsSelected < this.totalResults) ? true : false;
      }
    }
    else {
      this.activeIndex = selectedIndex;
      this.activeColumn = column;
      this.newPhoneNumberSelected.emit(selectedNumber);
      this.newNumberSvc.selectNewNumber.next({selectedNumbers: [selectedNumber]});
      this.clModalSvc.toggleOKBtn(!!selectedNumber);
    }
  }

  public getPrevPage($event){
    if(this.currentPage > 1 && this.currentPage <= this.totalPages){
      this.currentPage --;
      this.massagedNumbers = this.pagedResult[this.currentPage - 1];
    }
  }

  public getNextPage($event){
    if(this.currentPage < this.totalPages){
      this.massagedNumbers = this.pagedResult[this.currentPage];
      this.currentPage ++;
    }
  }


  private splitAvailableNumbersInColumn(numbersArray: AvailableNumbersModel[]): MassagedNumbersModel[] {
    let pagedResult = [];
    let result: MassagedNumbersModel[] = [];
    let totalCapacity = this.gridChunkSize * this.totalPages;
    if (numbersArray && numbersArray.length) {
      for (let i = 0; i <= totalCapacity; i = i + 2) {
        if( i === this.gridChunkSize * (pagedResult.length + 1)) {
          pagedResult.push(result);
          result = [];
          this.activeIndexes[pagedResult.length] = {
            left: [],
            right: []
          };
        }
        if(i <= numbersArray.length){
          result.push({
            left: numbersArray[i],
            right: numbersArray[i + 1]
          });
        }
      }
    }
    return pagedResult;
  }

}
