import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild, SimpleChanges,
  OnChanges, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlowsService } from 'src/app/services/flows.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, of } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NavigationConfirmationComponent } from 'src/app/navigation-confirmation/navigation-confirmation.component';
import { NgbModalRef, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {rightPanelCloseReason} from '../../shared/constants';
import * as Inputmask from 'inputmask';
import { CallflowAdaptorService } from 'src/app/services/callflow-adaptor.service';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { OnHoursScheduleComponent } from 'src/app/schedules/on-hours-schedule/on-hours-schedule.component';
import { HolidayScheduleComponent } from 'src/app/schedules/holiday-schedule/holiday-schedule.component';
import { RemovePhoneComponent } from 'src/app/flows/remove-phone/remove-phone.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import {LocationAdaptorService, Location, LocationTypes} from 'src/app/services/location-adaptor.service';
import { NotificationService } from '../../services/notification.service';
import * as _ from 'lodash';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import {CommonUtils} from '../../shared/common-utils';
import {PHONE_NUMBER_ASSIGNMENTS, PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING, UserPhoneService} from '../../services/phone-service';
import {PhoneServicesService} from '../../services/phone-services.service';
import {PhoneNumberFormatService} from '../../services/phone-number-format.service';
import {BillingImpactDC, LocationDC, QuotePriceProductDC, QuotePriceRequestDC} from 'src/app/modules/boss-api/generated/models';
import { TnAdapterService } from '../../services/tn-adapter.service';
import {PhoneNumber} from '../../services/tn-adaptor';
import {ModalTemplate} from '../../cl-modal/modal-template';
import {ClModalComponent} from '../../cl-modal/cl-modal.component';
import { NewNumberComponent } from '../../shared/components/new-number/new-number.component';
import {ItemsWithHeading} from '../../shared/common.interface';
import { FlowVisualizerService } from 'src/app/services/flow-visualizer.service';
import { flowComponentCRUDActionValues, callFlowComponentTypeValues, newComponentId } from '../../shared/constants';
import {  HierarchyDatum } from '../../models/flow-model';
import {ClHeaderComponent} from '@mitel/cloudlink-console-components';
import {ChangePhoneNumberComponent, ChangeNumberType, OldNumberAction,
  ChangePhoneNumberData } from 'src/app/flows/change-phone-number/change-phone-number.component';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {ProductsService} from '../../modules/boss-api/generated/services/products.service';
import {TemporaryNumber} from '../temporary-number/temporary-number.component';
import {ReviewOrderComponent} from '../../shared/components/review-order/review-order.component';
import {ReviewOrderMode} from '../../models/review-order-mode.enum';
import {ModalService} from '../../services/modal.service';
import {ClModalService} from '../../services/cl-modal.service';
import { FeatureService, FeatureType } from 'src/app/shared/features/features.service';
import { GeographicFeatures } from 'src/app/shared/features/geographic-feature.const';

@Component({
  selector: 'app-hunt-group',
  templateUrl: './hunt-group.component.html',
  styleUrls: ['./hunt-group.component.scss']
})

export class HuntGroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accountId: string;
  @Input() tabbable = true;
  @Input() toOpen: boolean;
  @Input() visualizerElRef: any;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() collapse: EventEmitter<any> = new EventEmitter();

  @Output() resetToOpen = new EventEmitter();
  @Output() newPhoneNumberSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() clearServerError: EventEmitter<void> = new EventEmitter<void>();
  @Output() serverError: EventEmitter<string> = new EventEmitter<string>();
  @Output() tempNumberUpdated: EventEmitter<TemporaryNumber> = new EventEmitter<TemporaryNumber>();
  // @ViewChild('removeMem') removeMem: ElementRef;
  @ViewChild('name') nameElement: ElementRef;
  @ViewChild('newPhoneNumberDigits') newPhoneNumberDigits: ElementRef;
  @ViewChild('auto') autoCompleteEl: ElementRef;

  huntGroupForm: FormGroup;
  huntGroupSection: FormGroup;

  huntGroup: any;
  extensionFromD2: string;
  showExistingExtn = false;
  showCustom = false;
  showSchedule = false;
  isCreate = false;

  submitting = false;
  locations: Location[] = [];
  onHoursSchedules: any[] = [];
  holidaySchedules: any[] = [];
  phoneTypes: any[] = [];

  huntGroupMembers: any[];
  clickedFlowSubscription: Subscription;
  subscriptions: Subscription[] = [];
  showMembers = false;
  selectedMemberListDn = [];

  modalRef: NgbModalRef;
  closeReason: string;
  deleteConfirmDisplayFlag = false;
  scheduleFlag = false;

  filteredMembers: any;
  existingExtnList: any;
  cfBusyList: any;
  cfNoAnswerList: any;
  offHoursList: any;
  displayLoadingSpinner = false;
  canDeleteHg = false;

  phoneNumberAssignment;
  rxjsSubscriptions: Subscription[] = [];
  addingNewPhone = false;
  currentUserPhoneService: UserPhoneService;
  loadingNewNumber = false;
  defaultPhoneNumber: PhoneNumber;
  defaultPhoneNumberValue: string;
  availablePhoneNumbers: ItemsWithHeading[] = [];
  translated;
  imgname: string;

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = {'max-width': '650px'};

  extnErrMessage = '';
  errorMsg: string[];

  ringsPerMemberErr = '';
  huntPatternIDErr = '';
  dnErr = '';
  existingExtnErr = '';
  cfBusyErr = '';
  cfNoAnswerErr = '';
  cfOffHoursErr = '';
  membersErr = '';
  memberCountErr = 0; // should be 0/8/16
  otherErr: string[] = [];
  user: any;
  loadedByIframe = false;
  temporaryTn: TemporaryNumber = { rcfTarget: null, mgmtGUID: null };
  usingExistingTn = false;
  defaultTnPortedOrTransferred = false;
  defaultUserCountryId = 840;
  countingCurrent = '';
  newPhoneNumber: any;
  isCartOpen = false;
  isVisError = false;
  wasIgnoreClicked = false;
  loadingLocations = true;
  hgLocation: LocationDC;
  disableLocation = false;
  updateHg = false;
  containsMouseUp = true;
  containsMouseDown = true;
  placeOrderDetails: BillingImpactDC;
  showHgMemberResults = true;
  currentSelectedMember: any = {};

  constructor(public flowsSvc: FlowsService,
              private huntgroupSvc: CallflowAdaptorService,
              public translateSvc: TranslateService,
              public tnAdapterSvc: TnAdapterService,
              private modalSvc: NgbModal,
              private modalService: ModalService,
              private clModalService: ClModalService,
              private elRef: ElementRef,
              private featureService: FeatureService,
              private notificationService: NotificationService,
              private locSvc2: LocationAdaptorService,
              public flowVizSvc: FlowVisualizerService,
              private clHeader: ClHeaderComponent,
              public phSvc: PhoneServicesService,
              private phoneNumberFormatService: PhoneNumberFormatService,
              private shoppingCartService: ShoppingCartService,
              private productsServices: ProductsService) {
    console.log('======Toolbar visibility==========');
    this.loadedByIframe = !clHeader.headerAuth.isToolbarVisible();
    console.log('==================================');
  }

  ngOnInit() {
    this.subscriptions.push(this.flowsSvc.componentNavigateIntent.subscribe(() => {
      this.resetToOpen.emit();
      this.toOpen = false;
      this.closeReason = null;
      this.checkDirtyAndCall(this.allowNavigateAway, {navigateAway: true});
    }));
    this.subscriptions.push(this.flowsSvc.rightPanelCloseInitiated.subscribe(() => {
      this.closeReason = null;
      this.onClose();
    }));
    this.subscriptions.push(this.clickedFlowSubscription = this.flowsSvc.clickedFlowChanged.subscribe(async huntGroup => {
      this.huntGroup = huntGroup;
      if (huntGroup != null && huntGroup.componentType === callFlowComponentTypeValues.hg) {
        this.canDeleteHg =  this.huntGroup.canDelete;
        await this.setFormWithHuntGroup();
      }
    }));

    this.huntGroup = this.flowsSvc.getClickedFlow();
    this.canDeleteHg =  this.huntGroup ? this.huntGroup.canDelete : false;
    this.initForm();
    this.setFormWithHuntGroup();
    // this.addInputmask();

    this.rxjsSubscriptions.push(this.translateSvc.get(['cl_dropdown_headers',
      'notification_messages', 'error_messages', 'hunt_group', 'base64_images', 'cl_dropdown']).subscribe(strings => {
      this.translated = strings;
      // console.log('Translated Strings', this.translated);
    }));

    this.rxjsSubscriptions.push(this.phSvc.getChangeLocationSubject().subscribe(strings => {
      if (this.addingNewPhone) {
        this.onChangedPhoneNumberAssignment({value: 'New'});
      }
    }));
    this.imgname = this.translated.base64_images.loading_360;
    let locationId;
    if (this.huntGroup) {
      locationId = this.huntGroup.locationUUID;
    } else {
      this.locations && this.locations.length > 0 ? locationId = this.locations[0].value : locationId = null;
    }
    this.phSvc.setSelectedLocation( locationId );
    // This method will actually get called twice as it is awaiting separate calls even though it emits one final result
    // this.rxjsSubscriptions.push(this.phSvc.getAvailPhoneNumbers(this.huntGroup, locationId).subscribe(availablePhoneNumbers => {
    //   this.availablePhoneNumbers = availablePhoneNumbers;
    //   this.defaultPhoneNumber = this.availablePhoneNumbers.length > 0 ? this.availablePhoneNumbers[0].items[0] : undefined;
    //   this.defaultPhoneNumberValue = _.head(this.phSvc.getAggregatedPhoneNumbers());
    // }));

    this.rxjsSubscriptions.push(
        this.shoppingCartService.cartButtonVisibilityInMainComponent.subscribe(
            cartButtonVisibile => this.isCartOpen = cartButtonVisibile));

    this.rxjsSubscriptions.push(this.flowVizSvc.visualizationError.subscribe((isVisErr) => {
      if (isVisErr) {
        this.isVisError = true;
      } else {
        this.isVisError = false;
      }
    }));

    this.rxjsSubscriptions.push(this.flowVizSvc.ignoreClicked.subscribe(() => {
      this.wasIgnoreClicked = true;
    }));

    this.rxjsSubscriptions.push(this.shoppingCartService.placeOrderDetails.subscribe((placeOrderDetails) => {
      this.placeOrderDetails = placeOrderDetails;
    }));
  }

  displayFn(member:  any) {
    if (member) {
      return member.extension ?
          member.extension.split('-')[1] + ' : ' + member.description :
          member.extension + ' : ' + member.description ;
    }
  }

  // displayCFDestination(destination:  any) {
  //   if (typeof destination === 'string') {
  //     return destination;
  //   } else if (destination != null) {
  //     return destination.extension ?  destination.extension.split('-')[1] + ' : ' + destination.description
  //         : destination.extension + ' : ' + destination.description ;
  //   }
  // }

  // addInputmask() {
  //   if (this.removeMem) {
  //     Inputmask({greedy: false }).mask(this.removeMem.nativeElement);
  //   }
  // }

  setConditionalValidators() {

    this.subscriptions.push(this.huntGroupForm.get('huntGroupSection.cfOffHoursHoliday').valueChanges
        .subscribe(cfOffHoursHoliday => {
          this.cfOffHoursErr = '';
          if ((cfOffHoursHoliday === null || cfOffHoursHoliday === '')
              && (this.huntGroupForm.get('huntGroupSection.holiday').value !== null
                  && this.huntGroupForm.get('huntGroupSection.holiday').value !== 'none')) {
            this.huntGroupForm.controls['huntGroupSection'].get('cfOffHoursHoliday').markAsTouched();
            this.huntGroupForm.controls['huntGroupSection'].get('cfOffHoursHoliday').setErrors({'required': true});
            this.huntGroupForm.updateValueAndValidity();
          }
        }));
  }

  getCFDestinations() {

    // CFType: 1 for Hunt Group Call Forward, 2 for Auto Attendant Take a Message, 3  for Auto Attendant Call Forward
    const cfType = 1;
    this.filteredMembers = this.getHuntGroupMembers('huntGroupSection.member', true);
    this.huntGroupForm.get('huntGroupSection.member').valueChanges
        .pipe(
            debounceTime(300),
            switchMap(mem => {
              return this.getHuntGroupMembers('huntGroupSection.member');
            }
            )
        ).subscribe(val => {
          this.filteredMembers = val.candidates;
        });
    this.huntGroupForm.get('huntGroupSection.existingExt').valueChanges
        .pipe(
            debounceTime(300),
            switchMap( mem => {
              return this.getDestinations('huntGroupSection.existingExt');
            })).subscribe(val => {
      this.existingExtnList = val.candidates;
    });

    this.huntGroupForm.get('huntGroupSection.cfBusy').valueChanges
        .pipe(
            debounceTime(300),
            switchMap(mem => {
              return this.getDestinations('huntGroupSection.cfBusy');
            })).subscribe(val => {
          this.cfBusyList = val.candidates;
        });

    this.huntGroupForm.get('huntGroupSection.cfNoAnswer').valueChanges
        .pipe(
            debounceTime(300),
            switchMap(mem => {
              return this.getDestinations('huntGroupSection.cfNoAnswer');
            })).subscribe(val => {
              this.cfNoAnswerList = val.candidates;
            });
    this.offHoursList = this.huntGroupForm.get('huntGroupSection.cfOffHoursHoliday').valueChanges
        .pipe(
            debounceTime(300),
            switchMap( mem => {
              return this.getDestinations('huntGroupSection.cfOffHoursHoliday');
            })).subscribe(val => {
          this.offHoursList = val.candidates;
        });
  }

  getDestinations(field: string) {
    // CFType: 1 for Hunt Group Call Forward, 2 for Auto Attendant Call Forward, 3 for Auto Attendant Take a Message
    const cfType = 1;
    const destList = [];

    if (this.huntGroupForm.get(field).dirty) {
      const fieldVal = this.huntGroupForm.get(field).value;
      /* istanbul ignore else */
      if (fieldVal && !fieldVal.trim().match(/ : /)) {
        return this.huntgroupSvc.getCallForwardDestinations(this.huntGroupForm.get(field).value, cfType);
      }
    }
    return destList;
  }

  getHuntGroupMembers(field: string, bypassDirty = false) {
    const hgSelectedMembers = _.cloneDeep(this.huntGroupMembers);
    const destList = [];
    if (bypassDirty) {
      return this.huntgroupSvc.getMemberList('', _.map(hgSelectedMembers, 'extensionFormatted'));
    } else {
      if (this.huntGroupForm.get(field).dirty) {
        const fieldVal = this.huntGroupForm.get(field).value;
        /* istanbul ignore else */
        if (fieldVal && !fieldVal.trim().match(/ : /)) {
          return this.huntgroupSvc.getMemberList(fieldVal.trim(), _.map(hgSelectedMembers, 'extensionFormatted'));
        }
      }
    }
    return destList;
  }



  /**
   * User changed the selected location in the huntgroup create/edit dialog.
   */
  onLocationChange() {
    const locationControl = this.huntGroupForm.get('huntGroupSection.location');
    if (locationControl && locationControl.value && locationControl.value.value) {
      const locationId = locationControl.value.value;
      this.phSvc.setSelectedLocation( locationId );
    } else {
      console.log('data :', 'location not found');
    }
  }

  phoneNumberReadOnlyValue() {
    const none = this.translated.hunt_group.phone_number_none;
    return this.huntGroup && this.huntGroup.displayPhoneNumber ?
        this.huntGroup.displayPhoneNumber : none;
  }

  onChangePhoneNumber() {
    this.deleteConfirmDisplayFlag = true;
    if (!this.modalRef) {
      // Create & display ChangePhoneNumberComponent as a modal.

      // Reference
      // https://ng-bootstrap.github.io/#/components/modal/api
      // https://ng-bootstrap.github.io/#/components/modal/examples

      const options: NgbModalOptions = {
        windowClass: 'md-modal',
        backdrop: 'static', /*  `'static'` for a backdrop which doesn't close the modal on click  */
        keyboard: false, /* false == don't close modal if Esc key is hit. Here, ChangePhoneNumberComponent has its own Esc handler */
        centered: true
      };

      // Pop open the modal
      this.modalRef = this.modalSvc.open(ChangePhoneNumberComponent, options);
      // load the modal component's Input()'s
      this.modalRef.componentInstance.hg = this.huntGroup;
      // this.modalRef.componentInstance.currentUserPhoneService = this.currentUserPhoneService;
      // this.modalRef.componentInstance.userPendingClose = this.userPendingClose; ???
      // listen for Output() event
      this.rxjsSubscriptions.push(this.modalRef.componentInstance.outputChangePhoneNumberData.subscribe(
          (outputChangePhoneNumberData: ChangePhoneNumberData) => {
            // user successfully submitted the form; here is the data that was entered
            // console.log('devices-n-services outputChangePhoneNumberData=', outputChangePhoneNumberData);

            if ([ChangeNumberType.replace_with_existing, ChangeNumberType.replace_with_new, ChangeNumberType.unassign]
                .indexOf(outputChangePhoneNumberData.changeType) > -1) {

              if (outputChangePhoneNumberData.oldNumberAction === OldNumberAction.KEEP_ON_ACCOUNT) {
                this.huntGroup.releaseTn = false;
              } else if (outputChangePhoneNumberData.oldNumberAction === OldNumberAction.RELEASE) {
                this.huntGroup.releaseTn = true;
              } else {
                console.log('action not applicable', 'selected');
              }
              // phone number changed
              if (outputChangePhoneNumberData.phoneNumber) {
                this.huntGroup.phoneNumber = outputChangePhoneNumberData.phoneNumber.value;
                this.huntGroup.tnId = outputChangePhoneNumberData.phoneNumber.value;
                this.huntGroup.tnCountryId = outputChangePhoneNumberData.phoneNumber.countryId;
                this.huntGroup.displayPhoneNumber = outputChangePhoneNumberData.phoneNumber.displayName;
              } else {
                this.huntGroup.phoneNumber = '';
                this.huntGroup.tnId = null;
                this.huntGroup.tnCountryId = 0;
                this.huntGroup.displayPhoneNumber = '';
              }
              this.huntGroup.rcfTarget = outputChangePhoneNumberData.rcfTarget;
              this.huntGroup.managementGUID = outputChangePhoneNumberData.managementGUID;
              if (outputChangePhoneNumberData.temporaryTn) {
                this.onTemporaryNumberUpdate(outputChangePhoneNumberData.temporaryTn);
                this.usingExistingTn = (outputChangePhoneNumberData.temporaryTn.rcfTarget) ? true : false;
                this.defaultTnPortedOrTransferred = true;
              }
            } else {
              console.log('ChangeNumberType :', 'not valid');
            }

            if (outputChangePhoneNumberData.changeType === ChangeNumberType.replace_with_new) {
              this.newPhoneNumber = outputChangePhoneNumberData.phoneNumber;
              this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
            } else {
              this.newPhoneNumber = {};
              this.countingCurrent = '';
              this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
            }

            if (outputChangePhoneNumberData.updateExtension === true) {
              // extension changed
              this.huntGroup.extension = outputChangePhoneNumberData.extension;
              this.huntGroupForm.get('huntGroupSection').patchValue(
                  {
                    extension: this.huntGroup.extension
                  });
            } else {
              console.log('extension', 'not updated');
            }
            this.huntGroupForm.markAsDirty();

            this.phSvc.setUserUpdated(true);  // indicate that we made a user change
            // close the ChangePhoneNumberComponent modal
            this.closeModal();
          },
          error => {
            console.error('onChangePhoneNumber', error);
            // close the ChangePhoneNumberComponent modal
            this.closeModal();
          },
          complete => {
            // complete, user canceled
            // close the ChangePhoneNumberComponent modal
            this.closeModal();
          }
      ));
    } else {
      console.log('no change', 'detected');
      this.modalRef = null;
    }
  }

  closeModal() {
    if (!_.isNull(this.modalRef)) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  /**
   * Called when user chooses a new source for DID phone numbers: Existing, New
   */
  onChangedPhoneNumberAssignment(event) {
    if (event.value === 'None') {
      this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
      this.usingExistingTn = false;
      this.tempNumberUpdated.emit(null);
      this.addingNewPhone = false;
      this.countingCurrent = '';
      this.newPhoneNumber = {};
      this.huntGroupForm.get('huntGroupSection').patchValue({phoneNumberDigits: {}});
      this.huntGroupForm.get('huntGroupSection').patchValue({newPhoneNumberDigits: null});
      this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').disable();
      this.setValidExtension(this.extensionFromD2);
      this.newPhoneNumberSelected.emit(null);
      if (!this.modalRef && CommonUtils.flowHasPhoneNumber(this.huntGroup)) {
        this.openReleaseNumberModal();
      }
    } else if (event.value === 'New') {
      if (this.tnAdapterSvc.wasNewPhoneNumberPreviouslyFetched === false) {
        this.addingNewPhone = true;
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
        this.huntGroupForm.get('huntGroupSection').patchValue({phoneNumberDigits: {}});
        this.huntGroupForm.get('huntGroupSection').patchValue({newPhoneNumberDigits: ''});
        if (this.phSvc.getSelectedLocation()) {
          this.loadingNewNumber = true;
          this.rxjsSubscriptions.push(this.phSvc.getLocationDC(this.phSvc.getSelectedLocation()).subscribe((location: LocationDC) => {
                this.rxjsSubscriptions.push(this.tnAdapterSvc.getAvailableNumbersFromSearchCriteria(location, 1)
                    .subscribe((availNumber) => {
                          this.loadingNewNumber = false;
                          this.clearServerError.emit();
                          if (availNumber && availNumber.length) {
                              this.addPhoneNumber(availNumber[0]);
                              // this.newPhoneNumber = availNumber[0];
                              this.tnAdapterSvc.wasNewPhoneNumberPreviouslyFetched = true;
                              this.setValidExtension(
                                  this.phSvc.convertPhoneNumberToExtension(
                                      availNumber[0].value
                                  )
                              );
                              this.displayShoppingCart(availNumber[0].turnupProductId);
                              this.newPhoneNumberSelected.emit(availNumber[0].turnupProductId);
                          } else {
                            this.huntGroupForm.get('huntGroupSection').patchValue({phoneNumberDigits: {}});
                            this.huntGroupForm.get('huntGroupSection').
                            patchValue({phoneNumber: PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING[0]});
                          }
                          this.huntGroupForm.markAsDirty();
                        } ,
                        (error) => {
                          this.rxjsSubscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                            this.showServerErrorOnForm(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                            this.serverError.emit(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                            this.loadingNewNumber = false;
                          }));
                        }));
              },
              (error) => {
                this.rxjsSubscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                  this.showServerErrorOnForm(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  this.serverError.emit(err.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
                  this.loadingNewNumber = false;
                }));
              }));
        }
      } else {
        this.addingNewPhone = true;
        this.setValidExtension(
            this.phSvc.convertPhoneNumberToExtension(this.tnAdapterSvc.availNumberValue[0].value)
        );
        this.newPhoneNumber = this.tnAdapterSvc.availNumberValue[0];
        this.newPhoneNumberSelected.emit(this.newPhoneNumber.turnupProductId); // add number to shopping cart using number already fetched
        this.shoppingCartService.cartButtonVisibilityInMainComponent.next(true);
        this.displayShoppingCart(this.tnAdapterSvc.availNumberValue[0].turnupProductId);
        this.huntGroupForm.get('huntGroupSection').patchValue({phoneNumberDigits: {}});
        this.huntGroupForm.get('huntGroupSection').patchValue({newPhoneNumberDigits: ''});
        this.addPhoneNumber(this.tnAdapterSvc.availNumberValue[0]);
      }
    } else { // 'Existing'
      this.usingExistingTn = true;
      this.addingNewPhone = false;
      this.shoppingCartService.cartButtonVisibilityInMainComponent.next(false);
      this.countingCurrent = '';
      this.newPhoneNumber = {};
      this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').enable();
      this.huntGroupForm.get('huntGroupSection').patchValue({newPhoneNumberDigits: ''});
      const phNumber = CommonUtils.flowHasPhoneNumber(this.huntGroup)
          ? this.huntGroup.displayPhoneNumber : this.defaultPhoneNumber;
      this.huntGroupForm.get('huntGroupSection').patchValue({
        phoneNumberDigits: phNumber
      });
      // if (phNumber && phNumber.displayName) {
      //   this.setValidExtension(
      //       this.phSvc.convertPhoneNumberToExtension(
      //           phNumber.value
      //       )
      //   );
      // }
      this.newPhoneNumberSelected.emit(null);
    }
  }

  requiresTempTn(): boolean {
    return this.usingExistingTn && this.defaultTnPortedOrTransferred;
  }

  onTemporaryNumberUpdate(tempTn: TemporaryNumber) {
    this.temporaryTn.rcfTarget = tempTn.rcfTarget;
    this.temporaryTn.mgmtGUID = tempTn.mgmtGUID;
    this.tempNumberUpdated.emit(this.temporaryTn);
  }

  onChangedPhoneNumberDigits(event: { value: PhoneNumber, reason: string }) {

    // // If set programmatically or if the new value is the same as the old value, pristinify and return
    // if (event.reason === 'set' || CommonUtils.userHasPhoneNumber(this.currentUserPhoneService) &&
    // event.value === this.currentUserPhoneService.phoneNumber) {
    //   // this.devicesAndServices.markAsPristine();
    //   return;
    // }

    if (!this.modalRef && CommonUtils.flowHasPhoneNumber(this.huntGroup) &&
        event.value !== this.currentUserPhoneService.phoneNumber) {
      this.openReleaseNumberModal();
    }

    this.defaultTnPortedOrTransferred = this.phoneNumberIsPortedOrTransferred(
        event.value
    );

    // update the extension with the based on phone number change from the available numbers
    if (!_.isEmpty(event.value) && !_.isEmpty(event.value.value)) {
      this.setValidExtension(this.phSvc.convertPhoneNumberToExtension(event.value.value));
    }

    const phoneNumber = this.huntGroupForm.get('huntGroupSection').get('phoneNumber').value;
    if (phoneNumber.value === PHONE_NUMBER_ASSIGNMENTS[1].value) {
      if (_.isEmpty(event.value.value)) {
        this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
      } else {
        if (this.availablePhoneNumbers[0].items[0].value == null) {
          this.availablePhoneNumbers[0].items.shift();
        }
       this.resetErrorsForPhoneNumber();
      }
    } else {
      this.resetErrorsForPhoneNumber();
    }

    this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').markAsTouched({onlySelf: true});
    this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').markAsDirty({onlySelf: true});
  }

  resetErrorsForPhoneNumber() {
    this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: null});
    this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').updateValueAndValidity();
  }

  phoneNumberIsPortedOrTransferred(number: PhoneNumber): boolean {
    let isPortedOrTransferred = false;
    if (
        number &&
        number.displayName &&
        number.displayName.length > 0 &&
        number.value != null &&
        this.availablePhoneNumbers
    ) {
      for (const numberList of this.availablePhoneNumbers) {
        if (numberList.heading === 'cl_dropdown_headers.ported') {
          if (numberList.items) {
            const foundNum = numberList.items.find(
                portedNum => portedNum.displayName === number.displayName
            );
            if (foundNum !== undefined) {
              isPortedOrTransferred = true;
            }
            break;
          }
        }
      }
    }
    return isPortedOrTransferred;
  }

  openReleaseNumberModal() {
    this.phSvc.changePhoneNumberType = 'release_number';
    const modTemp: ModalTemplate = {
      title: 'change_phone_number.release_number.title',
      content: RemovePhoneComponent,
      isContentHTML: true,
      primaryBtn: {
        value: true,
        label: 'change_phone_number.release_number.return_number',
        width: 150
      },
      defaultBtn: {
        value: false,
        label: 'change_phone_number.release_number.keep_number',
        width: 150
      }
    };

    const options: NgbModalOptions = {
      windowClass: 'sm-modal',
      centered: true,
      backdrop: 'static'
    };

    this.modalRef = this.modalSvc.open(ClModalComponent, options);
    this.modalRef.componentInstance.modalTemp = modTemp;

    this.modalRef.result.then((result) => {
      if (result === true) {
        this.onPhoneNumberChangeReturnNumber(); // place holder method for future use
      } else {
        this.onPhoneNumberChangeKeepNumber(); // place holder method for future use
      }
      // this.toOpen.emit(true);
      this.modalRef = null;
    }, (reason) => {
      this.modalRef = null;
    });
  }

  onNewPhoneInputClicked(event) {
    if (!this.loadingNewNumber) {
      this.openNewNumberModal();
    }
  }

  openNewNumberModal() {
    const modTemp: ModalTemplate = {
      title: 'choose_new_number.title',
      content: NewNumberComponent,
      isContentHTML: true,
      primaryBtn: {
        value: true,
        label: 'choose_new_number.assign',
        width: 95,
        disabled: true
      },
      defaultBtn: {
        value: false,
        label: 'choose_new_number.cancel',
        width: 95
      }
    };

    const options: NgbModalOptions = {
      windowClass: 'lg-modal',
      centered: true
    };

    this.modalRef = this.modalSvc.open(ClModalComponent, options);
    this.modalRef.componentInstance.modalTemp = modTemp;

    this.modalRef.result.then((result) => {
      if (result === true) {
        this.addPhoneNumber(this.phSvc.tnIntegration.currentSelectedPhoneNumber);
        this.newPhoneNumberSelected.emit(this.phSvc.tnIntegration.currentSelectedPhoneNumber.turnupProductId);
        if (this.phSvc.tnIntegration.saveSearchCriteria) {
          this.tnAdapterSvc.updateTNSearchCriteria();
        }
      }
      // this.toOpen.emit(true);
      this.modalRef = null;
    }, (reason) => {
      this.modalRef = null;
    });
  }

  addPhoneNumber(phoneNumberObj: any) {
    if (phoneNumberObj) {
      const availNumber: PhoneNumber = {
        value: phoneNumberObj.value,
        displayName: phoneNumberObj.displayName,
        locationUuid: phoneNumberObj.locationUuid,
        countryId: phoneNumberObj.countryId
      };
      // Workaround in order to trigger change detection in l-dropdown-wtih-headings
      const phoneNumbers = this.availablePhoneNumbers.length ?
          [...this.availablePhoneNumbers] : [{ heading: 'cl_dropdown_headers.ported', items: []}];
      this.tnAdapterSvc.availNumberValue = [phoneNumberObj];
      this.availablePhoneNumbers = phoneNumbers;
      this.newPhoneNumber = phoneNumberObj;
      setTimeout(() => { // Circumvent the timing issue of change detection in cl-dropdown-wtih-headings and
        //  calling patchValue on the dropdown
        if (this.newPhoneNumberDigits) {
          this.newPhoneNumberDigits.nativeElement.value = availNumber.displayName;
        }
        this.huntGroupForm.get('huntGroupSection').patchValue({phoneNumberDigits: availNumber});
        console.log('Phone Number Added');
      });
    }
  }

  onPhoneNumberChangeKeepNumber() {
    console.log('Phone Number Changed');
    this.phSvc.currentReleaseNumberFlag = false;
  }

  onPhoneNumberChangeReturnNumber() {
    console.log('Phone Number Changed');
    this.phSvc.currentReleaseNumberFlag = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.accountId && changes.accountId.currentValue &&
        changes.accountId.previousValue && changes.accountId.previousValue !== changes.accountId.currentValue) {
      this.accountId = changes.accountId.currentValue;
      await this.initLocationsList();
    }
  }

  async getAvailNumbers() {
    await this.phSvc.getAvailPhoneNumbers(this.huntGroup, this.phSvc.getSelectedLocation()).toPromise().then(availablePhoneNumbers => {
          this.availablePhoneNumbers = availablePhoneNumbers;
          let phoneNumberAssignment; 
          if (this.availablePhoneNumbers.length > 0) {
            this.availablePhoneNumbers[0].items.unshift({
              value: null,
              displayName: this.translated.hunt_group.select_phone_number
            });
            // if (
            //     !_.isEmpty(this.availablePhoneNumbers) && this.availablePhoneNumbers[0].heading ===
            //     'cl_dropdown_headers.ported'
            // ) {
            //   this.defaultTnPortedOrTransferred = true;
            // }
            this.defaultPhoneNumber = this.availablePhoneNumbers[0].items[1];
            phoneNumberAssignment = PHONE_NUMBER_ASSIGNMENTS;
          } else {
            this.defaultPhoneNumber = undefined;
            phoneNumberAssignment = PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING;
          }
          /** To remove the New option for UK */
          const isDisabled = this.featureService.isDisabled(FeatureType.GEOGRAPHIC, GeographicFeatures.FEATURE.CALL_FLOW_ADD_NEW_PHONE_NO);
          if (isDisabled) {
            phoneNumberAssignment = phoneNumberAssignment.filter((item) => item.value!== 'New');
          
          }
          this.phoneNumberAssignment = phoneNumberAssignment;
          this.defaultPhoneNumberValue = _.head(this.phSvc.getAggregatedPhoneNumbers());
        },
        error => {
          // this.showServerErrorOnForm(this.translated.error_messages.retrieve_numbers + ': ' + BossApiUtils.extractErrorMessage(error));
          console.error(BossApiUtils.extractErrorMessage(error));
        });
  }
  async initLocationsList() {
    let locationId = null;
    try {
      // this.locations = await this.locSvc.getLocationsMinimalList(this.accountId);
      await this.locSvc2.getLocations(this.accountId)
          .toPromise().then(
              data => {
                this.locations = _.cloneDeep(data);
                this.loadingLocations = false;
                this.locations && this.locations.length > 0 ? locationId = this.locations[0].value : locationId = null;
                this.phSvc.setSelectedLocation( locationId );
              },
              error => {
                console.error(error);
              });
    } catch (reason) {
      this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
        this.showServerErrorOnForm( err.location_list + ': ' + BossApiUtils.extractErrorMessage(reason));
      }));
      console.error('failed to retrieve locations list', reason);
    }
  }


  // close panel on esc key
  @HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.deleteConfirmDisplayFlag) {
      this.onCancel(event);
    }
  }

  @HostListener('document:touchend', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:mousedown', ['$event'])
  clickedOutside(event) {

    // check if it is the badge or pendo related menus and don't close if it is
    if (event.target && event.target.className &&
      (event.target.className === 'fade-in' ||
        (event.target.id && event.target.id.includes('pendo') && event.target.className.includes('pendo')))) {
          return;
    }

    // checking if the 'mouseup' or 'mousedown' events don't occur within the panel
    if (event.type === 'mouseup' && !this.elRef.nativeElement.contains(event.target)) {
      // also need to check if it is not the autocomplete panel, which gets overlayed outside of the right panel
      if (!this.autoCompleteEl) {
        this.containsMouseUp = false;
      }
    }

    if (event.type === 'mousedown' && !this.elRef.nativeElement.contains(event.target)) {
      // also need to check if it is not the autocomplete panel, which gets overlayed outside of the right panel
      if (!this.autoCompleteEl) {
        this.containsMouseDown = false;
      }
    }

    // did user click outside of this element?
    if (event.type === 'mouseup' && (!this.containsMouseUp && !this.containsMouseDown)) {
      // yes, clicked outside
      this.containsMouseUp = true;
      this.containsMouseDown = true;

      if (this.tabbable && this.huntGroupForm.dirty) {
        if (this.closeReason === rightPanelCloseReason.LEAVE) {
          this.callOnClose(true);
        } else if (this.closeReason === rightPanelCloseReason.COLLAPSE) {
          this.callOnCancel();
        } else if (this.closeReason === rightPanelCloseReason.NAVIGATEAWAY) {
          this.allowNavigateAway();
        } else if (this.closeReason === rightPanelCloseReason.STAY ||
          this.closeReason === rightPanelCloseReason.BACKDROP) {
          // Just close the dialog
        }
      } else if (this.tabbable && !this.toOpen && !this.deleteConfirmDisplayFlag && !this.isVisError) {
        this.callOnClose();
      } else if (this.isVisError) {
        // we want to not close the right panel if the visualization error modal is open
        // however if ignore was clicked (meaning they want to stay on the page)
        // we need to reset the flags so that outside clicks will close the right panel again
        if (this.wasIgnoreClicked) {
          this.resetVisErrorFlags();
        }
      }

    } else if (event.type === 'mouseup' && !(!this.containsMouseUp && !this.containsMouseDown)) {
      this.containsMouseUp = true;
      this.containsMouseDown = true;
    }

    if (!this.deleteConfirmDisplayFlag) {
      this.resetToOpen.emit();
      this.toOpen = false;
      this.closeReason = null;
    }
  }

  async setFormWithHuntGroup() {
    this.displayLoadingSpinner = true;
    this.isCreate = false;
    this.huntGroupForm.reset();
    if (!this.huntGroup) {
      this.isCreate = true;
      if (this.accountId) {
        await this.initLocationsList();
        await this.getAvailNumbers();
      }
      await this.huntgroupSvc.getNewHuntGroup().toPromise().then (
          hg => {
            this.huntGroup = hg;
            this.phSvc.clusterBasedExtensionLength.next(hg.extensionFormatted.length);
            this.extensionFromD2 = this.huntGroup.extensionFormatted;
            this.displayLoadingSpinner = false;
            const vizHGObj: HierarchyDatum = {
              name: hg.name,
              subText: hg.extensionFormatted,
              componentType: callFlowComponentTypeValues.hg,
              id: newComponentId
            };
            this.flowVizSvc.setSelectedFlow(vizHGObj, flowComponentCRUDActionValues.create);

          },
          error => {
            this.displayLoadingSpinner = false;
            console.error('failed to getNewHuntgroup', error);
            this.showServerError( 'Failed to get new huntgroup data. ' + BossApiUtils.extractErrorMessage(error));
          }
      );
    } else {
      await this.huntgroupSvc.getHuntGroup(this.huntGroup.id).toPromise().then (
          hg => {
            this.huntGroup = hg;
            this.phSvc.clusterBasedExtensionLength.next(hg.extensionFormatted.length);
            this.displayLoadingSpinner = false;
          },
          error => {
            this.displayLoadingSpinner = false;
            console.error('failed to fetch huntgroup with id', this.huntGroup.id, error);
            this.showServerError( 'Failed to fetch huntgroup. ' + BossApiUtils.extractErrorMessage(error));
          }
      );
      await this.getAvailNumbers();
    }

    if (this.huntGroup != null) {

      this.onHoursSchedules = this.huntGroup.onHourSchedules;
      this.holidaySchedules = this.huntGroup.holidaySchedules;
      // setting the display value for phonenumber
      if (this.huntGroup.tnId != null) {
        this.huntGroup.displayPhoneNumber = await this.phoneNumberFormatService
            .formatPhoneNumberBasedOnCountry(this.huntGroup.tnId);
      }

      this.huntGroupForm.get('huntGroupSection').patchValue({
        name: this.huntGroup.name,
        location: this.huntGroup.locationName,
        ptype: 'New', // To-DO : display based on what is saved in the database
        pnumber: this.huntGroup.phoneNumber,
        extension: this.huntGroup.extensionFormatted,
        ringStrategy: String(this.huntGroup.huntPatternID),
        rings: this.huntGroup.ringsPerMember,
        dailByName: this.huntGroup.includeInDialByName,
        ringMobileUsers: this.huntGroup.ringMobileUsers,
        makeExtPrivate: this.huntGroup.makeNumberPrivate,
        cfOffHoursHoliday: this.huntGroup.cfOffHoursHolidayFormatted,
      });

      // if (this.huntGroup.locationUUID) {
      //   this.huntGroupForm.get('huntGroupSection').patchValue({location: {value: this.huntGroup.locationUUID}});
      // }
      if (this.huntGroup.onHoursScheduleId > 0 || this.huntGroup.holidayScheduleId > 0) {
        this.huntGroupForm.get('huntGroupSection.schedule').patchValue('2');
        this.showSchedule = true;
      } else {
        this.huntGroupForm.get('huntGroupSection.schedule').patchValue('1');
        this.showSchedule = false;
      }

      if (this.huntGroup.cfBusy == null && this.huntGroup.cfNoAnswer == null) {
        this.huntGroupForm.get('huntGroupSection.noAnswer').patchValue('1');
        this.showCustom = false;
        this.showExistingExtn = true;
      } else if (this.huntGroup.cfBusy != null &&
                this.huntGroup.cfNoAnswer != null &&
                this.huntGroup.cfBusy === this.huntGroup.cfNoAnswer) {
        this.huntGroupForm.get('huntGroupSection.existingExt').patchValue(this.huntGroup.cfBusyFormatted);
        this.huntGroupForm.get('huntGroupSection.noAnswer').patchValue('1');
        this.showExistingExtn = true;
        this.showCustom = false;
      } else if (this.huntGroup.cfBusy != null &&
                this.huntGroup.cfNoAnswer != null &&
                this.huntGroup.cfBusy !== this.huntGroup.cfNoAnswer) {
        this.huntGroupForm.get('huntGroupSection.cfNoAnswer').patchValue(this.huntGroup.cfNoAnswerFormatted);
        this.huntGroupForm.get('huntGroupSection.cfBusy').patchValue(this.huntGroup.cfBusyFormatted);
        this.huntGroupForm.get('huntGroupSection.noAnswer').patchValue('4');
        this.showCustom = true;
        this.showExistingExtn = false;
      } else {
        this.huntGroupForm.get('huntGroupSection.cfNoAnswer').patchValue(this.huntGroup.cfNoAnswerFormatted);
        this.huntGroupForm.get('huntGroupSection.cfBusy').patchValue(this.huntGroup.cfBusyFormatted);
        this.huntGroupForm.get('huntGroupSection.noAnswer').patchValue('4');
        this.showCustom = true;
        this.showExistingExtn = false;
      }

      this.huntGroupMembers = this.huntGroup.huntGroupMembers;
      if (this.huntGroupMembers && this.huntGroupMembers.length > 0) {
        this.showMembers = true;
      }

      if (this.huntGroup.onHoursScheduleId != null && this.huntGroup.onHoursScheduleId > 0) {
        this.huntGroupForm.get('huntGroupSection').patchValue({onHours: {value: this.huntGroup.onHoursScheduleId.toString()}});
      } else {
        this.huntGroupForm.get('huntGroupSection').patchValue({onHours: {value: 'none'}});
      }
      if (this.huntGroup.holidayScheduleId != null && this.huntGroup.holidayScheduleId > 0) {
        this.huntGroupForm.get('huntGroupSection').patchValue({holiday: {value: this.huntGroup.holidayScheduleId.toString()}});
      } else {
        this.huntGroupForm.get('huntGroupSection').patchValue({holiday: {value: 'none'}});
      }

      if (this.isCreate) {
        if (this.locations && this.locations.length > 0) {
          this.addingNewPhone = false;
          this.huntGroupForm.get('huntGroupSection').patchValue({location: {value: this.locations[0].value}});
          this.disableLocation = this.locations.length <= 1;
        }
      } else {
        if (this.huntGroup.locationUUID != null) {
          await this.locSvc2.getFullLocationObjectForSelectedLocation(this.accountId, this.huntGroup.locationUUID).toPromise()
              .then(
                  result => {
                    this.hgLocation = result;
                  });
          if (this.hgLocation.locationTypeId === LocationTypes.GLOBAL_LOCATION) {
            this.locations = [this.locSvc2._makeLocation(this.hgLocation)];
            this.loadingLocations = false;
            if (this.locations && this.locations.length > 0) {
              this.huntGroupForm.get('huntGroupSection').patchValue({location: {value: this.huntGroup.locationUUID}});
              this.disableLocation = this.locations.length <= 1;
              this.huntGroupForm.markAsPristine();
            }
          } else {
            if (this.accountId) {
              await this.initLocationsList();
            }
            this.huntGroupForm.get('huntGroupSection').patchValue({location: {value: this.huntGroup.locationUUID}});
            this.disableLocation = this.locations.length <= 1;
            this.huntGroupForm.markAsPristine();
          }
        }
      }

      if (this.huntGroup && this.huntGroup.rcfTarget) {
        this.temporaryTn.rcfTarget = this.huntGroup.rcfTarget;
        this.usingExistingTn = true;
        this.defaultTnPortedOrTransferred = true;
      }

      // TODO the available phone numbers taking long time to load so always new is updating so the
      // we are replacing this block
      //   this.huntGroupForm.get('huntGroupSection').patchValue(
      //       {
      //         phoneNumber: PHONE_NUMBER_ASSIGNMENTS[1]
      //       });
      if (this.availablePhoneNumbers && this.availablePhoneNumbers.length) {
        this.huntGroupForm.get('huntGroupSection').patchValue(
            {
              phoneNumber: this.isCreate ? PHONE_NUMBER_ASSIGNMENTS[1] : PHONE_NUMBER_ASSIGNMENTS[2]
            });
      } else {
        this.huntGroupForm.get('huntGroupSection').patchValue(
            {
              phoneNumber: PHONE_NUMBER_ASSIGNMENTS[2]
            });
      }

      this.nameElement.nativeElement.focus();
      this.updateHg = !this.isCreate;
      this.huntGroupForm.markAsPristine();
      console.log(this.huntGroupForm.value);
    }
  }

  getHuntGroup(id: number): Promise<any>  {
    const promise = new Promise<any>(async (resolve, reject) => {
      try {
        const hg = await this.huntgroupSvc.getHuntGroup(id);
        resolve(hg);
      } catch (reason) {
        reject(reason);
      }
    });
    return promise;
  }



  async initForm() {
    this.huntGroupForm = new FormGroup({
      huntGroupSection: new FormGroup({
        'name': new FormControl('New Hunt group', Validators.required),
        'location': new FormControl('', Validators.required),
        'phoneNumber': new FormControl(),
        'phoneNumberDigits': new FormControl(),
        'newPhoneNumberDigits': new FormControl(),
        'extension': new FormControl('', Validators.required),
        'makeExtPrivate': new FormControl(),
        'dailByName': new FormControl(),
        'ringMobileUsers': new FormControl(),
        'member': new FormControl({value: ''}),
        'ringStrategy': new FormControl('1'),
        'rings': new FormControl('3', Validators.required),
        'schedule': new FormControl('1'),
        'noAnswer': new FormControl('1'),
        'existingExt': new FormControl(),
        'cfBusy': new FormControl(),
        'cfNoAnswer': new FormControl(),
        'onHours': new FormControl(),
        'holiday': new FormControl(),
        'holiday2': new FormControl(),
        'cfOffHoursHoliday': new FormControl(''),
      })
    });
    this.setConditionalValidators();
    this.getCFDestinations();
    // await this.initLocationsList();

  }

  checkDirtyAndCall = (cb?, action: { collapse?: boolean, navigateAway?: boolean} = {}) => {
    // TODO reset
    if (this.tabbable && this.huntGroupForm.dirty) {
      if (this.closeReason === rightPanelCloseReason.LEAVE || this.closeReason === rightPanelCloseReason.COLLAPSE ) {
        cb && cb();
      } else if (this.closeReason === rightPanelCloseReason.STAY ||
          this.closeReason === rightPanelCloseReason.BACKDROP) {
        // Just close the dialog
      } else {
        this.openConfirmDialog(action);
      }
    } else if (this.tabbable && !this.toOpen) {
      cb && cb();
    }
  }

  onClose = () => {
    this.resetToOpen.emit();
    this.toOpen = false;
    this.checkDirtyAndCall(this.callOnClose);
  }

  onCancel($event) {
    this.resetToOpen.emit();
    this.toOpen = false;
    if (!this.isCreate) {
      this.checkDirtyAndCall(this.callOnCancel, {collapse: true});
    } else {
      this.onClose();
    }
    $event.stopImmediatePropagation();
  }

  callOnClose = (flowsUpdated = false) => {
    this.flowsSvc.setClickedFlow(null);
    this.close.emit(flowsUpdated);
  }

  callOnCancel = () => {
    this.collapse.emit();
  }

  allowNavigateAway = () => {
    this.flowsSvc.componentNavigateAway.next();
  }

  resetVisErrorFlags() {
    this.isVisError = false;
    this.wasIgnoreClicked = false;
  }

  async onDelete() {
    console.log('Delete hunt group');
    if (!this.modalRef) {

      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };

      this.deleteConfirmDisplayFlag = true;
      this.modalRef = this.modalSvc.open(DeleteConfirmationComponent, options);
      this.modalRef.result.then(async (result) => {
        this.modalRef = null;
        console.log(result);
        if (result === true) {
          await this.handleDelete();
        } else {
          this.toOpen = true; // keep the Edit side window open
        }
        this.deleteConfirmDisplayFlag = false;
      }, (reason) => {
        // this.closeReason = this.getDismissReason(reason);
        this.modalRef = null;
        this.deleteConfirmDisplayFlag = false;
        console.log(reason);
      });
    } else {
      console.log('modalRef not se to null.');
      this.modalRef = null;
    }
  }

  async handleDelete() {
    this.clearServerErrorOnForm();
    this.displayLoadingSpinner = true;
    // const hgName = this.huntGroup.name;
    await this.huntgroupSvc.deleteHuntGroup(this.huntGroup.id)
        .toPromise().then(
            response => {
              console.log('response from delete', response);
              if (response != null && response.length > 0) {
                this.parseDeleteError(response[0]);
                console.error(response);
                this.showServerErrorOnForm('Failed to delete Huntgroup');
              } else {
                // this.callOnClose(true);
                this.flowVizSvc.setSelectedFlow(this.huntGroup, flowComponentCRUDActionValues.delete);
                this.collapse.emit();
                this.insertMessage('hunt_group.deleted_hg');
              }
              this.deleteConfirmDisplayFlag = false;
            },
            error => {
              // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
              console.error('failed to delete huntgroup ', error);
              this.displayLoadingSpinner = false;
              this.showServerErrorOnForm( 'Unable to delete huntgroup: ' + error.message ? error.message : JSON.stringify(error));

            }
        );
    this.displayLoadingSpinner = false;
  }

  onAddMembers() {

    // const member = this.huntGroupForm.get('huntGroupSection.member').value;
    // const hgMember = _.cloneDeep(this.huntGroupForm.get('huntGroupSection.member').value);
    // code updated
    const member = this.currentSelectedMember; // to support new autocomplete integration
    const memberTextValue = _.split(this.huntGroupForm.get('huntGroupSection.member').value, ' : ');
    // condition will check the selected member and input textbox value matches or not
    if (!_.isEmpty(member) && memberTextValue[1] === member.description) {
      console.log('Add member', member);
      if (!this.huntGroupMembers) {
        this.huntGroupMembers = new Array();
      }
      if (this.findMember(member) < 0) {
        if (member.extension) {
          this.selectedMemberListDn.push(member.extension);
          this.huntGroupMembers.push(member);
        } else {
          const tenantPrefix = this.huntGroup.extension ? this.huntGroup.extension.split('-')[0] + '-' : '';
          const memObj = {
            extension: tenantPrefix + member,
            extensionFormatted: member,
            description: '',
          };
          this.selectedMemberListDn.push(member);
          this.huntGroupMembers.push(memObj);
        }
      }
      this.huntGroupForm.get('huntGroupSection').patchValue({
        member: '',
      });
      this.currentSelectedMember = {};
      this.showMembers = true;
    } else {
      console.log('Add member', 'Not valid');
    }
    this.checkMemberLimit();
  }

  findMember(member: any) {
    let extension = '';
    if (typeof member === 'string') {
      extension = member;
    } else {
      extension = member.extensionFormatted;
    }
    const result = this.huntGroupMembers.findIndex(m => {
      return m.extensionFormatted === extension;
    });
    return result;
  }

  onRemoveMember(member) {

    console.log('remove member', member);
    let hgIndex;
    if (member.extension) {
      hgIndex = this.huntGroupMembers.findIndex(mem => {
        return mem.extension === member.extension;
      });
    }
    if (hgIndex > -1) {
      this.huntGroupForm.markAsDirty();
      this.huntGroupMembers.splice(hgIndex, 1);
    }

    const dnIndex = this.selectedMemberListDn.findIndex(dnlist => {
      return dnlist === member.extension;
    });
    if (dnIndex > -1) {
      this.selectedMemberListDn.splice(dnIndex, 1);
    }

    if (this.huntGroupMembers.length === 0) {
      this.showMembers = false;
    } else {
      this.checkMemberLimit();
    }
  }

  displayExistingExtension(value: any) {

    if (value === '1') {
      this.existingExtnErr = '';
      this.showExistingExtn = true;
      this.showCustom = false;
      this.huntGroupForm.get('huntGroupSection.existingExt').patchValue('');
    } else if (value === '4') {
      this.cfBusyErr = '';
      this.cfNoAnswerErr = '';
      this.showCustom = true;
      this.showExistingExtn = false;
      this.huntGroupForm.get('huntGroupSection.cfNoAnswer').patchValue(this.huntGroupForm.get('huntGroupSection.existingExt').value);
      this.huntGroupForm.get('huntGroupSection.cfBusy').patchValue(this.huntGroupForm.get('huntGroupSection.existingExt').value);
    } else {
      this.showExistingExtn = false;
      this.showCustom = false;
      this.huntGroupForm.get('huntGroupSection.cfNoAnswer').patchValue('');
      this.huntGroupForm.get('huntGroupSection.cfBusy').patchValue('');
      this.huntGroupForm.get('huntGroupSection.existingExt').patchValue('');
    }
  }

  displaySchedule(value: any) {

    if (value === '1') {
      this.showSchedule = false;
      this.cfOffHoursErr = '';

    } else if (value === '2') {
      this.showSchedule = true;

    }
  }

  async onSubmit() {
    if (this.memberCountErr > 0) {
      return;
    }

    this.submitting = true;
    const isPhoneNumber = this.huntGroupForm.get('huntGroupSection').value.phoneNumber;
    if (!_.isEmpty(this.newPhoneNumber)) {
      this.huntGroupForm.get('huntGroupSection.phoneNumberDigits').patchValue(this.newPhoneNumber);
    }

    if (isPhoneNumber.value === PHONE_NUMBER_ASSIGNMENTS[1].value) {
      if (!this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').value.value) {
        this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
      }
    } else {
      this.resetErrorsForPhoneNumber();
    }
    // if (isPhoneNumber === PHONE_NUMBER_ASSIGNMENTS[1] || isPhoneNumber === PHONE_NUMBER_ASSIGNMENTS[2]) {
    //   if (!this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').value) {
    //     this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
    //   } else {
    //     this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: false});
    //   }
    // }

    // if (!this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').value) {
    //   this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
    // } else {
    //   if (!this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').value.value) {
    //     this.huntGroupForm.get('huntGroupSection').get('phoneNumberDigits').setErrors({invalidPhone: true});
    //   }
    // }


    const extension = this.huntGroupForm.get('huntGroupSection').value.extension;
    if (extension != null && extension.length === this.phSvc.clusterBasedExtensionLength.value && this.extnErrMessage === '') {
      this.clearServerErrorOnForm();
      if (this.huntGroupForm.valid) {
        // const isPhoneNumber = this.huntGroupForm.get('huntGroupSection').value.phoneNumber;
        if (this.huntGroup && this.huntGroup.id != null) {
          console.log('Update Hunt Group');
          const params = this.getHuntGroupParams();
          if (_.isEmpty(this.newPhoneNumber)) {
            await this.updateHuntGroup(params);
          } else {
            await this.placeOrder(params, 'update', this.translated.error_messages.update_hunt_group);
          }
        } else {
          console.log('Create Hunt Group');
          const params = this.getHuntGroupParams();
          if (isPhoneNumber.value === PHONE_NUMBER_ASSIGNMENTS[2].value) {
            await this.placeOrder(params, 'create', this.translated.error_messages.create_hunt_group);
          } else {
            await this.createHuntGroup(params);
          }
        }
      } else {
        this.validateAllFormFields(this.huntGroupForm);
      }
    }
  }

  async updateHuntGroup(params) {
    this.displayLoadingSpinner = true;
    let formattedPhoneNumber = null;
    if (params != null && params.tnId != null) {
      formattedPhoneNumber = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.tnId, params.tnCountryId);
    }
    if (params != null && params.rcfTarget != null) {
      params.rcfTarget = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.rcfTarget, params.tnCountryId);
    }
    await this.huntgroupSvc.updateHuntGroup(this.huntGroup.id, params, formattedPhoneNumber)
        .toPromise().then(
            response => {
              this.huntGroup = response;
              if (this.huntGroup.errors != null && this.huntGroup.errors !== '') {
                console.error(this.huntGroup.errors);
                this.displayLoadingSpinner = false;
                this.showServerErrorOnForm('Failed to update Huntgroup');
                this.parseServerError(this.huntGroup.errors);
                this.shoppingCartService.placeOrderDetails.next('');
              } else {
                this.insertMessage('hunt_group.updated_hg');
                this.flowVizSvc.setSelectedFlow(this.huntGroup, flowComponentCRUDActionValues.update);
                this.collapse.emit();
                this.canDeleteHg = true;
                this.displayLoadingSpinner = false;
              }
            },
            error => {
              // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
              console.error('failed to update huntgroup', error);
              this.displayLoadingSpinner = false;
              this.clModalService.sendIsModalOpen(false);
              this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                this.showServerErrorOnForm( 'Unable to update huntgroup: ' +  BossApiUtils.extractErrorMessage(error));
              }));
            }
        );
    this.submitting = false;
  }

  async createHuntGroup(params) {
    this.displayLoadingSpinner = true;
    let formattedPhoneNumber = null;
    if (params != null && params.tnId != null) {
      formattedPhoneNumber = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.tnId, params.tnCountryId);
    }
    if (params != null && params.rcfTarget != null) {
      params.rcfTarget = await this.phoneNumberFormatService
          .formatE164PhoneNumberUsingIso3166NumericCountryCode(params.rcfTarget, params.tnCountryId);
    }
    await this.huntgroupSvc.createHuntGroup(params, formattedPhoneNumber)
        .toPromise().then(
            response => {
              this.huntGroup = response;
              if (this.huntGroup.errors != null && this.huntGroup.errors !== '') {
                console.error(this.huntGroup.errors);
                this.displayLoadingSpinner = false;
                this.showServerErrorOnForm('Failed to create Huntgroup');
                this.parseServerError(this.huntGroup.errors);
                this.shoppingCartService.placeOrderDetails.next('');
              } else {
                this.insertMessage('hunt_group.created_hg');
                this.flowVizSvc.setSelectedFlow(this.huntGroup, flowComponentCRUDActionValues.update);
                this.collapse.emit();
                this.canDeleteHg = true;
                this.displayLoadingSpinner = false;
              }
            },
            error => {
              // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
              console.error('failed to create huntgroup', error);
              this.displayLoadingSpinner = false;
              this.clModalService.sendIsModalOpen(false);
              this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
                this.showServerErrorOnForm( 'Unable to create huntgroup: ' +  BossApiUtils.extractErrorMessage(error));
              }));
            }
        );
    this.submitting = false;
  }

  async placeOrder(params, action, errorMsg) {
    const reviewModalRef = this.modalService.open(ReviewOrderComponent, {
      mode: ReviewOrderMode.LOCAL_NUMBERS,
      orderDetails: this.shoppingCartService.generateOrderPreview(
          this.placeOrderDetails
      )
    }, {
      windowClass : 'order-preview'
    });
    this.clModalService.sendIsModalOpen(true);
    reviewModalRef.result.then(async (result) => {
      console.log('Order reviewed successfully');
      await this.placeOrderAfterReview(result, params, action, errorMsg);
    },
        () => {
          console.log('Review modal Closed');
        });
  }

  async placeOrderAfterReview(reviewResult, params, action, errorMsg) {
    if (reviewResult) {
      this.displayLoadingSpinner = true;
      const returnObs = await this.tnAdapterSvc.placeOrder(
          [this.newPhoneNumber], this.newPhoneNumber.locationUuid).toPromise()
          .catch(error1 => {
            console.log('The telephone number is unvailable for ordering', error1);
            this.displayLoadingSpinner = false;
            this.showServerErrorOnForm(errorMsg + ': ' + BossApiUtils.extractErrorMessage(error1));
          });
      if (returnObs && returnObs.status === 'COMPLETE') {
        console.log(`The order with order id ${returnObs.order_id} was placed`);
        if (action === 'create') {
          await this.createHuntGroup(params);
        } else {
          await this.updateHuntGroup(params);
        }
      } else if (returnObs && returnObs.errors) {
        console.log('The telephone number is unvailable for ordering');
        this.displayLoadingSpinner = false;
        this.clModalService.sendIsModalOpen(false);
        this.showServerErrorOnForm(errorMsg + ': ' + returnObs.errors);
      }
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    console.log('Validate all form fields');
    Object.keys(formGroup.controls).forEach( field => {
      const control = formGroup.get(field);
      if ( control instanceof FormControl ) {
        control.markAsTouched( {onlySelf: true} );
        console.log('check control:', field, control.status, control.value);
      } else if ( control instanceof FormGroup ) {
        this.validateAllFormFields( control );
      }
    });
  }

  getHuntGroupParams() {
    const input = this.huntGroupForm.get('huntGroupSection').value;

    this.huntGroup.errors = null;
    const tenantPrefix = this.huntGroup.extension ? this.huntGroup.extension.split('-')[0] + '-' : '';
    this.huntGroup.name = input.name;
    this.huntGroup.locationName = input.location.displayName;
    this.huntGroup.locationUUID = input.location.value;
    this.huntGroup.phoneNumber = input.pnumber;
    this.huntGroup.extensionFormatted = input.extension;
    this.huntGroup.extension = tenantPrefix + input.extension;
    this.huntGroup.makeNumberPrivate = input.makeExtPrivate;
    this.huntGroup.includeInDialByName = input.dailByName;
    this.huntGroup.ringMobileUsers = input.ringMobileUsers;
    this.huntGroup.huntPatternID = input.ringStrategy;
    this.huntGroup.ringsPerMember = input.rings;
    // this.huntGroup.noAnswerRings = input.rings;
    this.huntGroup.callForwardOption = input.noAnswer;
    // Ring Strategy Calculation
    if (input.ringStrategy === '1' && this.huntGroupMembers.length > 0) {
      // One at a time, top to bottom
        this.huntGroup.noAnswerRings = input.rings * this.huntGroupMembers.length;
    } else {
      // Ring everyone at once or One at a time, top to bottom or with huntgroup members is 0
      this.huntGroup.noAnswerRings = input.rings;
    }

    if (typeof input.cfOffHoursHoliday === 'string') {
      this.huntGroup.cfOffHoursHoliday = input.cfOffHoursHoliday === '' ? null : tenantPrefix + input.cfOffHoursHoliday;
      this.huntGroup.cfOffHoursHolidayFormatted = input.cfOffHoursHoliday === '' ? null : input.cfOffHoursHoliday;
    } else {
      this.huntGroup.cfOffHoursHolidayFormatted = input.cfOffHoursHoliday ? input.cfOffHoursHoliday.extensionFormatted : null;
      this.huntGroup.cfOffHoursHoliday = input.cfOffHoursHoliday ? input.cfOffHoursHoliday.extension : null;
    }

    if (input.schedule === '1') {
      this.huntGroup.ringAllMembers = true;
      this.huntGroup.onHoursScheduleId = null;
      this.huntGroup.holidayScheduleId = null;
      this.huntGroup.cfOffHoursHoliday = null;
      this.huntGroup.cfOffHoursHolidayFormatted = '';
    } else {
      this.huntGroup.ringAllMembers = false;
      this.huntGroup.onHoursScheduleId = input.onHours ? input.onHours.value : 'none';
      this.huntGroup.holidayScheduleId = input.holiday ? input.holiday.value : 'none';
    }

    if (input.noAnswer === '1') {
      if (typeof input.existingExt === 'string') {

        this.huntGroup.cfBusyFormatted = input.existingExt;
        this.huntGroup.cfBusy = tenantPrefix + input.existingExt;
        this.huntGroup.cfNoAnswerFormatted = input.existingExt;
        this.huntGroup.cfNoAnswer = tenantPrefix + input.existingExt;
      } else {
        this.huntGroup.cfBusyFormatted = input.existingExt ? input.existingExt.extensionFormatted : null;
        this.huntGroup.cfBusy = input.existingExt ? input.existingExt.extension : null;
        this.huntGroup.cfNoAnswerFormatted = input.existingExt ? input.existingExt.extensionFormatted : null;
        this.huntGroup.cfNoAnswer = input.existingExt ? input.existingExt.extension : null;
      }

    } else if (input.noAnswer === '4') {
      if (typeof input.cfBusy === 'string') {
        this.huntGroup.cfBusyFormatted = input.cfBusy;
        this.huntGroup.cfBusy = tenantPrefix + input.cfBusy;
      } else {
        this.huntGroup.cfBusyFormatted = input.cfBusy ? input.cfBusy.extensionFormatted : null;
        this.huntGroup.cfBusy = input.cfBusy ? input.cfBusy.extension : null;
      }
      if (typeof input.cfNoAnswer === 'string') {
        this.huntGroup.cfNoAnswerFormatted = input.cfNoAnswer;
        this.huntGroup.cfNoAnswer = tenantPrefix + input.cfNoAnswer;
      } else {
        this.huntGroup.cfNoAnswerFormatted = input.cfNoAnswer ? input.cfNoAnswer.extensionFormatted : null;
        this.huntGroup.cfNoAnswer = input.cfNoAnswer ? input.cfNoAnswer.extension : null;
      }
    } else {
      this.huntGroup.cfBusyFormatted = null;
      this.huntGroup.cfBusy = null;
      this.huntGroup.cfNoAnswerFormatted = null;
      this.huntGroup.cfNoAnswer = null;
    }

    this.huntGroup.huntGroupMembers = this.huntGroupMembers;
    this.huntGroup.onHourSchedules = this.onHoursSchedules;
    this.huntGroup.holidaySchedules = this.holidaySchedules;

    if (input.phoneNumberDigits && input.phoneNumberDigits.value !== '') {
      this.huntGroup.tnId = input.phoneNumberDigits.value;
      this.huntGroup.tnCountryId = input.phoneNumberDigits.countryId;
    }
    if ( (this.temporaryTn != null) && (this.temporaryTn.rcfTarget) && (this.temporaryTn.rcfTarget.length > 0) ) {
      this.huntGroup.rcfTarget = this.temporaryTn.rcfTarget;
      this.huntGroup.managementGUID = this.temporaryTn.mgmtGUID;
    }
    if (!_.isEmpty(this.newPhoneNumber)) {
      this.huntGroup.tnId = this.newPhoneNumber.value;
      this.huntGroup.tnCountryId = this.newPhoneNumber.countryId;
    }
    return this.huntGroup;
  }

  async validateExtension(extn: string) {
    console.log('Validate Extension');
    this.extnErrMessage  = '';

    if ((extn !== '' && this.huntGroup.id == null) ||
        (extn !== '' && this.huntGroup.id !== null &&
            this.huntGroup.extensionFormatted !== extn)) {

      await this.huntgroupSvc.validateExtension(extn).toPromise().then (
          response => {

            if (!response.isValid && response.errorMessage) {
              this.extnErrMessage = response.errorMessage;
            }
            if (this.extnErrMessage !== '') {
              this.huntGroupForm.controls['huntGroupSection'].get('extension').setErrors({'invalidExtension': true});
              this.huntGroupForm.updateValueAndValidity();
            }
          },
          error => {
            console.error('failed to validate extension', error);
            this.subscriptions.push(this.translateSvc.get('error_messages').subscribe(err => {
              this.showServerError( 'validate extension. ' + BossApiUtils.extractErrorMessage(error));
            }));
          }
      );

    }
  }

  scheduleOnChange(field: string) {
    if (field === 'holiday') {
      this.huntGroupForm.controls['huntGroupSection'].get('cfOffHoursHoliday').setErrors(null);
      const holiday = this.huntGroupForm.get('huntGroupSection.holiday').value;
      const offHoursDest = this.huntGroupForm.get('huntGroupSection.cfOffHoursHoliday').value;

      if (holiday && holiday.value !== 'none' && holiday.value !== 'new' && (offHoursDest === null || offHoursDest === '')) {
        this.huntGroupForm.controls['huntGroupSection'].get('cfOffHoursHoliday').markAsTouched();
        this.huntGroupForm.controls['huntGroupSection'].get('cfOffHoursHoliday').setErrors({'required': true});
        this.huntGroupForm.updateValueAndValidity();
      }
      if (holiday.value === 'new') {
        this.onNewHolidaySchedule();
      }
    } else if (field === 'onHours') {
      const onHours = this.huntGroupForm.get('huntGroupSection.onHours').value;
      if (onHours && onHours.value === 'new') {
        this.onNewOnHoursSchedule();
      }
    }
  }

  checkMemberLimit() {
    // console.log('checkMemberLimit ---');
    const input = this.huntGroupForm.get('huntGroupSection').value;
    // console.log(input.ringMobileUsers);

    if (input.ringMobileUsers && this.huntGroupMembers.length > 8) {
      this.memberCountErr = 8;
    } else if (!input.ringMobileUsers && this.huntGroupMembers.length > 16) {
      this.memberCountErr = 16;
    } else {
      this.memberCountErr = 0;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedMemberListDn, event.previousIndex, event.currentIndex);
    moveItemInArray(this.huntGroupMembers, event.previousIndex, event.currentIndex);
    console.log('huntgroups', this.huntGroupMembers);
    console.log('huntgroups', this.selectedMemberListDn);
  }

  openConfirmDialog(action: { collapse?: boolean, navigateAway?: boolean} = {}) {
    if (!this.modalRef) {
      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        windowClass: 'confimation-box'
      };
      this.modalRef = this.modalSvc.open(NavigationConfirmationComponent, options);
      this.modalRef.result.then((result) => {
        this.modalRef = null;
        this.closeReason = this.getDismissReason(result, action);
        this.checkDirtyAndCall(this.callOnClose);
      }, (reason) => {
        this.closeReason = this.getDismissReason(reason, action);
        this.modalRef = null;
        this.checkDirtyAndCall(this.callOnClose);
      });
    }
  }

  getDismissReason(reason: any, action: { collapse?: boolean, navigateAway?: boolean } = {}): string {
    if (reason === ModalDismissReasons.ESC) {
      return rightPanelCloseReason.ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return rightPanelCloseReason.BACKDROP;
    } else if (reason === 'leave') {
      if (action.collapse) {
        return rightPanelCloseReason.COLLAPSE;
      } else if (action.navigateAway) {
        return rightPanelCloseReason.NAVIGATEAWAY;
      } else {
        return reason;
      }
    } else {
      return reason;
    }
  }

  onNewOnHoursSchedule() {
    console.log('on-hours schedule');
    this.deleteConfirmDisplayFlag = true;
    if (!this.modalRef) {

      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        windowClass : 'hours-schedule-custom'
      };

      this.modalRef = this.modalSvc.open(OnHoursScheduleComponent, options);
      this.modalRef.componentInstance.popUp = true;
      this.modalRef.componentInstance.dialogHeader = 'on_hours.add_title';
      this.modalRef.result.then(async (result) => {
        this.modalRef = null;
        this.onHoursSchedules.push({ value: result.id, displayName: result.name });
        this.huntGroupForm.get('huntGroupSection').patchValue({onHours: {value: result.id}});
      }, (reason) => {
        // this.closeReason = this.getDismissReason(reason);
        this.modalRef = null;
        console.log(reason);
      });
    } else {
      console.log('modalRef not se to null.');
      this.modalRef = null;
    }
  }

  onNewHolidaySchedule() {
    console.log('Holiday schedule');
    this.deleteConfirmDisplayFlag = true;
    if (!this.modalRef) {

      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        windowClass : 'hours-schedule-custom'
      };

      this.modalRef = this.modalSvc.open(HolidayScheduleComponent, options);
      this.modalRef.componentInstance.popUp = true;
      this.modalRef.componentInstance.dialogHeader = 'holiday.add_title';
      this.modalRef.result.then(async (result) => {
        this.modalRef = null;
        this.holidaySchedules.push({ value: result.id, displayName: result.name });
        this.huntGroupForm.get('huntGroupSection').patchValue({holiday: {value: result.id}});
      }, (reason) => {
        // this.closeReason = this.getDismissReason(reason);
        this.modalRef = null;
        console.log(reason);
      });
    } else {
      console.log('modalRef not se to null.');
      this.modalRef = null;
    }
  }

  ngOnDestroy() {
    this.tnAdapterSvc.wasNewPhoneNumberPreviouslyFetched = false;
    this.shoppingCartService.placeOrderDetails.next('');
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.rxjsSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  insertMessage(msg) {
    this.notificationService.updateMessage(msg);
  }

  showServerError( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  showServerErrorOnForm( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerErrorOnForm() {
    this.errMsg = '';
    this.showFormError = false;
    this.existingExtnErr = '';
    this.dnErr = '';
    this.ringsPerMemberErr = '';
    this.huntPatternIDErr = '';
    this.otherErr = [];
    this.cfBusyErr = '';
    this.cfNoAnswerErr = '';
    this.cfOffHoursErr = '';
  }

  parseServerError( message: string ) {
    const splitMsg =  message.split('Error:');

    this.errorMsg = splitMsg[1] ? splitMsg[1].split('\r\n') : [];
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(err => {
        this.showErrorMessage(err);
      });
    }
  }

  showErrorMessage( message ) {
    const selectedOption = this.huntGroupForm.get('huntGroupSection.noAnswer').value;
    const msg = message.split('-');

    if (msg[0].trim() === 'RingsPerMember') {
      this.ringsPerMemberErr = msg[1];
    } else if (msg[0].trim() === 'HuntPatternID') {
      this.huntPatternIDErr = msg[1];
    } else if (msg[0].trim() === 'dn.DN') {
      this.dnErr = msg[1];
    } else if (msg[0].trim() === 'dns') {
      this.membersErr = msg[1];
    } else if (msg[0].trim() === 'CFOffHoursHoliday') {
      this.cfOffHoursErr = msg[1];
    } else if (selectedOption === '1' && msg[0].trim() === 'CFBusy') {
      this.existingExtnErr = msg[1];
    } else if (selectedOption === '4' && msg[0].trim() === 'CFBusy') {
      this.cfBusyErr = msg[1];
    } else if (selectedOption === '4' && msg[0].trim() === 'CFNoAnswer') {
      this.cfNoAnswerErr = msg[1];
    } else if ((selectedOption === '1' && msg[0].trim() !== 'CFNoAnswer') && msg[0].trim() !== 'NoAnswerRings') {
      this.otherErr.push(message);
    }
  }

  parseDeleteError( message) {
    const msg = message.split('message": "');

    const err =  msg[1];
    const extractedMessage = msg[1].split('",')[0];
    this.otherErr.push(extractedMessage);
  }

  displayShoppingCart(turnupProductId: number) {
    if (!turnupProductId) {return; }

    // put up the shopping cart

    const products: Array<QuotePriceProductDC> = [];
    products.push({
      quantity: 1,
      productId: turnupProductId
      /**
       * Parent product ID
       * To quote profile service addon price, this field must be set to phone bundle product ID
       */
      // dependentOnProductId?: number;
    });

    const quotePriceRequestDC: QuotePriceRequestDC = {
      /**
       * Purchase effective date
       * Optional
       * If omitted, defaults to today
       */
      // effectiveDate?: string;

      /**
       * Products to quote
       * Array<QuotePriceProductDC>
       */
      products: products
    };

    this.subscriptions.push(
        this.productsServices.ProductsQuotePurchasePrice(
        { request: quotePriceRequestDC, locationUuid: this.phSvc.getSelectedLocation(),
          Authorization: null }).subscribe(
        (billingImpactDC: BillingImpactDC) => {
          // console.log('displayShoppingCart  billingImpactDC=', billingImpactDC);
          this.shoppingCartService.placeOrderDetails.next(billingImpactDC);
          this.placeOrderDetails = billingImpactDC;
          this.shoppingCartService.generateOrderInvoice((<BillingImpactDC>billingImpactDC),
              this.translateSvc.instant('cart_invoice_messages.add_order_details'));
        },
        error => {
          console.error('ProductsQuotePurchasePrice() failed1: ', error);
          this.showServerErrorOnForm(BossApiUtils.extractErrorMessage(error));
        }
    ));
  }

  setValidExtension(ext) {
    let newExt = ext;
    this.phSvc.isExtensionValid(newExt).then(validExt => {
      this.extnErrMessage = '';
      if (!validExt.isValid) {
        if (!_.isEmpty(validExt.suggestedExtension)) {
          newExt = validExt.suggestedExtension;
        } else {
          this.extnErrMessage = validExt.errorMessage;
        }
      }
      if (this.extnErrMessage === '') {
        this.huntGroupForm.controls['huntGroupSection'].get('extension').setErrors({'invalidExtension': null});
        this.huntGroupForm.updateValueAndValidity();
      }
      this.huntGroupForm.get('huntGroupSection').patchValue({
        extension: newExt
      });
    });
  }

  updateSelectedItem(data, field, member = false) {
    const extensionData = this.displayFn(data);
    this.huntGroupForm.get(field).setValue(extensionData);
    if (member) {
      this.currentSelectedMember = data;
    } else {
      this.currentSelectedMember = {};
    }
  }
}
