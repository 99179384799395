<div class="container" [ngClass]="{'container_non_pop': !popUp}">
    <div class="row top-header" [ngClass]="{'title-row-non-pop': !popUp}">
        <div class="col-12 title_non_popup" *ngIf="!popUp">
            {{extension? extension.name : ('extensions.new_title' | translate)}}
        </div>
        <div class="col-10 title" *ngIf="popUp">
            {{'extensions.add_title' | translate}}
        </div>
        <div class="col-2 header-close" *ngIf=popUp>
            <button *ngIf=popUp id="on-hours-close" type="button" class="close closeX" aria-label="Close"
                    (click)="cancel()"
                    aria-hidden="true">
                &#10005;
            </button>
        </div>
    </div>
    <div *ngIf="showFormError" class="errorPlacement">
        <cl-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></cl-error-alert>
    </div>
    <div *ngFor="let err of otherErr" class="text-danger error-align"> {{err}} </div>
    <form [formGroup]="extensionsForm" (ngSubmit)="onSubmit()" id="extensionPanel">
        <div formGroupName="extensionSection" class="content-scroll-block" [ngClass]="{'extension-content-non-popup': !popUp}">
            <div class="row content-1">
                <div class="row">
                    <div class="form-group col-11 name">
                        <label for="name">{{ 'extensions.name' | translate }}</label>
                        <input type="text"
                               formControlName="name"
                               [attr.tabindex]="tabbable ? 0 : -1"
                               maxlength="50"
                               class="form-control schedules-non-popup-width">
                        <div class="text-danger"
                             *ngIf="extensionsForm.get('extensionSection.name').touched && extensionsForm.get('extensionSection.name').hasError('required')">
                            {{ 'extensions.name_required' | translate }}
                        </div>
                        <div class="text-danger" *ngIf="extensionNameError != ''"> {{ extensionNameError }} </div>
                    </div>
                </div>
                <div class="row available-extensions-block">
                    <div class="form-group name">
                        <label for="name">{{ 'extensions.available_extensions' | translate }}</label>
                        <div class="available-extension-text form-group">
                            {{ 'extensions.select_sentence' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row content-2" [ngClass]="{'content-2-non-popup': !popUp}">
                <div class="row dropdown-block">
                    <div class="form-group col-11">
                        <app-dropdown-with-selected-count [items]="items"
                                                          [checkboxValue]="dropdownMainCheckBox"
                                                          [minusBlock]="minimumSelected"
                                                          [selectedValue]="reFormatSelectedExtensionsCountName()"
                                                          [manualWidth]="true"
                                                          id="existingPhoneNumbers" class="phone-number-dropdown"
                                                          [tabbable]="tabbable"
                                                          (checkboxUpdate)="onCheckboxUpdate($event)" ngDefaultControl>
                        </app-dropdown-with-selected-count>
                    </div>
                </div>
                <div class="row extension-block">
                    <div class="extension-list col-12" formArrayName="availableExtensions" *ngFor="let extension of extensionsForm.get('extensionSection').get('availableExtensions')['controls']; let i = index">
                        <div class="row" [formGroupName]="i">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox"
                                           id="repeatYearly{{i}}"
                                           class="form-control"
                                           [attr.tabindex]="tabbable ? 0 : -1"
                                           formControlName="enable"
                                           (click)="selectExtensions(extension)"
                                           (keyup.esc)="$event.preventDefault(); onClose()">
                                    <span class="checkmark"></span>
                                    <label for="repeatYearly{{i}}" class="form-check-label">
                                        {{extensionsForm.get('extensionSection').get('availableExtensions').controls[i].value.name}}
                                    </label>
                                    <span class="extension-top">x{{extensionsForm.get('extensionSection').get('availableExtensions').controls[i].value.id}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" [ngClass]="{'footer-non-popup': !popUp}">
            <div class="form-group col-12 footer-block"
            [ngClass]="{'footer-block-less-padding': popUp}">
                <div *ngIf="updateExtension" class="delete-action">
                    <button
                            type="button"
                            class="btn btn-default delete-hg"
                            (click)="onDelete()">
                        <img class="icon-delete-button" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAQABADASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAII/8QAHxAAAgICAgMBAAAAAAAAAAAAAQMCEQQSACEiMlET/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANIZCzkFscl85LW8LENIkeQFX137cY6zj/lHGyJhbMiS5R0iBYBuuuvXl5Rgp74tZBQm5bomZoEDW6P3xPGMVteiKWQbo9jpGBsAHarP3yHA/9k="
                             alt="delete">{{ 'extensions.delete' | translate }}
                    </button>
                </div>

                <div class="pull-right actions">
                    <button
                            type="button"
                            class="btn btn-default"
                            [attr.tabindex]="tabbable ? 0 : -1"
                            (click)="cancel()">{{ 'extensions.cancel' | translate }}</button>
                    <button *ngIf="!updateExtension"
                            type="submit"
                            class="btn btn-primary" style="width:170px"
                            [attr.tabindex]="tabbable ? 0 : -1">{{ 'extensions.add' | translate }}</button>
                    <button *ngIf="updateExtension"
                            type="submit"
                            class="btn btn-primary"
                            [attr.tabindex]="tabbable ? 0 : -1">{{ 'extensions.update' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
</div>

<app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" [customPosition]="{ top: '45%', left: '22%' }" message="{{ 'app.loading' | translate}}"></app-spinner>
