/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClusterDC } from '../models/cluster-dc';
import { ClusterSystemDetailDC } from '../models/cluster-system-detail-dc';
@Injectable({
  providedIn: 'root',
})
class ClustersService extends __BaseService {
  static readonly ClustersGetClusterPath = '/api/Clusters/{clusterId}';
  static readonly ClustersGetClusterDetailPath = '/api/Clusters/{clusterId}/SystemDetail';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: EditPBX (190)
   * @param params The `ClustersService.ClustersGetClusterParams` containing the following parameters:
   *
   * - `clusterId`: Cluster Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ClustersGetClusterResponse(params: ClustersService.ClustersGetClusterParams): __Observable<__StrictHttpResponse<ClusterDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Clusters/${params.clusterId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterDC>;
      })
    );
  }
  /**
   * Required Permission: EditPBX (190)
   * @param params The `ClustersService.ClustersGetClusterParams` containing the following parameters:
   *
   * - `clusterId`: Cluster Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ClustersGetCluster(params: ClustersService.ClustersGetClusterParams): __Observable<ClusterDC> {
    return this.ClustersGetClusterResponse(params).pipe(
      __map(_r => _r.body as ClusterDC)
    );
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `ClustersService.ClustersGetClusterDetailParams` containing the following parameters:
   *
   * - `clusterId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ClustersGetClusterDetailResponse(params: ClustersService.ClustersGetClusterDetailParams): __Observable<__StrictHttpResponse<ClusterSystemDetailDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Clusters/${params.clusterId}/SystemDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterSystemDetailDC>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `ClustersService.ClustersGetClusterDetailParams` containing the following parameters:
   *
   * - `clusterId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ClustersGetClusterDetail(params: ClustersService.ClustersGetClusterDetailParams): __Observable<ClusterSystemDetailDC> {
    return this.ClustersGetClusterDetailResponse(params).pipe(
      __map(_r => _r.body as ClusterSystemDetailDC)
    );
  }
}

module ClustersService {

  /**
   * Parameters for ClustersGetCluster
   */
  export interface ClustersGetClusterParams {

    /**
     * Cluster Id
     */
    clusterId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ClustersGetClusterDetail
   */
  export interface ClustersGetClusterDetailParams {
    clusterId: number;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { ClustersService }
