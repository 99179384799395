import * as _ from 'lodash';
import {Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef} from '@angular/core';

@Component({
  selector: 'app-dropdown-with-selected-count',
  templateUrl: './cl-dropdown-with-selection.component.html',
  styleUrls: ['./cl-dropdown-with-selection.component.scss'],
})

export class ClDropdownWithSelectionComponent implements OnInit {

  @Input() items: any[];
  @Input() selectedValue = 'None Selected';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() id = 'custom-dropdown-header';
  @Input() tabbable = true;
  @Input() manualWidth = false;
  @Input() checkboxValue = false;
  @Input() minusBlock = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Output() dropdownChange: EventEmitter<any> = new EventEmitter();
  @Output() checkboxUpdate: EventEmitter<any> = new EventEmitter();
  public onChange: any = Function.prototype; // Trascend the onChange event
  public onTouched: any = Function.prototype; // Trascent the onTouch event
  private _value: any;
  private itemValues: Array<any>;
  selectedItem = '';
  selectedItemIdx = 0;
  showMenu = false;
  onFocused = false;
  constructor(private el: ElementRef) {}

  ngOnInit() {
  }

  updateCheckBoxValue(event) {
    this.checkboxUpdate.emit(event.target.checked);
  }

  updateCheckBoxValueByMinusBlock() {
    this.checkboxUpdate.emit(true);
  }
}
