<div *ngIf="count > 0" class="tooltipcontainer" >

  <!-- The button. Click this button to display/hide the tooltip body that is below  -->
  <div class="float-right">
    <button type="button" style="cursor: pointer" class="cart-not-empty" (click)="onSubmit()">
      <span class="quantity badge">{{count}}</span>
      <img src="../../../../assets/images/Receipt.png" alt="Receipt"/>
      <span class="price">{{msg}}</span>
    </button>
  </div>

  <!-- the tooltip body  -->
  <div *ngIf="showCartContent" class="tooltiptext" style="width:379px; background-color: #273142; color: #FFFFFF;">
    <user-invoice-small [invoice]="invoice"></user-invoice-small>
    <div *ngIf="shouldShowCommitment">
      <app-commitment-chart
          [currentProfileAmount]="getCurrentProfileAmount()"
          [contractProfileAmount]=contractProfileAmount
          [availableContractsAllowed]="getAvailableContractsAllowed()"
          [countingCurrent]="countingCurrent"
          [countingOnEqualAmounts]="whenEqualAmounts"
          [showChart]="showCommitmentChart">
      </app-commitment-chart>
  </div>
  </div>
</div>
